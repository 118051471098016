/*eslint-disable*/
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import "./common-header.css"
import './header.css'
import {clearApiErrorMessage, logout, logoutAccount} from "../../../actions/account/login-actions";
import {doNotGoToRegistration} from '../../../actions/account/registration-action';
const ENV_VAR= process.env.REACT_APP_STATIC_PAGE === undefined ? "https://www.xenonstack.jobs": process.env.REACT_APP_STATIC_PAGE;

class Head extends Component {
    toggleHeader(e) {
        const navMenu = document.querySelectorAll(".navigation-menu");
        for (let i = 0; i < navMenu.length; i++) {
            navMenu[i].classList.toggle("Navbar__ToggleShow");
        }
    }

    hideToggleHeader() {
        document.getElementsByClassName("navigation-menu nav-left")[0].classList.remove("Navbar__ToggleShow");
        document.getElementsByClassName("navigation-menu nav-right")[0].classList.remove("Navbar__ToggleShow");
    }

    componentWillMount() {
        this.props.dispatch(clearApiErrorMessage())
    }

    openSignoutAccountDialog() {
        document.getElementsByClassName("overlay-delete")[0].style.display = "block";
    }

    closeSignoutAccountDialog() {
        
        if (!!document.getElementsByClassName("overlay-delete")[0]) {
            document.getElementsByClassName("overlay-delete")[0].style.display = "none";
        }
    }

    signout(e) {
        e.preventDefault();
        this.props.dispatch(logoutAccount());
        if (!!document.getElementsByClassName("overlay-delete")[0]) {
            document.getElementsByClassName("overlay-delete")[0].style.display = "none";
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.logoutMe === true) {
            nextProps.dispatch(logout());
        }
    }
    skipRegistration(link) {
        this.props.dispatch(doNotGoToRegistration());
        this.props.history.push(link);
    }

    render() {
        return (
            <>  
                <header className="user-header header fixed" id="user-header">
                    <div className="main-container">
                        <nav className="user-navigation-bar" id="user-navigation-bar">
                            <div class="brand-wrapper">
                                <div class="brand-image">
                                    <a href={ENV_VAR} id="brand-logo">
                                    <figure><img src={require("../../../static/images/logoxe.svg")} alt="XenonStack Careers Logo"/></figure>
                                    </a>
                                </div>
                            </div>
                            <div className="toggle-topMenu" onClick={this.toggleHeader.bind(this)} id="toggle-topMenu">
                                <div className="one"></div>
                                <div className="two"></div>
                                <div className="three"></div>
                            </div>
                            {
                            <div className="navigation-menu nav-left" onClick={this.hideToggleHeader.bind(this)}>
                                {
                                    (this.props.location.pathname !== "/drive/test/aptitude-test" && 
                                    this.props.location.pathname !== "/drive/test/instruction") &&
                                    <ul className="navbar-menu" id="navigation-menu-left">
                                        <li>
                                            <a href={ENV_VAR+"jobs/"} 
                                            className="nav-links">Jobs</a>
                                        </li>
                                        <li>
                                            <a href={ENV_VAR+"xenonstack-life/how-we-hire/"} 
                                            className="nav-links">Hiring Process</a>
                                        </li>
                                    </ul>
                                }
                            </div>
                            }
                            {
                            <div className="navigation-menu nav-right" onClick={this.hideToggleHeader.bind(this)}>
                                {
                                    (!this.props.state.RegisterReducer.goToRegistration && !this.props.state.LoginReducer.auth.isAuthenticated 
                                    && this.props.location.pathname !== "/login") &&
                                    <ul className="navbar-menu" id="navigation-menu-right">
                                        <li>
                                            <Link className="nav-links boxed-links" to="/login">Sign In</Link>
                                        </li>
                                    </ul>
                                }
                                {
                                    (this.props.isAuthenticated && this.props.location.pathname !== "/drive/test/aptitude-test" 
                                    && this.props.location.pathname !== "/drive/test/instruction") &&
                                    <ul className="navbar-menu" id="navigation-menu-right">
                                        <li>
                                            <a className="nav-links" href={ENV_VAR+"students-and-graduates/"} target="_blank">
                                            <i className="material-icons" style={{fontSize: "20px"}}>home</i> Careers home</a>
                                        </li>
                                        <li>
                                            <Link to="/feedback" className="nav-links"><i className="material-icons">feedback</i> Feedback</Link>
                                        </li>
                                        <li className="profile-nav">
                                            <Link className="nav-links profile-pic" to="/profile">
                                                <img src={require("../../../static/images/avatar.svg")} alt="profile picture"/>
                                            </Link>
                                        </li>
                                    </ul>
                                }
                            </div>
                            }
                        </nav>
                    </div>
                </header>
            </>
        )
    }
}

function mapStateToProps(state) {
    const {auth} = state.LoginReducer;
    const {goToRegistration} = state.RegisterReducer;
    const {isAuthenticated} = auth;
    return {isAuthenticated, goToRegistration, state}
}

export default withRouter(connect(mapStateToProps)(Head))
