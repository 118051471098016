import {
    GET_JOBS_REQUEST,
    GET_JOBS_SUCCESS,
    GET_JOBS_FAILURE,
    CLEAR_ALL,
    GET_TEAM_JOBS_REQUEST,
    GET_TEAM_JOBS_SUCCESS,
    GET_TEAM_JOBS_FAILURE,
    DELETE_JOB_REQUEST,
    DELETE_JOB_SUCCESS,
    DELETE_JOB_FAILURE,
    CLEAR_STATUS,
    EDIT_JOB_DETAILS_REQUEST,
    EDIT_JOB_DETAILS_SUCCESS,
    EDIT_JOB_DETAILS_FAILURE,
    APPLY_JOB, NO_APPLY_JOB,
    SAVE_APPLY_JOB_REQUEST, SAVE_APPLY_JOB_SUCCESS, SAVE_APPLY_JOB_FAILURE,
    SEARCH_JOBS,CHANGE_SELECTED_JOB_TYPE,
    SELECTED_DELETE_JOB,GET_JOB_TYPE_REQUEST,GET_JOB_TYPE_SUCCESS,GET_JOB_TYPE_FAILURE, CHANGE_SELECTED_TEAM,
    GET_KEYWORDS_REQUEST,GET_KEYWORDS_SUCCESS,GET_KEYWORDS_FAILURE,CHANGE_SELECTED_KEYWORD

} from "../../../../types/dashboard/user/jobs/jobs-types";


import {GET_API_WITHOUT_TOKEN} from "../../../../middleware/without_token/get-api-without-token";
import {CALL_DELETE_API} from "../../../../middleware/token/delete/without-body";
import {PUT_API} from "../../../../middleware/token/put_api/put-api-with-body";
import {CALL_POST_API} from "../../../../middleware/token/post-api";
import { authApi } from "../../../ApiActions";

const BASE_URL = authApi();



export function fetchJobType(){

    return {
         [GET_API_WITHOUT_TOKEN]:{
             endpoint: BASE_URL+'/v1/teamtype',
             types: [GET_JOB_TYPE_REQUEST,GET_JOB_TYPE_SUCCESS,GET_JOB_TYPE_FAILURE],
         }
     }
 }
 export function setSelectedJobType(jobType) {

    return {type: CHANGE_SELECTED_JOB_TYPE, jobType}
}
 
 export function setSelectedTeam(team) {
    return {type: CHANGE_SELECTED_TEAM, team}
}
export function setSelectedKeyword(keyword) {
    return {type: CHANGE_SELECTED_KEYWORD, keyword}
}

export function getKeywords(type ,team){
   
    var endpoint= BASE_URL + '/v1/keywords';
    if(type !=='' && type !== undefined ){
        endpoint += '?type='+type
    }else{
        endpoint += '?type='
    }

    if(team !=='' && team !== undefined ){
            endpoint += '&team='+team
    }
    return {
         [GET_API_WITHOUT_TOKEN]:{
             endpoint,
             types: [GET_KEYWORDS_REQUEST,GET_KEYWORDS_SUCCESS,GET_KEYWORDS_FAILURE],
         }
     }
 }

// calls api to save apply job
export function saveApplyJob(body) {
    
    return {
        [CALL_POST_API]: {
            endpoint: BASE_URL + '/v1/apply/'+body,
            types: [SAVE_APPLY_JOB_REQUEST, SAVE_APPLY_JOB_SUCCESS, SAVE_APPLY_JOB_FAILURE],
            // body: body
        }
    }
}

// calls api to get jobs
export function getJobs(type,team,keywords) {
    var endpoint= BASE_URL + '/v1/job';
    if(type !=='' && type !== undefined ){
        endpoint += '?type='+type
    }else{
        endpoint += '?type='
    }
    if(team !==''  && team !== undefined ){
        if( team.length >0){
                endpoint += '&team='+team
        }   
    }
    if(keywords !=='' && keywords !== undefined ){
        endpoint += "&keywords="+keywords
    }
    return {
        [GET_API_WITHOUT_TOKEN]: {
            endpoint,
            types: [GET_JOBS_REQUEST, GET_JOBS_SUCCESS, GET_JOBS_FAILURE]
        }
    }
}

// calls api to edit job
export function editJob( job, body) {
    return {
        [PUT_API]: {
            endpoint: BASE_URL + '/v1/job/'+job,
            types: [EDIT_JOB_DETAILS_REQUEST, EDIT_JOB_DETAILS_SUCCESS, EDIT_JOB_DETAILS_FAILURE],
            body: JSON.stringify(body)
        }
    }
}

// calls api to delete job
export function deleteJob( job) {
    return {
        [CALL_DELETE_API]: {
            endpoint: BASE_URL + '/v1/job/'+'/'+job,
            types: [DELETE_JOB_REQUEST, DELETE_JOB_SUCCESS, DELETE_JOB_FAILURE],
        }
    }
}

// calls api to  get team jobs
export function getTeamJobs(team) {
    return {
        [GET_API_WITHOUT_TOKEN]: {
            endpoint: BASE_URL + '/v1/job/'+team,
            types: [GET_TEAM_JOBS_REQUEST, GET_TEAM_JOBS_SUCCESS, GET_TEAM_JOBS_FAILURE]
        }
    }
}

// calls action to clear error message, response
export function clearAll() {
    return {type: CLEAR_ALL}
}

// calls action to clear error message, response
export function clearStatus() {
    return {type: CLEAR_STATUS}
}

// calls action to apply job
export function applyJob(job) {
    return {type: APPLY_JOB, job}
}

// calls action to no aply job
export function noApplyJob() {
    return {type: NO_APPLY_JOB}
}

// calls action to search jobs
export function searchJobs(search) {
    return {type: SEARCH_JOBS, search}
}

// calls action to delete selected job
export function selectedDeleteJob(data) {
    return {type: SELECTED_DELETE_JOB, data}
}