import React, {Component} from 'react';
import {withRouter,NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import AdminOnPageNavigation from '../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import { PulseLoader } from 'react-spinners';
import { Table, TableRow, TableBody, TableData, TableHeaderRow, TableHeader } from '../../../../components/dashboard/admin/xd-react-table/react-flex-table'
import { TableHeadComponent , ThComponent} from '../../../../components/dashboard/admin/xd-react-table/react-flex-table/components';
import Chart from 'react-google-charts'
import { getDrivewiseResult,changePageNumber } from '../../../../actions/dashboard/result/results'
import ReactTooltip from 'react-tooltip'
import {Helmet} from "react-helmet";
import moment from "moment";
import Pagination from 'react-js-pagination'


class DriveStudentResult extends Component {
    handlePageChange(pageNumber) {
        this.props.dispatch(changePageNumber(pageNumber));
        this.props.history.push('/dashboard/result/drivewise/'+this.props.match.params.drive+'/candidates/page='+pageNumber+'&pageSize=10')
        this.props.dispatch(getDrivewiseResult(this.props.match.params.drive,pageNumber));
        this.forceUpdate();
    }
    componentWillMount() {
        this.props.dispatch(changePageNumber(1));

    }
    componentDidMount(){
        this.props.dispatch(getDrivewiseResult(this.props.match.params.drive,this.props.match.params.pageNumber));
    }
    render() {
        var drivebarchart = this.props.driveCompare;
        if (drivebarchart.length===1){
            drivebarchart = drivebarchart.push([0,0,0,0,0,0]) //when there's no data for bar chart, empty bar chart will be displayed
        }
    return (
            <div>
            <Helmet>
                <title>Drivewise Result | Career Portal | Xenonstack</title>
             </Helmet>
                <AdminOnPageNavigation parentRoute="Dashboard" childRoute={"Drive Result"}/>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <div className="panel panel-default card-view custom-panel pool-panel">
                            <div className="panel-heading">
                                <h5 className="panel-title">Drive Result</h5>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                                 <div className="table-wrap">
                                        <div className="table-responsive">
                                        <div className="text-center">
                                        {this.props.getDrivewiseResult_PageLoading &&
                                         <PulseLoader
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#0562e8'}
                                         loading={this.props.getDrivewiseResult_PageLoading}
                                         />
                                        }</div>
                                        <div className="react-xd-table-wrapper">
                                            {(!this.props.getDrivewiseResult_PageLoading && this.props.driveCandidates.length > 0 ) &&
                                            <Table
                                                data={this.props.driveCandidates}
                                                pageSize={10}
                                                currentPage={this.props.currentPageNumber}
                                                pageSizeOptions={[10, 20, 30, 40, 50]}
                                                startIndex={0}
                                                endIndex={10}
                                                render={({
                                                    rows,
                                                    pageSize,
                                                    pageSizeOptions,
                                                    handlePageSizeChange,
                                                    handlePrevPage,
                                                    handleNextPage,
                                                    hasNextPage,
                                                    hasPrevPage,
                                                    currentPage,
                                                    total }) => {
                                                        return (
                                                                <React.Fragment>
                                                                <table className="react-xd-table striped-xd-table sortable-table footer-pagination-table">
                                                                        <TableHeaderRow component={TableHeadComponent}>
                                                                        <TableHeader
                                                                        accessor="sr_no"
                                                                        accessor_name="Sr. No."
                                                                        component={ThComponent}
                                                                        >
                                                                        Sr. No .
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="email"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Email
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="questions"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Total
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="attempted"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Attempted
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="correct"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Correct
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="accuracy"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Accuracy
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="percentage"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Percentage
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="time_taken"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Time Taken
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="average_time_taken"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Per Question Time
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="restart"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Restart
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="browser"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Browser Switch
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor_name="Action"
                                                                        component={ThComponent}>
                                                                        Action
                                                                        </TableHeader>
                                                                        </TableHeaderRow>
                                                                        <TableBody
                                                                            component="tbody"
                                                                            >
                                                                            {rows.map(({ rowKey, rowData }, rowIndex) => (
                                                                            <TableRow
                                                                                component="tr"
                                                                                className="xd-table-row xd-table-td"
                                                                                key={rowKey}
                                                                            >
                                                                            {rowData.map(({  accessor, data, key , rowsData }) => (

                                                                               <TableData
                                                                                component="td"
                                                                                key={key}
                                                                                >
                                                                                {accessor ? (
                                                                                    accessor === 'email' ? (<NavLink to={"/dashboard/candidate/" + rowsData.email + "/details"}    >{data}</NavLink> ) :
                                                                                    accessor === 'sr_no' ? rowIndex + (pageSize * (this.props.currentPageNumber-1))+1 :
                                                                                   accessor === 'time_taken' ? moment.utc(rowsData.time_taken*1000).format('HH:mm:ss'):
                                                                                   accessor === 'average_time_taken' ? moment.utc(rowsData.average_time_taken*1000).format('HH:mm:ss'):
                                                                                              (data)

                                                                                ) :

                                                                                (
                                                                                    <div className="table-action-group">
                                                                                     <NavLink to={"/dashboard/"+this.props.match.params.drive+"/users/"+rowsData.email+"/score/true"}  className="xd-table-btn view" data-tip="View Result"><i className="material-icons" >remove_red_eye</i><div style={{lineHeight:"10px"}}><ReactTooltip place="bottom" /></div></NavLink>
                                                                                </div>
                                                                                )}

                                                                                </TableData>
                                                                            ))}

                                                                            </TableRow>
                                                                            ))}
                                                                        </TableBody>

                                                </table>

                                                <div className="react-xd-table-footer">
                                                                          <div className="row">
                                                                          <div className="col-12 col-sm-12 col-md-12">
                                                                            <div className="react-xd-pagination-container">
                                                                              <div className="pagination-outer">
                                                                                  <div className="pagination-group">
                                                                                    <Pagination
                                                                                      firstPageText={"First"}
                                                                                      lastPageText="Last"
                                                                                      prevPageText={<i className='fa fa-angle-left'/>}
                                                                                      nextPageText={<i className='fa fa-angle-right'/>}
                                                                                      activePage={this.props.currentPageNumber}
                                                                                      itemsCountPerPage={pageSize}
                                                                                      totalItemsCount={this.props.drive_record_count}
                                                                                      pageRangeDisplayed={8}
                                                                                      onChange={this.handlePageChange.bind(this)}
                                                                                      />
                                                                                  </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                        </div>
                                                </React.Fragment>
                                                )
                                                }}
                                            />}
                                            {(!this.props.getDrivewiseResultPage_message === "") &&

                                                        <div className="not-found">
                                                            No Data found !!
                                                        </div>

                                            }
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6">
                    <div className="panel panel-default card-view custom-panel pool-panel">
                    <div className="panel-heading">
                                <h5 className="panel-title"> </h5>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                    <Chart
                        height={'350px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={this.props.driveCompare}
                        options={{
                            title: 'Accuracy of Candidates',
                                isStacked: true,
                                hAxis: {
                                title: 'Candidates',
                                minValue: 0,
                                },
                                vAxis: {
                                title: 'Month',
                                },
                                series: {
                                    0:{color:'#dc0f0f'},
                                    1:{color:'#fd9900'},
                                    2:{color:'#fb6514'},

                                        }
                                }}

                    // For tests
                        rootProps={{ 'data-testid': '3' }}
                    />

                    </div> </div>
                    </div> </div>


                    <div className="col-6 col-sm-6 col-md-6">
                    <div className="panel panel-default card-view custom-panel pool-panel">
                    <div className="panel-heading">
                                <h5 className="panel-title"> </h5>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                    <Chart
                            height={'350px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}

                            data={[
                                    ['col1', 'col2'],
                                    ['Wrong', this.props.drivePie.wrong],
                                    ['Not Attempted', this.props.drivePie.not_attempted],
                                    ['Correct', this.props.drivePie.correct],

                                    ]}
                            options={ {
                               colors: ['#dc0f0f', '#fd9900','#109619'],
                               title: 'Drive Performance'
                            }}
                            rootProps={{ 'data-testid': '1' }}
                            />
                            </div> </div> </div>
                    </div>
                </div>


            </div>
        )
    }
}


const mapStateToProps = (state) => {

    const {} = state.poolReducer;

    const {
        getDrivewiseResult_PageLoading,
        getDrivewiseResultPage_status,
        getDrivewiseResultPage_error,
        getDrivewiseResultPage_message,
        driveCandidates,
        driveCompare,
        drivePie, pageNumber,defaultPageSize,currentUserPageNumber,drive_record_count,currentPageNumber
    } = state.adminResultReducer;
    return {

        pageNumber,defaultPageSize,
        currentUserPageNumber,
        getDrivewiseResult_PageLoading,
        getDrivewiseResultPage_status,
        getDrivewiseResultPage_error,
        getDrivewiseResultPage_message,
        driveCandidates,
        driveCompare,
        drivePie,currentPageNumber,drive_record_count
    }
}

export default withRouter(connect(mapStateToProps)(DriveStudentResult))
