// create TEAM

export const CHANGE_CREATE_TEAM_FORM = "CHANGE_CREATE_TEAM_FORM";

export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAILURE = "CREATE_TEAM_FAILURE";
//
export const GET_TEAMS_REQUEST = "GET_TEAMS_REQUEST";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAILURE = "GET_TEAMS_FAILURE";

export const CLEAR_TEAMS = "CLEAR_TEAMS";

export const EDIT_TEAM_DETAILS_REQUEST = "EDIT_TEAM_REQUEST";
export const EDIT_TEAM_DETAILS_SUCCESS = "EDIT_TEAM_SUCCESS";
export const EDIT_TEAM_DETAILS_FAILURE = "EDIT_TEAM_FAILURE";


export const CLEAR_EDIT_TEAM = "CLEAR_EDIT_TEAM";



export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";

export const GET_TEAM_DETAILS_REQUEST = "GET_TEAM_DETAILS_REQUEST";
export const GET_TEAM_DETAILS_SUCCESS = "GET_TEAM_DETAILS_SUCCESS";
export const GET_TEAM_DETAILS_FAILURE = "GET_TEAM_DETAILS_FAILURE";
//
export const CLEAR_ALL = "CLEAR_ALL";

export const CLEAR_STATUS = "CLEAR_STATUS";

export const CLEAR_DELETE_TEAM_RESPONSE = "CLEAR_DELETE_TEAM_RESPONSE";

export const SELECTED_DELETE_TEAM = "SELECTED_DELETE_TEAM"
export const UPDATE_TEAM_INFO = 'UPDATE_TEAM_INFO';
export const UPDATE_SUB_TEAM_INFO = 'UPDATE_SUB_TEAM_INFO'
export const UPDATE_RES_INFO="UPDATE_RES_INFO";
export const UPDATE_TEAM_INFORMATION="UPDATE_TEAM_INFORMATION";

export const ADD_IMAGE_REQUEST = "ADD_IMAGE_REQUEST";
export const ADD_IMAGE_SUCCESS = "ADD_IMAGE_SUCCESS";
export const ADD_IMAGE_FAILURE = "ADD_IMAGE_FAILURE";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE";

export const UPDATE_IMAGES = "UPDATE_IMAGES";

export const GET_ADMIN_TEAMS_REQUEST = "GET_ADMIN_TEAMS_REQUEST";
export const GET_ADMIN_TEAMS_SUCCESS = "GET_ADMIN_TEAMS_SUCCESS";
export const GET_ADMIN_TEAMS_FAILURE = "GET_ADMIN_TEAMS_FAILURE";