import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AdminOnPageNavigation from "../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation";
import {
  changeCreateTeamForm,
  clearAll,
  clearEditTeam,
  createTeam,
  editTeamDetails,
  getTeamDetails,
  updateTeamInfo,
  updateSubTeams,
  addImage,
  deleteImage,
  updateTeamInformation,
  getAdminTeamDetails,
  updateResInfo,
} from "../../../../../actions/dashboard/admin/team/team";
import { checkValidation } from "../../../../../utils/validator";
import BarLoaderSpinner from "../../../../../components/app/spinner/barloader";
import createNotification from "../../../../../components/app/notification/notification";
import { NotificationContainer } from "react-notifications";
import { Helmet } from "react-helmet";
import Select from "react-select";

class CreateTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: 3,
      minRows: 3,
      maxRows: 20,
      options: [
        { value: "Experience", label: "Experience" },
        { value: "Internship", label: "Internship" },
        { value: "Graduates", label: "Graduates" },
      ],
    };
  }
  componentWillMount() {
    this.props.dispatch(clearEditTeam());
    this.props.dispatch(clearAll());
    this.forceUpdate();
    if (this.props.location.pathname.match("/edit")) {
      this.props.dispatch(getAdminTeamDetails(this.props.match.params.team));
    }
  }
  // submit the form
  submitCreateTeamForm(e) {

    e.preventDefault();
    const self = this.props;
    let descriptionError = false;
    if (self.state.adminTeamReducer.createTeam.description !== "") {
      descriptionError = false;
      document
        .getElementsByName("description")[0]
        .parentElement.classList.remove("has-error");
    } else {
      descriptionError = true;
      document.getElementsByName("description")[0].focus();
      document
        .getElementsByName("description")[0]
        .parentElement.classList.add("has-error");
    }

    // for job type image upload
    if (document.getElementsByName("featured_image")[0].value === '') {

      document.getElementsByName("featured_image")[0].focus();
      document.getElementsByName("featured_image")[0].parentElement.parentElement.parentElement.classList.add("has-error");
    } else {
      document.getElementsByName("featured_image")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
    }


    // for meet our team image upload : image-1 and image-2
    if (document.getElementsByName("image_1")[0].value === '') {

      document.getElementsByName("image_1")[0].focus();
      document.getElementsByName("image_1")[0].parentElement.parentElement.parentElement.classList.add("has-error");
    } else {
      document.getElementsByName("image_1")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
    }

    // for resposibility of team image upload
    if (document.getElementsByName("image_2")[0].value === '') {

      document.getElementsByName("image_2")[0].focus();
      document.getElementsByName("image_2")[0].parentElement.parentElement.parentElement.classList.add("has-error");
    } else {
      document.getElementsByName("image_2")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
    }



    // for add team values image upload
    if (document.getElementsByName("image")[0].value === '') {
      document.getElementsByName("image")[0].focus();
      document.getElementsByName("image")[0].parentElement.parentElement.parentElement.classList.add("has-error");
    } else {
      document.getElementsByName("image")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
    }

    // for add sub team image upload
    if(document.getElementsByClassName("team_image")[0]) {
      if (document.getElementsByClassName("team_image")[0].value === '') {
        document.getElementsByClassName('team_image')[0].focus();
        document.getElementsByClassName('team_image')[0].parentElement.parentElement.parentElement.classList.add("has-error");
      } else {
        document.getElementsByClassName('team_image')[0].parentElement.parentElement.parentElement.classList.remove("has-error");
      }
    }
 
    // image upload
    if(document.getElementsByClassName('sub_image_file')[0]) {
    if (document.getElementsByClassName('sub_image_file')[0].value === '') {
      document.getElementsByClassName('sub_image_file')[0].focus();
      document.getElementsByClassName('sub_image_file')[0].parentElement.parentElement.parentElement.classList.add("has-error");
    } else {
      document.getElementsByClassName('sub_image_file')[0].parentElement.parentElement.parentElement.classList.remove("has-error");
    }
  }


    if (e.target.checkValidity() && descriptionError === false) {
      const body = {
        title: self.state.adminTeamReducer.createTeam.title,
        meta_description: self.state.adminTeamReducer.createTeam.meta_description,
        name: self.state.adminTeamReducer.createTeam.name,
        description: self.state.adminTeamReducer.createTeam.description,
        featured_image: self.state.adminTeamReducer.createTeam.featured_image,
        team_information: this.props.team_information,
        responsibility_information: this.props.responsibility_information,
        what_will_do: this.props.what_will_do,
        sub_teams: this.props.sub_teams,
        type: this.props.createTeam.type,
        slug: this.props.createTeam.slug,
      };

      if (self.location.pathname.match("/edit")) {
        self.dispatch(editTeamDetails(self.match.params.team, body));
      } else {
        if (document.getElementsByName("featured_image")[0].value != ''
          && document.getElementsByName("image_1")[0].value != ''
          && document.getElementsByName("image_2")[0].value != ''
          && document.getElementsByName("image")[0].value != ''
          && document.getElementsByClassName("team_image")[0].value != ''
          && document.getElementsByClassName('sub_image_file')[0].value != '') {
          self.dispatch(createTeam(body));
        }

      }
    } else {
      const invalidElmsInput = document.querySelectorAll(
        ".create-team-form .form-group :invalid"
      );
      for (let i = 0; i < invalidElmsInput.length; i++) {
        invalidElmsInput[0].focus();
        invalidElmsInput[i].parentElement.classList.add("has-error");
      }
    }
  }
  slugify() {
    let val = document.getElementById("name").value;
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    let result = val
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text

    document.getElementById("slug").value = result;
    let newState = {
      target: { name: "slug", value: result },
    };
    this.onChange(newState, "automaticSlugChange");
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      nextProps.dispatch(clearEditTeam());
      nextProps.dispatch(clearAll());
      if (nextProps.location.pathname.match("/edit")) {
        nextProps.dispatch(getTeamDetails(nextProps.match.params.team));
      }
    }
    if (nextProps.edit_team_details_message !== "") {
      if (nextProps.edit_team_details_error) {
        createNotification("error", nextProps.edit_team_details_message);
        nextProps.dispatch(clearEditTeam());
      } else {
        createNotification("success", nextProps.edit_team_details_message);
        nextProps.history.push("/dashboard/team/list/page=1&pageSize=10");
      }
    }

    if (nextProps.message !== "") {
      if (nextProps.error) {
        createNotification("error", nextProps.message);
        nextProps.dispatch(clearEditTeam());
      } else {
        createNotification("success", nextProps.message);
        nextProps.history.push("/dashboard/team/list/page=1&pageSize=10");
      }
    }
  }

  handleChange = (selectedOption) => {
    const self = this.props;
    const newState = Object.assign(self.state.adminTeamReducer.createTeam, {
      type: selectedOption.value,
    });

    self.dispatch(changeCreateTeamForm(newState));
  };

  // called when +Add button in whatwillyoudo is clicked
  whatWillUdo() {
    const what_will_do = this.props.what_will_do;

    what_will_do.push({
      name: "",
      description: "",
      image: "",
    });

    this.props.dispatch(updateTeamInfo(what_will_do));
  }
  // called when clear button in whatwillyoudo is clicked
  remwhatWillUdo(i) {
    const self = this.props;
    const what_will_do = self.what_will_do;
    what_will_do.splice(i, 1);
    this.props.dispatch(updateTeamInfo(what_will_do));
  }

  subTeam() {
    const sub_teams = this.props.sub_teams;

    sub_teams.push({
      name: "",
      description: "",
      image: "",
    });

    this.props.dispatch(updateSubTeams(sub_teams));
  }

  remsubTeam(i) {
    const self = this.props;
    const sub_teams = self.sub_teams;
    sub_teams.splice(i, 1);
    this.props.dispatch(updateTeamInfo(sub_teams));
  }
  //   // called when  start date picker focus out
  onChange(e, i) {
    //code for extendable text area
    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = e.target.rows;
    e.target.rows = minRows; // reset number of rows in textarea
    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }
    this.setState({
      value: e.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
    // ///////////////////////////////////
    const self = this.props;
    if (i !== "automaticSlugChange") {
      checkValidation(e);
    }

    if (e.target.name === "description") {
      if (e.target.value !== "") {
        e.target.parentElement.classList.remove("has-error");
      } else {
        e.target.parentElement.classList.add("has-error");
      }
    }

    const newState = Object.assign(self.state.adminTeamReducer.createTeam, {
      [e.target.name]: e.target.value,
    });

    self.dispatch(changeCreateTeamForm(newState));
  }

  handleTeamImg(e, type, index) {
    const self = this.props;
    let newState = {};
    if (type === "featuredImage") {
      if (e.target.name === "featured_image") {
        const file = e.target.files[0];
        const adminTeamData = this.props.state.adminTeamReducer;
        self.dispatch(addImage(file, type, index, adminTeamData));
        document.getElementsByName("featured_image")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
      } else {
        document.getElementsByName("featured_image")[0].parentElement.parentElement.parentElement.classList.add("has-error");
        newState = Object.assign(self.createTeam, {
          [e.target.name]: e.target.value,
        });
        self.dispatch(updateTeamInfo(newState));
      }
    }

    if (type === "team_image") {
      if (e.target.name === "team_image") {
        const fileInput = document.getElementById("team_image" + index);
        const file = fileInput.files[0];
        const adminTeamData = this.props.state.adminTeamReducer;
        self.dispatch(addImage(file, type, index, adminTeamData));
        document.getElementsByName("team_image")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
      } else {
        document.getElementsByName("team_image")[0].parentElement.parentElement.parentElement.classList.add("has-error");
        newState = Object.assign(self.what_will_do, {
          [e.target.name]: e.target.value,
        });
        self.dispatch(updateTeamInfo(newState));
      }
    }

    if (type === "sub_image") {
      if (e.target.name === "sub_team_image") {
        const fileInput = document.getElementById("sub_image" + index);
        const file = fileInput.files[0];
        const adminTeamData = this.props.state.adminTeamReducer;
        self.dispatch(addImage(file, type, index, adminTeamData));
        document.getElementsByName("sub_team_image")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
      } else {
        document.getElementsByName("sub_team_image")[0].parentElement.parentElement.parentElement.classList.add("has-error");
        newState = Object.assign(self.what_will_do, {
          [e.target.name]: e.target.value,
        });
        self.dispatch(updateTeamInfo(newState));
      }
    }
  }

  handleTeamInfoImage1(e, type, index) {
    const self = this.props;
    let newState = {};
    if (type === "team_info_image1") {
      if (e.target.name === "image_1") {
        const file = e.target.files[0];
        const adminTeamData = this.props.state.adminTeamReducer;
        self.dispatch(addImage(file, type, index, adminTeamData));
        document.getElementsByName("image_1")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
      } else {
        document.getElementsByName("image_1")[0].parentElement.parentElement.parentElement.classList.add("has-error");

        newState = Object.assign(self.responsibility_information, {
          [e.target.name]: e.target.value,
        });
        self.dispatch(updateTeamInformation(newState));
      }
    }
  }
  handleTeamInfoImage2(e, type, index) {
    const self = this.props;
    let newState = {};
    if (type === "team_info_image2") {
      if (e.target.name === "image_2") {
        const file = e.target.files[0];
        const adminTeamData = this.props.state.adminTeamReducer;
        self.dispatch(addImage(file, type, index, adminTeamData));
        document.getElementsByName("image_2")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
      } else {
        document.getElementsByName("image_2")[0].parentElement.parentElement.parentElement.classList.add("has-error")
        newState = Object.assign(self.responsibility_information, {
          [e.target.name]: e.target.value
        });
        self.dispatch(updateTeamInformation(newState));
      }
    }
  }

  handleResImg(e, type, index) {
    const self = this.props;
    let newState = {};
    if (type === "res_image") {
      if (e.target.name === "image") {
        const file = e.target.files[0];
        const adminTeamData = this.props.state.adminTeamReducer;
        self.dispatch(addImage(file, type, index, adminTeamData));
        document.getElementsByName("image")[0].parentElement.parentElement.parentElement.classList.remove("has-error");
      } else {
        document.getElementsByName("image")[0].parentElement.parentElement.parentElement.classList.add("has-error");
        newState = Object.assign(self.responsibility_information, {
          [e.target.name]: e.target.value,
        });
        self.dispatch(updateResInfo(newState));
      }
    }
  }

  deleteImage(type, parentIndex, image) {
    const adminTeamData = this.props.state.adminTeamReducer;
    this.props.dispatch(deleteImage(type, parentIndex, image, adminTeamData));
  }

  handleTeamInfo(e) {
    const self = this.props;
    checkValidation(e);

    if (e.target.name === "description") {
      if (e.target.value !== "") {
        e.target.parentElement.classList.remove("has-error");
      } else {
        e.target.parentElement.classList.add("add-error");
      }
    }

    let name = e.target.name;
    let value = e.target.value;
    let team_information = this.props.team_information;
    team_information[name] = value;
    self.dispatch(updateTeamInformation(team_information));
  }

  handleResinfo(e) {
    const self = this.props;
    checkValidation(e);

    if (e.target.name === "description") {
      if (e.target.value !== "") {
        e.target.parentElement.classList.remove("has-error");
      } else {
        e.target.parentElement.classList.add("add-error");
      }
    }
    let name = e.target.name;
    let value = e.target.value;
    let responsibility_information = this.props.responsibility_information;
    responsibility_information[name] = value;
    self.dispatch(updateResInfo(responsibility_information));
  }

  handleWhat_will_do(e, i) {
    const self = this.props;
    checkValidation(e);

    if (e.target.name === "description") {
      if (e.target.value !== "") {
        e.target.parentElement.classList.remove("has-error");
      } else {
        e.target.parentElement.classList.add("add-error");
      }
    }
    let name = e.target.name;
    let value = e.target.value;
    let what_will_do = this.props.what_will_do;
    what_will_do[i][name] = value;
    self.dispatch(updateTeamInfo(what_will_do));
  }

  handleSub_teams(e, i) {
    checkValidation(e);
    if (e.target.name === "description") {
      if (e.target.value !== "") {
        e.target.parentElement.classList.remove("has-error");
      } else {
        e.target.parentElement.classList.add("add-error");
      }
    }
    let name = e.target.name;
    let value = e.target.value;
    let sub_teams = this.props.sub_teams;
    sub_teams[i][name] = value;

    this.props.dispatch(updateSubTeams(sub_teams));
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Create Team | Career Portal | Xenonstack</title>
        </Helmet>
        <AdminOnPageNavigation
          parentRoute="Dashboard"
          childRoute={
            this.props.location.pathname.match("/edit")
              ? "Edit Team"
              : "Add Team"
          }
        />
        <BarLoaderSpinner
          pageLoading={this.props.state.adminTeamReducer.pageLoading}
        />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="pool-form-wrapper">
              <h4 className="content-heading">
                {this.props.location.pathname.match("/edit")
                  ? "Edit Team Details"
                  : "Add Team Details"}
              </h4>
              <div className="divider-create-team" />
              <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-12">
                  <div className="">
                    {this.props.team_details_message !== "" && (
                      <div style={{ textAlign: "center" }}>
                        {this.props.team_details_message}
                      </div>
                    )}
                    <form
                      className="create-team-form"
                      onSubmit={this.submitCreateTeamForm.bind(this)}
                      noValidate={true}
                    >
                      <div className="form-body">
                        {/* Job type */}
                        <div className="create-team-form-inter-sectional-spacing">
                          <div className="form-group">
                            <label className="control-label mb-5">
                              Job Type<span className="req">*</span>
                            </label>
                            <Select
                              placeholder="Job Type"
                              name="type"
                              options={this.state.options}
                              onChange={this.handleChange}
                              required={true}
                              value={this.state.options.filter(
                                (option) =>
                                  option.label === this.props.createTeam.type
                              )}
                            />
                            <p className="with-error">
                              Please select Job Type.
                            </p>
                          </div>
                          <div className="form-group">
                            <label className="control-label mb-5">
                              Team Name<span className="req">*</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              minLength={3}
                              maxLength={50}
                              autoComplete={"off"}
                              id="name"
                              onBlur={this.slugify.bind(this)}
                              value={this.props.createTeam.name}
                              onChange={this.onChange.bind(this)}
                              required={true}
                              className="form-control"
                              placeholder="Team Name"
                            />
                            <p className="with-error">
                              Please enter team name (Min 3 char.).
                            </p>
                          </div>
                          <div className="form-group">
                            <label className="control-label">
                              Slug<span className="req">*</span>
                            </label>
                            <input
                              type="text"
                              id="slug"
                              value={this.props.createTeam.slug}
                              className="form-control"
                              name="slug"
                              minLength={3}
                              autoComplete={"off"}
                              required={true}
                              onChange={this.onChange.bind(this)}
                              placeholder="Slug"
                            />
                            <p className="with-error">
                              Please enter the slug for job (Min 3 char.).
                            </p>
                          </div>
                          <div className="form-group">
                            <label className="control-label mb-5">
                              Description<span className="req">*</span>
                            </label>
                            <textarea
                              rows={this.state.rows}
                              type="text"
                              className="form-control description"
                              name="description"
                              minLength={3}
                              autoComplete={"off"}
                              value={this.props.createTeam.description}
                              onChange={this.onChange.bind(this)}
                              required={true}
                              placeholder="Description"
                            />
                            <p className="with-error">
                              Please enter description (Min 3 char.).
                            </p>
                          </div>
                          <div className="form-group">

                            <label className="control-label mb-5">
                              Upload Image <span className="req">*</span>
                            </label>
                            <div className="custom-image-uploader">
                              <div className="custom-img-box" id="img-box">
                                <div>
                                  <i className="material-icons">
                                    file_upload
                                  </i>
                                  <span>Upload Image</span>
                                </div>
                                <input
                                  className="feature_image"
                                  type="file"
                                  name="featured_image"
                                  accept=".png, .jpg, .jpeg"
                                  onChange={(e) =>
                                    this.handleTeamImg(
                                      e,
                                      "featuredImage",
                                      this.props.createTeam.id
                                    )
                                  }
                                />

                              </div>

                              <div className="custom-img-preview">
                                {this.props.createTeam.featured_image !== "" && (
                                  <ul className="img-preview-ul">
                                    <li>
                                      <div className="prev-img-box">
                                        <div className="image-overlay"></div>
                                        <img
                                          src={
                                            this.props.createTeam.featured_image
                                          }
                                          alt="image"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                )}
                              </div>
                            </div>
                            <p className="with-error">Please upload Image</p>
                          </div>
                        </div>
                        {/* Job Type */}

                        {/* Meet our team */}
                        <div className="create-team-form-inter-sectional-spacing">
                          <div className="form-btn-heading">
                            <h4 className="content-heading2">
                              {this.props.location.pathname.match("/edit")
                                ? "Meet Our Team"
                                : "Meet our team"}
                            </h4>
                          </div>
                          <div className="divider" />

                          <div className="row-wrapper">
                            <div className="form-group">
                              <label className="control-label mb-5">
                                Team Title<span className="req">*</span>
                              </label>
                              <input
                                type="text"
                                name="title"
                                minLength={3}
                                maxLength={50}
                                autoComplete={"off"}
                                value={this.props.team_information.title}
                                onChange={(e) => this.handleTeamInfo(e)}
                                required={true}
                                className="form-control"
                                placeholder="Team Title"
                              />

                              <p className="with-error">
                                Please enter team title (Min 3 char.).
                              </p>
                            </div>
                            <div className="form-group">
                              <label className="control-label mb-5">
                                Upload Image 1<span className="req">*</span>
                              </label>
                              <div className="custom-image-uploader">
                                <div className="custom-img-box">
                                  <div>
                                    <i className="material-icons">
                                      file_upload
                                    </i>
                                    <span>Upload Image</span>
                                  </div>
                                  <input
                                    className="image_first"
                                    type="file"
                                    name="image_1"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) =>
                                      this.handleTeamInfoImage1(
                                        e,
                                        "team_info_image1",
                                        this.props.team_information.id
                                      )
                                    }
                                  />
                                </div>

                                <div className="custom-img-preview">
                                  {this.props.team_information.image_1 !==
                                    "" && (
                                      <ul className="img-preview-ul">
                                        <li>
                                          <div className="prev-img-box">
                                            <div className="image-overlay"></div>
                                            <img
                                              src={
                                                this.props.team_information
                                                  .image_1
                                              }
                                              alt="image"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                </div>
                              </div>
                              <p className="with-error">
                                Please upload Image
                              </p>
                            </div>
                            <div className="form-group">
                              <label className="control-label mb-5">
                                Upload Image 2<span className="req">*</span>
                              </label>
                              <div className="custom-image-uploader">
                                <div className="custom-img-box">
                                  <div>
                                    <i className="material-icons">
                                      file_upload
                                    </i>
                                    <span>Upload Image</span>
                                  </div>
                                  <input
                                    type="file"
                                    className="image_secondary"
                                    name="image_2"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) =>
                                      this.handleTeamInfoImage2(
                                        e,
                                        "team_info_image2",
                                        this.props.team_information.id
                                      )
                                    }
                                  />
                                </div>
                                <div className="custom-img-preview">
                                  {this.props.team_information.image_2 !==
                                    "" && (
                                      <ul className="img-preview-ul">
                                        <li>
                                          <div className="prev-img-box">
                                            <div className="image-overlay"></div>
                                            <img
                                              src={
                                                this.props.team_information
                                                  .image_2
                                              }
                                              alt="image"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                </div>
                              </div>
                              <p className="with-error">
                                Please upload Image
                              </p>
                            </div>
                            <div className="form-group">
                              <label className="control-label mb-5">
                                Description<span className="req">*</span>
                              </label>
                              <textarea
                                rows={this.state.rows}
                                type="text"
                                className="form-control description"
                                name="description"
                                minLength={3}
                                autoComplete={"off"}
                                value={this.props.team_information.description}
                                onChange={(e) => this.handleTeamInfo(e)}
                                required={true}
                                placeholder="Description"
                              />
                              <p className="with-error">
                                <p className="with-error">
                                  Please enter description (Min 3 char.).
                                </p>
                              </p>
                            </div>

                          </div>
                        </div>
                        {/* Meet our team */}
                        {/* Responsibility of the team */}
                        <div className="create-team-form-inter-sectional-spacing">
                          <div className="form-btn-heading">
                            <h4 className="content-heading2">
                              {this.props.location.pathname.match("/edit")
                                ? "Responsibilities of Team"
                                : "Responsibilities of Team"}
                            </h4>
                          </div>
                          <div className="divider" />
                          <div className="row-wrapper">
                            <div className="form-group">
                              <label className="control-label mb-5">
                                Team Title<span className="req">*</span>
                              </label>
                              <input
                                type="text"
                                name="title"
                                minLength={3}
                                maxLength={50}
                                autoComplete={"off"}
                                value={
                                  this.props.responsibility_information.title
                                }
                                onChange={(e) => this.handleResinfo(e)}
                                required={true}
                                className="form-control"
                                placeholder="Team Title"
                              />
                              <p className="with-error">
                                Please enter team value name (Min 3 char.).
                              </p>
                            </div>
                            <div className="form-group">
                              <label className="control-label mb-5">
                                Upload Image <span className="req">*</span>
                              </label>
                              <div className="custom-image-uploader">
                                <div className="custom-img-box">
                                  <div>
                                    <i className="material-icons">
                                      file_upload
                                    </i>
                                    <span>Upload Image</span>
                                  </div>
                                  <input
                                    className="image_third"
                                    type="file"
                                    name="image"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) =>
                                      this.handleResImg(
                                        e,
                                        "res_image",
                                        this.props.responsibility_information.id
                                      )
                                    }
                                  />
                                </div>
                                <div className="custom-img-preview">
                                  {this.props.responsibility_information
                                    .image !== "" && (
                                      <ul className="img-preview-ul">
                                        <li>
                                          <div className="prev-img-box">
                                            <div className="image-overlay"></div>
                                            <img
                                              src={
                                                this.props
                                                  .responsibility_information
                                                  .image
                                              }
                                              alt="image"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                </div>
                              </div>
                              <p className="with-error">
                                Please upload Image
                              </p>
                            </div>
                            <div className="form-group">
                              <label className="control-label mb-5">
                                Description<span className="req">*</span>
                              </label>
                              <textarea
                                rows={this.state.rows}
                                type="text"
                                className="form-control description"
                                name="description"
                                minLength={3}
                                autoComplete={"off"}
                                value={
                                  this.props.responsibility_information
                                    .description
                                }
                                onChange={(e) => this.handleResinfo(e)}
                                required={true}
                                placeholder="Description"
                              />
                              <p className="with-error">
                                <p className="with-error">
                                  Please enter description (Min 3 char.).
                                </p>
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* Responsibility of the team */}

                        {/* Add team values */}
                        <div className="create-team-form-inter-sectional-spacing">
                          <div className="form-btn-heading">
                            <h4 className="content-heading2">
                              {this.props.location.pathname.match("/edit")
                                ? "Edit Team Values"
                                : "Add Team Values"}
                            </h4>
                          </div>
                          <div className="divider" />
                          {this.props.what_will_do.map((d, i) => (
                            <div className={i === 0 ? "" : "row-wrapper"}>
                              <div className="form-group">
                                <label className="control-label mb-5">
                                  Team Value Name<span className="req">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  minLength={3}
                                  maxLength={50}
                                  autoComplete={"off"}
                                  value={d.name}
                                  onChange={(e) =>
                                    this.handleWhat_will_do(e, i)
                                  }
                                  required={true}
                                  className="form-control"
                                  placeholder="Team Value Name"
                                />

                                <p className="with-error">
                                  Please enter team value name (Min 3 char.).
                                </p>
                              </div>
                              <div className="form-group">
                                <label className="control-label mb-5">
                                  Upload Image <span className="req">*</span>
                                </label>
                                <div className="custom-image-uploader">
                                  <div className="custom-img-box">
                                    <div>
                                      <i className="material-icons">
                                        file_upload
                                      </i>
                                      <span>Upload Image</span>
                                    </div>
                                    <input
                                      className="team_image"
                                      type="file"
                                      name="team_image"
                                      id={"team_image" + i}
                                      accept=".png, .jpg, .jpeg"
                                      onChange={(e) =>
                                        this.handleTeamImg(e, "team_image", i)
                                      }
                                    />
                                  </div>
                                  <div className="custom-img-preview">
                                    {d.image !== "" && (
                                      <ul className="img-preview-ul">
                                        <li>
                                          <div className="prev-img-box">
                                            <div className="image-overlay"></div>
                                            <img src={d.image} alt="image" />
                                            <div className="image-dlt-details fadeIn-top">
                                              <i
                                                className="material-icons"
                                                onClick={() =>
                                                  this.deleteImage(
                                                    "team_image",
                                                    i,
                                                    d.image
                                                  )
                                                }
                                              >
                                                delete
                                              </i>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                </div>
                                <p className="with-error">
                                  Please upload Image
                                </p>
                              </div>

                              <div className="form-group">
                                <label className="control-label mb-5">
                                  Description<span className="req">*</span>
                                </label>
                                <textarea
                                  rows={this.state.rows}
                                  type="text"
                                  className="form-control description"
                                  name="description"
                                  minLength={3}
                                  autoComplete={"off"}
                                  value={d.description}
                                  onChange={(e) =>
                                    this.handleWhat_will_do(e, i)
                                  }
                                  required={true}
                                  placeholder="Description"
                                />
                                <p className="with-error">
                                  <p className="with-error">
                                    Please enter description (Min 3 char.).
                                  </p>
                                </p>
                              </div>
                              {i !== 0 && (
                                <div className="cross-btn">
                                  <span onClick={() => this.remwhatWillUdo(i)}>
                                    <i className="material-icons">clear</i>
                                  </span>
                                </div>
                              )}
                            </div>
                          ))}
                          <button
                            type="button"
                            className="btn mb-30"
                            onClick={this.whatWillUdo.bind(this)}
                          >
                            <span>+ </span>
                            ADD
                          </button>
                        </div>
                        {/* Add Team values */}

                        {/* Add Sub teams  */}
                        <div className="create-team-form-inter-sectional-spacing">
                          <div className="form-btn-heading">
                            <h4 className="content-heading2">
                              {this.props.location.pathname.match("/edit")
                                ? "Edit Sub Teams"
                                : "Add Sub Teams"}
                            </h4>
                          </div>

                          <div className="divider" />
                          {this.props.sub_teams.map((d, i) => (
                            <div className={i === 0 ? "" : "row-wrapper"}>
                              <div className="form-upload-heading">
                                <div className="form-group">
                                  <label className="control-label mb-5">
                                    Sub Team Name<span className="req">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    minLength={3}
                                    maxLength={50}
                                    autoComplete={"off"}
                                    value={d.name}
                                    onChange={(e) => this.handleSub_teams(e, i)}
                                    required={true}
                                    className="form-control"
                                    placeholder="Sub Team Name"
                                  />
                                  <p className="with-error">
                                    Please enter Sub team name (Min 3 char.).
                                  </p>
                                </div>
                                <div className="form-group">
                                  <label className="control-label mb-5">
                                    Upload Image<span className="req">*</span>
                                  </label>
                                  <div className="custom-image-uploader">
                                    <div className="custom-img-box">
                                      <div>
                                        <i className="material-icons">
                                          file_upload
                                        </i>
                                        <span>Upload Image</span>
                                      </div>
                                      <input
                                        className="sub_image_file"
                                        type="file"
                                        name="sub_team_image"
                                        id={"sub_image" + i}
                                        accept=".png, .jpg, .jpeg"
                                        onChange={(e) =>
                                          this.handleTeamImg(e, "sub_image", i)
                                        }
                                      />
                                    </div>
                                    <div className="custom-img-preview">
                                      {d.image !== "" && (
                                        <ul className="img-preview-ul">
                                          <li>
                                            <div className="prev-img-box">
                                              <div className="image-overlay"></div>
                                              <img
                                                src={d.image}
                                                alt={"image" + i}
                                              />
                                              <div className="image-dlt-details fadeIn-top">
                                                <i
                                                  className="material-icons"
                                                  onClick={() =>
                                                    this.deleteImage(
                                                      "sub_image",
                                                      i,
                                                      d.image
                                                    )
                                                  }
                                                >
                                                  delete
                                                </i>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  </div>
                                  <p className="with-error">
                                    Please upload Image
                                  </p>
                                </div>

                                <div className="form-group">
                                  <label className="control-label mb-5">
                                    Description<span className="req">*</span>
                                  </label>
                                  <textarea
                                    rows={this.state.rows}
                                    type="text"
                                    className="form-control description"
                                    name="description"
                                    minLength={3}
                                    autoComplete={"off"}
                                    value={d.description}
                                    onChange={(e) => this.handleSub_teams(e, i)}
                                    required={true}
                                    placeholder="Description"
                                  />
                                  <p className="with-error">
                                    <p className="with-error">
                                      Please enter description (Min 3 char.).
                                    </p>
                                  </p>
                                </div>
                              </div>
                              {i !== 0 && (
                                <div className="cross-btn">
                                  <span onClick={() => this.remsubTeam(i)}>
                                    <i className="material-icons">clear</i>
                                  </span>
                                </div>
                              )}
                            </div>
                          ))}

                          <button
                            type="button"
                            style={{ cursor: "pointer" }}
                            tabIndex={0}
                            className="btn mb-30"
                            onClick={this.subTeam.bind(this)}
                          >
                            <span>+ </span>
                            ADD
                          </button>
                        </div>
                        {/* Add Sub Teams */}

                        {/* Page Description  */}
                        <div className="create-team-form-inter-sectional-spacing">
                          <h4 className="content-heading2">Page Description</h4>
                          <div className="divider"></div>

                          <div className="form-group">
                            <label className="control-label">
                              Meta Title<span className="req">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              minLength={3}
                              autoComplete={"off"}
                              required={true}
                              value={this.props.createTeam.title}
                              onChange={this.onChange.bind(this)}
                              placeholder="Meta Title"
                            />
                            <p className="with-error">
                              Please enter Meta Title (Min 3 char.).
                            </p>
                          </div>
                          <div className="form-group">
                            <label className="control-label">
                              Meta Description<span className="req">*</span>
                            </label>
                            <input
                              type="text"
                              value={this.props.createTeam.meta_description}
                              className="form-control"
                              name="meta_description"
                              minLength={3}
                              autoComplete={"off"}
                              required={true}
                              onChange={this.onChange.bind(this)}
                              placeholder="Meta Description"
                            />
                            <p className="with-error">
                              Please enter Meta Description (Min 3 char.).
                            </p>
                          </div>
                        </div>
                        {/* Page Description */}
                        {/* Create team and cancel button  */}

                        <div className="create-team-form-inter-sectional-spacing">
                          <div className="form-group">
                            <button
                              type="submit"
                              disabled={this.props.pageLoading ? true : false}
                              className="btn mt-30"
                            >
                              {this.props.location.pathname.match("/edit")
                                ? "Update"
                                : "Create Team"}
                            </button>
                            <button
                              name="button"
                              style={{ marginLeft: "20px" }}
                              onClick={() =>
                                this.props.history.push(
                                  "/dashboard/team/list/page=1&pageSize=10"
                                )
                              }
                              className="btn mar-t-2"
                              type="submit"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                        {/* Create team and cancel button */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    createTeam,
    pageLoading,
    error,
    message,
    status,
    team_details_status,
    team_details_error,
    team_details_message,
    edit_team_details_status,
    edit_team_details_error,
    edit_team_details_message,
    responsibility_information,
    team_information,
    what_will_do,
    sub_teams,
  } = state.adminTeamReducer;
  return {
    createTeam,
    pageLoading,
    error,
    message,
    status,
    state,
    team_details_status,
    team_details_error,
    team_details_message,
    edit_team_details_status,
    edit_team_details_error,
    edit_team_details_message,
    responsibility_information,
    team_information,
    what_will_do,
    sub_teams,
  };
}

export default withRouter(connect(mapStateToProps)(CreateTeam));
