import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from "moment";

class DriveHeader extends Component {
   
    render() {
        return (
            <>
               <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="dashboard-card">
                            <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                            <span className="info-box-icon bg-green">
                                            <i class="fa fa-refresh fa-spin fa-3x fa-fw" aria-hidden="true" style={{fontSize:" inherit"}}></i>

                                            </span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">OnGoing</span>
                                        <span className="info-box-number">{(this.props.driveSummary.ongoing!== ''&&this.props.driveSummary.ongoing!== undefined )?this.props.driveSummary.ongoing: 0}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                            <span className="info-box-icon bg-green">
                                            <i class="fa fa-minus-square-o" aria-hidden="true"></i>
                                            </span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Not Started</span>
                                        <span className="info-box-number">{(this.props.driveSummary.not_started!=='' && this.props.driveSummary.not_started!== undefined)?this.props.driveSummary.not_started: 0}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                            <span className="info-box-icon bg-green">
                                            <i class="fa fa-check-square" aria-hidden="true"></i>
                                            </span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Completed</span>
                                        <span className="info-box-number">{(this.props.driveSummary.completed!=='' &&this.props.driveSummary.completed!== undefined)?this.props.driveSummary.completed:0}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                            <span className="info-box-icon bg-green">
                                            <i class="fa fa-users" aria-hidden="true"></i>

                                            </span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Registered</span>
                                        <span className="info-box-number">{(this.props.driveSummary.registered!=='' &&this.props.driveSummary.registered!== undefined)?this.props.driveSummary.registered:0}</span>
                                    </div>
                                </div>
                            </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="dashboard-card">
                                <div className="row">
                                    <div className="col-12 col-sm-3 col-m-3 drive-header">
                                        <div className="info">
                                            <span className="info-text">Drive Type</span>
                                            <span className="info-number">{(this.props.driveSummary.type!=='' &&this.props.driveSummary.type!== undefined)?this.props.driveSummary.type:0}</span>
                                        </div>
                                    </div>
                                    {this.props.driveSummary.type==='college'&&
                                        <div className="col-12 col-sm-3 col-m-3 drive-header">
                                            <div className="info">
                                                <span className="info-text">College Name</span>
                                                <span className="info-number">{(this.props.collegeSummary!=='' &&this.props.collegeSummary!== undefined)?this.props.collegeSummary.name:""}</span>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-12 col-sm-3 col-m-3 drive-header">
                                        <div className="info">
                                            <span className="info-text">Test Name</span>
                                            <span className="info-number">{(this.props.driveSummary.name!=='' &&this.props.driveSummary.name!== undefined)?this.props.driveSummary.name:0}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3 col-m-3 drive-header">
                                        <div className="info">
                                            <span className="info-text">Total Question</span>
                                            <span className="info-number">{(this.props.total!=='' &&this.props.total!== undefined)?this.props.total.name:0}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3 col-m-3 drive-header">
                                        <div className="info">
                                            <span className="info-text">Start Date</span>
                                            <span className="info-number">{moment.unix(this.props.summaryStartDate).format("DD/MM/YYYY")}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3 col-m-3 drive-header">
                                        <div className="info">
                                            <span className="info-text">End Date</span>
                                            <span className="info-number">{moment.unix(this.props.summaryEndDate).format("DD/MM/YYYY")}</span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    const {
        driveSummary,
        collegeSummary,
        summaryStartDate,
        summaryEndDate
    } = state.adminDriveReducer;
    return {
        driveSummary,
        collegeSummary,
        summaryStartDate,
        summaryEndDate
    }
};

export default withRouter(connect(mapStateToProps)(DriveHeader))
