/* --------------------------------------- creating pool -----------------------------------------------*/

export const CHANGE_CREATE_POOL_FORM = "CHANGE_CREATE_POOL_FORM";
export const CHANGE_CSV_DATA='CHANGE_CSV_DATA'
export const CREATE_POOL_REQUEST = "CREATE_POOL_REQUEST";
export const CREATE_POOL_SUCCESS = "CREATE_POOL_SUCCESS";
export const CREATE_POOL_FAILURE = "CREATE_POOL_FAILURE";

export const GET_POOL_REQUEST = "GET_POOL_REQUEST";
export const GET_POOL_SUCCESS = "GET_POOL_SUCCESS";
export const GET_POOL_FAILURE = "GET_POOL_FAILURE";

export const GET_POOL_DOWNLOAD_REQUEST = "GET_POOL_DOWNLOAD_REQUEST";
export const GET_POOL_DOWNLOAD_SUCCESS = "GET_POOL_DOWNLOAD_SUCCESS";
export const GET_POOL_DOWNLOAD_FAILURE = "GET_POOL_DOWNLOAD_FAILURE";


export const EDIT_POOL_REQUEST = "EDIT_POOL_REQUEST";
export const EDIT_POOL_SUCCESS = "EDIT_POOL_SUCCESS";
export const EDIT_POOL_FAILURE = "EDIT_POOL_FAILURE";

export const DELETE_POOL_REQUEST = "DELETE_POOL_REQUEST";
export const DELETE_POOL_SUCCESS = "DELETE_POOL_SUCCESS";
export const DELETE_POOL_FAILURE = "DELETE_POOL_FAILURE";

export const ADD_QUESTION_TO_POOL_REQUEST = "ADD_QUESTION_TO_POOL_REQUEST";
export const ADD_QUESTION_TO_POOL_SUCCESS = "ADD_QUESTION_TO_POOL_SUCCESS";
export const ADD_QUESTION_TO_POOL_FAILURE = "ADD_QUESTION_TO_POOL_FAILURE";

/* ----------------------------------------- adding question to pool ------------------------------------*/

export const ADD_IMAGE_REQUEST = "ADD_IMAGE_REQUEST";
export const ADD_IMAGE_SUCCESS = "ADD_IMAGE_SUCCESS";
export const ADD_IMAGE_FAILURE = "ADD_IMAGE_FAILURE";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE";

export const UPDATE_IMAGES = "UPDATE_IMAGES";

export const CHANGE_QUESTION = "CHANGE_QUESTION";
export const CHANGE_OPTION = "CHANGE_OPTION";

/*----------------------------------------------- pool questions list ------------------------------------*/

export const GET_QUESTIONS_REQUEST = "GET_QUESTIONS_REQUEST";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILURE = "GET_QUESTIONS_FAILURE";

export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";

/*------------------------------------------------- get question details -----------------------------------*/

export const GET_QUESTION_DETAILS_REQUEST = "GET_QUESTION_DETAILS_REQUEST";
export const GET_QUESTION_DETAILS_SUCCESS = "GET_QUESTION_DETAILS_SUCCESS";
export const GET_QUESTION_DETAILS_FAILURE = "GET_QUESTION_DETAILS_FAILURE";

/* ---------------------------------------------------edit question ----------------------------------------*/

export const EDIT_QUESTION_DETAILS_REQUEST = "EDIT_QUESTION_DETAILS_REQUEST";
export const EDIT_QUESTION_DETAILS_SUCCESS = "EDIT_QUESTION_DETAILS_SUCCESS";
export const EDIT_QUESTION_DETAILS_FAILURE = "EDIT_QUESTION_DETAILS_FAILURE";

/*-------------------------------------------------- upload questions using csv ----------------------------*/

export const UPLOAD_CSV_REQUEST = "UPLOAD_CSV_REQUEST";
export const UPLOAD_CSV_SUCCESS = "UPLOAD_CSV_SUCCESS";
export const UPLOAD_CSV_FAILURE = "UPLOAD_CSV_FAILURE";

export const SELECTED_DELETE_QUESTION='SELECTED_DELETE_QUESTION'
/* ------------------------------------------- common -----------------------------------------------------*/

export const CLEAR_ALL = "CLEAR_ALL";

export const CLEAR_STATUS = "CLEAR_STATUS";

export const SELECT_DELETE_POOL = "SELECT_DELETE_POOL";

export const CHANGE_CREATE_QUESTION_CSV_FORM = "CHANGE_CREATE_QUESTION_CSV_FORM"

export const HANDLE_PAGINATION_NUMBER_CHANGE = 'HANDLE_PAGINATION_NUMBER_CHANGE'

export const    NEW_USER_PAGE_NUMBER ='NEW_USER_PAGE_NUMBER'