import React from 'react'
import Pagination from "react-js-pagination";

export class TablePaginationNumber extends React.Component {
  state = {
    pageSizeOptions: this.props.pageSizeOptions
  }

  static defaultProps = {
    pageSizeOptions: []
  }


  render() {
    const { pageSizeOptions } = this.state
    const { pageSize, hasPrevPage, hasNextPage, handlePageChange,  handlePrevPage, handleNextPage, currentPage, total } = this.props
    let totalPage = Math.ceil(total/pageSize);

    return (
      <div className="pagination-outer">
          <div className="pagination-group">
          <Pagination
            firstPageText={"First"}
            lastPageText="Last"
            prevPageText={<i className='fa fa-angle-left'/>}
            nextPageText={<i className='fa fa-angle-right'/>}
            activePage={currentPage}
            itemsCountPerPage={pageSize}
            totalItemsCount={totalPage}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
            />
          </div>
        </div>
    )
  }
}
