import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    changeEmailRegisterForm,
    changeLoginForm,
    clearApiErrorMessage,
    login
} from "../../../../actions/account/login-actions";
import Button from '../../../../components/app/button/button';
import BarLoaderSpinner from '../../../../components/app/spinner/barloader';
import Helmet from 'react-helmet';
import { authApi } from '../../../../actions/ApiActions';


const ENV_VAR = process.env.REACT_APP_STATIC_PAGE === undefined ? "https://xenonstack.jobs/" : process.env.REACT_APP_STATIC_PAGE;
const BASE_URL =authApi();
class Applymanual extends Component {
    componentWillMount() {
        document.title = "Login | Xenonstack Hiring Portal";
        this.props.dispatch(clearApiErrorMessage());
        this.props.dispatch(changeLoginForm({ email: "", password: "" }));
    }
    componentDidMount() {
        if (new URL(window.location.href).searchParams.get("token")) {
            const token = new URL(window.location.href).searchParams.get("token");
            const sys_role = new URL(window.location.href).searchParams.get(
              "role_id"
            );
            const name = new URL(window.location.href).searchParams.get("name");
            const email = new URL(window.location.href).searchParams.get("email");
            localStorage.setItem("token", token);
            localStorage.setItem("sys_role", sys_role);
            localStorage.setItem("name", name);
            localStorage.setItem("email", email);
            window.location.replace('/app/dashboard');
        }
 
    }
  
    // submit the form
    submitLoginForm(e) {
        e.preventDefault();
        const self = this.props;
        if (e.target.checkValidity()) {
            const body = {
                username: self.email,
                password: self.password
            };
            self.dispatch(login(body));
        } else {
            const invalidElms = document.querySelectorAll(".login-form .form-group input:invalid");
            invalidElms[0].focus();
            for (let i = 0; i < invalidElms.length; i++)
                invalidElms[i].parentElement.classList.add("has-error")
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status === 200) {
            this.props.history.push("/admin");
        }
    }

    // called when onchange html event fired
    onChange(e) {
        const self = this.props;
        self.dispatch(clearApiErrorMessage());
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove("has-error");
        } else {
            e.target.parentElement.classList.add("has-error");
        }
        if (e.target.id === "checkEmail") {
            const newState = Object.assign(self.emailRegisterForm, {
                [e.target.name]: e.target.value
            });
            self.dispatch(changeEmailRegisterForm(newState))
        } else {
            const newState = Object.assign(self.loginForm, {
                [e.target.name]: e.target.value
            });
            self.dispatch(changeLoginForm(newState))
        }
    }

    loginWithGoogle=()=>{
     window.location.replace(`${BASE_URL}/v1/google/login?redirect=${window.location.href}`)
    }

    loginWithLinkedIn=()=>{
        window.location.replace(`${BASE_URL}/v1/linkedin/login?redirect=${window.location.href}`)
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Login | XenonStack Careers</title>
                </Helmet>
                {/* <Header/> */}
                <main className="main-body" id="main-body">
                    <div className="wrapper-container signing-container sign-up">
                        {/* <div className="container"> */}
                        <div className="container  user-jounrney-form-container">
                            <div className="user-journey-form">
                                <div className="xenonstack-jobs-logo">
                                    <a href={ENV_VAR}> <img src={require('../../../../static/images/logoxe.svg')} /></a>
                                </div>
                                <div className="dashbaord-user-form">
                                    <div className="section_header">
                                        <h3 className="big-heading">New User</h3>
                                        <h4 className="subtitle">If you are not registered yet . Get an Account in few easy steps</h4>
                                    </div>
                                    <div className="signup-page" id="signup-page">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-10  mar-xs-30">
                                                <div className="login-div new-user" id="new-user">

                                                    <form id="loginForm" noValidate={true}>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-10 col-md-12">
                                                                {/* <div className="form-group">
                                                        <label>If you are not registered yet, get an account in a few easy
                                                            steps</label>
                                                    </div> */}
                                                            </div>
                                                            <div className="col-12 col-sm-10 col-md-12">
                                                                <div className="form-group btn-group">
                                                                    <Button
                                                                        onClick={this.loginWithLinkedIn}
                                                                        type="button" id="loginwithLinkedin" className="btn linkedin_btn"
                                                                        text="Apply with Linkedin" />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-10 col-md-12">
                                                                <div className="form-group btn-group">
                                                                    <Button
                                                                        onClick={this.loginWithGoogle}
                                                                        type="button" id="loginwithLinkedin" className="btn linkedin_btn"
                                                                        text="Apply with Google" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-10 col-md-12">
                                                                <div className="form-group">
                                                                    <label className="or_label">OR</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-10 col-md-12">
                                                                <div className="form-group btn-group">
                                                                    <Button type="Button"
                                                                        onClick={() => this.props.history.push("/apply-manual/check-email")}
                                                                        id="loginManually" className="btn manually_btn"
                                                                        text="Sign Up Manually" />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <NavLink className="dont-have-an-account" to="/login">Already have an Account ? Sign in</NavLink>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xenonstack-jobs-copyright">
                                    <p>Xenonstack | </p>
                                    <i class="fa fa-copyright"></i>
                                    <p>2021 All Rights Reserved</p>
                                </div>
                            </div>
                            <BarLoaderSpinner pageLoading={this.props.loginPageLoading} />
                        </div>
                        {/* </div> */}
                    </div>
                </main>
                {/* <Footer/> */}
            </>
        )
    }
}

function mapStateToProps(state) {
    const { loginForm, emailRegisterForm, loginPageLoading, message, status, isAuthenticated } = state.LoginReducer;
    const { email, password } = loginForm;
    const { emailAddress } = emailRegisterForm;
    return { loginForm, emailRegisterForm, email, password, emailAddress, loginPageLoading, message, status, isAuthenticated };
}

export default withRouter(connect(mapStateToProps)(Applymanual))
