export const ACTIVITIES_REQUEST = "ACTIVITIES_REQUEST";
export const ACTIVITIES_SUCCESS = "ACTIVITIES_SUCCESS";
export const ACTIVITIES_FAILURE = "ACTIVITIES_FAILURE";
export const CLEAR_ALL = "CLEAR_ALL";

export const GET_USER_RESULT_REQUEST='GET_USER_RESULT_REQUEST'
export const GET_USER_RESULT_SUCCESS='GET_USER_RESULT_SUCCESS'
export const GET_USER_RESULT_FAILURE='GET_USER_RESULT_FAILURE'

export const UPLOAD_RESUME_REQUEST="UPLOAD_RESUME_REQUEST";
export const UPLOAD_RESUME_SUCCESS="UPLOAD_RESUME_SUCCESS";
export const UPLOAD_RESUME_FAILURE="UPLOAD_RESUME_FAILURE";