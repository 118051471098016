import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Input from '../../../../../components/app/input/input';
import NextBackSection from './next-back';
import { changeRegistrationForm, checkSignupStatus } from "../../../../../actions/account/registration-action";
import { checkValidation } from "../../../../../actions/app/app";
const ENV_VAR = process.env.REACT_APP_STATIC_PAGE === undefined ? "https://xenonstack.jobs/" : process.env.REACT_APP_STATIC_PAGE;

class CreatePasswordSection extends Component {
    componentWillMount() {
        window.scrollTo(0, 0);
        document.title = "Registration | Create Password | XenonStack Hiring Portal";
        checkSignupStatus(this.props.goToRegistration, window.location.href);
    }

    componentDidMount() {
        document.getElementById("oldPassword").focus();
    }
    // called when onchange html event fired
    onChange(e) {
        const self = this.props;
        checkValidation(e);
        if (e.target.name === "confirm_password") {
            if (e.target.value !== self.password) {
                e.target.parentElement.classList.add("has-error")
            }
            else {
                e.target.parentElement.classList.remove("has-error")
            }
        }
        const newState = Object.assign(self.registrationForm, {
            [e.target.name]: e.target.value
        });
        self.dispatch(changeRegistrationForm(newState))
    }

    // submit the create password form
    submitCreatePasswordForm(e) {
        e.preventDefault();
        const self = this.props;
        let passwordMatch = true;
        if (self.password !== self.confirm_password) {
            passwordMatch = false
        }
        if (e.target.checkValidity() && passwordMatch) {
            self.history.push("/apply-manual/preview");
        }
        else {
            if (!passwordMatch) {
                document.getElementsByName("confirm_password")[0].parentElement.classList.add("has-error")
            }
            const invalidElms = document.querySelectorAll(".create-password-form .form-group input:invalid");
            for (let i = 0; i < invalidElms.length; i++)
                invalidElms[i].parentElement.classList.add("has-error")
        }
    }

    render() {
        // console.log((this.props.state.RegisterReducer.registrationForm.interestOne.concat(this.props.state.RegisterReducer.registrationForm.interestTwo)).concat(this.props.state.RegisterReducer.registrationForm.interestThree), "concat...")
        window.onbeforeunload = function () {
            return "Do you really want to leave our brilliant application?";
        };
        return (
            <div className="wrapper-container signing-container create-password">
                <div className='container  user-jounrney-form-container'>
                    <div className='user-journey-form user-journey-form-internal-steps'>
                        <div className="xenonstack-jobs-logo">
                            <a href={ENV_VAR}> <img src={require('../../../../../static/images/logoxe.svg')} /></a>
                        </div>
                        <div className='dashbaord-user-form'>
                            <div className="section_header">
                                <label>Step 5/5</label>
                                <h3 className="big-heading">Create Password </h3>
                                <h4 className="subtitle">Speed up other applications by creating an account. We'll
                                    prefill your application using the information that you previously submitted.</h4>
                            </div>
                            <div className="password" id="password">
                                <form onSubmit={this.submitCreatePasswordForm.bind(this)} noValidate={true}
                                    className="create-password-form">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Password<span className="req">*</span></label>
                                                <Input
                                                    type="password"
                                                    name="password"
                                                    id="oldPassword"
                                                    autoComplete="new-password"
                                                    maxLength={32}
                                                    className="form-ctrl"
                                                    placeholder="Password"
                                                    pattern={"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"}
                                                    required={true}
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.props.password} />
                                                <p className="with-error" style={{ color: "unset" }}>Enter your
                                                    password (<span
                                                        style={{ color: `${this.props.password.length >= 8 ? "green" : "red"}` }}>Min 8 characters</span>, <span
                                                            style={{ color: `${this.props.password.match("(?=.*?[A-Z])") ? "green" : "red"}` }}>at least one uppercase letter</span>, <span
                                                                style={{ color: `${this.props.password.match("(?=.*?[a-z])") ? "green" : "red"}` }}>one lowercase letter</span>, <span
                                                                    style={{ color: `${this.props.password.match("(?=.*?[0-9])") ? "green" : "red"}` }}>one number and </span>
                                                    <span
                                                        style={{ color: `${this.props.password.match("(?=.*?[#?!@$%^&*-])") ? "green" : "red"}` }}>one special character required</span>).
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Confirm Password<span className="req">*</span></label>
                                                <Input
                                                    type="password"
                                                    name="confirm_password"
                                                    autoComplete="new-password"
                                                    maxLength={32}
                                                    className="form-ctrl"
                                                    placeholder="Confirm Password"
                                                    pattern={"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"}
                                                    required={true}
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.props.confirm_password} />
                                                <p className="with-error">Both password should match.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <NextBackSection>
                                        {this.props.children}
                                    </NextBackSection>
                                </form>
                            </div>
                        </div>
                        <div className="xenonstack-jobs-copyright">
                            <p>Xenonstack | </p>
                            <i class="fa fa-copyright"></i>
                            <p>2021 All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { registrationForm, goToRegistration } = state.RegisterReducer;
    const { password, confirm_password } = registrationForm;
    return { password, confirm_password, goToRegistration, registrationForm, state }
}

export default withRouter(connect(mapStateToProps)(CreatePasswordSection))
