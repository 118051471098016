import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    changeCreateCollegeForm, clearStatus, createCollege, editCollegeDetails, getCollege,
    getCollegeDetails, clearAll, clearTextBox
} from '../../../../../actions/dashboard/admin/college/college'
import {checkValidation} from "../../../../../actions/app/app";
import createNotification from "../../../../../components/app/notification/notification";
 

class AddCollegeManually extends Component {
  componentWillMount() {
    if (this.props.location.pathname.match("/edit")) {
      this.props.dispatch(getCollegeDetails(this.props.match.params.college));
    }
  }
  // submit the form
    handleSubmit(e) {
        e.preventDefault();
        const self = this.props;
        if (e.target.checkValidity()) {
            const body = self.createCollege;
            if (self.location.pathname.match("/edit")) {
                self.dispatch(editCollegeDetails(self.match.params.college, body))
            }
            else {
                self.dispatch(createCollege(body))
            }
        }
        else {
            const invalidElmsInput = document.querySelectorAll(".create-college-form .form-group input:invalid");
            for (let i = 0; i < invalidElmsInput.length; i++) {
                invalidElmsInput[i].parentElement.classList.add("has-error")
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.createCollegeMessage !== "") {
            if (nextProps.createCollegeError) {
                createNotification('error', nextProps.createCollegeMessage);
                nextProps.dispatch(clearStatus());
            } else {
                createNotification('success', nextProps.createCollegeMessage);
                nextProps.dispatch(changeCreateCollegeForm({name: "", location: ""}))
                nextProps.dispatch(getCollege("1"));
            }
        }
        if (nextProps.editCollegeDetailsMessage !== "") {
            if (nextProps.editCollegeDetailsError) {
                createNotification('error', nextProps.editCollegeDetailsMessage);
                nextProps.dispatch(clearStatus());
            } else {
                createNotification('success', nextProps.editCollegeDetailsMessage);
                nextProps.dispatch(changeCreateCollegeForm({name: "", location: ""}))
                nextProps.dispatch(clearStatus());
                nextProps.history.push("/dashboard/college/add/"+'1')
                nextProps.dispatch(getCollege("1"));
            }
        }
    }

    // called when onchange html event fired
    handleChange(e) {
        checkValidation(e);
        const newState = Object.assign(this.props.createCollege, {
            [e.target.name]: e.target.value
        });
        this.props.dispatch(changeCreateCollegeForm(newState))
        this.forceUpdate();
    }
    // add college manually
    addCollegeManually() {
      this.props.dispatch(clearAll());
      this.props.dispatch(getCollege("1"));
      this.props.history.push("/dashboard/college/add/1")
      
    }

    onCancel(){
        this.props.dispatch(clearTextBox())
        this.props.history.push('/dashboard/college/add/1');
    }
    render() {
        // console.log(render)
        return (
            <div>
                <div className="form-group mar-t-3">
                    <label className="subtitle">{this.props.location.pathname.match("/edit") ? "Edit College" : "Add College Manually"}</label>
                    {
                      this.props.location.pathname.match("/edit") &&
                      <a style={{cursor: "pointer"}} onClick={this.addCollegeManually.bind(this)}>Add college manually</a>
                    } 

                </div>
                <form onSubmit={this.handleSubmit.bind(this)} className="create-college-form" id="college_form" noValidate={true}>
                    <div className="form-body">
                        <div class="row">
                            <div className="col-lg-12 col-md-6 col-sm-6 form-group">
                                <label className="control-label mb-5">College Name<span className="req">*</span></label>
                                <input type="text" className="form-control"
                                    name="name"
                                    onChange={this.handleChange.bind(this)}
                                    pattern={"[a-zA-Z0-9+-/&][a-zA-Z+-/&0-9 ]+[a-z+-/&A-Z0-9]$"}
                                    value={this.props.createCollege.name}
                                    required={true}
                                    minLength={3}
                                    placeholder="Enter College Name"/>
                                <p className="with-error">Please enter College Name (Min 3 characters required, space before and after the text is not allowed, only -/& special characters are allowerd).</p>
                            </div>
                            <div className="col-lg-12 col-md-6 col-sm-6 form-group">
                                <label className="control-label mb-5">Location<span className="req">*</span></label>
                                <input type="text" className="form-control"
                                    name="location"
                                    onChange={this.handleChange.bind(this)}
                                    pattern={"[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"}
                                    value={this.props.createCollege.location}
                                    required={true}
                                    minLength={3}
                                    placeholder="Enter Location"/>
                                <p className="with-error">Please enter college location (Min 3 characters required, no
                                    special
                                    characters allowed, space before text is not allowed).</p>
                            </div>
                        </div>
                    
                        <button type="submit"
                                className="btn">{this.props.location.pathname.match("/edit") ? "Edit College" : "Add College"}</button>
                    
                        <button type="reset" value="Reset" onClick={()=> this.onCancel()}
                                style={{marginLeft: "20px"}}    className="btn">Cancel</button>
                    
                    </div>
                </form>
                <div className="spinner-container">
                    {/* <ClipLoader
                        className='spinner'
                        sizeUnit={"px"}
                        size={60}
                        color={'#123abc'}
                        loading={this.props.createCollegePageLoading || this.props.editCollegeDetailsPageLoading}
                    /> */}
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {
        editCollegeDetailsPageLoading, editCollegeDetailsStatus, editCollegeDetailsError, editCollegeDetailsMessage,
        createCollege, createCollegePageLoading, createCollegeStatus, createCollegeError, createCollegeMessage
    } = state.adminCollegeReducer;
    return {
        editCollegeDetailsPageLoading, editCollegeDetailsStatus, editCollegeDetailsError, editCollegeDetailsMessage,
        createCollege, createCollegePageLoading, createCollegeStatus, createCollegeError, createCollegeMessage
    }
}

export default withRouter(connect(mapStateToProps)(AddCollegeManually))
