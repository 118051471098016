import {
  GET_ALL_LANES_FAILURE, GET_ALL_LANES_SUCCESS, GET_ALL_LANES_REQUEST, DELETE_SELECTED_LANE, CREATE_LANE_REQUEST, CREATE_LANE_SUCCESS,
  CREATE_LANE_FAILURE,CHANGE_CREATE_LANE_FORM, DELETE_LANE_REQUEST, DELETE_LANE_SUCCESS, DELETE_LANE_FAILURE, EDIT_LANE_REQUEST,
  EDIT_LANE_SUCCESS, EDIT_LANE_FAILURE,CLEAR_ALL, CLEAR_STATUS,EDIT_STATUS_FAILURE, EDIT_STATUS_SUCCESS, EDIT_STATUS_REQUEST,
  EDIT_DATE_REQUEST, EDIT_DATE_SUCCESS, CREATE_COMMENT_REQUEST, CREATE_COMMENT_SUCCESS, CREATE_COMMENT_FAILURE, GET_COMMENT_REQUEST,
  GET_COMMENT_SUCCESS, GET_COMMENT_FAILURE, GET_SELECTED_CARD, CHANGE_CREATE_COMMENT_FORM
} from "../../../../types/dashboard/admin/wekanboard/wekanboard";
import { GET_API } from "../../../../middleware/token/get-api";
import { CALL_POST_API } from "../../../../middleware/token/post-api";
import { CALL_DELETE_API } from "../../../../middleware/token/delete/without-body";
import { PUT_API } from "../../../../middleware/token/put_api/put-api-with-body";
import { authApi } from "../../../ApiActions";
const BASE_URL = authApi();

export function getAllLanes() {
  return {
    [GET_API]: {
      endpoint: BASE_URL + "/v1/lane",
      types: [ GET_ALL_LANES_REQUEST, GET_ALL_LANES_SUCCESS, GET_ALL_LANES_FAILURE ]
    }
  };
}
export function changeCreateLaneForm(newState) {
  return {
    type: CHANGE_CREATE_LANE_FORM,
    newState
  };
}
export function createLane(body) {
  return {
    [CALL_POST_API]: {
      endpoint: BASE_URL + "v1/lane",
      types: [CREATE_LANE_REQUEST, CREATE_LANE_SUCCESS, CREATE_LANE_FAILURE],
      body: body
    }
  };
}
export function deleteLane(lane_id) {
  return {
    [CALL_DELETE_API]: {
      endpoint: BASE_URL + "v1/lane/" + lane_id,
      types: [DELETE_LANE_REQUEST, DELETE_LANE_SUCCESS, DELETE_LANE_FAILURE]
    }
  };
}
export function deleteSeletedLane(lane) {
  return {
    type: DELETE_SELECTED_LANE,
    lane
  };
}
export function editLane(lane_id, body) {
  return {
    [PUT_API]: {
      endpoint: BASE_URL + "v1/lane/" + lane_id,
      types: [EDIT_LANE_REQUEST, EDIT_LANE_SUCCESS, EDIT_LANE_FAILURE],
      body: JSON.stringify(body)
    }
  };
}
export function openSelectedCard(card){
  return{
    type:GET_SELECTED_CARD,
    card
  }
}
export function changeCreateCommentForm(newState){
   
  return{
    type:CHANGE_CREATE_COMMENT_FORM,
    newState
  }
}
export function clearStatus() {
  return {
    type: CLEAR_STATUS
  };
}
export function clearAll() {
  return {
    type: CLEAR_ALL
  };
}

export function updateCardStatus(body) //laneid and card email
{ 
  return{
    [PUT_API]:{
      endpoint: BASE_URL +'/v1/card/status',
      types:[EDIT_STATUS_REQUEST,EDIT_STATUS_SUCCESS,EDIT_STATUS_FAILURE],
      body:JSON.stringify(body) //here we will pass email and laneId 
    }
  }
}

export function updateCardDate(body)//cardid and updated date
{
  return{
    [PUT_API]:{
      endpoint:BASE_URL + '/v1/card/date',
      types:[EDIT_DATE_REQUEST,EDIT_DATE_SUCCESS,EDIT_LANE_FAILURE],
      body:JSON.stringify(body)
    }
  }
}

export function createCardComment(body)
{
  return{
    [CALL_POST_API]:{
      endpoint:BASE_URL +'/v1/card/comment',
      types:[CREATE_COMMENT_REQUEST,CREATE_COMMENT_SUCCESS,CREATE_COMMENT_FAILURE],
      body
    }
  }
}
export function getCardComment(emailid){
   
  return{
    [GET_API]:{
      endpoint: BASE_URL+'/v1/card/comment/'+emailid,
      types:[GET_COMMENT_REQUEST,GET_COMMENT_SUCCESS,GET_COMMENT_FAILURE]
    }
  }
}