import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AdminOnPageNavigation from '../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import {getQuestions, clearStatus, deleteQuestion, clearAll, handlePaginationPropsChange,selectedDeleteQuestion} from "../../../../../actions/dashboard/admin/pool/pool";
import createNotification from "../../../../../components/app/notification/notification";
import {NotificationContainer} from 'react-notifications';
import { PulseLoader } from 'react-spinners';
import Pagination from 'react-js-pagination';
import DeleteDialogBox from "../../../../../components/app/dialogBox/delete-dialog-box";
import { Table, TableRow, TableBody, TableHeaderRow, TableHeader } from '../../../../../components/dashboard/admin/xd-react-table/react-flex-table'
import { TableHeadComponent , ThComponent, PageSizeChooser} from '../../../../../components/dashboard/admin/xd-react-table/react-flex-table/components';
import {Helmet} from "react-helmet";

class PoolDetails extends Component {
    handlePageChange = e => {
      this.props.dispatch(handlePaginationPropsChange(e, this.props.defaultPageSize))
      this.props.history.push('/dashboard/pool/'+this.props.match.params.pool_id+'/details/page='+e+'&pageSize='+this.props.defaultPageSize)
    }
    handlePageSizeChange = e => {
        
      this.props.dispatch(handlePaginationPropsChange(this.props.pageNumber, Number(e.target.value)))
      this.props.history.push('/dashboard/pool/'+this.props.match.params.pool_id+'/details/page='+this.props.pageNumber+'&pageSize='+e.target.value)
    }
    componentWillMount() {
        this.props.dispatch(handlePaginationPropsChange(Number(this.props.match.params.pageNumber), Number(this.props.match.params.pageSize)))
        this.props.dispatch(clearAll());
        this.props.dispatch(getQuestions(this.props.match.params.pool_id));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleteQuestionMessage !== "") {
            if (nextProps.error) {
                createNotification('error', nextProps.deleteQuestionMessage);
                nextProps.dispatch(clearStatus());
            }
            else {
                createNotification('success', nextProps.deleteQuestionMessage);
                nextProps.dispatch(getQuestions(nextProps.match.params.pool_id));
            }
        }
    }

    // redirect to edit page
    editPoolQuestion(question) {
        // /dashboard/pool/:pool_id/details/questions/:question/edit
        this.props.history.push("/dashboard/pool/" + this.props.match.params.pool_id + "/details/questions/" + question + "/edit")
    }

    // calls api to delete question
    deleteQuestion(e) {
        e.preventDefault();
        this.props.dispatch(deleteQuestion(this.props.match.params.pool_id, this.props.selected))
        if (!!document.getElementsByClassName("overlay-delete")[1]) {
            document.getElementsByClassName("overlay-delete")[1].style.display = "none";
        }
    }
    openDeleteQuestionDialog(question) {
        this.props.dispatch(selectedDeleteQuestion(question));
       
        document.getElementsByClassName("overlay-delete")[1].style.display = "block";
         
    }
    closeDeleteQuestionDialog() {
        
        if (!!document.getElementsByClassName("overlay-delete")[1]) {
            document.getElementsByClassName("overlay-delete")[1].style.display = "none";
        }
    }
    render() {
        
        return (
            <div>
            <Helmet>
                <title>Pool Details | Career Portal | Xenonstack</title>
             </Helmet>
                <AdminOnPageNavigation parentRoute="Dashboard" childRoute="Questions List"/>
                <button className="btn mar-b-3"
                    onClick={() => this.props.history.push("/dashboard/pool/" + this.props.match.params.pool_id + "/add/questions")}>Add
                    question
                </button>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-default card-view custom-panel pool-panel">
                            <div className="panel-heading">
                                <h5 className="panel-title">Questions List - ({this.props.questions.length} Questions)</h5>
                                <div className="clearfix"></div>
                            </div>
                            <div className="panel-wrapper collapse in">
                              <div className="panel-body">
                                  <div className="table-wrap">
                                  <div className="text-center">
                                      {this.props.pageLoading &&
                                       <PulseLoader
                                       margin="30px"
                                       sizeUnit={"px"}
                                       size={15}
                                       color={'#48194e'}
                                       loading={this.props.isPageLoading}
                                       />
                                  }</div>
                                <div className="">
                                <div className="react-xd-table-wrapper">
                                    { !this.props.pageLoading && this.props.questions.length > 0 &&
                                                <Table
                                                    data={this.props.questions}
                                                    pageSize={this.props.defaultPageSize}
                                                    pageSizeOptions={[10,20,30,40,50]}
                                                    currentPage={this.props.pageNumber}
                                                    render={({
                                                    rows,
                                                    pageSize,
                                                    pageSizeOptions,
                                                    handlePrevPage,
                                                    handleNextPage,
                                                    hasNextPage,
                                                    hasPrevPage,
                                                    currentPage,
                                                    total
                                                    }) => {

                                                    return (
                                                        <React.Fragment>
                                                        <div className="react-xd-table-body">
                                                            <table className="react-xd-table striped-xd-table ver-responsive-table footer-pagination-table">
                                                            <TableHeaderRow component={TableHeadComponent}>
                                                                <TableHeader
                                                                  accessor="sr_no"
                                                                  accessor_name="Sr. No."
                                                                  component={ThComponent}
                                                                  >
                                                                  Sr. No .
                                                                </TableHeader>
                                                                <TableHeader
                                                                  accessor="title"
                                                                  accessor_name="Questions"
                                                                  component={ThComponent}
                                                                >
                                                                Questions
                                                                </TableHeader>

                                                                <TableHeader
                                                                    accessor_name="Action"
                                                                    component={ThComponent}
                                                                >
                                                                Action
                                                                </TableHeader>
                                                                </TableHeaderRow>
                                                                <TableBody
                                                                    component="tbody"
                                                                    >
                                                                    {rows.map(({ rowKey, rowData, selected, }, rowIndex) => (
                                                                    <TableRow
                                                                        component="tr"
                                                                        className="xd-table-row xd-table-td"
                                                                        key={rowIndex}
                                                                    >
                                                                    {rowData.map(({ accessor, accessor_name, data, key , rowsData }, index) => {

                                                                      return ( accessor ? (<td  data-value={accessor_name} key={key} style={{'minWidth':'100px'}}>
                                                                      {accessor === 'title' ? (<div dangerouslySetInnerHTML={{__html: rowsData.title}}/>) :
                                                                        accessor === 'sr_no' ? rowIndex + (this.props.defaultPageSize * (this.props.pageNumber-1))+1 :
                                                                       (data)}
                                                                      </td>) :
                                                                       (<td key={key}  data-value={accessor_name} style={{'minWidth':'150px'}}>
                                                                            <div className="table-action-group">
                                                                              <button type="button" onClick={() => this.props.history.push("/dashboard/pool/" + this.props.match.params.pool_id + "/details/questions/" + rowsData.id + "/edit")} className="xd-table-btn view">Edit</button>
                                                                              <button type="button"  onClick={() => this.openDeleteQuestionDialog(rowsData.id)} className="xd-table-btn delete">Delete</button>
                                                                            </div>
                                                                        </td>
                                                                      )) }
                                                                    )}
                                                                    </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                                </table>
                                                                </div>
                                                                <div className="react-xd-table-footer">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-5 col-md-4">
                                                                        <div className="react-xd-pagesize-chooser">
                                                                            <span>Rows per page:</span>
                                                                            <PageSizeChooser
                                                                            pageSize={this.props.defaultPageSize}
                                                                            pageSizeOptions={pageSizeOptions}
                                                                            handlePageSizeChange={this.handlePageSizeChange.bind(this)}
                                                                            />
                                                                        </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-7 col-md-8">
                                                                        <div className="react-xd-pagination-container">
                                                                        <div className="pagination-outer">
                                                                            <div className="pagination-group">
                                                                            <Pagination
                                                                                firstPageText={"First"}
                                                                                lastPageText="Last"
                                                                                prevPageText={<i className='fa fa-angle-left'/>}
                                                                                nextPageText={<i className='fa fa-angle-right'/>}
                                                                                activePage={this.props.pageNumber}
                                                                                itemsCountPerPage={this.props.defaultPageSize}
                                                                                totalItemsCount={total}
                                                                                pageRangeDisplayed={3}
                                                                                onChange={this.handlePageChange.bind(this)}
                                                                                />
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                            </React.Fragment>
                                                        )
                                                        }}
                                                    />}
                                                    </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DeleteDialogBox
                    formName="Delete Team"
                    submitForm={this.deleteQuestion}
                    operation="Delete Team"
                    keyword={"Delete"}
                    closeForm={this.closeDeleteQuestionDialog}
                    pageLoading={this.props.pageLoading}
                    selected={this.props.deleteQuestion}
                    name={this.props.deleteTeam}
                />
                <NotificationContainer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {questions, pageLoading, error, message, status, deleteQuestionMessage, pageNumber,defaultPageSize,deleteQuestion} = state.poolReducer;
    return {questions, pageLoading, error, message, status, deleteQuestionMessage, pageNumber,defaultPageSize,deleteQuestion}
}

export default withRouter(connect(mapStateToProps)(PoolDetails))
