import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AdminOnPageNavigation from '../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import {clearAll, getUserDetails} from '../../../../actions/dashboard/admin/user/user';
import moment from 'moment';
import {
    clearAll as clearActivitiesResponse,
    getActivities,
    uploadResume
} from "../../../../actions/dashboard/user/activities/activities";
import { PulseLoader } from 'react-spinners';
import {Helmet} from "react-helmet";
import 'antd/dist/antd.css';
import createNotification from "../../../../components/app/notification/notification";

let interests = "";
class CandidateDetails extends Component {
    componentWillMount() {
        this.props.dispatch(clearActivitiesResponse());
        this.props.dispatch(clearAll());
        this.props.dispatch(getUserDetails(this.props.match.params.email));
        this.props.dispatch(getActivities("/v1/users/" + this.props.match.params.email + "/jobs"))
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!!nextProps.userDetails.profile) {
            if (nextProps.userDetails.profile.interests.length > 0) {
                interests = "";
                const selectedInterest = nextProps.userDetails.profile.interests;
                for (let i in selectedInterest) {
                    interests = interests + selectedInterest[i] + " , ";
                }
                interests = interests.slice(0, interests.length - 2)
            }
        }
        if(nextProps.uploadResume_Message!==""){
            if(nextProps.uploadResume_Error){
                createNotification("error",nextProps.uploadResume_Message)
            }else{
                 nextProps.dispatch(getUserDetails(this.props.match.params.email))
                 createNotification("success",nextProps.uploadResume_Message)
            }
        }
    }
    resumeUpload(e) {
        this.props.dispatch(uploadResume(e.target.files[0], this.props.match.params.email))

    }
  
    render() {
        let url      
        if(!this.props.state.userReducer.pageLoading && this.props.userDetails.resumeLink){
            url=this.props.userDetails.resumeLink.split('?')[0].split('.').pop()
        }
         return (
            <div>
            <Helmet>
                <title>Candidate Details | Career Portal | Xenonstack</title>
             </Helmet>
                <AdminOnPageNavigation parentRoute="Dashboard" childRoute="Candidates List"/>
                <div className="row">
                
                   
                    <div className="col-lg-12 col-sm-12 col-md-12">
                        <div className="pool-form-wrapper">
                            <div className="row">
                            
                                <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className="text-center">
                                                    {this.props.state.userReducer.pageLoading &&
                                                    <PulseLoader
                                                    margin="30px"
                                                    sizeUnit={"px"}
                                                    size={15}
                                                    color={'#48194e'}
                                                    loading={this.props.pageLoading || this.props.activitiesPageLoading}
                                                    />
                                                }</div>
                                {!this.props.state.userReducer.pageLoading &&
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="row" id="personal">
                                                <div className="col-lg-12 col-sm-12 col-md-12">
                                                
                                                    <div className="user-profile-flex">
                                                        <h3 className="">Personal Information </h3>
                                                    </div>
                                                    <div className="profile-wrap">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group mobile-profile">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Name :</label> </div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{!!this.props.userDetails.profile && (this.props.userDetails.profile.fname + " " + this.props.userDetails.profile.mname + " " + this.props.userDetails.profile.lname)}</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group mobile-profile">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Email Address :</label></div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{!!this.props.userDetails.profile && this.props.userDetails.profile.email}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group mobile-profile">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Contact Number :</label></div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{!!this.props.userDetails.profile && this.props.userDetails.profile.contact}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group mobile-profile">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Country :</label></div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{!!this.props.userDetails.profile && this.props.userDetails.profile.country}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group mobile-profile">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>State :</label></div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{!!this.props.userDetails.profile && this.props.userDetails.profile.state}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group mobile-profile">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>City :</label></div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{!!this.props.userDetails.profile && this.props.userDetails.profile.city}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Postal Code :</label></div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{ !!this.props.userDetails.profile  &&  this.props.userDetails.profile.postal  }</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="row" id="education">
                                                <div className="col-lg-12 col-sm-12 col-md-12">
                                                    <div className="user-profile-flex">
                                                        <h3 className="">Education </h3>
                                                    </div>
                                                    <div className="profile-wrap">
                                                    {
                                                        !!this.props.userDetails.profile ?
                                                            (
                                                                this.props.userDetails.profile.education.length > 0 ?
                                                                    (
                                                                        this.props.userDetails.profile.education.map((d, i) => (
                                                                            <div>
                                                                                <div className="row">
                                                                                    <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-6 col-md-5">
                                                                                                <label>College/University :</label>
                                                                                            </div>
                                                                                            <span className="col-12 col-sm-6 col-md-7">{d.school}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-12 col-sm-12 col-md-12 form-group admin-form-group ">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-6 col-md-5">
                                                                                                <label>{i === 0 ? "Highest Qualification :" : "Other Qualification :"}</label>
                                                                                            </div>
                                                                                            <span
                                                                                                className="col-12 col-sm-6 col-md-7">{d.degree}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                        <div className="row">
                                                                                            <div
                                                                                                className="col-12 col-sm-6 col-md-5">
                                                                                                <label>Duration :</label></div>
                                                                                            <span className="col-12 col-sm-6 col-md-7">{d.start} to {d.end}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12 col-md-12">
                                                                                    <div className="divider"></div>
                                                                                </div>
                                                                            </div>
                                                                        )))
                                                                    :
                                                                    (
                                                                        <div>
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-12 col-sm-6 col-md-5">
                                                                                            <label>College/University :</label>
                                                                                        </div>
                                                                                        <span
                                                                                            className="col-12 col-sm-6 col-md-7">NA</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-12 col-sm-6 col-md-5">
                                                                                            <label>Highest Qualification :</label>
                                                                                        </div>
                                                                                        <span
                                                                                            className="col-12 col-sm-6 col-md-7">NA</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-12 col-sm-6 col-md-5">
                                                                                            <label>Duration :</label></div>
                                                                                        <span
                                                                                            className="col-12 col-sm-6 col-md-7">NA</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            )
                                                            :

                                                            (   <div>
                                                                    <div className="row">
                                                                        <div
                                                                            className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-12 col-sm-6 col-md-5">
                                                                                    <label>College/University :</label>
                                                                                </div>
                                                                                <span
                                                                                    className="col-12 col-sm-6 col-md-7"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-12 col-sm-6 col-md-5">
                                                                                    <label>Highest Qualification :</label></div>
                                                                                <span
                                                                                    className="col-12 col-sm-6 col-md-7"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-12 col-sm-6 col-md-5">
                                                                                    <label>Duration :</label></div>
                                                                                <span
                                                                                    className="col-12 col-sm-6 col-md-7"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="row" id="experience">
                                                <div className="col-lg-12 col-sm-12 col-md-12">
                                                    <div className="user-profile-flex">
                                                        <h3 className="">Experience </h3>
                                                    </div>
                                                    <div className="profile-wrap">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"> <label>Experience :</label> </div>
                                                                    <span className="col-12 col-sm-6 col-md-7">
                                                                    {!!this.props.userDetails.profile && this.props.userDetails.profile.whoYouAre}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12">
                                                                <div className="divider"></div>
                                                            </div>
                                                        </div>
                                                        {

                                                            !!this.props.userDetails.profile ?
                                                                (
                                                                    this.props.userDetails.profile.experience.length >= 0 ?
                                                                        (
                                                                            this.props.userDetails.profile.experience.map((d, i) => (
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-6 col-md-5">  <label>Company :</label></div>
                                                                                            <span className="col-12 col-sm-6 col-md-7">{d.name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-6 col-md-5">
                                                                                                <label>Designation :</label></div>
                                                                                            <span className="col-12 col-sm-6 col-md-7">{d.position}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-sm-6 col-md-5">
                                                                                                <label>Duration :</label></div>
                                                                                            <span className="col-12 col-sm-6 col-md-7">{d.start + " to " + d.end}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-12 col-md-12">
                                                                                        <div className="divider"></div>
                                                                                    </div>
                                                                                </div>
                                                                            )))
                                                                        :
                                                                        (

                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-12 col-sm-6 col-md-5">
                                                                                            <label>Company :</label></div>
                                                                                        <span className="col-12 col-sm-6 col-md-7"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-12 col-sm-6 col-md-5">
                                                                                            <label>Designation :</label></div>
                                                                                        <span className="col-12 col-sm-6 col-md-7"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-12 col-sm-6 col-md-5">
                                                                                            <label>Duration :</label></div>
                                                                                        <span className="col-12 col-sm-6 col-md-8"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12 col-md-12">
                                                                                    <div className="divider"></div>
                                                                                </div>
                                                                            </div>

                                                                        )
                                                                )
                                                                :

                                                                (<div className="row">
                                                                        <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-6 col-md-5">
                                                                                    <label>Company
                                                                                        :</label></div>
                                                                                <span className="col-12 col-sm-6 col-md-7"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-6 col-md-5">
                                                                                    <label>Designation
                                                                                        :</label></div>
                                                                                <span
                                                                                    className="col-12 col-sm-6 col-md-7"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-6 col-md-5">
                                                                                    <label>Duration
                                                                                        :</label></div>
                                                                                <span
                                                                                    className="col-12 col-sm-6 col-md-7"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12">
                                                                            <div className="divider"></div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                        }
                                                        {
                                                            (!!this.props.userDetails.profile) &&
                                                            this.props.userDetails.profile.projects.map((d, i) => (
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">Project {i + 1}</div>
                                                                    <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-6 col-md-5">
                                                                                <label>Project Name :</label></div>
                                                                            <span className="col-12 col-sm-6 col-md-7">{d["name"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-6 col-md-5">
                                                                                <label>Description
                                                                                    :</label></div>
                                                                            <span
                                                                                className="col-12 col-sm-6 col-md-7">{d["description"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-12 form-group admin-form-group">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-6 col-md-5">
                                                                                <label>Skills Applied
                                                                                    :</label></div>
                                                                            <span
                                                                                className="col-12 col-sm-6 col-md-7">{d["skills"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-12">
                                                                        <div className="divider"></div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="row" id="basicinfo">
                                                <div className="col-lg-12 col-sm-12 col-md-12" >
                                                    <div className="user-profile-flex">
                                                        <h3 className="">Basic Information </h3>
                                                    </div>
                                                    <div className="profile-wrap">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 form-group mobile-profile admin-form-group">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5">
                                                                        <label>Area of Interest :</label>
                                                                    </div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{ (interests.length>=0) ? "NA": interests  }</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 form-group mobile-profile admin-form-group">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Notification:</label></div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{!!this.props.userDetails.profile && (this.props.userDetails.profile.notify ? "Yes" : "No")}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 form-group mobile-profile admin-form-group">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Resume :</label></div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{!!this.props.userDetails.profile && (this.props.userDetails.profile.resume ? "Download Resume ->" : "NA")}
                                                                        {
                                                                            !!this.props.userDetails.resumeLink && !!this.props.userDetails.resumeLink &&
                                                                            <a style={{marginLeft: "10px"}}
                                                                            href={this.props.userDetails.resumeLink}
                                                                            target="_blank">download</a>
                                                                        }
                                                                    </span>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 form-group mobile-profile admin-form-group">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Applied
                                                                        Date :</label></div>
                                                                    <span className="col-12 col-sm-6 col-md-7">{!!this.props.userDetails.profile && (moment(this.props.userDetails.profile.appliedDate * 1000)).format('Do MMM YYYY')}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 form-group mobile-profile admin-form-group">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>LinkedIn URL
                                                                        :</label></div>
                                                                    <div className="col-12 col-sm-6 col-md-7">
                                                                        <span>{!!this.props.userDetails.profile &&

                                                                        !!this.props.userDetails.profile.linkedin ?
                                                                            <a href={this.props.userDetails.profile.linkedin}
                                                                            target="_blank">{this.props.userDetails.profile.linkedin}</a> : ""
                                                                        }
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 form-group mobile-profile admin-form-group">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Github URL :</label>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-7 ">
                                                                        <span>{!!this.props.userDetails.profile &&

                                                                        !!this.props.userDetails.profile.github ?
                                                                            <a href={this.props.userDetails.profile.github}
                                                                                target="_blank">{this.props.userDetails.profile.github}</a> : "NA"
                                                                        }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 form-group mobile-profile admin-form-group">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6 col-md-5"><label>Other URL :</label>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-md-7 ">
                                                                        <span>{!!this.props.userDetails.profile &&
                                                                            !!this.props.userDetails.profile.otherLink ?
                                                                                <a href={this.props.userDetails.profile.otherLink}
                                                                                target="_blank">{this.props.userDetails.profile.otherLink}</a> : "NA"
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12 col-md-12">
                                                    <div className="user-profile-flex">
                                                        <h3 className="">Applied Jobs </h3>
                                                    </div>
                                                    <div className="profile-wrap">
                                                        <div className="activity-page">
                                                            <table id="job-activity" className="admin-job-activity">
                                                                <tr>
                                                                    <th>Job Name</th>
                                                                    <th>Team Name</th>
                                                                    <th>Applied Date</th>
                                                                </tr>
                                                                {
                                                                    !this.props.activitiesPageLoading && this.props.activities.length > 0 &&
                                                                    (
                                                                        this.props.activities.map((a, i) => (
                                                                            <tr>
                                                                                <td>{a.job_name}</td>
                                                                                <td>{a.team_name}</td>
                                                                                <td>{moment(a.applied_date * 1000).format('Do MMM YYYY')}</td>
                                                                            </tr>
                                                                        ))
                                                                    )
                                                                }
                                                                {
                                                                    !this.props.activitiesPageLoading && this.props.activities.length === 0 && this.props.activitiesMessage === "" &&
                                                                    <tr>
                                                                        <td colSpan={3} style={{textAlign: "center"}}>No applied jobs
                                                                            found...
                                                                        </td>
                                                                    </tr>

                                                                }
                                                                {
                                                                    !this.props.activitiesPageLoading && this.props.activitiesMessage !== "" &&
                                                                    <tr>
                                                                        <td colSpan={3}
                                                                            style={{textAlign: "center"}}>{this.props.activitiesMessage}</td>
                                                                    </tr>
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12 col-md-12">
                                                    <div className="user-profile-flex">
                                                        <h3 className="">Drives Applied </h3>
                                                    </div>
                                                    <div className="profile-wrap">
                                                        <div className="activity-page">
                                                            <table id="job-activity" className="admin-job-activity">
                                                                <tr>
                                                                    <th>Drive Name</th>
                                                                    <th>Accuracy</th>
                                                                    <th>Percentage</th>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                
                                
                                <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className="text-center">
                                {this.props.state.userReducer.pageLoading &&
                                <PulseLoader
                                margin="30px"
                                sizeUnit={"px"}
                                size={15}
                                color={'#48194e'}
                                loading={this.props.pageLoading || this.props.activitiesPageLoading}
                                />
                                }</div>
                                {!this.props.state.userReducer.pageLoading &&
                                    
                                                    <div className="sticky-div">
                                                        <div className="user-profile-flex">
                                                            <h3 className="">Resume </h3>
                                                            <span className="candidate-buttons">{
                                                                !!!!this.props.userDetails.resumeLink && !!this.props.userDetails.resumeLink&&
                                                                <a style={{marginLeft: "10px"}}
                                                                href={this.props.userDetails.resumeLink}
                                                                target="_blank"><i className="material-icons" style={{fontSize: "20px"}}>file_download</i>Download</a>}
                                                                <div className="custom-img-box candidate-resume-button">
                                                                     <div className="candidate-upload-button">
                                                                         <i className="material-icons">file_upload </i>
                                                                         <span>Upload Resume</span>
                                                                         <input 
                                                                         type="file"
                                                                         name="image"
                                                                         accept=".doc,.docx,.pdf"
                                                                         onChange={(e) => this.resumeUpload(e)}
                                                                         />
                                                                         </div>
                                                                         </div>
                                                            </span>
                                                            </div>
                                                        <div className="profile-wrap">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-12 form-group mobile-profile admin-form-group">
                                                                
                                                                {
                                                                    !!this.props.userDetails.resumeLink && this.props.userDetails.resumeLink && url !== undefined?
                                                                    (url === "pdf" ?
                                                                    <iframe src={"https://docs.google.com/viewer?url=" +this.props.userDetails.resumeLink +"&embedded=true"} 
                                                                    width="100%" height="1000px"
                                                                     ></iframe>
                                                                      :
                                                                      ((url === "docx"|| url === "doc")  &&
                                                                      <iframe src={"https://view.officeapps.live.com/op/embed.aspx?src="+this.props.userDetails.resumeLink+"&amp;wdStartOn=1"}
                                                                      width="100%" height="1000px" ></iframe>
                                                                      ))
                                                                      :
                                                                    <div class="not-found">Resume Not Available!!</div>
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                    }
                                </div>
                                
                            </div>                 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {activities, activitiesError, activitiesMessage, activitiesStatus, activitiesPageLoading,} = state.activitiesReducer;
    const {pageLoading, getUserDetailsError, getUserDetailsMessage, getUserDetailsStatus, userDetails ,uploadResume_Error,
        uploadResume_Message} = state.userReducer;
    return {
        activities, activitiesError, activitiesMessage, activitiesStatus, activitiesPageLoading,
        pageLoading, getUserDetailsError, getUserDetailsMessage, getUserDetailsStatus, userDetails,uploadResume_Error,
        uploadResume_Message,state
    }
}

export default withRouter(connect(mapStateToProps)(CandidateDetails))
