import {
    WEBSOCKET_LOADING,
    ASSIGN_USER_CSV_FAILURE,
    ASSIGN_USER_CSV_REQUEST,
    ASSIGN_USER_CSV_SUCCESS,
    ASSIGN_USER_MANUALLY_FAILURE,
    ASSIGN_USER_MANUALLY_REQUEST,
    ASSIGN_USER_MANUALLY_SUCCESS,
    CHANGE_ASSIGN_USER_CSV_FORM,
    CHANGE_ASSIGN_USER_MANUALLY_FORM,
    CHANGE_CREATE_DRIVE_FORM,
    CLEAR,
    CLEAR_ALL,
    CREATE_DRIVE_FAILURE,
    CREATE_DRIVE_REQUEST,
    CREATE_DRIVE_SUCCESS,
    DELETE_DRIVE_FAILURE,
    DELETE_DRIVE_REQUEST,
    DELETE_DRIVE_SUCCESS,
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    EDIT_DRIVE_DETAILS_FAILURE,
    EDIT_DRIVE_DETAILS_REQUEST,
    EDIT_DRIVE_DETAILS_SUCCESS,
    GET_DRIVE_DETAILS_FAILURE,
    GET_DRIVE_DETAILS_REQUEST,
    GET_DRIVE_DETAILS_SUCCESS,
    GET_DRIVE_FAILURE,
    GET_DRIVE_REQUEST,
    GET_DRIVE_SUCCESS,
    GET_USER_FAILURE,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    CHANGE_CSV_LINK,
    GET_USER_SCORE_REQUEST,
    GET_USER_SCORE_SUCCESS,
    GET_USER_SCORE_FAILURE,
    SELECTED_DELETE_DRIVE,
    GET_DOWNLOAD_RESULT_FAILURE,
    DELETE_ALL_USER_REQUEST,
    DELETE_ALL_USER_SUCCESS,
    DELETE_ALL_USER_FAILURE,
    GET_DOWNLOAD_RESULT_REQUEST,
    GET_DOWNLOAD_RESULT_SUCCESS,
    SELECTED_DELETE_USER,
    GET_DRIVE_RESULT_SUCCESS,
    GET_DRIVE_RESULT_FAILURE
    ,GET_DRIVE_RESULT_REQUEST, 
    GET_DRIVE_SUMMARY_REQUEST, 
    GET_DRIVE_SUMMARY_SUCCESS,
    GET_DRIVE_SUMMARY_FAILURE,
    GET_LATEST_DRIVE_REQUEST,
    GET_LATEST_DRIVE_SUCCESS,
    GET_LATEST_DRIVE_FAILURE
} from "../../../../types/dashboard/admin/drive/drive";
import {GET_API} from "../../../../middleware/token/get-api";
import {CALL_DELETE_API} from "../../../../middleware/token/delete/without-body";
import {CALL_POST_API} from "../../../../middleware/token/post-api";
import {PUT_API} from "../../../../middleware/token/put_api/put-api-with-body";
import {logout} from "../../../account/login-actions";
import { driveApi } from "../../../ApiActions";

const BASE_URL = driveApi();

/* ----------------------------------------------------- create drive manually --------------------------------------------------------*/
// calls action to change drive form
export function changeCreateDriveForm(newState) {

    return {type: CHANGE_CREATE_DRIVE_FORM, newState}
}

// calls api to create drive
export function createDrive(body) {
    return {
        [CALL_POST_API]: {
            endpoint: BASE_URL + '/v1/drive',
            types: [CREATE_DRIVE_REQUEST, CREATE_DRIVE_SUCCESS, CREATE_DRIVE_FAILURE],
            body: body
        }
    }
}

/*---------------------------------------------------- get drive test --------------------------------------------------------------------*/
// calls api to get drive
export function getDrive(status) {
    

    var endpoint = BASE_URL + '/v1/drive';

    if(status !== undefined){
        endpoint += '?value='+status
    }
    return {
        [GET_API]: {
            endpoint,
            types: [GET_DRIVE_REQUEST, GET_DRIVE_SUCCESS, GET_DRIVE_FAILURE]
        }
    }
}
export function getLatestDrive(){
    return{
        [GET_API]:{
            endpoint:BASE_URL+'/v1/latestdrive',
            types:[GET_LATEST_DRIVE_REQUEST,GET_LATEST_DRIVE_SUCCESS,GET_LATEST_DRIVE_FAILURE]
        }
    }
}


/*---------------------------------------------------- get drive details --------------------------------------------------------------------*/
// calls api to get drive details
export function getDriveDetails(drive_id) {
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/drive/' + drive_id,
            types: [GET_DRIVE_DETAILS_REQUEST, GET_DRIVE_DETAILS_SUCCESS, GET_DRIVE_DETAILS_FAILURE]
        }
    }
}
export function getDriveResult(drive_id,pageNumber){
    console.log('actions')
    if(pageNumber === undefined){
        pageNumber = 1;
    }
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/result/drive/' + drive_id+'?page='+pageNumber,
            types: [GET_DRIVE_RESULT_REQUEST,GET_DRIVE_RESULT_SUCCESS,GET_DRIVE_RESULT_FAILURE]
        }
    }
}
/*----------------------------------------------------  drive summary--------------------------------------------------------------------*/

export function getDriveSummary(drive_id){
   
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/drive/' + drive_id+'/summary',
            types: [GET_DRIVE_SUMMARY_REQUEST,GET_DRIVE_SUMMARY_SUCCESS,GET_DRIVE_SUMMARY_FAILURE]
        }
    }
}
/*----------------------------------------------------  result drive --------------------------------------------------------------------*/

export function downloadDriveResult(drive_id){
   
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/result/drive/' + drive_id+'/csv',
            types: [GET_DOWNLOAD_RESULT_REQUEST,GET_DOWNLOAD_RESULT_SUCCESS,GET_DOWNLOAD_RESULT_FAILURE]
        }
    }
}
export function setCSVLink() {
    return {type: CHANGE_CSV_LINK}
}

/*---------------------------------------------------- delete drive --------------------------------------------------------------------*/
// calls api to delete drive
export function deleteDrive(drive_id) {
    return {
        [CALL_DELETE_API]: {
            endpoint: BASE_URL + '/v1/drive/' + drive_id,
            types: [DELETE_DRIVE_REQUEST, DELETE_DRIVE_SUCCESS, DELETE_DRIVE_FAILURE]
        }
    }
}

/*---------------------------------------------------- edit drive details --------------------------------------------------------------------*/
// calls api to edit drive details
export function editDriveDetails(drive_id, body) {
    return {
        [PUT_API]: {
            endpoint:BASE_URL + '/v1/drive/' + drive_id,
            types: [EDIT_DRIVE_DETAILS_REQUEST, EDIT_DRIVE_DETAILS_SUCCESS, EDIT_DRIVE_DETAILS_FAILURE],
            body: JSON.stringify(body)
        }
    }
}

/*--------------------------------------------------- assign user to drive(manually) -------------------------------------------------------------------*/

// calls action to assign user manually form
export function changeAssignUserManuallyForm(newState) {
    return {type: CHANGE_ASSIGN_USER_MANUALLY_FORM, newState}
}

//call api to assign user manually
export function assignUserManually(drive_id, body) {
    return {
        [CALL_POST_API]: {
            endpoint: BASE_URL + '/v1/drive/' + drive_id + '/user',
            types: [ASSIGN_USER_MANUALLY_REQUEST, ASSIGN_USER_MANUALLY_SUCCESS, ASSIGN_USER_MANUALLY_FAILURE],
            body: body
        }
    }
}

/*--------------------------------------------------- assign user to drive(csv) -------------------------------------------------------------------*/

// calls action to change assign user csv form
export function changeAssignUserCsvForm(newState) {
    return {type: CHANGE_ASSIGN_USER_CSV_FORM, newState}
}

// calls action to request assign user csv
export function assignUserCsvRequest() {
    return {type: ASSIGN_USER_CSV_REQUEST}
}

// calls action to assign user csv success
export function assignUserCsvSuccess(response) {
    return {type: ASSIGN_USER_CSV_SUCCESS, response}
}
// calls action to assign user csv failure
export function assignUserCsvFailure(response) {
    return {type: ASSIGN_USER_CSV_FAILURE, response}
}
// calls api to assign user csv
export function assignUserCsv(file, drive_id) {
    let formData = new FormData();
    formData.append('users', file);
    let status = "";
    const config = {
        method: "POST",
        headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`},
        body: formData
    };
    return dispatch => {
        dispatch(assignUserCsvRequest());
        fetch(BASE_URL + '/v1/drive/' + drive_id + '/csv_user', config)
            .then(function (res) {
                status = res.status;
                return res.json()
            })
            .then(function (res) {
                    if (status === 200) {
                        dispatch(assignUserCsvSuccess({data: {error: res.error, message: res.message}, status: 200}));
                    } else {
                        if (status === 401) {
                            dispatch(logout())
                        } else {
                            dispatch(assignUserCsvFailure({data: {error: true, message: res.message}, status: status}))
                        }
                    }
                },
                function () {
                    dispatch(assignUserCsvFailure({
                        data: {message: "Error while uploading users", error: true},
                        status: 500
                    }))
                })
    }
}


/*---------------------------------------------------- get user --------------------------------------------------------------------*/

// calls action to request get user

export function getUserScore(drive_id,email,value){
    console.log(value,'inactions')
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/result/drive/' + drive_id+'?email='+email+'&&results='+value.toString(),
            types: [GET_USER_SCORE_REQUEST,GET_USER_SCORE_SUCCESS,GET_USER_SCORE_FAILURE]
        }
    }
}

/*---------------------------------------------------- delete user --------------------------------------------------------------------*/

//  calls api to delete user
export function deleteUser(drive_id,  email) {
    return {
        [CALL_DELETE_API]: {
            endpoint: BASE_URL + '/v1/drive/' + drive_id + '/user/' + email,
            types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE]
        }
    }
}

export function deleteAllUser(drive_id) {
    console.log(drive_id,'delete action')
    return {
        [CALL_DELETE_API]: {
            endpoint: BASE_URL + '/v1/drive/' + drive_id + '/user' ,
            types: [DELETE_ALL_USER_REQUEST, DELETE_ALL_USER_SUCCESS, DELETE_ALL_USER_FAILURE]
        }
    }
}
/*---------------------------------------------------- common --------------------------------------------------------------------*/

// calls action to clear all data error, message and blank everything
export function clearAll() {
    return {type: CLEAR_ALL}
}

// calls action to clear all data error, message and blank everything
export function clear() {
    return {type: CLEAR}
}

// calls action to select drive to delete
export function selectedDeleteDrive(drive) {
    return {type: SELECTED_DELETE_DRIVE, drive}
}
// calls action to select user to delete
export function selectedDeleteUser(user) {
    return {type: SELECTED_DELETE_USER, user}
}
