import {
  CHANGE_EMAIL_REGISTER_FORM,
  CHANGE_LOGIN_FORM,
  CHANGE_USER_NAME,
  CLEAR_API_ERROR_MESSAGE,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_ACCOUNT_FAILURE,
  LOGOUT_ACCOUNT_REQUEST,
  LOGOUT_ACCOUNT_SUCCESS,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  LOGIN_WITH_GOOGLE_REQUEST,
  LOGIN_WITH_GOOGLE_SUCCESS,
  LOGIN_WITH_GOOGLE_FAILURE,
  LOGIN_WITH_LINKEDIN_REQUEST,
  LOGIN_WITH_LINKEDIN_SUCCESS,
  LOGIN_WITH_LINKEDIN_FAILURE,
} from "../../types/account/Login";


const initialState = {
  auth: {
    isAuthenticated: localStorage.getItem("token") ? true : false,
    sys_role: localStorage.getItem("sys_role"),
  },
  loginForm: {
    email: "",
    password: "",
  },
  emailRegisterForm: {
    emailAddress: "",
  },
  pageLoading:false,
  loginPageLoading: false,
  message: "",
  logoutPageLoading: false,
  logoutMe: "",
  status: "",
  error: "",
  userName: localStorage.getItem("name"),

  loginWithGooglePageloading:false,
  loginWithGoogleStatus:"",
  loginWithGoogleMessage:"",
  loginWithGoogleError:"",
  redirectLink:"",


  loginWithLinkedInPageloading:false,
  loginWithLinkedInStatus:"",
  loginWithLinkedInMessage:"",
  loginWithLinkedInError:""
  
};

export default function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOGIN_FORM:
      return Object.assign({}, state, {
        loginForm: action.newState,
        status: "",
        message: "",
      });
    case CHANGE_EMAIL_REGISTER_FORM:
      return Object.assign({}, state, {
        emailRegisterForm: action.newState,
      });
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        loginPageLoading: true,
      });
    case LOGIN_SUCCESS:
      if (action.response.data.error) {
        return Object.assign({}, state, {
          loginPageLoading: false,
          message: action.response.data.message,
          status: action.response.status,
          error: true,
        });
      } else {
        if (
          !!action.response.data.token &&
          !!action.response.data.expire &&
          !!action.response.data.sys_role
        ) {
          localStorage.setItem(
            "name",
            !!action.response.data.name ? action.response.data.name : ""
          );
          localStorage.setItem("token", action.response.data.token);
          localStorage.setItem("expire", action.response.data.expire);
          localStorage.setItem("sys_role", action.response.data.sys_role);
          return Object.assign({}, state, {
            loginPageLoading: false,
            auth: {
              isAuthenticated: true,
              sys_role: action.response.data.sys_role,
            },
            status: 200,
            loginForm: { email: "", password: "" },
            error: false,
            userName: !!action.response.data.name
              ? action.response.data.name
              : "",
          });
        } else {
          return Object.assign({}, state, {
            loginPageLoading: false,
            auth: {
              isAuthenticated: false,
              sys_role: "",
            },
            status: 200,
            loginForm: { email: "", password: "" },
            error: false,
          });
        }
      }
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        loginPageLoading: false,
        message: action.response.data.message,
        status: action.response.status,
        error: true,
      });
    case CLEAR_API_ERROR_MESSAGE:
      return Object.assign({}, state, {
        loginPageLoading: false,
        logoutMe: "",
        status: "",
        message: "",
        error: "",
      });

    // for force logout the account in case of user playing with app storage, unauthorized......

    case LOGOUT_SUCCESS:
      localStorage.clear();
      sessionStorage.clear();
      return Object.assign({}, state, {
        auth: {
          isAuthenticated: false,
          sys_role: "",
        },
      });

    // for logging out the account

    case LOGOUT_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        logoutPageLoading: true,
      });

    case LOGOUT_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        logoutPageLoading: false,
        logoutMe: !action.response.data.error,
      });
    case LOGOUT_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        logoutPageLoading: false,
        logoutMe: false,
      });

/*---------------------------------------------------Login with Google/Linkedin----------------------------------------------------------------*/
      case LOGIN_WITH_GOOGLE_REQUEST:
        return Object.assign({},state,{
          loginWithGooglePageloading:true,
          loginWithGoogleStatus:"",
          loginWithGoogleMessage:"",
          loginWithGoogleError:"",
        })

      case LOGIN_WITH_GOOGLE_SUCCESS:
        return Object.assign({},state,{
          loginWithGooglePageloading:false,
          loginWithGoogleStatus:action.response.data.status,
          loginWithGoogleMessage:action.response.data.message,
          loginWithGoogleError:action.response.data.error,
        })
        case LOGIN_WITH_GOOGLE_FAILURE:
        return Object.assign({},state,{
          loginWithGooglePageloading:false,
          loginWithGoogleStatus:action.response.data.status,
          loginWithGoogleMessage:action.response.data.message,
          loginWithGoogleError:true,
        })


        case LOGIN_WITH_LINKEDIN_REQUEST:
          return Object.assign({},state,{
            loginWithLinkedInPageloading:true,
            loginWithLinkedInStatus:"",
            loginWithLinkedInMessage:"",
            loginWithLinkedInError:"",
            redirectLink:""
          })
        case LOGIN_WITH_LINKEDIN_SUCCESS:
         return Object.assign({},state,{
            loginWithLinkedInPageloading:false,
            loginWithLinkedInStatus:action.response.data.status,
            loginWithLinkedInMessage:action.response.data.message,
            redirectLink:action.headers.data,
            loginWithLinkedInError:action.response.data.error
           })
        case LOGIN_WITH_LINKEDIN_FAILURE:
            return Object.assign({},state,{
              loginWithLinkedInPageloading:false,
              loginWithLinkedInStatus:action.response.data.status,
              loginWithLinkedInMessage:action.response.data.message,
              redirectLink:"",
              loginWithLinkedInError:true
            })
        

    /* --------------------------------------------- refresh token --------------------------------------------------*/

    case REFRESH_TOKEN_REQUEST:
      return Object.assign({}, state, {
        loginPageLoading: true,
      });
    case REFRESH_TOKEN_SUCCESS:
      if (!action.response.error) {
        if (
          !!action.response.data.token &&
          !!action.response.data.expire &&
          !!action.response.data.sys_role
        ) {
          localStorage.setItem(
            "name",
            !!action.response.data.name ? action.response.data.name : ""
          );
          localStorage.setItem("token", action.response.data.token);
          localStorage.setItem("expire", action.response.data.expire);
          localStorage.setItem("sys_role", action.response.data.sys_role);
          return Object.assign({}, state, {
            auth: {
              isAuthenticated: true,
              sys_role: action.response.data.sys_role,
            },
          });
        } else {
          return Object.assign({}, state, {
            loginPageLoading: false,
          });
        }
      } else {
        return Object.assign({}, state, {
          loginPageLoading: false,
        });
      }
    case REFRESH_TOKEN_FAILURE:
      return Object.assign({}, state, {
        loginPageLoading: false,
      });

    /* ---------------------------------------------------------------------------------------------------------------------*/

    case CHANGE_USER_NAME:
      return Object.assign({}, state, {
        userName: action.name,
      });
      default:
      return state;
  }
}
