import React, { Component } from "react";
import AdminOnPageNavigation from "../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation";
import {
  getAdminList,
  selectedDeleteAdmin,
  deleteAdminList,
  clearStatus,
  clearAll
} from "../../../../../actions/dashboard/admin/invite/admin";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DeleteDialogBox from "../../../../../components/app/dialogBox/delete-dialog-box";
import createNotification from "../../../../../components/app/notification/notification";
import { Table, TableRow, TableBody, TableData, TableHeaderRow, TableHeader } from '../../../../../components/dashboard/admin/xd-react-table/react-flex-table'
import { TableHeadComponent , ThComponent} from '../../../../../components/dashboard/admin/xd-react-table/react-flex-table/components';
import { PulseLoader } from 'react-spinners';
import {Helmet} from "react-helmet";

class ListAdmin extends Component {
  componentWillMount() {
    this.props.dispatch(getAdminList());
  }
  componentWillReceiveProps(nextProps) {
      if (nextProps.deleteAdminMessage !== "") {
          
          if (nextProps.deleteAdminError) {
              createNotification('error', nextProps.deleteAdminMessage);
              nextProps.dispatch(clearStatus());
          }
          else {
              createNotification('success', nextProps.deleteAdminMessage);
              nextProps.dispatch(getAdminList())
          }
      }
  }

  openDeleteDialogBox(id, name) {
    this.props.dispatch(selectedDeleteAdmin({ id, name }));
   
    document.getElementsByClassName("overlay-delete")[1].style.display =
      "block";
  }
  deleteAdmin(e) {
    e.preventDefault();
     
    this.props.dispatch(deleteAdminList(this.props.selected));
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }
  closeDeleteAdminDialog(e) {
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }
  render() {
     
    return (
        <div>
        <Helmet>
                <title>View Admin | Career Portal | Xenonstack</title>
             </Helmet>
            <AdminOnPageNavigation   parentRoute="Dashboard"  childRoute="Admin List" />
            <div className="mar-b-3">
                <button  className="btn"  onClick={() => this.props.history.push("/dashboard/invite/add")} > Add Admin </button>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="panel panel-default card-view custom-panel pool-panel">
                        <div className="panel-heading">
                            <h5 className="panel-title">Admin List</h5>
                            <div className="clearfix" />
                        </div>
                        <div className="panel-wrapper collapse in">
                            <div className="panel-body">
                                <div className="table-wrap ">
                                    <div className="table-responsive">
                                        <div className="text-center">
                                        {this.props.getListPageLoading &&
                                         <PulseLoader 
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#48194e'}
                                         loading={this.props.getListPageLoading} 
                                         />
                                    }
                                    </div>
                                    </div>
                                    <div className="react-xd-table-wrapper">
                                            { (!this.props.getListPageLoading &&
                                            this.props.list.length > 0) && 
                                                        <Table
                                                            data={this.props.list}
                                                            pageSize={this.props.defaultPageSize}
                                                            pageSizeOptions={[10,20,30,40,50]}
                                                            currentPage={this.props.pageNumber}
                                                            render={({
                                                            rows,
                                                            
                                                            }) => {
                                                            return (
                                                                <React.Fragment>
                                                                <div className="react-xd-table-body">
                                                                    <table className="react-xd-table striped-xd-table sortable-table footer-pagination-table">
                                                                    <TableHeaderRow component={TableHeadComponent}>
                                                                     
                                                                        <TableHeader
                                                                        accessor="name"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Name
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="email"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Email
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="sys_role"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        System Role
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                            component={ThComponent}
                                                                        
                                                                        >
                                                                       Actions
                                                                        </TableHeader>
                                                                        </TableHeaderRow>
                                                                        <TableBody
                                                                            component="tbody"
                                                                            >
                                                                            {rows.map(({ rowKey, rowData, selected }, index) => (
                                                                            <TableRow
                                                                                component="tr"
                                                                                className="xd-table-row xd-table-td"
                                                                                key={rowKey}
                                                                            > 
                                                                            {rowData.map(({ accessor, data, key , rowsData }) => (
                                                                              
                                                                               <TableData
                                                                                component="td"
                                                                                key={key}
                                                                                >
                                                                                {accessor ? 
                                                                                            (data)
                                                                                 :
                                                                                (   
                                                                                <div className="table-action-group">
                                                                                  
                                                                                    <button type="button" onClick={() => this.openDeleteDialogBox(rowsData.userid, rowsData.name)} className="xd-table-btn delete">Delete</button>
                                                                                    
                                                                                </div>
                                                                                )}  
                                                                                </TableData>
                                                                            ))}
                                                                            </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                        </table>
                                                                        </div>
                                                                        
                
                                                                    </React.Fragment>
                                                                )
                                                                }}
                                                            />}
                                                             
                                                            </div>
                                   
                                                            {!this.props.pageLoading &&
                                                                        this.props.list.length === 0 &&
                                                                        this.props.getListMessage === "" && (
                                                                    
                                                                                <div className="not-found">
                                                                                    No List Found !!
                                                                                </div>
                                                                        
                                                            )}
                                                            {!this.props.pageLoading &&
                                                                this.props.getListMessage !== "" && (
                                                                
                                                                        <div className="not-found">
                                                                            {this.props.getListMessage}
                                                                        </div>
                                                                
                                                            )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <DeleteDialogBox
                formName="Delete List"
                submitForm={this.deleteAdmin}
                operation="Delete List"
                keyword={"Delete"}
                closeForm={this.closeDeleteAdminDialog}
                pageLoading={this.props.pageLoading}
                selected={this.props.deleteAdmin.id}
                name={this.props.deleteAdmin.name}
            />
        </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    list,
    getListPageLoading,
    deleteAdmin,
    getListMessage,
    deleteAdminMessage,
    deleteAdminError,
    deleteAdminPageLoading
  } = state.adminListReducer;
  const {selectedDeleteId} = deleteAdmin
  return {
    list,
    getListMessage,
    getListPageLoading,
    deleteAdmin,
    deleteAdminMessage,
    deleteAdminError,
    deleteAdminPageLoading
  };
}

export default withRouter(connect(mapStateToProps)(ListAdmin));
