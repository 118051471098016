import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {store} from '../src/index';
import Login from "./container/account/login/Login";
import OnboardingApp from './container/account/OnboardingApp'
import EmailVerification from './container/account/email_verification/email-verification';
import UserDashboard from "./container/dashboard/user/index";
import Activities from "./container/dashboard/user/activities/activities";
import DriveDetails from "./container/dashboard/user/drive/drive-details";
import Profile from './container/dashboard/user/profile/index';
import UpdatePassword from './container/dashboard/user/profile/change-password';
import Feedback from './container/dashboard/user/feedback/send-feedback';
import Head from "./components/app/header/header";
import ForgotPassword from './container/account/forgot_password/forgot-password';
import ResetPassword from './container/account/reset_password/reset-password';
import Register from './container/account/register/manual/register';
import RegisterThroughLinkedIn from './container/account/register/linkedin/register-through-linkedin';
import Footer from "./components/app/footer/footer";
import Toolbar from "./components/dashboard/user/sections/toolbar-section";
import Menubar from "./components/dashboard/user/sections/menubar-section";
import AdminDashboard from './container/dashboard/admin/index/index';
import AdminFeedback from './container/dashboard/admin/user_feedback/user-feedback';
import AdminToolbar from './components/dashboard/admin/toolbar/toolbar';
import AdminSidebar from './components/dashboard/admin/sidebar/sidebar';
import ListPool from './container/dashboard/admin/pool/list/list';
import PoolDetails from './container/dashboard/admin/pool/details/Details';
import AddQuestionsToPool from './container/dashboard/admin/pool/questions/question/details/Details';
import PreviewSection from "./components/account/register/manual/section/preview/index";
import CreateTeam from "./container/dashboard/admin/team/create/team";
import ListTeam from './container/dashboard/admin/team/list/list';
import CreateJob from "./container/dashboard/admin/job/create/create";
import ListJob from "./container/dashboard/admin/job/list/list";
import {NotificationContainer} from 'react-notifications';
import Candidates from "./container/dashboard/admin/user/all-users";
import CandidateDetails from "./container/dashboard/admin/user/user-details";
import CreateDrive from './container/dashboard/admin/drive/create/create';
import ViewDrive from './container/dashboard/admin/drive/view/view';
import AnalyticalBoard from './container/dashboard/admin/drive/analyticalboard/analyticalboard';
import DriveResult from './container/dashboard/admin/results/driveresult';
import DriveStudentResult from './container/dashboard/admin/results/drivestudentresult'
import PoolList from './container/dashboard/admin/results/poollist'
import PoolResult from './container/dashboard/admin/results/poolresult';
import AssignDrive from "./container/dashboard/admin/drive/assign/assign";
import AdminDriveDetails from "./container/dashboard/admin/drive/details/Details";
import UserScoreList from './container/dashboard/admin/user/user-score-list';
import CreateTest from './container/dashboard/admin/test/create/create';
import ListTest from './container/dashboard/admin/test/list/list';
import College from './container/dashboard/admin/college/index';
import ViewUser from './container/dashboard/admin/drive/assign/assign-list';
import WekanBoard   from "./container/dashboard/admin/wekan/WekanBoard";
import ListAdmin from './container/dashboard/admin/invite/list/admin-list';
import AddAdmin from './container/dashboard/admin/invite/add/add-admin';
import CheckEmailSection from "./components/account/register/manual/section/check-email-section";
import HomePage from "./container/homepage/index";
import AllJobs from "./container/homepage/jobs/jobs";
import JobsDetail from "./container/homepage/jobs/job-detail";
import {GET_API} from "./middleware/token/get-api";
import {REFRESH_TOKEN_FAILURE, REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS} from "./types/account/Login";
import Applymanual from './container/account/register/manual/Applymanual';

import  './static/css/simple-line-icons.css';

let BASE_URL="";
let rt = 15000, authenticated = null;

/**
 *
 * This function checks wheather the user is logged in or logged out.
 * isAuthenticated(true) - logged in
 * isAuthenticated(false) - logged out
 */
function checkAuth() {
    const {auth} = store.getState().LoginReducer;
    const {isAuthenticated} = auth;
    return isAuthenticated
}

/**
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 * The purpose of this constant is to-
 * check wheather user is logged in or logged out,
 * if logged in - user can visit any routes (eg. /tenants, /users, /hosta)
 */
export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        return checkAuth() ?
            <Component {...props}/>
            : <Redirect to='/login'/>
    }
    }/>
);


/**
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */

export const ProtectedRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        return checkAuth() ?
            <Redirect to='/dashboard'/>
            : <Component {...props}/>
    }}/>
);

// calls api to refresh token
function refreshToken() {

    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/refresh_token',
            types: [REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE]
        }
    }
}


export function BodyWrapper(props) {
    return (
        <Switch>
            {
                props.props.sys_role === "admin" &&
                <div className="admin-dashboard-pages"> 
                <AdminToolbar/> 
                <AdminSidebar/>
               
                <div className="custom-content page-wrapper">
                    <div className="container-fluid">
                        <Switch>
                        
                            <PrivateRoute exact path="/dashboard" component={AdminDashboard}/>
                            <PrivateRoute exact path="/dashboard/candidate/list/:pageNumber" component={Candidates}/>
                            <PrivateRoute path="/dashboard/candidate/:email/details" component={CandidateDetails}/>
                            <PrivateRoute exact path="/dashboard/feedback/:type" component={AdminFeedback}/>
                            <PrivateRoute exact path="/dashboard/:drive/users/:email/score/:resultValue" component={UserScoreList}/>
                            <PrivateRoute exact path="/dashboard/pool/list" component={ListPool}/>
                            <PrivateRoute exact path="/dashboard/pool/:pool_id/details/page=:pageNumber&pageSize=:pageSize" component={PoolDetails}/>
                            <PrivateRoute exact path="/dashboard/pool/:pool_id/add/questions"
                                          component={AddQuestionsToPool}/> // // csv required also
                            <PrivateRoute exact path="/dashboard/pool/:pool_id/details/questions/:question/edit"
                                          component={AddQuestionsToPool}/>
                            <PrivateRoute exact path="/dashboard/pool/:pool_id/details/add-question"
                                          component={AddQuestionsToPool}/>
                            <PrivateRoute exact path="/dashboard/drive/create" component={CreateDrive}/>
                            <PrivateRoute exact path="/dashboard/drive/view/page=:pageNumber&pageSize=:pageSize" component={ViewDrive}/>
                            <PrivateRoute exact path="/dashboard/result/drive/page=:pageNumber&pageSize=:pageSize" component={DriveResult}/>
                            <PrivateRoute exact path="/dashboard/result/drivewise/:drive/candidates/page=:pageNumber" component={DriveStudentResult}/>
                            <PrivateRoute exact path="/dashboard/result/pool/page=:pageNumber&pageSize=:pageSize" component={PoolList}/>
                            <PrivateRoute exact path="/dashboard/result/poolwise/:pool/candidates/page=:pageNumber&pageSize=:pageSize" component={PoolResult}/>
                            <PrivateRoute path="/dashboard/analytics" component={AnalyticalBoard}/>
                            <PrivateRoute exact path="/dashboard/drive/:drive/edit" component={CreateDrive}/>
                            <PrivateRoute exact path="/dashboard/drive/details" component={AdminDriveDetails}/>
                            <PrivateRoute exact path="/dashboard/drive/:drive/assign" component={AssignDrive}/> // csv required also
                            <PrivateRoute exact path="/dashboard/drive/:drive/assign/list/page=:pageNumber" component={ViewUser}/>
                            <PrivateRoute path="/dashboard/team/create" component={CreateTeam}/>
                            <PrivateRoute path="/dashboard/team/list/page=:pageNumber&pageSize=:pageSize" component={ListTeam}/>
                            <PrivateRoute path="/dashboard/team/:team/edit" component={CreateTeam}/>
                            <PrivateRoute path="/dashboard/job/create" component={CreateJob}/>
                            <PrivateRoute path="/dashboard/job/list/page=:pageNumber&pageSize=:pageSize" component={ListJob}/>
                            <PrivateRoute path="/dashboard/job/:job/edit" component={CreateJob}/>
                            <PrivateRoute path="/dashboard/test/create" component={CreateTest}/>
                            <PrivateRoute path="/dashboard/test/:test_id/edit" component={CreateTest}/>
                            <PrivateRoute path="/dashboard/test/list/page=:pageNumber&pageSize=:pageSize" component={ListTest}/>
                            <PrivateRoute path="/dashboard/college/add/:pageNumber" component={College}/> // csv required also
                            <PrivateRoute path="/dashboard/college/:college/edit" component={College}/>
                            <PrivateRoute path="/dashboard/board" component={WekanBoard}/>
                            <PrivateRoute path="/dashboard/invite/list" component={ListAdmin}/>
                            <PrivateRoute path="/dashboard/invite/add" component={AddAdmin}/>

                            <Redirect from="*" to='/dashboard'/>
                        </Switch>
                        <NotificationContainer/>
                    </div>
                </div>
                </div>
            }
            {
                props.props.sys_role === "user" &&
                <div><Head/>
                    <div className="main-container">
                      <div className="outer-wrapper">
                                    
                                        <Toolbar/>
                                   
                                        <Menubar/>
                                   
                    <Switch>
                        <PrivateRoute exact path="/dashboard" component={UserDashboard}/>
                        <PrivateRoute path="/my-activities" component={Activities}/>
                        <PrivateRoute path="/drive-details" component={DriveDetails}/>
                        <PrivateRoute exact path="/profile" component={Profile}/>
                        <PrivateRoute path="/profile/:section/edit" component={RegisterThroughLinkedIn}/>
                        <PrivateRoute path="/profile/change-password" component={UpdatePassword}/>
                        <PrivateRoute path="/feedback" component={Feedback}/>
                        <Route exact path="/jobs/team-id=:teamid&&team-name=:name&&team-type=:type" component={AllJobs}/>
                        <Route exact path="/jobs/:team/:job" component={JobsDetail} />
                        <Route exact path="/" component={HomePage}/>
                        <Redirect from="*" to='/dashboard'/>
                        
                    </Switch>
                    <Footer/>
                    </div>
                    </div>
                </div>
            }
            <Redirect from="*" to='/'/>
        </Switch>
    )
}

class RouteComponent extends Component {
    componentWillMount() {
        let timeInterval=process.env.REACT_APP_REFRESH_TOKEN;
        BASE_URL=process.env.REACT_APP_AUTH_API;
        authenticated = this.props.isAuthenticated;
        setInterval(() => {

            if (authenticated) {
                rt = setInterval(() => {
                    clearInterval(rt);
                    if (authenticated) {
                        this.props.dispatch(refreshToken())
                    }
                }, 100)
            } else {

                clearInterval(rt);
            }
        }, timeInterval)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated)
            authenticated = nextProps.isAuthenticated
    }

    render() {
        return (
            <>
           
                <Switch>

                    <Route exact path="/" component={OnboardingApp}/>
                     <Route path="/login" component={Login}/>
                     <Route  exact path="/apply-manual" component={Applymanual}/>
                    <ProtectedRoute exact path="/forgot-password" component={ForgotPassword}/>
                    <ProtectedRoute exact path="/reset-password" component={ResetPassword}/>
                    <Route exact path="/verify-email" component={EmailVerification}/>
                    <ProtectedRoute exact  path="/apply-linkedIn" component={CheckEmailSection}/>
                    <ProtectedRoute exact  path="/apply-Google" component={CheckEmailSection}/>
                    <ProtectedRoute exact  path="/apply-manual/check-email" component={Register}/>
                    <ProtectedRoute exact path="/apply-manual/personal-information" component={Register}/>
                    <ProtectedRoute exact path="/apply-manual" component={Register}/>
                    <ProtectedRoute exact  path="/apply-manual/education" component={Register}/>
                    <ProtectedRoute exact  path="/apply-manual/experience" component={Register}/>
                    <ProtectedRoute exact  path="/apply-manual/information" component={Register}/>
                    <ProtectedRoute exact  path="/apply-manual/create-password" component={Register}/>
                    <ProtectedRoute exact  path="/apply-manual/preview" component={Register}/>
                    <ProtectedRoute exact  path="/apply-manual/review" component={Register}/>
                    <ProtectedRoute exact  path="/apply-manual/preview" component={PreviewSection}/>

                    <ProtectedRoute exact path="/apply-linkedIn/register"  component={RegisterThroughLinkedIn}/>                             
                    <BodyWrapper props={this.props}/>
                    <Route   path="/apply-manual" component={Applymanual}/>
                </Switch> 
                
            
            </>
        )
    }
}

function mapStateToProps(state) {
    const {auth} = state.LoginReducer;
    const {isAuthenticated, sys_role} = auth;
    return {isAuthenticated, sys_role}
}

export default withRouter(connect(mapStateToProps)(RouteComponent));
