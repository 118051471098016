import {
  CHANGE_CREATE_JOB_FORM,
  CLEAR_ALL,
  CREATE_JOB_FAILURE,
  CREATE_JOB_REQUEST,
  CREATE_JOB_SUCCESS,
  GET_JOB_DETAILS_REQUEST,
  GET_JOB_DETAILS_SUCCESS,
  GET_JOB_DETAILS_FAILURE,
  CLEAR_CREATE_JOB_RESPONSE,
  CLEAR_STATUS,
  IMAGE_REQUEST,
  IMAGE_SUCCESS,
  IMAGE_FAILURE,
  UPDATE_IMAGES,
  GET_EMAIL_REQUEST,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAILURE,
  UPDATE_DETAILS,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
} from "../../../../types/dashboard/admin/job/job";
import { CALL_POST_API } from "../../../../middleware/token/post-api";
import { GET_API } from "../../../../middleware/token/get-api";
import { authApi,driveApi } from "../../../ApiActions";

const BASE_URL = authApi();
const BASE_URL_DRIVE = driveApi();

// create JOB

// calls action to change form
export function changeCreateJobForm(newState) {
  return { type: CHANGE_CREATE_JOB_FORM, newState };
}

// calls api to create job
export function createJob(body) {
  return {
    [CALL_POST_API]: {
      endpoint: BASE_URL +'/v1/job',
      types: [CREATE_JOB_REQUEST, CREATE_JOB_SUCCESS, CREATE_JOB_FAILURE],
      body: body,
    },
  };
}

// calls api to get job details
export function getJobDetails(job_id) {
  return {
    [GET_API]: {
      endpoint: BASE_URL + "/v1/job/" + job_id,
      types: [
        GET_JOB_DETAILS_REQUEST,
        GET_JOB_DETAILS_SUCCESS,
        GET_JOB_DETAILS_FAILURE,
      ],
    },
  };
}


export function getEmail(teamname){
  return{
    [GET_API]:{
      endpoint:BASE_URL+'/v1/email-alerts?teamname='+teamname,
      types:[GET_EMAIL_REQUEST,GET_EMAIL_SUCCESS,GET_EMAIL_FAILURE]
  
    }
  }
}

export function sendEmailData(body){
  return{
    [CALL_POST_API]:{
      endpoint:BASE_URL+'/v1/send-emails',
      types:[SEND_EMAIL_REQUEST,SEND_EMAIL_SUCCESS,SEND_EMAIL_FAILURE],
      body:body
    }
  }
}

// calls action to clear response
export function clearCreateJobResponse() {
  return { type: CLEAR_CREATE_JOB_RESPONSE };
}
// calls action to clear error msg, status, and blank everything
export function clearAll() {
  return { type: CLEAR_ALL };
}
// calls action to clear error msg, status, and blank everything
export function clearStatus() {
  return { type: CLEAR_STATUS };
}
export function addImageRequest() {
  return { type: IMAGE_REQUEST };
}
// calls action when add image success
export function addImageSuccess(response) {
  return { type: IMAGE_SUCCESS, response };
}
// calls action when add image failure
export function addImageFailure(response) {
  return { type: IMAGE_FAILURE, response };
}

export function updateDetails(data){
  return{
    type:UPDATE_DETAILS,
    data,
  }
}
export function updateImages(status, updatedImages) {
  return { type: UPDATE_IMAGES, status, newState: updatedImages };
}

export function   addImage(file, createJobData) {
  let formData = new FormData();
  formData.append("image", file);

  let status = "";
  const config = {
    method: "POST",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    body: formData,
  };

  return (dispatch) => {
    dispatch(addImageRequest());
    fetch(BASE_URL_DRIVE + "/v1/upload_image", config)
      .then(function(res) {
        status = res.status;
        return res.json();
      })
      .then(function(res) {
        if (status === 200) {
          if (res.error) {
            dispatch(
              addImageSuccess({
                data: { error: true, message: res.message },
                status: 200,
              })
            );
          } else {
            let job_image = createJobData;
            job_image["image"] = res.link;

            dispatch(
              addImageSuccess({
                data: { error: false, message: "Image Added", job_image },
                status: 200,
              })
            );
          }
        } else {
          dispatch(
            addImageFailure({
              data: { error: true, message: res.message },
              status: status,
            })
          );
        }
      });
  };
}
