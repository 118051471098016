import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AdminOnPageNavigation from "../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation";
import {
  clearAll,
  clearStatus,
  deleteTeam,
  selectedDeleteTeam,
  getAdminTeams,
} from "../../../../../actions/dashboard/admin/team/team";
import ReactTooltip from "react-tooltip";

import createNotification from "../../../../../components/app/notification/notification";
import { NotificationContainer } from "react-notifications";
import DeleteDialogBox from "../../../../../components/app/dialogBox/delete-dialog-box";
import {
  Table,
  TableRow,
  TableBody,
  TableData,
  TableHeaderRow,
  TableHeader,
} from "../../../../../components/dashboard/admin/xd-react-table/react-flex-table";
import {
  TableHeadComponent,
  ThComponent,
  PageSizeChooser
} from "../../../../../components/dashboard/admin/xd-react-table/react-flex-table/components";
import { PulseLoader } from "react-spinners";
import Pagination from "react-js-pagination";
import { handlePaginationPropsChange } from "../../../../../actions/dashboard/admin/pool/pool";
import { Helmet } from "react-helmet";

class ListTeam extends Component {
  handlePageChange = (e) => {
    this.props.dispatch(
      handlePaginationPropsChange(e, this.props.defaultPageSize)
    );
    this.props.history.push(
      "/dashboard/team/list" +
        "/page=" +
        e +
        "&pageSize=" +
        this.props.defaultPageSize
    );
  };
  handlePageSizeChange = (e) => {
    this.props.dispatch(
      handlePaginationPropsChange(this.props.pageNumber, Number(e.target.value))
    );
    this.props.history.push(
      "/dashboard/team/list" +
        "/page=" +
        this.props.pageNumber +
        "&pageSize=" +
        e.target.value
    );
  };
  componentWillMount() {
    this.props.dispatch(
      handlePaginationPropsChange(
        Number(this.props.match.params.pageNumber),
        Number(this.props.match.params.pageSize)
      )
    );
    this.props.dispatch(selectedDeleteTeam(""));
    this.props.dispatch(clearAll());
    this.props.dispatch(getAdminTeams());
  }

  // called when delete team event fired
  deleteTeam(e) {
    e.preventDefault();
    this.props.dispatch(deleteTeam(this.props.selected));
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }
  // open the dialog
  openDeleteTeamDialog(team , name) {
    if (team !== 1 && team !== 2) {
      this.props.dispatch(selectedDeleteTeam(team,name));
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "block";
    } else if (team == 1 || team == 2) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "block";
    }
  }
  // closes the dialog
  closeDeleteTEamDialog() {
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.delete_team_message !== "") {
      if (nextProps.delete_team_error) {
        createNotification("error", nextProps.delete_team_message);
        nextProps.dispatch(clearStatus());
      } else {
        createNotification("success", "Selected team successfully deleted");
        nextProps.dispatch(getAdminTeams());
      }
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>View Teams | Career Portal | Xenonstack</title>
        </Helmet>
        <AdminOnPageNavigation parentRoute="Dashboard" childRoute="All Team" />
        {/* <BarLoaderSpinner pageLoading={this.props.pageLoading}/> */}
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="panel panel-default card-view custom-panel pool-panel">
              <div className="panel-heading">
                <h5 className="panel-title">All Teams</h5>
                <div className="clearfix"></div>
              </div>
              <div className="panel-wrapper collapse in">
                <div className="panel-body">
                  <div className="table-wrap">
                    <div className="table-responsive">
                      <div className="text-center">
                        {this.props.pageLoading && (
                          <PulseLoader
                            margin="30px"
                            sizeUnit={"px"}
                            size={15}
                            color={"#0562e8"}
                            loading={this.props.pageLoading}
                          />
                        )}
                      </div>
                      <div className="react-xd-table-wrapper">
                        {!this.props.pageLoading &&
                          this.props.get_admin_teams.length > 0 && (
                            <Table
                              data={this.props.get_admin_teams}
                              pageSize={this.props.defaultPageSize}
                              pageSizeOptions={[10, 20, 30, 40, 50]}
                              currentPage={this.props.pageNumber}
                              render={({
                                rows,
                                pageSize,
                                pageSizeOptions,
                                handlePageSizeChange,
                                handlePrevPage,
                                handleNextPage,
                                hasNextPage,
                                hasPrevPage,
                                currentPage,
                                total,
                              }) => {
                                return (
                                  <React.Fragment>
                                    <div className="react-xd-table-body">
                                      <table className="react-xd-table striped-xd-table sortable-table footer-pagination-table">
                                        <TableHeaderRow
                                          component={TableHeadComponent}
                                        >
                                          <TableHeader
                                            accessor="sr_no"
                                            accessor_name="Sr. No."
                                            component={ThComponent}
                                          >
                                            Sr. No.
                                          </TableHeader>
                                          <TableHeader
                                            accessor="name"
                                            component={ThComponent}
                                            sortable
                                          >
                                            Team Name
                                          </TableHeader>
                                          <TableHeader
                                            accessor="type"
                                            component={ThComponent}
                                            sortable
                                          >
                                            Team Type
                                          </TableHeader>
                                          <TableHeader component={ThComponent}>
                                            Action
                                          </TableHeader>
                                        </TableHeaderRow>
                                        <TableBody component="tbody">
                                          {rows.map(
                                            (
                                              { rowKey, rowData, selected },
                                              rowIndex
                                            ) => (
                                              <TableRow
                                                component="tr"
                                                className="xd-table-row xd-table-td"
                                                key={rowKey}
                                              >
                                                {rowData.map(
                                                  ({
                                                    accessor,
                                                    data,
                                                    key,
                                                    rowsData,
                                                  }) => (
                                                    <TableData
                                                      component="td"
                                                      key={key}
                                                    >
                                                      {accessor ? (
                                                        accessor === "sr_no" ? (
                                                          rowIndex +
                                                          this.props
                                                            .defaultPageSize *
                                                            (this.props
                                                              .pageNumber -
                                                              1) +
                                                          1
                                                        ) : (
                                                          data
                                                        )
                                                      ) : (
                                                        <div className="table-action-group">
                                                          <span
                                                            className="xd-table-btn view"
                                                            onClick={() =>
                                                              this.props.history.push(
                                                                "/dashboard/team/" +
                                                                  rowsData.id +
                                                                  "/edit"
                                                              )
                                                            }
                                                            data-tip="Edit"
                                                          >
                                                            <i className="material-icons">
                                                              mode_edit
                                                            </i>
                                                            <div className="tooltipHeight">
                                                              <ReactTooltip place="bottom" />
                                                            </div>
                                                          </span>
                                                          <span
                                                            className="xd-table-btn delete"
                                                            onClick={() =>
                                                              this.openDeleteTeamDialog(
                                                                rowsData.id , rowsData.name
                                                              )
                                                            }
                                                          >
                                                            <i
                                                              className="material-icons"
                                                              data-tip="Delete"
                                                            >
                                                              delete
                                                            </i>
                                                            <div className="tooltipHeight">
                                                              <ReactTooltip place="bottom" />
                                                            </div>
                                                          </span>
                                                        </div>
                                                      )}
                                                    </TableData>
                                                  )
                                                )}
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </table>
                                    </div>
                                    <div className="react-xd-table-footer">
                                      <div className="row">
                                        <div className="col-12 col-sm-5 col-md-4">
                                          <div className="react-xd-pagesize-chooser">
                                            <span>Rows per page:</span>
                                            <PageSizeChooser
                                              pageSize={
                                                this.props.defaultPageSize
                                              }
                                              pageSizeOptions={pageSizeOptions}
                                              handlePageSizeChange={this.handlePageSizeChange.bind(
                                                this
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 col-sm-7 col-md-8">
                                          <div className="react-xd-pagination-container">
                                            <div className="pagination-outer">
                                              <div className="pagination-group">
                                                <Pagination
                                                  firstPageText={"First"}
                                                  lastPageText="Last"
                                                  prevPageText={
                                                    <i className="fa fa-angle-left" />
                                                  }
                                                  nextPageText={
                                                    <i className="fa fa-angle-right" />
                                                  }
                                                  activePage={
                                                    this.props.pageNumber
                                                  }
                                                  itemsCountPerPage={
                                                    this.props.defaultPageSize
                                                  }
                                                  totalItemsCount={total}
                                                  pageRangeDisplayed={3}
                                                  onChange={this.handlePageChange.bind(
                                                    this
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              }}
                            />
                          )}
                      </div>
                      {!this.props.pageLoading &&
                        this.props.get_admin_teams.length === 0 &&
                        this.props.get_admin_teams_message === "" && (
                          <div className="not-found">No Team found !!</div>
                        )}
                      {!this.props.pageLoading &&
                        this.props.get_admin_teams_message !== "" && (
                          <div className="not-found">
                            {this.props.get_admin_teams_message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteDialogBox
          formName="Delete Team"
          submitForm={this.deleteTeam}
          operation="Delete Team"
          keyword={"Delete"}
          closeForm={this.closeDeleteTEamDialog}
          deletingName={this.props.deleteName}
          pageLoading={this.props.pageLoading}
          selected={this.props.deleteTeam}
          name={this.props.deleteTeam}
        />
        <NotificationContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    deleteTeam,
    deleteName,
    pageLoading,
    get_admin_teams_status,
    get_admin_teams_error,
    get_admin_teams_message,
    get_admin_teams,
    delete_team_error,
    delete_team_message,
    delete_team_status,
  } = state.adminTeamReducer;
  const { pageNumber, defaultPageSize } = state.poolReducer;
  return {
    deleteTeam,
    pageLoading,
    deleteName,
    get_admin_teams_status,
    get_admin_teams_error,
    get_admin_teams_message,
    get_admin_teams,
    delete_team_error,
    delete_team_message,
    delete_team_status,
    state,
    pageNumber,
    defaultPageSize,
  };
}

export default withRouter(connect(mapStateToProps)(ListTeam));
