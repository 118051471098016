import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BarLoaderSpinner from '../../../components/app/spinner/barloader';
import { changeEmailVerificationForm, verifyEmail } from '../../../actions/account/email-verification-actions';
import Input from '../../../components/app/input/input';
import Button from '../../../components/app/button/button';
import {
    changeRegistrationForm,
    checkEmail,
    clearApiErrorMessage,
    registerNow
} from "../../../actions/account/registration-action";
import { defaultRegistrationForm } from "../../../reducers/account/register-reducer";
const ENV_VAR = process.env.REACT_APP_STATIC_PAGE === undefined ? "https://xenonstack.jobs/" : process.env.REACT_APP_STATIC_PAGE;

let self = null;

function windowReload() {
    if (window.performance) {
        if (performance.navigation.type == 1) {
            window.location.replace("" + window.location.origin + "/login");
        }
    }
}

class EmailVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validationError: false
        }
    }

    componentWillMount() {
        self = this.props;
        document.title = "Email Verification | Xenonstack Hiring Portal";
        this.props.dispatch(clearApiErrorMessage());
        this.props.dispatch(changeEmailVerificationForm({
            verification_code_1: "",
            verification_code_2: "",
            verification_code_3: "",
            verification_code_4: "",
            verification_code_5: "",
            verification_code_6: ""
        }))
    }

    componentDidMount() {
        document.getElementsByName("verification_code_1")[0].focus();
        windowReload();
    }

    componentWillUnmount() {
        windowReload = undefined
    }

    // submit the form
    handleSubmit(e) {
        e.preventDefault();
        this.props.dispatch(changeRegistrationForm(this.props.state.RegisterReducer.registrationForm));
        const self = this.props;
        const thi = this;
        const form = document.getElementById("email-verification-form");
        if (form.checkValidity()) {
            thi.setState({
                validationError: false
            });
            self.dispatch(verifyEmail(Object.assign(self.state.emailVerifyReducer.emailVerificationForm, {
                email: sessionStorage.getItem("email"),
                verification_code: self.state.emailVerifyReducer.emailVerificationForm.verification_code_1 + self.state.emailVerifyReducer.emailVerificationForm.verification_code_2 + self.state.emailVerifyReducer.emailVerificationForm.verification_code_3 + self.state.emailVerifyReducer.emailVerificationForm.verification_code_4 + self.state.emailVerifyReducer.emailVerificationForm.verification_code_5 + self.state.emailVerifyReducer.emailVerificationForm.verification_code_6
            })));
        } else {
            thi.setState({
                validationError: true
            });
            const invalidElms = document.querySelectorAll(".form-group input:invalid");
            invalidElms[0].focus();
            for (let i = 0; i < invalidElms.length; i++)
                invalidElms[i].parentElement.parentElement.classList.add("has-error");
        }
    }

    // called when onchange html event fired
    onChange(e) {
        const self = this.props;
        const thi = this;
        if (!isNaN(e.target.value)) {
            this.props.dispatch(clearApiErrorMessage());
            thi.setState({
                validationError: false
            });
            if (e.target.value !== " ") {
                let newState = Object.assign(self.state.emailVerifyReducer.emailVerificationForm, { [e.target.name]: e.target.value });
                self.dispatch(changeEmailVerificationForm(newState));
            }
            if (e.target.value.length === 1 && e.target.value !== " ") {
                switch (e.target.id) {
                    case "verification_code_1":
                        return document.getElementById("verification_code_2").focus();
                    case "verification_code_2":
                        return document.getElementById("verification_code_3").focus();
                    case "verification_code_3":
                        return document.getElementById("verification_code_4").focus();
                    case "verification_code_4":
                        return document.getElementById("verification_code_5").focus();
                    case "verification_code_5":
                        return document.getElementById("verification_code_6").focus();
                    case "verification_code_6":
                        return thi.handleSubmit(e);
                    default:
                        return 0
                }
            }
        }
    }

    // calls the function when key down pressed
    keyDownCalled(event) {

        let key = event.keyCode || event.charCode;
        if (key !== 32 && key !== 9) {
            let newState = Object.assign(self.state.emailVerifyReducer.emailVerificationForm, { [event.target.name]: "" });
            self.dispatch(changeEmailVerificationForm(newState));
        }
        if (key == 8 || key == 46) {
            switch (event.target.id) {
                case "verification_code_2":
                    return document.getElementById("verification_code_1").focus();
                case "verification_code_3":
                    return document.getElementById("verification_code_2").focus();
                case "verification_code_4":
                    return document.getElementById("verification_code_3").focus();
                case "verification_code_5":
                    return document.getElementById("verification_code_4").focus();
                case "verification_code_6":
                    return document.getElementById("verification_code_5").focus();
                default:
                    return 0

            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.state.emailVerifyReducer.status === 200 && !nextProps.state.emailVerifyReducer.error) {
            nextProps.dispatch(registerNow());
            const registrationForm = defaultRegistrationForm;
            nextProps.dispatch(changeRegistrationForm(registrationForm));
            nextProps.history.push("/apply-manual/personal-information");
        }
    }

    componentWillUnmount() {
        sessionStorage.removeItem("email");
    }

    // calls when otp to resend
    resendOtp() {

        this.setState({
            validationError: false
        });
        this.props.dispatch(clearApiErrorMessage());
        this.props.dispatch(changeEmailVerificationForm({
            verification_code_1: "",
            verification_code_2: "",
            verification_code_3: "",
            verification_code_4: "",
            verification_code_5: "",
            verification_code_6: ""
        }));
        this.props.dispatch(changeRegistrationForm(self.state.RegisterReducer.registrationForm));
        this.props.dispatch(checkEmail({ email: sessionStorage.getItem("email") }));
        document.getElementsByName("verification_code_1")[0].focus();
    }

    render() {
        return (
            <>
                {/* <Header/> */}
                <main className="main-body" id="main-body">
                    <div className="wrapper-container verification-container">
                        <div className="container user-jounrney-form-container">
                           <div className="user-journey-form">
                           <div className="xenonstack-jobs-logo">
                                <a href={ENV_VAR}> <img src={require('../../../static/images/logoxe.svg')} /></a>
                            </div>
                            <div className="dashboard-user-form">
                                <div className="section_header  otp-header">
                                    <h2 className="big-heading">Email Verification</h2>
                                    <h4 className="subtitle">We have sent verification code(OTP) to <b style={{
                                        fontSize: "2rem",
                                        fontFamily: "Roboto",
                                        fontWeight: "500",
                                        color: "#1e1e1e ",
                                        wordWrap: "break-word"
                                    }}>
                                        {sessionStorage.getItem("email")}</b></h4>
                                    {/* <p className="note-msg"><strong>Note : </strong> Please check your Inbox/Spam/Updates/Promotions for OTP.</p> */}
                                </div>
                                <div className="emailOTP" id="emailOTP">
                                    <form id="email-verification-form" onSubmit={this.handleSubmit.bind(this)}
                                        autoComplete="false" className="generalForm autoClearer" noValidate={true}>
                                        <input type="hidden" name="resetPassword" />
                                        <div className="row">
                                            <div className="form-group col-12 col-sm-12 col-md-12">
                                                <div className="otp-ul">
                                                    <Input
                                                        className="form-ctrl"
                                                        id="verification_code_1"
                                                        name="verification_code_1"
                                                        tabIndex={1}
                                                        maxLength={1}
                                                        autoComplete={"false"}
                                                        onKeyDown={this.keyDownCalled.bind(this)}
                                                        type="password"
                                                        required={true}
                                                        value={this.props.state.emailVerifyReducer.emailVerificationForm.verification_code_1}
                                                        onPaste={(e) => e.preventDefault()}
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                    <Input
                                                        className="form-ctrl"
                                                        id="verification_code_2"
                                                        name="verification_code_2"
                                                        tabIndex={2}
                                                        onKeyDown={this.keyDownCalled.bind(this)}
                                                        type="password"
                                                        maxLength={1}
                                                        required={true}
                                                        value={this.props.state.emailVerifyReducer.emailVerificationForm.verification_code_2}
                                                        onPaste={(e) => e.preventDefault()}
                                                        onChange={this.onChange.bind(this)}
                                                        autoComplete={"false"}
                                                    />
                                                    <Input
                                                        className="form-ctrl"
                                                        id="verification_code_3"
                                                        name="verification_code_3"
                                                        tabIndex={3}
                                                        onKeyDown={this.keyDownCalled.bind(this)}
                                                        maxLength={1}
                                                        type="password"
                                                        required={true}
                                                        value={this.props.state.emailVerifyReducer.emailVerificationForm.verification_code_3}
                                                        onPaste={(e) => e.preventDefault()}
                                                        onChange={this.onChange.bind(this)}
                                                        autoComplete={"false"}
                                                    />
                                                    <Input
                                                        className="form-ctrl"
                                                        id="verification_code_4"
                                                        name="verification_code_4"
                                                        onKeyDown={this.keyDownCalled.bind(this)}
                                                        tabIndex={4}
                                                        maxLength={1}
                                                        type="password"
                                                        required={true}
                                                        value={this.props.state.emailVerifyReducer.emailVerificationForm.verification_code_4}
                                                        onPaste={(e) => e.preventDefault()}
                                                        onChange={this.onChange.bind(this)}
                                                        autoComplete={"false"}
                                                    />
                                                    <Input
                                                        className="form-ctrl"
                                                        id="verification_code_5"
                                                        name="verification_code_5"
                                                        onKeyDown={this.keyDownCalled.bind(this)}
                                                        tabIndex={5}
                                                        maxLength={1}
                                                        type="password"
                                                        required={true}
                                                        value={this.props.state.emailVerifyReducer.emailVerificationForm.verification_code_5}
                                                        onPaste={(e) => e.preventDefault()}
                                                        onChange={this.onChange.bind(this)}
                                                        autoComplete={"false"}
                                                    />
                                                    <Input
                                                        className="form-ctrl"
                                                        id="verification_code_6"
                                                        name="verification_code_6"
                                                        onKeyDown={this.keyDownCalled.bind(this)}
                                                        tabIndex={6}
                                                        maxLength={1}
                                                        type="password"
                                                        required={true}
                                                        value={this.props.state.emailVerifyReducer.emailVerificationForm.verification_code_6}
                                                        onPaste={(e) => e.preventDefault()}
                                                        onChange={this.onChange.bind(this)}
                                                        autoComplete={"false"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12 ">
                                                <div className="resend-otp">
                                                    <a style={{
                                                        background: `${((this.props.state.emailVerifyReducer.pageLoading || this.props.state.RegisterReducer.pageLoading) ? "none" : "none")}`,
                                                        cursor: "pointer"
                                                    }} onClick={this.resendOtp.bind(this)} tabIndex={7}>Resend OTP</a></div>
                                            </div>
                                        </div>
                                        {
                                            this.state.validationError &&
                                            <div className="bPad24px error-responsive ">
                                                <span className="errorText">
                                                    Invalid  Code or Your Verification Code has expired.
                                                </span>
                                            </div>
                                        }
                                        {
                                            this.props.state.emailVerifyReducer.error &&
                                            <div className="bPad24px error-responsive ">
                                                <span className="errorText">
                                                    {this.props.state.emailVerifyReducer.message}
                                                </span>
                                            </div>
                                        }
                                        {
                                            (this.props.state.RegisterReducer.message !== "") &&
                                                this.props.state.RegisterReducer.error ?
                                                <div>
                                                    <div className="form-group">
                                                        <div className="bPad24px error-responsive">
                                                            <span className="errorText">
                                                                {this.props.state.RegisterReducer.message}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <div className="form-group">
                                                        <div className="bPad24px error-responsive">
                                                            <span className="errorText success">
                                                                {this.props.state.RegisterReducer.message}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                        <div className="row">
                                            <div className=" col-12 col-sm-12 col-md-12 ">
                                                <div>
                                                    <Button type="submit" id="submitOtp" name="submitOtp" tabIndex={8}
                                                        disabled={((this.props.state.emailVerifyReducer.pageLoading || this.props.state.RegisterReducer.pageLoading) ? true : false)}
                                                        className="btn mar-t-2" text="Next" />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ position: "relative", top: "20px" }}>
                                            <BarLoaderSpinner
                                                pageLoading={this.props.state.emailVerifyReducer.pageLoading || this.props.state.RegisterReducer.pageLoading} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="xenonstack-jobs-copyright">
                                <p>Xenonstack | </p>
                                <i class="fa fa-copyright"></i>
                                <p>2021 All Rights Reserved</p>
                            </div>
                           </div>
                        </div>
                    </div>
                </main>
                {/* <Footer/> */}
            </>
        )
    }
}

function mapStateToProps(state) {
    return { state }
}

export default withRouter(connect(mapStateToProps)(EmailVerification))
