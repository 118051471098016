import React, { Component } from "react";
import update from "immutability-helper";
import { PulseLoader } from "react-spinners";
import {Helmet} from "react-helmet";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Board from 'react-trello'
import CustomLane from "../../../../components/dashboard/admin/wekan/CustomLane";
import CustomCard from "../../../../components/dashboard/admin/wekan/CustomCard";
import createNotification from "../../../../components/app/notification/notification";
import {
  changeCreateLaneForm,
  getAllLanes,
  updateCardStatus,
  openSelectedCard,
  changeCreateCommentForm,
  createCardComment,
  getCardComment,
  createLane,
  clearStatus
} from "../../../../actions/dashboard/admin/wekanboard/wekanboard";
import { checkValidation } from "../../../../actions/app/app";
import moment from "moment";
let cardTitle;

class WekanBoard extends Component {
  componentDidMount() {
    this.props.dispatch(getAllLanes());
  }
  handleDragStart = (cardId, laneId) => {
  };
  handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {
      const body = {
      status: targetLaneId  ,
      email: cardId
    };
    this.props.dispatch(updateCardStatus(body));
  }

  componentWillReceiveProps(nextProps) {

    // calls when we delete a Lane
    if (nextProps.deleteLaneMessage !== "") {
      if (!nextProps.deleteLaneError) {
        createNotification("success", nextProps.deleteLaneMessage);
        nextProps.dispatch(getAllLanes())
      }
    }

    //calls when we add a new lane
    if (nextProps.createLaneMessage !== "") {
      this.props.dispatch(getAllLanes());
      if (nextProps.createLaneError) {
        createNotification("error", nextProps.createLaneMessage);
      } else {
        nextProps.dispatch(changeCreateLaneForm({ roundname: "" }));
        createNotification("success", nextProps.createLaneMessage);
      }
    }

    //calls when we update the name of the lane
    if (nextProps.editLaneMessage !== "") {
      if(nextProps.editLaneError){
        createNotification("error", nextProps.editLaneMessage);
      }
      else{
        createNotification("success", nextProps.editLaneMessage);
        nextProps.dispatch(getAllLanes());
      }
    }

    //calls when we add new comment

    if(nextProps.createCommentStatus === 200){
      if(!nextProps.createCommentError)
      {
        nextProps.dispatch(getCardComment(this.props.cardDetails.cardId));
        createNotification("success",nextProps.createCommentMessage)
      }
      else{
        createNotification("error",nextProps.createCommentMessage)
      }
    }

    if(nextProps.editStatusMessage !== ""){
      if(nextProps.editStatusError){
        createNotification("error",nextProps.editStatusMessage)
      }
      else{
        createNotification("success",nextProps.editStatusMessage)
        nextProps.dispatch(getAllLanes())
      }
    }

  }
  onCardClickEvent = (cardId, title) => {
      this.props.lanes.forEach(lane => {
      lane.cards.forEach(card => {
        if (cardId === card.email) {
          cardTitle = card.name;
        }
      });
    });
    this.props.dispatch(openSelectedCard({ cardId, cardTitle }));
    this.props.dispatch(getCardComment(cardId));
    this.props.dispatch(
      changeCreateCommentForm(
        Object.assign(this.props.comments, { email: "" }, { comment: "" })
      )
    );
    document
      .querySelectorAll(".create-creating-pool.txt-form .form-group")[0]
      .classList.remove("has-error");
    document.getElementsByClassName("overlay-edit-pool")[0].style.display =
      "block";
  };
  closePopup() {
    if (!!document.getElementsByClassName("overlay-edit-pool")[0]) {
      document.getElementsByClassName("overlay-edit-pool")[0].style.display =
        "none";
    }
  }
  onChange(e) {
    const self = this.props;
    checkValidation(e);
    const newState = Object.assign(self.comments, {
      [e.target.name]: e.target.value
    });

    self.dispatch(changeCreateCommentForm(newState));
  }
  submitCreateCommentForm(e) {
    e.preventDefault();
    if (e.target.checkValidity()) {
      const body = {
        email: this.props.cardDetails.cardId,
        comment: this.props.comments.comment
      };
      this.props.dispatch(createCardComment(body));
    }
  }
  addNewLanes(data) {
    const result = {
      roundname: ""
    };
    result.roundname = data.title;
    if (result.roundname === "") {
      createNotification("error", "Title of Lane cannot be empty!");
      this.props.dispatch(getAllLanes());
    } else {
      this.props.dispatch(createLane(result));
    }
  }
  render() {

    // converting API data into proper format.

    let laneData = {lanes:[]};
    if (this.props.lanes.length > 0) {
      let laneDataList = this.props.lanes;
      let lanesList = [];

      laneDataList.forEach(lanes => {
        lanesList.push({
          id: lanes.id,
          title: lanes.roundname,
          date: lanes.date,
          cards: lanes.cards.map((cards, index) => ({
            id: cards.email,
            name: cards.name,
            date: cards.date
          }))
        });
      });

      if(lanesList.length > 0){
        laneData = Object.assign({lanes: lanesList});
      }
    }
    return (
    <>
      <Helmet>
                <title>Board | Career Portal | Xenonstack</title>
             </Helmet>
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-md-12">
            <div class="heading-bg">
              <h4 class="txt-dark">
                <a href="/dashboard">Dashboard</a>
                <small>
                  /<a href="/dashboard/board"> Selection Process Board</a>
                </small>
              </h4>
            </div>
          </div>
        </div>
        <div className="text-center">
          <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        {this.props.getLanePageLoading && (
                          <PulseLoader
                            margin="30px"
                            sizeUnit={"px"}
                            size={15}
                            color={"#48194e"}
                            loading={this.props.getLanePageLoading}
                          />
                        )}
                        </div>
                      </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12">
            <div className="board-height">
            {laneData.lanes.length > 0 &&
              <Board
                addCardLink={<></>}
                className="wekanBoardContainer"
                data={laneData}
                draggable
                editable
                hideCardDeleteIcon={true}
                canAddLanes
                onLaneAdd={data => this.addNewLanes(data)}
                eventBusHandle={this.setEventBus}
                handleDragStart={this.handleDragStart}
                handleDragEnd={this.handleDragEnd}
                onCardClick={(cardId, title) => {
                  this.onCardClickEvent(cardId, title);
                }}
                customLaneHeader={<CustomLane data={laneData} />}
                tagStyle={{ fontSize: "80%" }}
                customCardLayout
                laneDraggable={false}
                components={{Card: CustomCard}}
               >
              </Board>}
               {!this.props.getLanePageLoading && laneData.lanes.length===0 && (<div>No Data Found</div>)}

              {!this.props.pageLoading && this.props.getLaneMessage !== "" && (
                <tr>
                  <td colSpan={4}>
                    <div className="table-body-loader">
                      <div className="flex-center">
                        <div className="server-error-response">
                          {this.props.getLaneMessage}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </div>
          </div>
        </div>
        <div id="popup1" className="overlay overlay-edit-pool">
          <form  className="create-creating-pool txt-form"
            onSubmit={this.submitCreateCommentForm.bind(this)} >
            <div className="board-popup">
              <div className="board-popup-heading">
                <h4>
                  {this.props.cardDetails.cardTitle} (
                  {this.props.cardDetails.cardId})
                </h4>
                <a className="board-close" onClick={this.closePopup}>
                  &times;
                </a>
              </div>
              <div className="content">
                <div className="form-group">
                  <input
                    type="text"
                    autoComplete="off"
                    minLength={3}
                    maxLength={50}
                    pattern={
                      "([a-zA-Z.!-”$%&’()*\\+,\\/;\\[\\\\\\]\\^_`{|}~]+\\s)*[a-zA-Z.!-”$%&’()*\\+,\\/;\\[\\\\\\]\\^_`{|}~]+$"
                    }
                    className="form-control"
                    value={this.props.comments.comment}
                    name="comment"
                    onChange={this.onChange.bind(this)}
                    placeholder="Write Comment"
                    required={true}
                  />
                  <p className="with-error">Error.</p>
                </div>
                <div className="btn-group ">
                  <button type="submit" className="btn">
                    Add Comment
                  </button>
                </div>
              </div>
              <hr></hr>
              <div className="content">
              <h3>Comments</h3>
                  {this.props.cardComment.map((d, i) => (
                  <div>
                     <div class="wekancomment-section">
                      <div class="heading-div">
                      </div>
                      <p class="date-text">{moment(d.date * 1000).format("Do MMM YYYY")}</p>
                      <div class="feedback-detail"><p class="page-heading-desc">{d.comment}</p></div></div>
                    </div>
                    ))}
                </div>
            </div>
          </form>
         </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const {
    lanes,
    getLanePageLoading,
    getLaneStatus,
    getLaneMessage,
    getLaneError,
    deleteLaneError,
    deleteLaneMessage,
    createLaneMessage,
    deleteLaneStatus,
    createLaneError,
    editLaneError,
    editLaneMessage,
    editLaneStatus,
    cardDetails,
    comments,
    editStatusError,
    editStatusMessage,
    createCommentMessage,
    createCommentError,
    cardComment,
    createCommentStatus
  } = state.laneReducer;
  const { id, comment } = comments;

  return {
    lanes,
    getLanePageLoading,
    getLaneMessage,
    getLaneStatus,
    getLaneError,
    deleteLaneError,
    deleteLaneMessage,
    deleteLaneStatus,
    createLaneMessage,
    createLaneError,
    editLaneError,
    editLaneMessage,
    editLaneStatus,
    cardDetails,
    comments,
    createCommentMessage,
    createCommentError,
    id,
    editStatusError,
    editStatusMessage,
    comment,
    cardComment,
    createCommentStatus
  };
}

export default withRouter(connect(mapStateToProps)(WekanBoard));
