export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";


export const UPDATE_RESUME_REQUEST = "UPDATE_RESUME_REQUEST";
export const UPDATE_RESUME_SUCCESS = "UPDATE_RESUME_SUCCESS";
export const UPDATE_RESUME_FAILURE = "UPDATE_RESUME_FAILURE";


export const SET_UPDATE_RESUME_GO_TO_PROFILE_TRUE = "SET_UPDATE_RESUME_GO_TO_PROFILE_TRUE"
