import React, {Component} from 'react';
import {withRouter , NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import AdminOnPageNavigation from '../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import moment from "moment";
import { Table, TableRow, TableBody, TableData, TableHeaderRow, TableHeader } from '../../../../components/dashboard/admin/xd-react-table/react-flex-table'
import { TableHeadComponent , ThComponent, PageSizeChooser} from '../../../../components/dashboard/admin/xd-react-table/react-flex-table/components';
import { PulseLoader } from 'react-spinners';
import Pagination from 'react-js-pagination'
import { handlePaginationPropsChange} from "../../../../actions/dashboard/admin/pool/pool";
import { getAllDrivewiseResult } from '../../../../actions/dashboard/result/results'
import {Helmet} from "react-helmet";

class DriveResult extends Component {


    handlePageChange = e => {
        this.props.dispatch(handlePaginationPropsChange(e, this.props.defaultPageSize))
        this.props.history.push('/dashboard/result/drive'+'/page='+e+'&pageSize='+this.props.defaultPageSize)
      }
      handlePageSizeChange = e => {
        this.props.dispatch(handlePaginationPropsChange(this.props.pageNumber, Number(e.target.value)))
        this.props.history.push('/dashboard/drive/view'+'/page='+this.props.pageNumber+'&pageSize='+e.target.value)
      }

    componentDidMount() {
        this.props.dispatch(handlePaginationPropsChange(Number(this.props.match.params.pageNumber), Number(this.props.match.params.pageSize)))
        this.props.dispatch(getAllDrivewiseResult())
    }

    handleClick(driveId,driveName){
        localStorage.setItem("drive_id",driveId)
        localStorage.setItem("drive_name",driveName)


    }

    render() {
        return (
            <div>
            <Helmet>
                <title>Drivewise Result | Career Portal | Xenonstack</title>
             </Helmet>
                <div className="row">
                <div className="col-9 col-sm-9 col-md-9">
                    <AdminOnPageNavigation parentRoute="Dashboard" childRoute="Drive Result"/> </div>
                    <div className="col-2 col-sm-2 col-md-2 heading-bg">
                    {/*<Checkbox checked={this.state.isChecked} name="Checked" onChange={this.toggleChange} labelValue="Open" id="checked-input" value="checked-onchange" />
                    <Checkbox checked={this.state.isChecked} name="Checked" onChange={this.toggleChange} labelValue="Campus" id="checked-input" value="checked-onchange" />
                */}</div>
               </div>

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <div className="panel panel-default card-view custom-panel pool-panel">
                            <div className="panel-heading">
                                <h5 className="panel-title">All Drives</h5>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                                 <div className="table-wrap">
                                        <div className="table-responsive">
                                        <div className="text-center">
                                        {this.props.getAllDrivewiseResult_PageLoading &&
                                         <PulseLoader
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#48194e'}
                                         loading={this.props.getAllDrivewiseResult_PageLoading}
                                         />
                                        }</div>
                                        <div className="react-xd-table-wrapper">
                                            {!this.props.getAllDrivewiseResult_PageLoading && this.props.allDrivewiseresult.length > 0 &&
                                                        <Table
                                                        data={this.props.allDrivewiseresult}
                                                        pageSize={this.props.defaultPageSize}
                                                        pageSizeOptions={[10,20,30,40,50]}
                                                        currentPage={this.props.pageNumber}
                                                            render={({
                                                            rows,
                                                            pageSize,
                                                            pageSizeOptions,
                                                            handlePageSizeChange,
                                                            handlePrevPage,
                                                            handleNextPage,
                                                            hasNextPage,
                                                            hasPrevPage,
                                                            currentPage,
                                                            total
                                                            }) => {
                                                            return (
                                                                <React.Fragment>
                                                                <div className="react-xd-table-body">
                                                                    <table className="react-xd-table striped-xd-table sortable-table footer-pagination-table">
                                                                    <TableHeaderRow component={TableHeadComponent}>
                                                                    <TableHeader
                                                                        accessor="sr_no"
                                                                        accessor_name="Sr. No."
                                                                        component={ThComponent}
                                                                        >
                                                                        Sr. No .
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="rank"
                                                                        component={ThComponent}
                                                                        >
                                                                        Rank
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="name"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Name
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="total"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Candidates
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="attempted"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Attempted
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="correct"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Correct
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="accuracy"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Accuracy
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="percentage"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Percentage
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="time_taken"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Total Time
                                                                        </TableHeader>

                                                                        </TableHeaderRow>
                                                                        <TableBody
                                                                            component="tbody"
                                                                            >
                                                                            {rows.map(({ rowKey, rowData, selected }, rowIndex) => (
                                                                            <TableRow
                                                                                component="tr"
                                                                                className="xd-table-row xd-table-td"
                                                                                key={rowKey}
                                                                            >
                                                                            {rowData.map(({  accessor, data, key , rowsData }) => (

                                                                               <TableData
                                                                                component="td"
                                                                                key={key}
                                                                                >

                                                                                {accessor ?
                                                                                (accessor === 'name' ? (<NavLink to={"/dashboard/result/drivewise/"+rowsData.id+"/candidates/page=1&pageSize=10"} onClick={() => this.handleClick( rowsData.id,rowsData.name)}  >{data}</NavLink> ) :
                                                                                 accessor === 'sr_no' ? rowIndex + (this.props.defaultPageSize * (this.props.pageNumber-1))+1:
                                                                                 accessor === 'time_taken' ? moment.utc(rowsData.time_taken*1000).format('HH:mm:ss'):
                                                                                 accessor === 'rank' ? ((rowsData.rank === 'same')&&(<i className="material-icons same">remove</i>)||(rowsData.rank === 'up')&&(<i className="material-icons greenarrow">arrow_upward</i>)||(rowsData.rank === 'down')&&(<i className="material-icons redarrow">arrow_downward</i>)) :
                                                                                              (data)
                                                                                ) :

                                                                                (
                                                                                <div>
                                                                                <span></span>
                                                                                </div>
                                                                                )}

                                                                                </TableData>
                                                                            ))}

                                                                            </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                        </table>
                                                                        </div>
                                                                        <div className="react-xd-table-footer">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-5 col-md-4">
                                                                                <div className="react-xd-pagesize-chooser">
                                                                                    <span>Rows per page:</span>
                                                                                    <PageSizeChooser
                                                                                    pageSize={this.props.defaultPageSize}
                                                                                    pageSizeOptions={pageSizeOptions}
                                                                                    handlePageSizeChange={this.handlePageSizeChange.bind(this)}
                                                                                    />
                                                                                </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-7 col-md-8">
                                                                                <div className="react-xd-pagination-container">
                                                                                <div className="pagination-outer">
                                                                                    <div className="pagination-group">
                                                                                    <Pagination
                                                                                        firstPageText={"First"}
                                                                                        lastPageText="Last"
                                                                                        prevPageText={<i className='fa fa-angle-left'/>}
                                                                                        nextPageText={<i className='fa fa-angle-right'/>}
                                                                                        activePage={this.props.pageNumber}
                                                                                        itemsCountPerPage={this.props.defaultPageSize}
                                                                                        totalItemsCount={total}
                                                                                        pageRangeDisplayed={3}
                                                                                        onChange={this.handlePageChange.bind(this)}
                                                                                        />
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>

                                                                    </React.Fragment>
                                                                )
                                                                }}
                                                            />
                                            }
                                                            </div>
                                                            {
                                                    (!this.props.getAllDrivewiseResult_PageLoading && this.props.allDrivewiseresult.length === 0) &&

                                                            <div className="not-found">
                                                                No Drive found !!
                                                            </div>

                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                        </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {

    const { pageNumber,defaultPageSize} = state.poolReducer;

    const {
        getAllDrivewiseResult_PageLoading,
        getAllDrivewiseResultPage_status,
        getAllDrivewiseResultPage_error,
        getAllDrivewiseResultPage_message,
        allDrivewiseresult
    } = state.adminResultReducer;


    return {
        pageNumber,defaultPageSize,
        getAllDrivewiseResult_PageLoading,
        getAllDrivewiseResultPage_status,
        getAllDrivewiseResultPage_error,
        getAllDrivewiseResultPage_message,
        allDrivewiseresult
    }
};

export default withRouter(connect(mapStateToProps)(DriveResult))
