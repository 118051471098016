import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AdminOnPageNavigation from "../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation";
import createNotification from "../../../../../components/app/notification/notification";
import {
  Table,
  TableRow,
  TableBody,
  TableData,
  TableHeaderRow,
  TableHeader,
} from "../../../../../components/dashboard/admin/xd-react-table/react-flex-table";
import {
  TableHeadComponent,
  ThComponent,
  PageSizeChooser
} from "../../../../../components/dashboard/admin/xd-react-table/react-flex-table/components";
import {
  clearStatus,
  deleteJob,
  getJobs,
  selectedDeleteJob,
} from "../../../../../actions/dashboard/user/jobs/jobs";
import { NotificationContainer } from "react-notifications";
import ReactTooltip from "react-tooltip";
import DeleteDialogBox from "../../../../../components/app/dialogBox/delete-dialog-box";
import Pagination from "react-js-pagination";
import { handlePaginationPropsChange } from "../../../../../actions/dashboard/admin/pool/pool";
import { Helmet } from "react-helmet";

let props = null;
class ListJob extends Component {
  handlePageChange = (e) => {
    this.props.dispatch(
      handlePaginationPropsChange(e, this.props.defaultPageSize)
    );
    this.props.history.push(
      "/dashboard/job/list" +
      "/page=" +
      e +
      "&pageSize=" +
      this.props.defaultPageSize
    );
  };

  handlePageSizeChange = (e) => {

    this.props.dispatch(
      handlePaginationPropsChange(this.props.pageNumber, Number(e.target.value))
    );
    this.props.history.push(
      "/dashboard/job/list" +
      "/page=" +
      this.props.pageNumber +
      "&pageSize=" +
      e.target.value
    );
  };
  componentWillMount() {
    this.props.dispatch(
      handlePaginationPropsChange(
        Number(this.props.match.params.pageNumber),
        Number(this.props.match.params.pageSize)
      )
    );
    this.props.dispatch(getJobs());
    props = this.props;
  }

  // called when delete job event fired
  deleteJob(e) {
    e.preventDefault();

    this.props.dispatch(deleteJob(this.props.selected)); //team , job
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }

  // opens the dialog
  openDeleteTeamDialog(team_id, job_id, job_name) {
    this.props.dispatch(selectedDeleteJob({ team_id, job_id, job_name }));

    document.getElementsByClassName("overlay-delete")[1].style.display =
      "block";
  }

  // closes the dialog
  closeDeleteJobDialog() {
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }
  componentWillReceiveProps(nextProps) {
    props = nextProps;
    if (nextProps.delete_job_message !== "") {
      if (nextProps.delete_job_error) {
        createNotification("error", nextProps.delete_job_message);
        nextProps.dispatch(clearStatus());
      } else {
        createNotification("success", nextProps.delete_job_message);
        nextProps.dispatch(clearStatus());
        nextProps.dispatch(getJobs());
      }
    }
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>View Job | Career Portal | Xenonstack</title>
        </Helmet>
        <AdminOnPageNavigation parentRoute="Dashboard" childRoute="All Jobs" />
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default card-view custom-panel pool-panel">
              <div className="panel-heading">
                <h5 className="panel-title">All Jobs</h5>
                <div className="clearfix"></div>
              </div>
              <div className="panel-wrapper collapse in">
                <div className="panel-body">
                  <div className="table-wrap">
                    <div className="table-responsive">
                      <div className="react-xd-table-wrapper">
                        {!this.props.pageLoading && this.props.jobs.length > 0 && (
                          <Table
                            data={this.props.jobs}
                            pageSize={this.props.defaultPageSize}
                            pageSizeOptions={[10, 20, 30, 40, 50]}
                            currentPage={this.props.pageNumber}
                            render={({ rows, pageSizeOptions, total }) => {
                              return (
                                <React.Fragment>
                                  <div className="react-xd-table-body">
                                    <table className="react-xd-table striped-xd-table sortable-table footer-pagination-table">
                                      <TableHeaderRow
                                        component={TableHeadComponent}
                                      >
                                        <TableHeader
                                          accessor="sr_no"
                                          accessor_name="Sr. No."
                                          component={ThComponent}
                                        >
                                          Sr. No .
                                        </TableHeader>
                                        <TableHeader
                                          accessor="name"
                                          component={ThComponent}
                                          sortable
                                        >
                                          Job Name
                                        </TableHeader>
                                        <TableHeader
                                          accessor="team_name"
                                          component={ThComponent}
                                          sortable
                                        >
                                          Team Name
                                        </TableHeader>

                                        <TableHeader
                                          accessor="keywords"
                                          component={ThComponent}
                                          sortable
                                        >
                                          Job Keywords
                                        </TableHeader>
                                        <TableHeader component={ThComponent}>
                                          Action
                                        </TableHeader>
                                      </TableHeaderRow>
                                      <TableBody component="tbody">
                                        {rows.map(
                                          (
                                            { rowKey, rowData, selected },
                                            rowIndex
                                          ) => (
                                            <TableRow
                                              component="tr"
                                              className="xd-table-row xd-table-td"
                                              key={rowKey}
                                            >
                                              {rowData.map(
                                                ({
                                                  accessor,
                                                  data,
                                                  key,
                                                  rowsData,
                                                }) => (
                                                  <TableData
                                                    component="td"
                                                    key={key}
                                                  >
                                                    {accessor ? (
                                                      accessor ===
                                                        "keywords" ? (
                                                        rowsData.keywords ===
                                                          null ? (
                                                          <></>
                                                        ) : (
                                                          rowsData.keywords.join(
                                                            " , "
                                                          )
                                                        )
                                                      ) : accessor ===
                                                        "sr_no" ? (
                                                        rowIndex +
                                                        this.props
                                                          .defaultPageSize *
                                                        (this.props
                                                          .pageNumber -
                                                          1) +
                                                        1
                                                      ) : (
                                                        data
                                                      )
                                                    ) : (
                                                      <div className="table-action-group">
                                                        <span
                                                          className="xd-table-btn view"
                                                          onClick={() =>
                                                            this.props.history.push(
                                                              "/dashboard/job/" +
                                                              rowsData.id +
                                                              "/edit"
                                                            )
                                                          }
                                                          data-tip="Edit"
                                                        >
                                                          <i className="material-icons">
                                                            mode_edit
                                                          </i>
                                                          <div className="tooltipHeight">
                                                            <ReactTooltip place="bottom" />
                                                          </div>
                                                        </span>
                                                        <span
                                                          className="xd-table-btn delete"
                                                          onClick={() =>
                                                            this.openDeleteTeamDialog(
                                                              rowsData.team_id,
                                                              rowsData.id,
                                                              rowsData.name
                                                            )
                                                          }
                                                          data-tip="Delete"
                                                        >
                                                          <i className="material-icons">
                                                            delete
                                                          </i>
                                                          <div className="tooltipHeight">
                                                            <ReactTooltip place="bottom" />
                                                          </div>
                                                        </span>
                                                      </div>
                                                    )}
                                                  </TableData>
                                                )
                                              )}
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </table>
                                  </div>
                                  <div className="react-xd-table-footer">
                                    <div className="row">
                                      <div className="col-12 col-sm-5 col-md-4">
                                        <div className="react-xd-pagesize-chooser">
                                          <span>Rows per page:</span>
                                          <PageSizeChooser
                                            pageSize={
                                              this.props.defaultPageSize
                                            }
                                            pageSizeOptions={pageSizeOptions}
                                            handlePageSizeChange={this.handlePageSizeChange.bind(
                                              this
                                            )}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-7 col-md-8">
                                        <div className="react-xd-pagination-container">
                                          <div className="pagination-outer">
                                            <div className="pagination-group">
                                              <Pagination
                                                firstPageText={"First"}
                                                lastPageText="Last"
                                                prevPageText={
                                                  <i className="fa fa-angle-left" />
                                                }
                                                nextPageText={
                                                  <i className="fa fa-angle-right" />
                                                }
                                                activePage={
                                                  this.props.pageNumber
                                                }
                                                itemsCountPerPage={
                                                  this.props.defaultPageSize
                                                }
                                                totalItemsCount={total}
                                                pageRangeDisplayed={3}
                                                onChange={this.handlePageChange.bind(
                                                  this
                                                )}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {!this.props.pageLoading &&
                    this.props.jobs.length === 0 &&
                    this.props.message === "" && (
                      <div className="not-found">No Job Found !!</div>
                    )}
                  {!this.props.pageLoading && this.props.message !== "" && (
                    <div className="not-found">{this.props.message}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteDialogBox
          formName="Delete Job"
          submitForm={this.deleteJob}
          operation="Delete Job"
          keyword={"Delete"}
          closeForm={this.closeDeleteJobDialog}
          pageLoading={this.props.pageLoading}
          selected={this.props.deleteJob.job_id}
          name={this.props.deleteJob.job_name}
        />
        <NotificationContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    deleteJob,
    pageLoading,
    status,
    error,
    message,
    jobs,
    delete_job_status,
    delete_job_error,
    delete_job_message,
  } = state.userJobsReducer;
  const { pageNumber, defaultPageSize } = state.poolReducer;

  return {
    deleteJob,
    pageLoading,
    status,
    error,
    message,
    jobs,
    delete_job_status,
    delete_job_error,
    delete_job_message,
    pageNumber,
    defaultPageSize,
  };
}

export default withRouter(connect(mapStateToProps)(ListJob));
