import React, { Component } from "react";
import { withRouter, Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  clearApiErrorMessage,
  logout,
  logoutAccount,
} from "../../../../actions/account/login-actions";
import {
  deleteAllNotification,
  deleteSingleNotification,
  getNotification,
} from "../../../../actions/dashboard/admin/notification/notification";
import DeleteDialogBox from "../../../app/dialogBox/delete-dialog-box";
import moment from "moment";
class AdminToolbar extends Component {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      popupVisible: false,
    };
  }
  componentWillMount() {
    this.props.dispatch(clearApiErrorMessage());
    this.props.dispatch(getNotification());
  }
  handleClick() {
    if (!this.state.popupVisible) {
      // attach/remove event handler
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  }

  // opens the dialog
  openSignoutAccountDialog() {
    document.getElementsByClassName("overlay-delete")[0].style.display =
      "block";
  }

  // closes the dialog
  closeSignoutAccountDialog() {
    if (!!document.getElementsByClassName("overlay-delete")[0]) {
      document.getElementsByClassName("overlay-delete")[0].style.display =
        "none";
    }
  }

  // signout the account
  signout(e) {
    e.preventDefault();
    this.props.dispatch(logoutAccount());
    if (!!document.getElementsByClassName("overlay-delete")[0]) {
      document.getElementsByClassName("overlay-delete")[0].style.display =
        "none";
    }
  }
  deleteNotification() {
    this.props.dispatch(deleteAllNotification());
  }
  singleDeleteNotification(id) {
    this.props.dispatch(deleteSingleNotification(id));
  }

  // jobs notification panel scroll

  notificationPanelScroll() {
    document
      .getElementsByClassName("applied-jobs-list")[0]
      .classList.toggle("applied-jobs-list-scroll-active");
  }

  notificationPanel() {
    document
      .querySelector(".navbar-right")
      .classList.toggle("dropdown-menu-active");
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.logoutMe === true) {
      nextProps.dispatch(logout());
    }
    if (nextProps.deleteSingleNotificationMessage !== "") {
      nextProps.dispatch(getNotification());
    }
    if (nextProps.deleteAllNotificationMessage !== "") {
      nextProps.dispatch(getNotification());
    }
  }
  render() {
    return (
      <nav className="navbar navbar-inverse navbar-fixed-top custom-header">
        <div className="mobile-only-brand pull-left">
          <div className="nav-header pull-left">
            <div className="logo-wrap">
              <Link to="/dashboard">
                {" "}
                <img
                  className="brand-img"
                  src={require("../../../../static/images/dashboard-normal-logo.svg")}
                  alt="Admin image"
                />
                <img
                  className="brand-img-cropped"
                  src={require("../../../../static/images/dashboard-logo-cropped.svg")}
                  alt="Admin image"
                />
                {" "}
                {/* <span className="brand-text">Career Portal</span> */}
              </Link>
            </div>
          </div>
          <a
            id="toggle_nav_btn"
            className="toggle-left-nav-btn inline-block ml-20 pull-left"
          >
            <img src={require('../../../../static/images/career-portal-hamburger.svg')} className="hamburger" />
          </a>
        </div>
        <div id="mobile_only_nav" className="mobile-only-nav pull-right">
          <ul
            className="nav navbar-right top-nav pull-right"
            ref={(node) => {
              this.node = node;
            }}
          >
            <li className="dropdown alert-drp">
              <a href="javascript: void(0)" onClick={this.handleClick}>
                {/* <i className="zmdi zmdi-notifications top-nav-icon"></i> */}
                <img
                  src={require("../../../../static/images/notification-bell.svg")}
                  className="dashboard-admin-notification-bell"
                />
                <span className="top-nav-icon-badge">
                  {this.props.notificationList === undefined ||
                    this.props.notificationList === ""
                    ? 0
                    : this.props.notificationList.length}
                </span>
              </a>
              {this.state.popupVisible && (
                <ul className="notification dropdown-menu alert-dropdown">
                  <li>
                    <div className="notification-box-head-wrap">
                      <span className="notification-box-head pull-left inline-block">
                        notifications
                      </span>
                      <a
                        className="txt-danger pull-right clear-notifications inline-block"
                        href="javascript:void(0)"
                        onClick={this.deleteNotification.bind(this)}
                      >
                        Clear All
                      </a>
                      <div className="clearfix"></div>
                      <hr className="light-grey-hr ma-0" />
                    </div>
                  </li>

                  <li className="applied-jobs-list">
                    <div className="sl-item">
                      <a href="javascript:void(0)">
                        {this.props.pageLoading ||
                          this.props.notificationList === undefined ||
                          this.props.notificationList === "" ||
                          this.props.notificationListError === true ? (
                          <h4 style={{ textAlign: "center" }}>
                            No Notification Available
                          </h4>
                        )
                          : (
                            this.props.notificationList.map((d) => (
                              <div className="sl-content">
                                <div className="notification-panel-heading">
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {moment.unix(d.time).format("DD/MM/YYYY")}
                                  </span>
                                  <span
                                    className="inline-block capitalize-font  pull-left truncate head-notifications"
                                    onClick={() => {
                                      this.singleDeleteNotification(d.id);
                                    }}
                                  >
                                    <NavLink to={d.redirect_link}>
                                      {d.title}
                                    </NavLink>
                                  </span>

                                </div>

                                <div className="notification-panel-content">
                                  <p className="truncate">{d.message}</p>
                                </div>
                              </div>
                            ))
                          )}
                      </a>
                    </div>
                  </li>




                  <li>
                    <div className="notification-box-bottom-wrap">
                      <hr className="light-grey-hr ma-0" />
                      {this.props.notificationList === "" ||
                        this.props.notificationList === undefined ? (
                        <a
                          className="block text-center read-all"
                          href="javascript:void(0) "
                        >
                        </a>
                      ) : (
                        <a
                          className="block text-center read-all"
                          href="javascript:void(0) "
                          onClick={this.notificationPanelScroll}
                        >
                          read all
                        </a>
                      )}

                      <div className="clearfix"></div>
                    </div>
                  </li>
                </ul>
              )}
            </li>
            <li className="dropdown auth-drp">
              <a
                href="#"
                className="dropdown-toggle pr-0"
                data-toggle="dropdown"
                style={{ cursor: "default" }}
              >
                <img
                  src={require("../../../../static/images/admin-dashboard-profile.svg")}
                  alt="user_auth"
                  className="user-auth-img img-circle"
                />
                <span> Admin</span>
              </a>
            </li>
            <li className="dropdown auth-drp">
              <a
                href="#"
                onClick={this.openSignoutAccountDialog.bind(this)}
                className="dropdown-toggle pr-0"
                data-toggle="dropdown"
              >
                <span> Logout</span>
              </a>
            </li>
          </ul>
        </div>
        <DeleteDialogBox
          formName="Signout-account"
          submitForm={this.signout}
          operation="Logout Account"
          keyword="Logout"
          closeForm={this.closeSignoutAccountDialog}
          pageLoading={false}
          selected=""
          name=""
        />
      </nav>
    );
  }
}

function mapStateToProps(state) {
  const { logoutMe } = state.LoginReducer;
  const {
    notificationList,
    notificationListError,
    notificationListPageLoading,
    deleteSingleNotificationMessage,
    deleteAllNotificationMessage,
  } = state.NotificationReducer;
  return {
    logoutMe,
    notificationList,
    notificationListError,
    notificationListPageLoading,
    deleteSingleNotificationMessage,
    deleteAllNotificationMessage,
    state,
  };
}

export default withRouter(connect(mapStateToProps)(AdminToolbar));
