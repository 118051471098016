//-----------------------get API types========================//
export const GET_ALL_LANES_REQUEST='GET_ALL_LANES_REQUEST';
export const GET_ALL_LANES_SUCCESS='GET_ALL_LANES_SUCCESS';
export const GET_ALL_LANES_FAILURE='GET_ALL_LANES_FAILURE';

//----------------------Create API--------------------------//
export const CREATE_LANE_REQUEST='CREATE_LANE_REQUEST';
export const CREATE_LANE_SUCCESS='CREATE_LANE_SUCCESS';
export const CREATE_LANE_FAILURE='CREATE_LANE_FAILURE';

export const CHANGE_CREATE_LANE_FORM='CHANGE_CREATE_LANE_FORM'

export const DELETE_LANE_REQUEST="DELETE_LANE_REQUEST"
export const DELETE_LANE_SUCCESS="DELETE_LANE_SUCCESS" 
export const DELETE_LANE_FAILURE="DELETE_LANE_FAILURE" 

export const DELETE_SELECTED_LANE="DELETE_SELECTED_LANE"

export const EDIT_BUTTON_CLICK="EDIT_BUTTON_CLICK"

export const EDIT_LANE_REQUEST="EDIT_LANE_REQUEST" 
export const EDIT_LANE_SUCCESS="EDIT_LANE_SUCCESS"
export const EDIT_LANE_FAILURE="EDIT_LANE_FAILURE"

export const CLEAR_ALL="CLEAR_ALL";

export const CLEAR_STATUS="CLEAR_STATUS"

//update update status of card 
export const  EDIT_STATUS_REQUEST='EDIT_STATUS_REQUEST'
export const  EDIT_STATUS_SUCCESS='EDIT_STATUS_SUCCESS'
export const  EDIT_STATUS_FAILURE='EDIT_STATUS_FAILURE'

//update date on the card
export const EDIT_DATE_REQUEST='EDIT_DATE_REQUEST'
export const EDIT_DATE_SUCCESS='EDIT_DATE_SUCCESS'
export const EDIT_DATE_FAILURE='EDIT_DATE_FAILURE'

//add comments of the cards
export const CREATE_COMMENT_REQUEST='CREATE_COMMENT_REQUEST'
export const CREATE_COMMENT_SUCCESS='CREATE_COMMENT_SUCCESS'
export const CREATE_COMMENT_FAILURE='CREATE_COMMENT_FAILURE'

export const GET_SELECTED_CARD='GET_SELECTED_CARD';

//SHOW COMMENTS
export const GET_COMMENT_REQUEST='GET_COMMENT_REQUEST'
export const GET_COMMENT_SUCCESS='GET_COMMENT_SUCCESS'
export const GET_COMMENT_FAILURE='GET_COMMENT_FAILURE'


export const CHANGE_CREATE_COMMENT_FORM='CHANGE_CREATE_COMMENT_FORM'