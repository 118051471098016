import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import "./footerbanner.css";
import '../.././static/css/newGrid.css'
import '../.././static/css/newPrimary.css'
const ENV_VAR = process.env.REACT_APP_STATIC_PAGE === undefined ? "https://xenonstack.jobs/" : process.env.REACT_APP_STATIC_PAGE;
class FooterBanner extends Component {
    render() {
        return (
            <section class="footer-upper-section">
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="footer-container">
                    <div class="quiz-section">
                      <span class="quiz-heading">Work at XenonStack</span>
                      <p class="quiz-para">
                      We acknowledge individuals who wish to explore their capabilities with XenonStack, further adding value to our teams.
                      </p>
                      <a href={ENV_VAR+"jobs/"} className="xenonstack-contact-us-button">Job Openings
                      <div class="btn-quiz checkout-button xenonstack-header-btn">
                      </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-background">
              <img src={require('../../static/images/quiz-background.svg')} alt="background" />
            </div>
          </section>
      
        )
    }
}

export default withRouter(FooterBanner)