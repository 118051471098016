import config from "../config";

export function authApi(){
    return config.app.REACT_APP_AUTH_API;
}

export function driveApi(){
    return config.app.REACT_APP_DRIVE_API
}

export function imageApi(){
    return config.app.REACT_APP_IMAGE_URL
}

export function staticApi(){
    return config.app.REACT_APP_STATIC_PAGE
}
export function testApi(){
    return config.app.REACT_APP_TEST_MODULE_URL
}