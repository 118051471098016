export const USER_FEEDBACK_FAILURE = "USER_FEEDBACK_FAILURE";
export const USER_FEEDBACK_REQUEST = "USER_FEEDBACK_REQUEST";
export const USER_FEEDBACK_SUCCESS = "USER_FEEDBACK_SUCCESS";

export const ARCH_UNARCH_REQUEST = "ARCH_UNARCH_REQUEST";
export const ARCH_UNARCH_FEEDBACK_SUCCESS = "ARCH_UNARCH_FEEDBACK_SUCCESS";
export const ARCH_UNARCH_FEEDBACK_FAILURE = "ARCH_UNARCH_FEEDBACK_FAILURE";

export const CLEAR_STATUS = "CLEAR_STATUS";
export const CLEAR_ALL = "CLEAR_ALL";
