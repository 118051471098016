export const CHANGE_LOGIN_FORM = "CHANGE_LOGIN_FORM";
export const CHANGE_EMAIL_REGISTER_FORM = "CHANGE_EMAIL_REGISTER_FORM";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";


export const LOGIN_WITH_GOOGLE_REQUEST="LOGIN_WITH_GOOGLE_REQUEST";
export const LOGIN_WITH_GOOGLE_SUCCESS="LOGIN_WITH_GOOGLE_SUCCESS";
export const LOGIN_WITH_GOOGLE_FAILURE="LOGIN_WITH_GOOGLE_FAILURE";


export const LOGIN_WITH_LINKEDIN_REQUEST="LOGIN_WITH_LINKEDIN_REQUEST";
export const LOGIN_WITH_LINKEDIN_SUCCESS="LOGIN_WITH_LINKEDIN_SUCCESS";
export const LOGIN_WITH_LINKEDIN_FAILURE="LOGIN_WITH_LINKEDIN_FAILURE";


export const CLEAR_API_ERROR_MESSAGE = "CLEAR_API_ERROR_MESSAGE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const LOGOUT_ACCOUNT_REQUEST = "LOGOUT_ACCOUNT_REQUEST";
export const LOGOUT_ACCOUNT_SUCCESS = "LOGOUT_ACCOUNT_SUCCESS";
export const LOGOUT_ACCOUNT_FAILURE = "LOGOUT_ACCOUNT_FAILURE";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const CHANGE_USER_NAME = "CHANGE_USER_NAME";
