/*--------------------------------------------------- all pool result---------------------------------------------------------------*/

export const GET_ALLPOOLWISE_RESULT_REQUEST='GET_ALLPOOLWISE_RESULT_REQUEST';
export const GET_ALLPOOLWISE_RESULT_SUCCESS='GET_ALLPOOLWISE_RESULT_SUCCESS';
export const GET_ALLPOOLWISE_RESULT_FAILURE='GET_ALLPOOLWISE_RESULT_FAILURE';

/*--------------------------------------------------- candidates result in pool---------------------------------------------------------------*/

export const GET_POOLWISE_RESULT_REQUEST='GET_POOLWISE_RESULT_REQUEST';
export const GET_POOLWISE_RESULT_SUCCESS='GET_POOLWISE_RESULT_SUCCESS';
export const GET_POOLWISE_RESULT_FAILURE='GET_POOLWISE_RESULT_FAILURE';

/*--------------------------------------------------- all drive result---------------------------------------------------------------*/

export const GET_ALLDRIVEWISE_RESULT_REQUEST='GET_ALLDRIVEWISE_RESULT_REQUEST';
export const GET_ALLDRIVEWISE_RESULT_SUCCESS='GET_ALLDRIVEWISE_RESULT_SUCCESS';
export const GET_ALLDRIVEWISE_RESULT_FAILURE='GET_ALLDRIVEWISE_RESULT_FAILURE';

/*--------------------------------------------------- drive candidates result ---------------------------------------------------------------*/

export const GET_DRIVEWISE_RESULT_REQUEST='GET_DRIVEWISE_RESULT_REQUEST';
export const GET_DRIVEWISE_RESULT_SUCCESS='GET_DRIVEWISE_RESULT_SUCCESS';
export const GET_DRIVEWISE_RESULT_FAILURE='GET_DRIVEWISE_RESULT_FAILURE';

/*--------------------------------------------------- analytics ---------------------------------------------------------------*/

export const GET_ANALYTICS_REQUEST='GET_ANALYTICS_REQUEST';
export const GET_ANALYTICS_SUCCESS='GET_ANALYTICS_SUCCESS';
export const GET_ANALYTICS_FAILURE='GET_ANALYTICS_FAILURE';

export const NEW_PAGE_NUMBER='NEW_PAGE_NUMBER'