/*eslint-disable*/
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {NotificationContainer} from 'react-notifications'; // S6
import {
    doNotGoToRegistration,
    updateSkipEducation,
    updateSkipExperience
} from "../../../actions/account/registration-action";
import {noApplyJob, saveApplyJob} from "../../../actions/dashboard/user/jobs/jobs";
import {clearAll as clearDriveResponse, clearStatus, driveList} from "../../../actions/dashboard/user/drives/drives";
import {
    clearAll as clearActivitiesResponse,
    getActivities,
    getUserTestResult
} from "../../../actions/dashboard/user/activities/activities";
import moment from "moment";
import createNotification from "../../../components/app/notification/notification";
import { PulseLoader } from 'react-spinners';
import {getProfile} from "../../../actions/dashboard/profile";
import {Dialogbox} from 'xd-react-dialogbox'

const ENV_VAR= process.env.REACT_APP_STATIC_PAGE === undefined ? "https://careers.xenon.work": process.env.REACT_APP_STATIC_PAGE;
const BASE_URL=process.env.REACT_APP_TEST_MODULE_URL
class UserDashboard extends Component {
    componentWillMount() {  
        document.title = "Dashboard | Xenonstack Hiring Portal";
        // calling getProfile to check for resume
        this.props.dispatch(getProfile());
        this.props.dispatch(clearDriveResponse());
        this.props.dispatch(updateSkipEducation(false));
        this.props.dispatch(updateSkipExperience(false));
        this.props.dispatch(clearActivitiesResponse());
        this.forceUpdate();
        this.props.dispatch(getUserTestResult());
        this.props.dispatch(driveList());
        // console.log(this.props.applyForJob.job,"<==applyforjob props")
        const id= localStorage.getItem('jobId')
        console.log(id,'==job id is',localStorage.getItem('jobId'))
        if (id!==null) {
            // hit the api here to save the job
            this.props.dispatch(saveApplyJob(id));
            this.props.dispatch(noApplyJob())
        } else {
            this.props.dispatch(getActivities("/v1/apply"))
        }
        if (this.props.goToRegistration)
            this.props.dispatch(doNotGoToRegistration())
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveApplyJobMessage !== "") {
            if (nextProps.saveApplyJobError) {
                createNotification('error', nextProps.saveApplyJobMessage);
                nextProps.dispatch(clearStatus());
                nextProps.dispatch(getActivities("/v1/apply"))
            } else {
                createNotification('success', nextProps.saveApplyJobMessage);
                nextProps.dispatch(clearStatus());
                nextProps.dispatch(getActivities("/v1/apply"))
            }
        }
        console.log(nextProps.state.RegisterReducer.status === 200,!nextProps.state.RegisterReducer.error,nextProps.registrationForm.resume !== '')
        if(nextProps.state.RegisterReducer.status === 200){
            if(!nextProps.state.RegisterReducer.error){
                if(nextProps.registrationForm.resume === ''){
                    this.openResumeDialog()
                }
            }
        }
    }
    onStart(id,test,drivename,start){
        let today=new Date(),startDate=moment.unix(start).toDate();
       if(startDate  <= today){
         let width=window.screen.width;
         let height=window.screen.height;
        let url =BASE_URL + "/test-instructions?token=" + localStorage.getItem("token") + "&&drive_id=" + id + "&&test_id=" + test+"&&drivename="+drivename;
        let strWindowFeatures = "menubar=no,minimizable=no,status=yes,dialog=yes,width="+width+",height="+height+"fullscreen=1"
        window.open(url,"NewWindow", strWindowFeatures);

        }
        else{
            document.getElementsByClassName("has-error")[0].innerHTML="You cannot start your test before time"
        }
    }
    openResumeDialog() {
        document.querySelector('.dialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
      }
      // submit response of dialog
    submitResumeDialog(){
          document.querySelector('.dialog').classList.remove('opened');
          document.querySelector('body').classList.remove('has-dialog');
          this.props.history.push('/profile/information/edit')
      }
    cancelResumeDialog() {
        this.props.history.push('/profile')
        document.querySelector('.dialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    render() {
        return (
            <section className="">
                <div className="container">
                    <div className="content-detail">
                        <div className="text-center">
                            <h1 className="main-heading-h1">Welcome to XenonStack Hiring Portal</h1>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                                <div className="dash-status">

                                    <div className="activity-status">
                                        <h2>Recent Activity</h2>
                                        <div className="text-center">
                                        {this.props.activitiesPageLoading &&
                                         <PulseLoader
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#48194e'}
                                         loading={this.props.activitiesPageLoading}
                                         />}</div>
                                        {
                                            (!this.props.activitiesPageLoading && this.props.activities.length > 0 )&&

                                            <div className="activity-show" id="activity-show">
                                                <div className="status-div">
                                                    <ul className="dashboard-activity-ul">
                                                        <li>Applied For :    <p> {this.props.activities[0]["job_name"]}</p></li>
                                                    </ul>
                                                    <ul>
                                                        <li>Team Name<p>{this.props.activities[0]["team_name"]}</p></li>
                                                        <li>Applied On
                                                            <p>{moment(this.props.activities[0]["applied_date"] * 1000).format('Do MMMM YYYY')}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }

                                        {
                                            !this.props.activitiesPageLoading && this.props.activities.length === 0 && this.props.activitiesMessage === "" &&
                                            <div className="no-activity text-center">
                                                <div className="">
                                                    <figure><img src={require("../../../static/images/activity.svg")}
                                                                 alt={"No Activity"}/></figure>
                                                </div>
                                                <h3>No Activity Recorded</h3>
                                                <p>You haven't applied for any job yet or may be your job is completed</p>
                                            </div>
                                        }
                                        {
                                            !this.props.activitiesPageLoading && this.props.activitiesMessage !== "" &&
                                            <div className="no-activity text-center">
                                                <div className="">
                                                    <figure><img src={require("../../../static/images/activity.svg")}
                                                                 alt={"No Activity"}/></figure>
                                                </div>
                                                <h3>Something Went wrong...</h3>
                                                <p>{this.props.activitiesMessage}</p>
                                            </div>
                                        }
                                    </div>

                                    <div className="current-status">
                                        <h2>Your Test Status</h2>
                                        <div className="text-center">
                                        {this.props.resultPageLoading &&
                                         <PulseLoader
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#48194e'}
                                         loading={this.props.resultPageLoading}
                                         />}</div>
                                        { (!this.props.resultPageLoading &&
                                            this.props.result.length>0) &&

                                            <div className="activity-show" id="activity-show">
                                                <div className="status-div">

                                                    <ul>
                                                        <li>Test Name<p>{ this.props.result[0]["test"] }</p></li>
                                                        <li>Drive Name:
                                                            <p>{this.props.result[0]["drive"]}</p>
                                                        </li>
                                                        <li>Completed On:
                                                            <p>{ moment(this.props.result[0]["completed"] * 1000).format('Do MMMM YYYY') }</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        }
                                        { (!this.props.resultPageLoading &&
                                            this.props.result.length === 0)      &&
                                        <div className="no-activity text-center">
                                            <div className="">
                                                <figure><img src={require("../../../static/images/status.svg")}
                                                             alt={"No status"}/></figure>
                                            </div>
                                            <h3>No Test to Show</h3>
                                            <p>You haven't applied for any job yet or may be your job is completed</p>
                                        </div>
                                        }

                                    </div>

                                    <div className="upcoming-status">
                                        <h2>Upcoming Round</h2>
                                        <div className="text-center">
                                        {this.props.pageLoading &&
                                         <PulseLoader
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#48194e'}
                                         loading={this.props.pageLoading}
                                         />}</div>
                                        {
                                            (!this.props.pageLoading && this.props.drives.length > 0) &&
                                            this.props.drives.map((d, i) => (
                                                <div className="activity-show" id="activity-show">
                                                    <div className="status-div upcoming">
                                                        <h3>{d.name.charAt(0).toUpperCase() + d.name.slice(1)} </h3>
                                                        <p>Your Online Aptitude and Technical Test has been scheduled from <i>{moment(d.start *1000).format('Do MMM YYYY, h:mm a')}</i> to <i>{moment(d.end *1000).format('Do MMM YYYY, h:mm a')}</i>.</p>
                                                        <div className="row">
                                                        <div className="col-md-3 col-sm-3" key={i}>
                                                            <button type="button" className="btn mar-t-2" onClick={()=>this.onStart(d.id,d.test_id,d.name,d.start)} >Start Test</button>
                                                        </div>
                                                        <div className="col-md-6 col-sm-2">
                                                               <div className="has-error" style={{marginTop:"3rem",color:"#d50000"}}></div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {
                                            !this.props.pageLoading && this.props.drives.length === 0 && this.props.message === "" &&
                                            <div className="no-activity text-center">
                                                <div className="">
                                                    <figure><img
                                                        src={require("../../../static/images/upcoming.svg")}
                                                        alt={"No Tet"}/></figure>
                                                </div>
                                                <h3>No Upcoming Test</h3>
                                                <p>You haven't applied for any job yet or may be your job is completed</p>
                                            </div>
                                        }

                                        {
                                            !this.props.pageLoading && this.props.message !== "" &&
                                            <div className="no-activity text-center">
                                                <div className="">
                                                    <figure><img
                                                        src={require("../../../static/images/upcoming.svg")}
                                                        alt={"No Tet"}/></figure>
                                                </div>
                                                <h3>Something Went wrong...</h3>
                                                <p>{this.props.message}</p>
                                            </div>
                                        }


                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4">


                                <div className="dash-side">
                                    <div className="all-test">
                                        <ul>
                                            <li>Selection Rounds</li>
                                            <li className="ongoing"><span>01</span> Online Aptitude Test</li>
                                            <li><span>02</span> Online Technical Test</li>
                                            <li><span>03</span> Written Technical Test/ Technical Interview</li>
                                            <li><span>04</span> HR Interview Round</li>
                                            <li><span>05</span>Final Discussion/ Psychometric Test 
                                            <a
                                                href={ENV_VAR+"xenonstack-life/how-we-hire/"} className="light-btn"><button class="btn-2"><span>View Detail</span></button>    </a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            
                <NotificationContainer/>

                <Dialogbox
                    title="Notification"
                    successButton="Upload"
                    cancelButton="Cancel"
                    submitDialog={this.submitResumeDialog.bind(this)}
                    closeDialog={this.cancelResumeDialog.bind(this)} >
                    <p>Please upload Resume to continue.</p>
                </Dialogbox>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const {activities, activitiesError, activitiesMessage, activitiesStatus, activitiesPageLoading,
            result,resultPageLoading} = state.activitiesReducer;
    const {
        saveApplyJobPageLoading, saveApplyJobStatus, saveApplyJobError, saveApplyJobMessage,
        drives, error, message, pageLoading
    } = state.userDrivesReducer;
    const {applyForJob} = state.userJobsReducer;
    const {goToRegistration,registrationForm} = state.RegisterReducer;

    return {
        activities, activitiesError, activitiesMessage, activitiesStatus, activitiesPageLoading,
        saveApplyJobPageLoading, saveApplyJobStatus, saveApplyJobError, saveApplyJobMessage,
        applyForJob, goToRegistration,
        drives, error, message, pageLoading,result,resultPageLoading,registrationForm,state
    }
}

export default withRouter(connect(mapStateToProps)(UserDashboard))
