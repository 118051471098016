import React, { Component } from "react";
import {
  clearAll,
  getUserScore
} from "../../../../actions/dashboard/admin/drive/drive";
import AdminOnPageNavigation from "../../../../components/dashboard/admin/on_page_navigation/on-page-navigation";
import BarLoaderSpinner from "../../../../components/app/spinner/barloader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UserScorePieChart from './UserScoreChart';
import { PulseLoader } from 'react-spinners';
import {Helmet} from "react-helmet";

class UserScoreList extends Component {
  componentWillMount() {
    this.props.dispatch(clearAll());
  }

  componentDidMount() {
    this.props.dispatch(
      getUserScore(this.props.match.params.drive, this.props.match.params.email,this.props.match.params.resultValue)
    );
}
  render() {
    let overallResult = [];
    if(this.props.userScore !== undefined && this.props.userScore.pool_result!==null){
      overallResult =[
        {
            name: 'Not Attempted',
            y: this.props.userScore.result.total- (this.props.userScore.result.correct+ this.props.userScore.result.wrong)
        },
        {
            name: 'Correct',
            y: this.props.userScore.result.correct
        },
        {
            name: 'Wrong',
            y: this.props.userScore.result.wrong
        }
      ]
    }
    return (
      <div>
      <Helmet>
                <title>Candidate Scoreboard | Career Portal | Xenonstack</title>
             </Helmet>
        <AdminOnPageNavigation
          parentRoute="Dashboard"
          childRoute={"User Scoreboard (" + this.props.match.params.email +")"}
        />


        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="pool-form-wrapper">
            <h4 className="content-heading">Overall Result</h4>
            <div className="divider" />

            <div className="row">
            <div className="text-center">
              {(this.props.getUserScorePageLoading && this.props.userScore.pool_result!==null )&& (
                <PulseLoader
                  margin="30px"
                  sizeUnit={"px"}
                  size={15}
                  color={"#48194e"}
                  loading={this.props.getUserScorePageLoading}
                />
              )}
            </div>
              {(!this.props.getUserScorePageLoading && this.props.userScore.pool_result!==null) && (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="panel panel-warning card-view custom-panel pool-panel scoreboard">
                    <div className="panel-heading">
                      <span>User Score List</span>
                    </div>

                    <div className="panel-wrapper collapse in">
                      <div className="panel-body test-panel-body">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="user-score-div">
                            <ul>
                              <li>
                                Total :
                                <strong> {this.props.userScore.result.total}</strong>
                              </li>
                              <li>
                                Attempted :
                                <strong> {this.props.userScore.result.attempted}
                                </strong>
                              </li>
                              <li>
                                Correct :
                                <strong> {this.props.userScore.result.correct}</strong>
                              </li>

                              <li>
                                Wrong :
                                <strong> {this.props.userScore.result.wrong}</strong>
                              </li>
                            </ul>

                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="user-score-chart-outer">
                            <UserScorePieChart UserScoreResultData={overallResult === null ? [] : overallResult} />
                          </div>
                        </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}{" "}
           
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <h4 className="content-heading">Pool wise Result</h4>
              <div className="divider" />
              <div className="text-center">
                                        {this.props.getUserScorePageLoading && this.props.userScore.pool_result !== null &&
                                         <PulseLoader
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#48194e'}
                                         loading={this.props.getUserScorePageLoading}
                                         />
                                    }</div>
              <div className="row">
                {" "}
                {this.props.userScore.pool_result === null?
                  <div className="text-center">No Data Found! </div>
                :
                this.props.userScore.pool_result.length > 0 &&
                  this.props.userScore.pool_result.map((r, i) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                      <div className="panel panel-warning card-view custom-panel pool-panel scoreboard">
                        <div className="panel-heading">
                          <span>Pool Name -  <strong>{r.pool_name}</strong></span>
                        </div>
                        <div className="panel-wrapper collapse in">
                          <div className="panel-body test-panel-body">
                            <div className="row">
                              <div className="col-12 col-sm-6">
                                <div className="user-score-div">
                                  <ul>
                                    <li>
                                      Total :
                                      <strong> {r.total}</strong>
                                    </li>
                                    <li>
                                      Attempted :
                                      <strong> {r.attempted}
                                      </strong>
                                    </li>
                                    <li>
                                      Correct :
                                      <strong> {r.correct}</strong>
                                    </li>

                                    <li>
                                      Wrong :
                                      <strong> {r.wrong}</strong>
                                    </li>
                                  </ul>

                                </div>
                              </div>
                              <div className="col-12 col-sm-6">
                                <div className="user-score-chart-outer">
                                    <UserScorePieChart UserScoreResultData={
                                      [
                                        {
                                            name: 'Not Attempted',
                                            y: r.total- (r.correct+ r.wrong)
                                        },
                                        {
                                            name: 'Correct',
                                            y: r.correct
                                        },
                                        {
                                            name: 'Wrong',
                                            y: r.wrong
                                        }
                                      ]
                                    } />
                                </div>
                              </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    userScore,
    getUserScorePageLoading,
    getUserScore_status,
    getUserScore_error,
    getUserScore_message
  } = state.adminDriveReducer;

  return {
    userScore,
    getUserScorePageLoading,
    getUserScore_status,
    getUserScore_error,
    getUserScore_message
  };
};

export default withRouter(connect(mapStateToProps)(UserScoreList));
