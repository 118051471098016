export const GET_NOTIFICATION_REQUEST="GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS="GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE="GET_NOTIFICATION_FAILURE";

export const DELETE_NOTIFICATION_REQUEST="DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS="DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILURE="DELETE_NOTIFICATION_FAILURE";

export const DELETE_SINGLE_NOTIFICATION_REQUEST="DELETE_SINGLE_NOTIFICATION_REQUEST";
export const DELETE_SINGLE_NOTIFICATION_SUCCESS="DELETE_SINGLE_NOTIFICATION_SUCCESS";
export const DELETE_SINGLE_NOTIFICATION_FAILURE="DELETE_SINGLE_NOTIFICATION_FAILURE";
