import React, {Component} from 'react';
import Header from "../../../components/Header/header";
import Footer from "../../../components/Footer/footer";
import {connect} from 'react-redux';
import "../dist/css/homepage/teams.css";
import {Helmet} from "react-helmet";
import ListJobs from './ListJobs'
import {withRouter} from 'react-router-dom';

class AllJobs extends Component {
    componentWillMount(){
        window.scrollTo(0, 0);
    }
    render() {
        return (
        <div>
            <Helmet>
            <title>Current Job Openings and Career Opportunities at XenonStack</title>
                <meta name="description" content="Current Open Job Positions & Internships at XenonStack - Big Data Analytics, DevOps, Data Science, Design, AI, ML, etc." />

                {/* FACEBOOK OPENGRAPH META  */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="Website" />
                <meta property="og:title" content="Current Job Openings and Career Opportunities at XenonStack" />
                <meta property="og:description" content="Current Open Job Positions & Internships at XenonStack - Big Data Analytics, DevOps, Data Science, Design, AI, ML, etc." />
                <meta property="og:url" content="https://careers.xenonstack.com/jobs/" />
                <meta property="og:site_name" content="XenonStack" />
                <meta property="og:image" content="https://www.xenonstack.com/images/career/og_image.png" />

                {/* TWITTER CARD META */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@XenonStackCareers"/>
                <meta name="twitter:creator" content="@XenonStackCareers"/>
                <meta name="twitter:url" content="https://careers.xenonstack.com/jobs/" />
                <meta name="twitter:title" content="Current Job Openings and Career Opportunities at XenonStack" />
                <meta name="twitter:description" content="Current Open Job Positions & Internships at XenonStack - Big Data Analytics, DevOps, Data Science, Design, AI, ML, etc." />
                <meta name="twitter:image" content="https://www.xenonstack.com/images/career/og_image.png" />
            </Helmet>
			<Header/>
            <main>
                <section className="banner-section jobs-banner" id="page-banner">
                    <div className="jobs-banner-container banner-container" id="banner-container">
                    <div className="banner-background-outer jobs-banner-background"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 offset-md-2 col-md-8 text-center">
                                    <div className="banner-content-box" id="banner-content-box">
                                        <div className="banner-content-box-item">
                                            <span className="text-badge">We At XenonStack</span>
                                            <h1 className="wb-banner-heading">Jobs At XenonStack</h1>
                                            <p className="wb-page-desc">Work with our team from different technology stacks like Data Engineering, Data Science, DevOps, Data Visualization and Many More. If you have potential, you are at right platform.</p>										
                                            <div className="banner-job-search with-center">
                                                <input type="text" className="search-job" placeholder="Search XenonStack Jobs"  name="search"/>
                                                <button type="submit" className="search-btn"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="jobs-list-section  mar-t-8" id="jobs-list-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-10">
                                <div className="section-heading-box" id="section-heading">
                                    <h2>Find open roles</h2>
                                    <p className="wb-page-desc">Work with our team from different technology stacks like Data Engineering, Data Science, DevOps, Data Visualization and Many More. If you have potential, you are at right platform. </p>
                                    {/* <p className="wb-page-desc"> <span className="text-badge">2651</span> Open Roles</p> */}
                                </div>
                            </div>
                        </div>
                        <ListJobs/>
                    </div>
                </section>
            </main>
            <Footer/>
        </div>
        )
    }
}


function mapStateToProps(state) {
    const { jobs, error, message, jobType,selectedJobType,keywords,team} = state.userJobsReducer;
    const {get_teams}=state.adminTeamReducer;

    return { jobs, error, message, jobType,selectedJobType,get_teams,keywords,team}
}

export default withRouter(connect(mapStateToProps)(AllJobs))