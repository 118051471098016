import React, {Component} from 'react';
import {withRouter,NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from "moment";
import {getLatestDrive} from "../../../../actions/dashboard/admin/drive/drive";

class Interviews extends Component {
    componentWillMount(){
        this.props.dispatch(getLatestDrive())
    }
    render() {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div className="panel panel-warning card-view custom-panel pool-panel ">
                    <div className="panel-heading">
                        <h5 className="panel-title">Latest Drive ( {this.props.driveName} )</h5>
                        <div>
                            <a className="pull-left inline-block mr-15 txt-light" data-toggle="collapse"
                               href="#collapse_4" aria-expanded="true">
                                <i className="zmdi zmdi-chevron-down"></i> <i
                                className="zmdi zmdi-chevron-up"></i>
                            </a>
                        </div>
                    </div>
                    <div id="collapse_4" className="panel-wrapper collapse in">
                        <div className="panel-body">
                            <div className="table-wrap fix-height">
                                <div className="table-responsive">
                                    <table id="myTable1" className="table display table-hover pb-30">
                                       {this.props.getLatestDriveError
                                       ?( <thead>
                                        </thead>)
                                        :( <thead>
                                            <tr>
                                                <th className="">Email</th>
                                                <th className="">Start Date</th>
                                                <th className="">End Date</th>
                                            </tr>
                                            </thead>)
                                       }
                                        {this.props.getLatestDrivePageLoading || this.props.latestDrive===undefined || this.props.latestDrive===""||this.props.getLatestDriveError?
                                        ( 
                                        <p style={{textAlign:"center"}}> No users completed exam on last closed drive</p>
                                        )
                                        :( this.props.latestDrive.map((d)=>(
                                            <tbody>
                                            <tr>
                                                <td><NavLink to={'/dashboard/candidate/'+d.Email+'/details'}>{d.Email}</NavLink></td>
                                                <td>{moment.unix(d.Start).format("DD/MM/YYYY")}</td>
                                                <td>{moment.unix(d.Expire).format("DD/MM/YYYY")}</td>
                                                <td></td>
                                                 </tr>
                                                 </tbody>
                                                 )))
                                                 }
                                                 </table>
                                                  </div>
                                                  </div>
                                                  {this.props.getLatestDriveError
                                                  ?( <div className="index-btn"><NavLink to={'/dashboard/drive/view/page=1&pageSize=10'}className="table-inside-btn right ">View All Drives</NavLink></div>)
                                                  :( <div className="index-btn"><NavLink to={'/dashboard/drive/'+this.props.driveId +'/assign/list/page=1'}className="table-inside-btn right ">View Drive</NavLink></div>)}
                                                </div>
                                                </div>
                                            </div>
                                        
                                        </div>
        )
    }
}

function mapStateToProps(state){
    const{
        latestDrive,
        driveName,
        driveId,
        driveStart,
        driveEnd,
        latestDriveName,
        getLatestDrivePageLoading,
        getLatestDriveError
    }=state.adminDriveReducer;
    return{
        latestDrive,
        driveName,
        driveId,
        driveStart,
        driveEnd,
        latestDriveName,
        getLatestDrivePageLoading,
        getLatestDriveError,
        state,
    }
}

export default withRouter(connect(mapStateToProps)(Interviews))
