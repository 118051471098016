/*--------------------------------------------------- get drive ---------------------------------------------------------------*/

export const GET_DRIVE_REQUEST = "GET_DRIVE_REQUEST";
export const GET_DRIVE_SUCCESS = "GET_DRIVE_SUCCESS";
export const GET_DRIVE_FAILURE = "GET_DRIVE_FAILURE";

/*--------------------------------------------------- get drive result---------------------------------------------------------------*/
export const GET_DRIVE_RESULT_REQUEST='GET_DRIVE_RESULT_RESULT';
export const GET_DRIVE_RESULT_SUCCESS='GET_DRIVE_RESULT_SUCCESS';
export const GET_DRIVE_RESULT_FAILURE='GET_DRIVE_RESULT_FAILURE';

export const GET_LATEST_DRIVE_REQUEST='GET_LATEST_DRIVE_REQUEST';
export const GET_LATEST_DRIVE_SUCCESS='GET_LATEST_DRIVE_SUCCESS';
export const GET_LATEST_DRIVE_FAILURE='GET_LATEST_DRIVE_FAILURE';


/*--------------------------------------------------- get drive summary---------------------------------------------------------------*/

export const GET_DRIVE_SUMMARY_REQUEST='GET_DRIVE_SUMMARY_REQUEST'
export const GET_DRIVE_SUMMARY_SUCCESS='GET_DRIVE_SUMMARY_SUCCESS'
export const GET_DRIVE_SUMMARY_FAILURE='GET_DRIVE_SUMMARY_FAILURE'
/*--------------------------------------------------- get drive RESULT CSV---------------------------------------------------------------*/


export const GET_DOWNLOAD_RESULT_REQUEST='GET_DOWNLOAD_RESULT_REQUEST'
export const GET_DOWNLOAD_RESULT_SUCCESS='GET_DOWNLOAD_RESULT_SUCCESS'
export const GET_DOWNLOAD_RESULT_FAILURE='GET_DOWNLOAD_RESULT_FAILURE'
/*--------------------------------------------------- delete drive ---------------------------------------------------------------*/


export const DELETE_DRIVE_REQUEST = "DELETE_DRIVE_REQUEST";
export const DELETE_DRIVE_SUCCESS = "DELETE_DRIVE_SUCCESS";
export const DELETE_DRIVE_FAILURE = "DELETE_DRIVE_FAILURE";

/*--------------------------------------------------- create drive ---------------------------------------------------------------*/

export const CREATE_DRIVE_REQUEST = "CREATE_DRIVE_REQUEST";
export const CREATE_DRIVE_SUCCESS = "CREATE_DRIVE_SUCCESS";
export const CREATE_DRIVE_FAILURE = "CREATE_DRIVE_FAILURE";

export const CHANGE_CREATE_DRIVE_FORM = "CHANGE_CREATE_DRIVE_FORM";

/*--------------------------------------------------- edit drive ---------------------------------------------------------------*/

export const EDIT_DRIVE_DETAILS_REQUEST = "EDIT_DRIVE_DETAILS_REQUEST";
export const EDIT_DRIVE_DETAILS_SUCCESS = "EDIT_DRIVE_DETAILS_SUCCESS";
export const EDIT_DRIVE_DETAILS_FAILURE = "EDIT_DRIVE_DETAILS_FAILURE";

/*--------------------------------------------------- get drive details ------------------------------------------------------------*/


export const GET_DRIVE_DETAILS_REQUEST = "GET_DRIVE_DETAILS_REQUEST";
export const GET_DRIVE_DETAILS_SUCCESS = "GET_DRIVE_DETAILS_SUCCESS";
export const GET_DRIVE_DETAILS_FAILURE = "GET_DRIVE_DETAILS_FAILURE";

/* ============================================================ assign user to drive ================================================*/

/*---------------------------------------------------------- manually ----------------------------------------------------------------*/

export const ASSIGN_USER_MANUALLY_REQUEST = "ASSIGN_USER_MANUALLY_REQUEST";
export const ASSIGN_USER_MANUALLY_SUCCESS = "ASSIGN_USER_MANUALLY_SUCCESS";
export const ASSIGN_USER_MANUALLY_FAILURE = "ASSIGN_USER_MANUALLY_FAILURE";

export const CHANGE_ASSIGN_USER_MANUALLY_FORM = "CHANGE_ASSIGN_USER_MANUALLY_FORM";
export const CHANGE_CSV_LINK='CHANGE_CSV_LINK'
/*--------------------------------------------------------- csv -----------------------------------------------------------------------*/

export const ASSIGN_USER_CSV_REQUEST = "ASSIGN_USER_CSV_REQUEST";
export const ASSIGN_USER_CSV_SUCCESS = "ASSIGN_USER_CSV_SUCCESS";
export const ASSIGN_USER_CSV_FAILURE = "ASSIGN_USER_CSV_FAILURE";

export const CHANGE_ASSIGN_USER_CSV_FORM = "CHANGE_ASSIGN_USER_CSV_FORM";

/*-------------------------------------------------------- get assigned user ----------------------------------------------------------*/

export const GET_ASSIGN_USER_REQUEST = "GET_ASSIGN_USER_REQUEST";
export const GET_ASSIGN_USER_SUCCESS = "GET_ASSIGN_USER_SUCCESS";
export const GET_ASSIGN_USER_FAILURE = "GET_ASSIGN_USER_FAILURE";

/*-------------------------------------------------------- delete assign user ----------------------------------------------------------*/

export const DELETE_ASSIGN_USER_REQUEST = "DELETE_ASSIGN_USER_REQUEST";
export const DELETE_ASSIGN_USER_SUCCESS = "DELETE_ASSIGN_USER_SUCCESS";
export const DELETE_ASSIGN_USER_FAILURE = "DELETE_ASSIGN_USER_FAILURE";


/*--------------------------------------------------- get user ---------------------------------------------------------------*/

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";


export const GET_USER_SCORE_REQUEST = "GET_USER_SCORE_REQUEST";
export const GET_USER_SCORE_SUCCESS = "GET_USER_SCORE_SUCCESS";
export const GET_USER_SCORE_FAILURE = "GET_USER_SCORE_FAILURE";

/*--------------------------------------------------- delete user ---------------------------------------------------------------*/

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

/*--------------------------------------------------- delete all user ---------------------------------------------------------------*/

export const DELETE_ALL_USER_REQUEST = "DELETE_ALL_USER_REQUEST";
export const DELETE_ALL_USER_SUCCESS = "DELETE_ALL_USER_SUCCESS";
export const DELETE_ALL_USER_FAILURE = "DELETE_ALL_USER_FAILURE";

/*--------------------------------------------------- common ---------------------------------------------------------------*/

export const CLEAR = "CLEAR";

export const CLEAR_ALL = "CLEAR_ALL";

export const WEBSOCKET_LOADING = "WEBSOCKET_LOADING";

export const SELECTED_DELETE_DRIVE = "SELECTED_DELETE_DRIVE";

export const SELECTED_DELETE_USER = "SELECTED_DELETE_USER";