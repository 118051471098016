import React, {Component} from 'react';
import {withRouter,NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import AdminOnPageNavigation from '../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import {
    clear,
    deleteUser,getDriveResult,
     selectedDeleteUser,
     getDriveSummary,
     downloadDriveResult,
     setCSVLink,deleteAllUser
} from '../../../../../actions/dashboard/admin/drive/drive';
import Pagination from 'react-js-pagination'
import {changeUserPageNumber } from "../../../../../actions/dashboard/admin/pool/pool";
import createNotification from "../../../../../components/app/notification/notification";
import BarLoaderSpinner from "../../../../../components/app/spinner/barloader";
import DeleteDialogBox from "../../../../../components/app/dialogBox/delete-dialog-box";
import ReactTooltip from 'react-tooltip';
import { PulseLoader } from 'react-spinners';
import { Table, TableRow, TableBody, TableData, TableHeaderRow, TableHeader } from '../../../../../components/dashboard/admin/xd-react-table/react-flex-table'
import { TableHeadComponent , ThComponent} from '../../../../../components/dashboard/admin/xd-react-table/react-flex-table/components';
import DriveHeader from '../header/DriveHeader'
import {Helmet} from "react-helmet";
import {EditOutlined } from '@ant-design/icons';

let ws = null, props = null;
const BASE_URL= process.env.REACT_APP_DRIVE_API
class ViewUser extends Component {
    
    handlePageChange(pageNumber) {
        
        this.props.dispatch(changeUserPageNumber(pageNumber));
        this.props.history.push("/dashboard/drive/"+localStorage.getItem("drive_id")+"/assign/list/page="+pageNumber)
        this.props.dispatch(getDriveResult(this.props.match.params.drive,pageNumber))
      }

    componentWillMount() {
        this.props.dispatch(getDriveSummary(this.props.match.params.drive))
        props = this.props
    }

    componentDidMount() {
        this.props.dispatch(changeUserPageNumber(1));
        // this.props.dispatch(handlePaginationPropsChange(Number(this.props.match.params.pageNumber), Number(this.props.match.params.pageSize)))
        this.props.dispatch(getDriveResult(this.props.match.params.drive,1))
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.delete_user_message !== "") {
            if (nextProps.delete_user_error) {
                createNotification('error', nextProps.delete_user_message);
                nextProps.dispatch(clear());
            } else {
                createNotification('success', nextProps.delete_user_message);
                nextProps.dispatch(changeUserPageNumber(1));
                nextProps.dispatch(clear());
                nextProps.dispatch(getDriveResult(this.props.match.params.drive,1));

            }
        }
        if (nextProps.delete_all_user_message !== "") {
            if (nextProps.delete_all_user_error) {
                createNotification('error', nextProps.delete_all_user_message);
                nextProps.dispatch(clear());
            } else {
                createNotification('success', nextProps.delete_all_user_message);
                nextProps.dispatch(changeUserPageNumber(1));
                nextProps.dispatch(clear());
                nextProps.dispatch(getDriveResult(this.props.match.params.drive,1));

            }
        }

    }

    // when delete user action called
    deleteUser(e) {
        e.preventDefault();
        this.props.dispatch(deleteUser(props.match.params.drive,  this.props.selected));
        if (!!document.getElementsByClassName("overlay-delete")[1]) {
            document.getElementsByClassName("overlay-delete")[1].style.display = "none";
        }
    }

    // open the dialog
    openDeleteUserDialog(uid) {
        this.props.dispatch(selectedDeleteUser(uid));

        document.getElementsByClassName("overlay-delete")[1].style.display = "block";
    }

    // closes the dialog
    closeDeleteUserDialog() {
        if (!!document.getElementsByClassName("overlay-delete")[1]) {
            document.getElementsByClassName("overlay-delete")[1].style.display = "none";
        }
    }
    handleClick(){
        this.props.dispatch(changeUserPageNumber(1));
        this.props.dispatch(getDriveResult(this.props.match.params.drive,1))
        this.props.dispatch(getDriveSummary(this.props.match.params.drive))
    }
    downloadResult(){
        this.props.dispatch(downloadDriveResult(this.props.match.params.drive))
    }

    //for deleting All User Button
    openDeleteAllUserDialog(){
        document.getElementById("popup1 deleteAllUser").style.display = "block";
    }
    closeDeleteAllUserDialog() {
        if (!!document.getElementById("popup1 deleteAllUser").style.display) {
            document.getElementById("popup1 deleteAllUser").style.display = "none";
        }
    }
    deleteAllUser(e) {
        e.preventDefault();
        this.props.dispatch(deleteAllUser(this.props.match.params.drive));
        if (!!document.getElementById("popup1 deleteAllUser").style.display) {
            document.getElementById("popup1 deleteAllUser").style.display = "none";
        }
    }
    ////////////////////////////////////
    

    render() {
        const drive_name=localStorage.getItem("drive_name");
        let url,url2=''
        
        if(this.props.csvLink !== ''){
            url= BASE_URL+ '/v1/result_csv/'+ this.props.csvLink;
            url2= BASE_URL+ '/v1/result_csv/'+ this.props.csvPool;
            window.open(url)
            window.open(url2)
             this.props.dispatch(setCSVLink())
        }

        return (
            <div>
            <Helmet>
                <title>Drive Details | Career Portal | Xenonstack</title>
             </Helmet>
                <AdminOnPageNavigation parentRoute="Dashboard" childRoute={"All Drives / "+drive_name}/>
                <DriveHeader driveName={drive_name}/>
                <BarLoaderSpinner pageLoading={this.props.getUserPageLoading}/>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <div className="panel panel-default card-view custom-panel pool-panel custom-drive-list-panel">
                            <div className="panel-heading">
                                <h5 className="panel-title">All User ({this.props.totalUserCount})</h5>
                                <div>
                                    <a className="btn icons" style={{position:"relative",left:"12px"}} data-tip="Edit Table"> <EditOutlined  onClick={() => this.props.history.push("/dashboard/drive/"+localStorage.getItem("drive_id")+"/edit")}/> </a>
                                    <a className="btn icons" onClick={(e)=>this.handleClick(e)} data-tip="Refresh-Table">
                                        <i className="material-icons" >refresh</i><div style={{lineHeight:"10px"}}></div>
                                    </a>
                                    <a className="btn icons"
                                            onClick={() => this.downloadResult()} data-tip="Download Result"><i className="material-icons">file_download</i>
                                            
                                    </a>
                                    <a className="btn icons" onClick={() => this.openDeleteAllUserDialog()} data-tip="Delete All Users">
                                        <i className="material-icons txt-light">delete</i>
                                    </a>
                                    <a className="btn signin" 
                                            onClick={() => this.props.history.push("/dashboard/drive/" + this.props.match.params.drive + "/assign")}>Assign User
                                    </a>
                                    <ReactTooltip place="bottom" />
                                </div>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                                    <div className="table-wrap">
                                        <div className="table-responsive">
                                        <div className="text-center">
                                        {this.props.getDriveResultPageLoading &&
                                         <PulseLoader
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#48194e'}
                                         loading={this.props.getDriveResultPageLoading}
                                         />
                                    }</div>
                                         <div className="react-xd-table-wrapper">
                                            {(!this.props.getDriveResultPageLoading && this.props.result !== undefined )  &&
                                                    this.props.result.length >0  &&
                                                        <Table
                                                        data={this.props.result}
                                                        pageSize={this.props.defaultPageSize}
                                                        pageSizeOptions={[10,20,30,40,50]}
                                                        currentPage={this.props.pageNumber}
                                                            render={({
                                                            rows,
                                                            pageSize,
                                                            pageSizeOptions,
                                                            handlePageSizeChange,
                                                            handlePrevPage,
                                                            handleNextPage,
                                                            hasNextPage,
                                                            hasPrevPage,
                                                            currentPage,
                                                            total
                                                            }) => {
                                                            return (
                                                                <React.Fragment>
                                                                <div className="react-xd-table-body">
                                                                    <table className="react-xd-table striped-xd-table sortable-table footer-pagination-table">
                                                                    <TableHeaderRow component={TableHeadComponent}>
                                                                <TableHeader
                                                                  accessor="sr_no"
                                                                  accessor_name="Sr. No."
                                                                  component={ThComponent}
                                                                  >
                                                                  Sr. No.
                                                                </TableHeader>
                                                                <TableHeader
                                                                  accessor="email"
                                                                  accessor_name="Questions"
                                                                  component={ThComponent}
                                                                >
                                                                Candidate Email
                                                                </TableHeader>
                                                                <TableHeader
                                                                  accessor="test_status"
                                                                  accessor_name="Questions"
                                                                  component={ThComponent}
                                                                >
                                                               Test Status
                                                                </TableHeader>
                                                                 
                                                                <TableHeader
                                                                  accessor="attempted"
                                                                  accessor_name="Questions"
                                                                  component={ThComponent}
                                                                >
                                                               Attempted
                                                                </TableHeader>
                                                                <TableHeader
                                                                  accessor="correct"
                                                                  accessor_name="Questions"
                                                                  component={ThComponent}
                                                                >
                                                               Correct
                                                                </TableHeader>
                                                                <TableHeader
                                                                  accessor="wrong"
                                                                  accessor_name="Questions"
                                                                  component={ThComponent}
                                                                >
                                                               Wrong
                                                                </TableHeader>
                                                                <TableHeader
                                                                  accessor="time_taken"
                                                                  accessor_name="TimeTaken"
                                                                  component={ThComponent}
                                                                >
                                                               Time Taken
                                                                </TableHeader>
                                                                <TableHeader
                                                                  accessor="browser"
                                                                  accessor_name="Browser"
                                                                  component={ThComponent}
                                                                >
                                                               Browser Switch
                                                                </TableHeader>
                                                                <TableHeader
                                                                  accessor="restart"
                                                                  accessor_name="Restart"
                                                                  component={ThComponent}
                                                                >
                                                               Restart
                                                                </TableHeader>
                                                                <TableHeader
                                                                    accessor_name="Action"
                                                                    component={ThComponent}
                                                                >
                                                                Action
                                                                </TableHeader>
                                                                </TableHeaderRow>
                                                                        <TableBody
                                                                            component="tbody"
                                                                            >
                                                                            {rows.map(({ rowKey, rowData, selected }, rowIndex) => (
                                                                            <TableRow
                                                                                component="tr"
                                                                                className="xd-table-row xd-table-td"
                                                                                key={rowKey}
                                                                            >
                                                                            {rowData.map(({  accessor, data, key , rowsData }) => (

                                                                               <TableData
                                                                                component="td"
                                                                                key={key}
                                                                                >
                                                                                {accessor ? (
                                                                                    accessor === 'email' ? (<NavLink to={"/dashboard/candidate/" + rowsData.email + "/details"}    >{data}</NavLink> ) :
                                                                                    //           accessor === 'start' ? (moment(rowsData.start * 1000).format('LLL')) :
                                                                                              accessor === 'time_taken' ? (<>{Math.floor(rowsData.time_taken / 60)>0 && <>{Math.floor(rowsData.time_taken / 60)}min.</>} {Math.floor(rowsData.time_taken % 3600 % 60)>0 && <>{Math.floor(rowsData.time_taken % 3600 % 60)}sec.</>}</>) :
                                                                                    accessor === 'sr_no' ? rowIndex + (pageSize * (this.props.currentUserPageNumber-1))+1 :
                                                                                              (data)
                                                                                ) :

                                                                                (
                                                                                <div className="table-action-group">
                                                                                    {this.props.poolWise === true ? <NavLink to={"/dashboard/"+this.props.match.params.drive+"/users/"+rowsData.email+"/score/false"}  className="xd-table-btn view" data-tip="View Result">
                                                                                    <i className="material-icons" >remove_red_eye</i><div style={{lineHeight:"10px"}}></div>
                                                                                    </NavLink> : <div></div>} 
                                                                                    <span className="xd-table-btn delete" onClick={() => this.openDeleteUserDialog(rowsData.email,'deleteUser')} data-tip="Delete"><i className="material-icons" >delete</i><div style={{lineHeight:"10px"}}></div></span>
                                                                                    <ReactTooltip place="bottom" />
                                                                                </div>
                                                                                )}

                                                                                </TableData>
                                                                            ))}

                                                                            </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                        </table>
                                                                        </div>
                                                                        <div className="react-xd-table-footer">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-5 col-md-4">
                                                                                <div className="react-xd-pagesize-chooser">


                                                                                </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-7 col-md-8">
                                                                                <div className="react-xd-pagination-container">
                                                                                <div className="pagination-outer">
                                                                                    <div className="pagination-group">

                                                                                    <Pagination
                                                                                        firstPageText={"First"}
                                                                                        lastPageText="Last"
                                                                                        prevPageText={<i className='fa fa-angle-left'/>}
                                                                                        nextPageText={<i className='fa fa-angle-right'/>}
                                                                                        activePage={this.props.currentUserPageNumber}
                                                                                        itemsCountPerPage={pageSize}
                                                                                        totalItemsCount={this.props.totalUserCount}
                                                                                        pageRangeDisplayed={5}
                                                                                        onChange={this.handlePageChange.bind(this)}
                                                                                        />
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>

                                                                    </React.Fragment>
                                                                )
                                                                }}
                                                            />}
                                                             {
                                                    (!this.props.getDriveResultPageLoading && this.props.result=== undefined && this.props.getDriveResultMessage === "" ) &&
                                                    <tr>
                                                        <td  >
                                                            <div className="not-found">
                                                                No User Found !!
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    this.props.getDriveResultMessage !== ""   &&
                                                    <tr>
                                                        <td  >
                                                            <div className="not-found">
                                                                {this.props.getDriveResultMessage}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                        </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DeleteDialogBox
                    formName="Delete Candidate"
                    submitForm={this.deleteUser}
                    operation="Delete Candidate"
                    keyword={"Delete"}
                    closeForm={this.closeDeleteUserDialog}
                    pageLoading={this.props.delete_user_pageLoading}
                    selected={this.props.deleteUser}
                    name={this.props.deleteUser}
                />
                <DeleteDialogBox
                    formName="Delete All Candidates"
                    submitForm={this.deleteAllUser}
                    operation="Delete All Candidate"
                    keyword={"Delete"}
                    closeForm={this.closeDeleteAllUserDialog}
                    pageLoading={this.props.delete_all_user_pageLoading}
                    name={"all Users"}
                    dialogId={"deleteAllUser"}
                />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const {
        deleteUser,
        users,
        getUserPageLoading, getUser_status, getUser_error, getUser_message,
        delete_user_pageLoading, delete_user_error, delete_user_message, delete_user_status,result, getDriveResultPageLoading,
        getDriveResultMessage,totalUserCount,driveSummary,csvLink,getDownloadResult_message,getDownloadResult_error,delete_all_user_error,
        delete_all_user_message,poolWise,csvPool
    } = state.adminDriveReducer;
    const { pageNumber,defaultPageSize,currentUserPageNumber} = state.poolReducer;
    return {
        deleteUser,
        users,
        getUserPageLoading, getUser_status, getUser_error, getUser_message,
        delete_user_pageLoading, delete_user_error, delete_user_message, delete_user_status,result
        ,getDriveResultPageLoading,getDriveResultMessage,csvLink,
        pageNumber,defaultPageSize,currentUserPageNumber,totalUserCount,driveSummary,getDownloadResult_message,getDownloadResult_error,
        delete_all_user_error,delete_all_user_message,poolWise,csvPool
    }
}

export default withRouter(connect(mapStateToProps)(ViewUser))
