import {
    CLEAR_ALL, 
    GET_ALL_USERS_FAILURE, 
    GET_ALL_USERS_REQUEST, 
    GET_ALL_USERS_SUCCESS, 
    GET_USER_DETAILS_FAILURE,
    GET_USER_DETAILS_REQUEST, 
    GET_USER_DETAILS_SUCCESS, 
    NEW_PAGE_NUMBER,
    DROPDOWN_FILTER_DATA_REQUEST, 
    DROPDOWN_FILTER_DATA_SUCCESS, 
    DROPDOWN_FILTER_DATA_FAILURE, 
    FILTER_USER,
    PUT_SELECTED_CANDIDATE_FAILURE,
    PUT_SELECTED_CANDIDATE_REQUEST,
    PUT_SELECTED_CANDIDATE_SUCCESS,
    FILTER_DATA_REQUEST,
    FILTER_DATA_SUCCESS,
    FILTER_DATA_FAILURE,
    GET_DOWNLOAD_CANDIDATE_REQUEST,
    GET_DOWNLOAD_CANDIDATE_SUCCESS,
    GET_DOWNLOAD_CANDIDATE_FAILURE,
    CHANGE_CSV_DATA,

} from "../../../../types/dashboard/admin/user/user";
import {GET_API} from "../../../../middleware/token/get-api";
import {PUT_API} from "../../../../middleware/token/put_api/put-api-with-body";
import { authApi } from "../../../ApiActions";
const BASE_URL = authApi();

// calls action to clear error message, response
export function clearAll() {
    return {type: CLEAR_ALL}
}

// calls api to get dropdown filter data
export function dropdownFilterData() {
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/filterdata',
            types: [DROPDOWN_FILTER_DATA_REQUEST, DROPDOWN_FILTER_DATA_SUCCESS, DROPDOWN_FILTER_DATA_FAILURE]
        }
    }
};
export function getDropDownData(location,qualification,applied,experience){
   
 let endpoint=BASE_URL + '/v1/filterdata?'
   if (location !==undefined || location !== "") {
        endpoint +='&&location='+location
    }

    if (qualification !==undefined || qualification!=="") {
        endpoint +='&&qualification='+qualification
    }

    if (applied !==undefined || applied !=="") {
        endpoint +='&&job='+applied
    }
    
    if(experience!==undefined || experience !==""){
        endpoint +='&&experience='+experience

    }
    return{
        [GET_API]:{
            endpoint,
            types:[FILTER_DATA_REQUEST,FILTER_DATA_SUCCESS,FILTER_DATA_FAILURE]
        }
    }
} 
export function getAllUsers(page,location, qualification,applied,search,startDate,endDate,experience) {
    let endpoint = BASE_URL + '/v1/users?page='+page;
    if (search !== undefined) {
        endpoint += '&&search='+search
    }

    if (location !==undefined || location !== "") {
        endpoint +='&&location='+location
    }

    if (qualification !==undefined || qualification!=="") {
        endpoint +='&&qualification='+qualification
    }
    if (applied !==undefined || applied !=="") {
        endpoint +='&&applied='+applied
    }
    if (startDate !==undefined || startDate !=="") {
        endpoint+='&&from='+startDate
    }

    if (endDate !==undefined || endDate !=="") {
        endpoint+='&&to='+endDate
    }
    if (experience !==undefined || experience !==""){
        endpoint +='&&experience='+experience
    }
    return {
        [GET_API]: {
            endpoint,
            types: [GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_FAILURE]
        }
    }
}
export function getCandidateList(location,qualification,applied,search,startDate,endDate,experience){
    let endpoint= BASE_URL + '/v1/users?csv=true';
    if (search!==undefined) {
        endpoint+='&search='+search
    }
    
    if (location !==undefined || location !== "") {
        endpoint +='&location='+location
    }

    if (qualification !==undefined || qualification!=="") {
        endpoint +='&qualification='+qualification
    }

    if (applied !==undefined || applied !=="") {
        endpoint +='&applied='+applied
    }

    if (startDate !==undefined || startDate !=="") {
        endpoint+='&from='+startDate
    }

    if (endDate !==undefined || endDate !=="") {
        endpoint+='&to='+endDate
    }
    if (experience !==undefined || experience!=="") {
        endpoint +='&experience='+experience
    }
    return{
        [GET_API]:{
            endpoint,
            types: [ GET_DOWNLOAD_CANDIDATE_REQUEST,GET_DOWNLOAD_CANDIDATE_SUCCESS,GET_DOWNLOAD_CANDIDATE_FAILURE]

        }

    }
}
export function setCSVLink() {
    return {type: CHANGE_CSV_DATA}
}

// calls api to get user details
export function getUserDetails(email) {
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/users/' + email,
            types: [GET_USER_DETAILS_REQUEST, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE]
        }
    }
}

//calls action to change page no.
export function changePageNumber(newPageNumber) {
    return {
        type: NEW_PAGE_NUMBER, newPageNumber
    }
}

// calls action to filter user
export function filterUser(newState) {
    return {type: FILTER_USER, newState}
}
 
export function selectedCandidate(body) {

    return {
        [PUT_API]: {
            endpoint: BASE_URL + '/v1/card/status',
            types: [PUT_SELECTED_CANDIDATE_REQUEST,PUT_SELECTED_CANDIDATE_SUCCESS,PUT_SELECTED_CANDIDATE_FAILURE],
            body:JSON.stringify(body)
        }
    }
}
