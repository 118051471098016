import React, { Component } from "react";
import AdminOnPageNavigation from "../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  changeCreateJobForm,
  clearAll,
  clearCreateJobResponse,
  clearStatus as clearJobs,
  createJob,
  getJobDetails,
  addImage,
  //updateDetails,
} from "../../../../../actions/dashboard/admin/job/job";
import {
  clear_get_teams,
  getAdminTeams,
} from "../../../../../actions/dashboard/admin/team/team";
import { checkValidation } from "../../../../../utils/validator"
import BarLoaderSpinner from "../../../../../components/app/spinner/barloader";
import createNotification from "../../../../../components/app/notification/notification";
import {
  clearStatus,
  editJob,
} from "../../../../../actions/dashboard/user/jobs/jobs"; 
import { NotificationContainer } from "react-notifications"; 
import { CKEditor } from 'ckeditor4-react';
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Helmet } from "react-helmet";
import { authApi } from "../../../../../actions/ApiActions";

const BASE_URL = authApi();

class EditorPreview extends Component {
  render() {
    return (
      <div className="editor-preview">
        <h2>Rendered content:</h2>
        <div dangerouslySetInnerHTML={{ __html: this.props.value }}></div>
      </div>
    );
  }
}
EditorPreview.defaultProps = {
  value: "",
};
class CreateJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      bodyError: false,
      tag: "",
      skillError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }
  // called when ck editor changes value
  onEditorChange(evt) {
    this.setState({
      value: evt.editor.getData(),
    });

    window.setTimeout(() => {
      if (this.state.value === "") {
        this.setState({
          bodyError: true,
        });
      } else {
        this.setState({
          bodyError: false,
        });
      }
    }, 250);
  }

  // called when  start date picker focus out
  handleChange(changeEvent) {
    this.setState({
      value: changeEvent.target.value,
      bodyError: false,
    });
  }
  componentWillMount() {
    this.props.dispatch(clearJobs());
    this.props.dispatch(clearStatus());
    this.props.dispatch(clearAll());
    this.props.dispatch(getAdminTeams());
    if (this.props.location.pathname.match("/edit")) {
      this.props.dispatch(getJobDetails(this.props.match.params.job));
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      nextProps.dispatch(clearJobs());
      nextProps.dispatch(clearStatus());
      nextProps.dispatch(clearAll());
      nextProps.dispatch(getAdminTeams());
      this.setState({ value: "", bodyError: false });
      if (nextProps.location.pathname.match("/edit")) {
        nextProps.dispatch(
          getJobDetails(nextProps.match.params.team, nextProps.match.params.job)
        );
      }
    }

    const self = this;
    if (
      nextProps.state.adminJobReducer.status === 200 &&
      !nextProps.state.adminJobReducer.error
    ) {
      self.setState({
        bodyError: false,
      });
      nextProps.dispatch(clearCreateJobResponse());
    }

    if (nextProps.state.adminTeamReducer.get_teams.length > 0) {
      const newTeams = nextProps.state.adminTeamReducer.get_teams;
      nextProps.dispatch(clear_get_teams());
      nextProps.dispatch(
        changeCreateJobForm(
          Object.assign(nextProps.state.adminJobReducer.createJob, {
            all_teams: newTeams,
          })
        )
      );
    }
    if (nextProps.state.adminJobReducer.edit_job_message !== "") {
      if (nextProps.state.adminJobReducer.edit_job_error) {
        createNotification(
          "error",
          nextProps.state.adminJobReducer.edit_job_message
        );
        nextProps.dispatch(clearJobs());
      } else {
        createNotification(
          "success",
          nextProps.state.adminJobReducer.edit_job_message
        );
        nextProps.history.push("/dashboard/job/list/page=1&pageSize=10");
      }
    }

    if (nextProps.state.adminJobReducer.message !== "") {
      if (nextProps.state.adminJobReducer.error) {
        createNotification("error", nextProps.state.adminJobReducer.message);
        nextProps.dispatch(clearJobs());
      } else {
        createNotification("success", nextProps.state.adminJobReducer.message);
        nextProps.history.push("/dashboard/job/list/page=1&pageSize=10");
      }
    }
    if (nextProps.state.adminJobReducer.body !== "") {
      self.setState({
        value: nextProps.state.adminJobReducer.body,
        bodyError: false,
      });

      nextProps.dispatch(
        changeCreateJobForm(
          Object.assign(nextProps.state.adminJobReducer.createJob, {
            body: nextProps.state.adminJobReducer.body,
          })
        )
      );
    }
  }
  // called when  start date picker focus out
  handleChange(value) {
    this.setState({ text: value, bodyError: false });
  }

  // submit the form

  slugify() {
    let val = document.getElementById("name").value;
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    let result = val
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text

    document.getElementById("slug").value = result;
    let newState = {
      target: { name: "slug", value: result },
    };
    this.onChange(newState, "automaticSlugChange");
  }

  submitCreateJobForm(e) {
    e.preventDefault();
    const self = this.props;
    const thi = this;
    let summaryError = false;
    if (self.state.adminJobReducer.createJob.summary === "") {
      summaryError = true;
    }
    if (
      e.target.checkValidity() &&
      thi.state.value !== "" &&
      !summaryError &&
      this.props.state.adminJobReducer.createJob.skills.length >= 3
    ) {
      const body = {
        meta_description: self.state.adminJobReducer.createJob.meta_description,
        title: self.state.adminJobReducer.createJob.title,
        name: self.state.adminJobReducer.createJob.name,
        summary: self.state.adminJobReducer.createJob.summary,
        location: self.state.adminJobReducer.createJob.location,
        keywords: this.props.state.adminJobReducer.createJob.skills,
        body: thi.state.value,
        featured_image: this.props.state.adminJobReducer.createJob.image,
        team_id: self.state.adminJobReducer.createJob.team,
        slug: self.state.adminJobReducer.createJob.slug,
        
        };
      if (self.location.pathname.match("/edit")) {
        self.dispatch(editJob(self.match.params.job, body));
      } else {
        self.dispatch(createJob(body));
        
      }
    } else {
      if (this.props.state.adminJobReducer.createJob.skills.length < 3) {
        thi.setState({ skillError: true });
      }
      if (summaryError) {
        document
          .getElementsByName("summary")[0]
          .parentElement.classList.add("has-error");
        document.getElementsByName("summary")[0].focus();
      }
      if (thi.state.value === "") {
        thi.setState({ bodyError: true });
      }
      const invalidTextArea = document.querySelectorAll(
        ".job-form .form-group textarea:invalid"
      );

      for (let i = 0; i < invalidTextArea.length; i++) {
        invalidTextArea[0].focus();
        invalidTextArea[i].parentElement.classList.add("has-error");
      }
      const invalidElmsInput = document.querySelectorAll(
        ".job-form .form-group input:invalid"
      );
      for (let i = 0; i < invalidElmsInput.length; i++) {
        invalidElmsInput[0].focus();
        invalidElmsInput[i].parentElement.classList.add("has-error");
      }
      const invalidSelect = document.querySelectorAll("select");
      for (let i = 0; i < invalidSelect.length; i++) {
        if (invalidSelect[i].value === "") {
          invalidSelect[i].parentElement.classList.add("has-error");
          invalidSelect[i].parentElement.classList.add(
            "personal-select-with-error"
          );
          invalidSelect[i].parentElement.classList.remove(
            "personal-select-without-error"
          );
        }
      }
    }
  }
  // called when  start date picker focus out
  onChange(e, i) {
    const self = this.props;
    this.setState({ bodyError: false });
    if (i !== "automaticSlugChange") checkValidation(e);
    let newState = {};
    newState = Object.assign(self.state.adminJobReducer.createJob, {
      [e.target.name]: e.target.value,
    });
    self.dispatch(changeCreateJobForm(newState));
  }
  

  handleJobImg(e) {
    const self = this.props;
    const file = e.target.files[0];
    const createJobData = this.props.state.adminJobReducer.createJob
    self.dispatch(addImage(file, createJobData));
  }

  handleChange1(tags) {
    //comparing for now adding duplicates in the list.
    if( this.props.state.adminJobReducer.createJob.skills.length = 3){
      this.setState({skillError:false})
    }
    if (
      !this.props.state.adminJobReducer.createJob.skills.includes(
        this.state.tag
      )
    ) {
      const self = this.props;
      let newState = {};
      newState = Object.assign(self.state.adminJobReducer.createJob, {
        skills: tags,
      });
      self.dispatch(changeCreateJobForm(newState));
    }
  }
  onChangeInput(tag) {
    this.setState({ tag });
  }
  render() {
    const { value, tag } = this.state;

    return (
      <div>
        <Helmet>
          <title>Create Job | Career Portal | Xenonstack</title>
        </Helmet>
        <AdminOnPageNavigation
          parentRoute="Dashboard"
          childRoute={
            this.props.location.pathname.match("/edit") ? "Edit Job" : "Add Job"
          }
        />
        <BarLoaderSpinner
          pageLoading={this.props.state.adminJobReducer.pageLoading}
        />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="pool-form-wrapper">
              <h4 className="content-heading">
                {this.props.location.pathname.match("/edit")
                  ? "Edit Job"
                  : "Add Job"}
              </h4>
              <div className="divider"></div>
              <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-12">
                  <div className="form-group">
                    <form
                      className="create-job-form job-form"
                      onSubmit={this.submitCreateJobForm.bind(this)}
                      noValidate={true}
                    >
                      <div className="form-body">
                        <h4>Job Description</h4>

                        <div className="form-group personal-select-without-error">
                          <label className="control-label">
                            Job Type <span className="req">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="team"
                            value={
                              this.props.state.adminJobReducer.createJob.team
                            }
                            required={true}
                            onChange={this.onChange.bind(this)}
                            data-placeholder="Choose a Category"
                            tabIndex="1"
                          >
                           <option value="">Choose Job Type</option>
                            {this.props.state.adminJobReducer.createJob.all_teams.map(
                              (d) => (
                                <option value={d.id}>{d.name}</option>
                                
                              )
                            )}
                          </select>
                          <p className="with-error">Select any team name.</p>
                        </div>
                        
                         <div className="form-group">
                          <label htmlFor="name" className="control-label">
                            Title <span className="req">*</span>
                          </label>
                          <input
                            type="text"
                            id="name"
                            value={
                              this.props.state.adminJobReducer.createJob.name
                            }
                            className="form-control"
                            name="name"
                            minLength={3}
                            autoComplete={"off"}
                            required={true}
                            onBlur={this.slugify.bind(this)}
                            onChange={this.onChange.bind(this)}
                            placeholder="Enter Job Name"
                          />
                          <p className="with-error" alt="with-error">
                            Please enter job name (Min 3 characters required).
                          </p>
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor="slug" className="control-label">
                            Slug<span className="req">*</span>
                          </label>
                          <input
                            type="text"
                            id="slug"
                            value={
                              this.props.state.adminJobReducer.createJob.slug
                            }
                            className="form-control"
                            name="slug"
                            minLength={3}
                            autoComplete={"off"}
                            required={true}
                            onChange={this.onChange.bind(this)}
                            placeholder="Enter Slug"
                          />
                          <p className="with-error">
                            Please enter the slug for job (Min 3 characters
                            required).
                          </p>
                        </div>
                       
                        <div className="form-group">
                          <label htmlFor="location" className="control-label">
                            Location<span className="req">*</span>
                          </label>
                          <input
                            id="location"
                            type="text"
                            value={
                              this.props.state.adminJobReducer.createJob
                                .location
                            }
                            minLength={3}
                            maxLength={50}
                            pattern={"[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"}
                            autoComplete={"off"}
                            required={true}
                            name="location"
                            onChange={this.onChange.bind(this)}
                            className="form-control"
                            placeholder="Enter Location"
                          />
                          <p className="with-error">
                            Please enter location (Min 3 characters required).
                          </p>
                        </div>
                        
                         <div className="form-group">
                          <label className="control-label mb-5">
                            Add Keywords <span className="req">*</span>
                          </label>
                          <div className="tag-input">
                            <TagsInput
                              value={
                                this.props.state.adminJobReducer.createJob
                                  .skills
                              }
                              inputValue={tag}
                              onChangeInput={this.onChangeInput.bind(this)}
                              inputProps={{ placeholder: "add keyword" }}
                              onChange={this.handleChange1.bind(this)}
                            />
                            {this.state.skillError &&(
                              <p
                                style={{
                                  display: "block",
                                  color: "#d50000",
                                  fontSize: "13px",
                                  padding: "5px 0 0 0",
                                }}
                              >
                                Please enter atleast three keywords and press
                                enter key.
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="summary" className="control-label mb-5">
                            Summary <span className="req">*</span>
                          </label>
                          <textarea
                            id="summary"
                            cols="5"
                            rows="4"
                            name="summary"
                            value={
                              this.props.state.adminJobReducer.createJob.summary
                            }
                            minLength={120}
                            autoComplete={"off"}
                            required={true}
                            placeholder="Enter Summary"
                            onChange={this.onChange.bind(this)}
                            style={{ width: "100%" }}
                            className="form-control"
                          ></textarea>
                          <p className="with-error">
                            Please enter summary (Min 120 characters required).
                          </p>
                        </div>

                        <div className="form-group">
                          <label className="control-label mb-5">
                            Body <span className="req">*</span>
                          </label>
                          <div className="App">
                            <CKEditor
                              data={value}
                              onChange={this.onEditorChange}
                            />
                            {this.state.bodyError && (
                              <p
                                style={{
                                  display: "block",
                                  color: "#d50000",
                                  fontSize: "13px",
                                  padding: "5px 0 0 0",
                                }}
                              >
                                This field is required.
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="image" className="control-label mb-5">
                            Upload Image<span className="req">*</span>
                          </label>
                          <div className="custom-image-uploader">
                            <div className="custom-img-box">
                              <div>
                                <i className="material-icons">file_upload </i>
                                <span>Upload Image</span>
                              </div>
                              <input
                                id="image"
                                type="file"
                                name="image"
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) => this.handleJobImg(e)}
                              />
                            </div>
                            <p className="with-error">Please Upload Image</p>
                            <div className="custom-img-preview">
                              {this.props.state.adminJobReducer.createJob
                                .image !== "" && (
                                <ul className="img-preview-ul">
                                  <li>
                                    <div className="prev-img-box">
                                      <div className="image-overlay"></div>
                                      <img
                                        src={
                                          this.props.state.adminJobReducer
                                            .createJob.image
                                        }
                                      />
                                    </div>
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="meta-title" className="control-label">
                            Meta Title<span className="req">*</span>
                          </label>
                          <input
                            id="meta-title"
                            type="text"
                            className="form-control"
                            name="title"
                            minLength={3}
                            autoComplete={"off"}
                            required={true}
                            value={
                              this.props.state.adminJobReducer.createJob.title
                            }
                            onChange={this.onChange.bind(this)}
                            placeholder="Enter Meta Title"
                          />
                          <p className="with-error">
                            Please enter Meta Title (Min 3 characters required).
                          </p>
                        </div>
                        <div className="form-group">
                          <label htmlFor="meta_description" className="control-label">
                            Meta Description<span className="req">*</span>
                          </label>
                          <input
                            id="meta_description"
                            type="text"
                            value={
                              this.props.state.adminJobReducer.createJob
                                .meta_description
                            }
                            className="form-control"
                            name="meta_description"
                            minLength={3}
                            autoComplete={"off"}
                            required={true}
                            onChange={this.onChange.bind(this)}
                            placeholder="Enter Meta Description"
                          />
                          <p className="with-error">
                            Please enter Meta Description (Min 3 characters
                            required).
                          </p>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            disabled={
                              this.props.state.adminJobReducer.pageLoading
                                ? true
                                : false
                            }
                            className="btn mt-30"
                          >
                            {this.props.location.pathname.match("/edit")
                              ? "Update Job"
                              : "Create Job"}
                          </button>
                          <button
                            name="button"
                            style={{ marginLeft: "20px" }}
                            className="btn mar-t-2"
                            type="button"
                            onClick={() =>
                              this.props.history.push(
                                "/dashboard/job/list/page=1&pageSize=10"
                              )
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  
  return { state };
}

export default withRouter(connect(mapStateToProps)(CreateJob));
