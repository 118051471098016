import React, {Component} from 'react';
import AdminOnPageNavigation from '../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import TotalApplied from './total-applied';
import ShortlistedCandidates from './shortedlisted-candidates';
import PlannedInterviews from './planned-interviews';
import HiredCandidates from './hired-candidates';
import Candidates from './candidates';
import Feedbacks from './feedbacks';
import Drives from './drives';
import Interviews from './interviews';
import {clearAll, getAllUsers} from '../../../../actions/dashboard/admin/user/user';
import {Helmet} from "react-helmet";

class Dashboard extends Component {
    componentWillMount() {
        this.props.dispatch(clearAll());
        this.props.dispatch(getAllUsers(1, "", "", "","","","",""));
    }
    render() {
        
        return (
            <div>
            <Helmet>
                <title>Dashboard | Career Portal | Xenonstack</title>
             </Helmet>
                <AdminOnPageNavigation parentRoute="Dashboard" childRoute=""/>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="dashboard-card">
                            <div className="row">
                                <TotalApplied/>
                                <ShortlistedCandidates Shortlisted={this.props.state.userReducer.allUsersDetails.shortlisted}/>
                                <HiredCandidates Rejected={this.props.state.userReducer.allUsersDetails.rejected}/>
                                <PlannedInterviews/>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Candidates id="candidates" name={"Latest Candidate"} candidates = {this.props.allUsers}/>
                    {/* <Candidates id="candidatesE" name={"Experienced Candidates"} candidates = {this.props.allUsers}/> */}
                    <Drives/>
                </div>
                {/* <div className="row">
                    <Candidates id="candidatesG" name={"New Graduate Candidates"} candidates = {this.props.allUsers}/>
                    <Candidates id="candidatesI" name={"Internship Candidates"} candidates = {this.props.allUsers}/>
                </div> */}
                <div className="row">
                   
                    <Interviews/>

                    <Feedbacks/>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    const allUsers = state.userReducer;
    
    return {state,allUsers}
}
export default withRouter(connect(mapStateToProps)(Dashboard))
