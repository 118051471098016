import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';
import AdminOnPageNavigation from '../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import {checkValidation} from "../../../../../actions/app/app";
import {
    changeCreateDriveForm,
    clear,
    clearAll,
    createDrive,
    editDriveDetails,
    getDriveDetails
} from "../../../../../actions/dashboard/admin/drive/drive";
import {getTest} from "../../../../../actions/dashboard/admin/test/test";
import {getCollege} from "../../../../../actions/dashboard/admin/college/college";
import createNotification from "../../../../../components/app/notification/notification";
import 'react-datepicker/dist/react-datepicker.min.css';
import DatePicker from 'react-datepicker';
import moment from "moment";
import '../../../../../static/css/react-datepicker.css';
import ReactSelect from 'react-select';
import {Helmet} from "react-helmet";

class CreateDrive extends Component {
    componentWillMount() {
        this.props.dispatch(clear());
        this.props.dispatch(clearAll());
        if (this.props.location.pathname.match("/edit")) {
            this.props.dispatch(getDriveDetails(this.props.match.params.drive));
        }
        this.props.dispatch(getTest());
        // this.props.dispatch(getCollege())
    }

    // submit the form
    onCreateSubmit(e) {
        e.preventDefault();
        const self = this.props;
        let notvalidDateTime = false;
        let currentDateTime = new Date().getTime();

        let startDateTime = Date.parse(self.createDrive.startDate);
        let endDateTime = Date.parse(self.createDrive.endDate);

        if (isNaN(startDateTime) || isNaN(endDateTime)) {
            notvalidDateTime = true
        } else {

            currentDateTime = (Math.floor((currentDateTime / 1000) * 100) / 100).toFixed(0);
            startDateTime = (Math.floor((startDateTime / 1000) * 100) / 100).toFixed(0);
            endDateTime = (Math.floor((endDateTime / 1000) * 100) / 100).toFixed(0);

            if (startDateTime >= endDateTime) {
                notvalidDateTime = true
            }
        }

        if (e.target.checkValidity() && !notvalidDateTime) {
            let body = {};
            if (self.createDrive.type === "open") {
                body = {
                    type: "open",
                    name: self.createDrive.name,
                    start: startDateTime.toString(),
                    end: endDateTime.toString(),
                    startStr: self.createDrive.startDate,
                    endStr: self.createDrive.endDate,
                    test_id: self.createDrive.test_id
                }
            } else {
                body = {
                    type: "college",
                    name: self.createDrive.name,
                    start: startDateTime.toString(),
                    end: endDateTime.toString(),

                    startDateUnderstandable: self.createDrive.startDateUnderstandable,
                    endDateUnderstandable: self.createDrive.endDateUnderstandable,
                    test_id: self.createDrive.test_id,
                    college_id: self.createDrive.college_id
                }

            }
            if (self.location.pathname.match("/edit")) {
                body = {
                    type: self.createDrive.type,
                    name: self.createDrive.name,
                    start: startDateTime ,
                   // end: moment( endDateTime * 1000).format("MMMM Do YYYY, h:mm:ss a"),
                    end: endDateTime,
                    startDateUnderstandable: moment( endDateTime  * 1000).format("MMMM Do YYYY, h:mm:ss a"),
                    endDateUnderstandable: moment( endDateTime* 1000).format("MMMM Do YYYY, h:mm:ss a"),
                    test_id: self.createDrive.test_id,
                    college_id: self.createDrive.college_id

                }

                self.dispatch(editDriveDetails(self.match.params.drive, body));
            } else {
                self.dispatch(createDrive(body));
            }
        } else {
            if (notvalidDateTime) {
                self.dispatch(changeCreateDriveForm(Object.assign(self.createDrive, {invalidDateTimeError: "Invalid start date and end date. End date should be greater than Start Date."})))
            }
            const invalidElmsInput = document.querySelectorAll(".create-drive-form .form-group input:invalid");
            for (let i = 0; i < invalidElmsInput.length; i++) {
                invalidElmsInput[i].parentElement.classList.add("has-error")
            }
            const invalidSelect = document.querySelectorAll("select");
            for (let i = 0; i < invalidSelect.length; i++) {
                if (invalidSelect[i].value === "") {
                    invalidSelect[i].parentElement.classList.add("has-error");
                    invalidSelect[i].parentElement.classList.add("personal-select-with-error");
                    invalidSelect[i].parentElement.classList.remove("personal-select-without-error");
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            nextProps.dispatch(clear());
            nextProps.dispatch(clearAll());
            if (nextProps.location.pathname.match("/edit")) {
                nextProps.dispatch(getDriveDetails(this.props.match.params.drive));
            }
            nextProps.dispatch(getTest());
            nextProps.dispatch(getCollege())
        }
        if (nextProps.createDriveMessage !== "") {
            if (nextProps.createDriveError) {
                createNotification('error', nextProps.createDriveMessage);
                nextProps.dispatch(clear());
            } else {
                createNotification('success', nextProps.createDriveMessage);
                nextProps.history.push("/dashboard/drive/view/page=1&pageSize=10")
            }
        }

        if (nextProps.editDriveDetailsMessage !== "") {
            if (nextProps.editDriveDetailsError) {
                createNotification('error', nextProps.editDriveDetailsMessage);
                nextProps.dispatch(clear());
            } else {
                createNotification('success', nextProps.editDriveDetailsMessage);
                nextProps.history.push("/dashboard/drive/view/page=1&pageSize=10")
            }
        }
    }

    // called when onchange html event fired
    onChange(e) {
        const self = this.props;
        checkValidation(e);
        let newState = Object.assign(self.createDrive, {
            [e.target.id]: e.target.value
        });

        self.dispatch(changeCreateDriveForm(newState));
        if (e.target.id === "type") {
            newState = {
                type: e.target.value,
                name: "",
                startDate: "",
                startDateUnderstandable: "",
                endDate: "",
                endDateUnderstandable: "",
                invalidDateTimeError: "",
                test_id: "",
                tests: self.createDrive.tests,
                college_id: "",
                collegeName: "",
                colleges: self.createDrive.colleges
            };
            self.dispatch(changeCreateDriveForm(newState));
        }

    }
    // on change for college list search
    onChange1(e){
        const self = this.props;
        let result ;

        let newStateCollege = {
            type:"college" ,
            name: self.createDrive.name,
            startDate: self.createDrive.startDate,
            startDateUnderstandable: self.createDrive.startDateUnderstandable,
            endDate: self.createDrive.endDate,
            endDateUnderstandable: self.createDrive.endDateUnderstandable,
            invalidDateTimeError: self.createDrive.invalidDateTimeError,
            test_id: self.createDrive.test_id,
            tests: self.createDrive.tests,
            college_id: '',
            collegeName:'',
        };

        let newState = Object.assign(this.props.createDrive,
            newStateCollege
        );
        self.dispatch(changeCreateDriveForm(newState));

    }

    handleCollegeChange(e){
      if(e !== null){
        const self = this.props;
        let newStateCollege = {
            type:"college" ,
            name: self.createDrive.name,
            startDate: self.createDrive.startDate,
            startDateUnderstandable: self.createDrive.startDateUnderstandable,
            endDate: self.createDrive.endDate,
            endDateUnderstandable: self.createDrive.endDateUnderstandable,
            invalidDateTimeError: self.createDrive.invalidDateTimeError,
            test_id: self.createDrive.test_id,
            tests: self.createDrive.tests,
            college_id: e.value,
            collegeName:e.label
        };
        let newState = Object.assign(this.props.createDrive,
            newStateCollege
        );
        self.dispatch(changeCreateDriveForm(newState));
     }
    }
    handleInputChange(inputValue){
      if(inputValue.length >= 3){
        this.props.dispatch(getCollege(1,inputValue));
      }
    }
    customFilter() {
      return true;
    }

    // called when  date picker1 clicked
    datePicker_1(startDate) {

        this.props.dispatch(changeCreateDriveForm(Object.assign(this.props.createDrive, {
            startDate,
            invalidDateTimeError: ""
        })));
    }

    // called when  start date picker focus out
    handleOnBlur(event) {

        this.props.dispatch(changeCreateDriveForm(Object.assign(this.props.createDrive, {startDateUnderstandable: event.target.value})));

    }

    // called when  date picker2 clicked
    datePicker_2(endDate) {
        this.props.dispatch(changeCreateDriveForm(Object.assign(this.props.createDrive, {
            endDate,
            invalidDateTimeError: ""
        })));
    }

    // called when  end date picker focus out
    endDateFocusOut(event) {
        this.props.dispatch(changeCreateDriveForm(Object.assign(this.props.createDrive, {endDateUnderstandable: event.target.value})));
    }
    render() {
        const onKeyDown = e => {
            // catch the code of the key pressed
            if (e.keyCode === 13) {
                e.preventDefault()
                return;
            }
          };
      let collegeListUpdated= [];
      if(this.props.colleges.length > 0){
        let CollegeList = this.props.colleges;
        for(let i =0; i < CollegeList.length; i++){
          collegeListUpdated.push({"label": CollegeList[i].name + ', ' + CollegeList[i].location, "value": CollegeList[i].id})
        }
      }
        return (
            <div>
            <Helmet>
                <title>Create Drive | Career Portal | Xenonstack</title>
             </Helmet>
                <AdminOnPageNavigation parentRoute="Dashboard"
                                       childRoute={this.props.location.pathname.match("/edit") ? "Edit Drive" : "Create Drive"}/>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="pool-form-wrapper">
                            <h4 className="content-heading">{this.props.location.pathname.match("/edit") ? "Edit Drive" : "Create Drive"}</h4>
                            <div className="divider"></div>
                            <div className="row">
                                <div className="col-lg-6 col-md-8 col-sm-12">
                                    <div className="">
                                        <form className="create-drive-form" onSubmit={this.onCreateSubmit.bind(this)}
                                              noValidate={true}>
                                            <div className="form-body">
                                                <div className="form-group">
                                                    <label className="control-label mb-10">Drive Type</label>
                                                    <div className="row">
                                                    {
                                                            (!this.props.location.pathname.includes('/edit')) ?
                                                            (
                                                                <React.Fragment>
                                                                <div className="col-md-6 col-xs-12 col-sm-12">
                                                                    <label className="cstm-radio">Open Drive
                                                                        <input type="radio" name="radio"
                                                                            id="type"
                                                                            value="open"
                                                                            checked={this.props.createDrive.type === "open" ? true : false}
                                                                            onChange={this.onChange.bind(this)}/>
                                                                        <span
                                                                            className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-6 col-xs-12 col-sm-12">
                                                                    <label className="cstm-radio">Campus Drive
                                                                        <input type="radio" name="radio"
                                                                            id="type"
                                                                            value="college"
                                                                            checked={this.props.createDrive.type === "college" ? true : false}
                                                                            onChange={this.onChange.bind(this)}/>
                                                                        <span
                                                                            className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                </React.Fragment>
                                                            ) : (
                                                                <div className="col-md-6 col-xs-12 col-sm-12">
                                                                    {this.props.createDrive.type === "open" ?
                                                                        (
                                                                            <h3>Open Drive</h3>
                                                                        ) : (
                                                                            <h3>Campus Drive</h3>
                                                                        )}
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                                {this.props.location.pathname.match("/edit")
                                                ?(    <div className="form-group">
                                                <label className="control-label mb-5">Drive Name<span
                                                    className="req">*</span></label>
                                                <input type="text" name="name"
                                                       id="name"
                                                       disabled
                                                       onChange={this.onChange.bind(this)}
                                                    //    pattern="[a-zA-Z][a-zA-Z.\s]{2,}$"
                                                       autoComplete="off"
                                                       required={true}
                                                       minLength={3}
                                                       className="form-control" placeholder="Enter Drive Name"
                                                       value={this.props.createDrive.name}/>
                                                <p className="with-error">Please enter drive name (Min 3 characters
                                                    required).</p>

                                            </div>)
                                                :(    <div className="form-group">
                                                <label className="control-label mb-5">Drive Name<span
                                                    className="req">*</span></label>
                                                <input type="text" name="name"
                                                       id="name"
                                                       onChange={this.onChange.bind(this)}
                                                    //    pattern="[a-zA-Z][a-zA-Z.\s]{2,}$"
                                                       autoComplete="off"
                                                       required={true}
                                                       minLength={3}
                                                       className="form-control" placeholder="Enter Drive Name"
                                                       value={this.props.createDrive.name}/>
                                                <p className="with-error">Please enter drive name (Min 3 characters
                                                    required).</p>

                                            </div>)}
                                                {
                                                    this.props.createDrive.type === "college" &&

                                                    <div  className="form-group collegeFormGroup">
                                                        <label className="control-label mb-5">College/University</label>
                                                        <ReactSelect
                                                          options={collegeListUpdated}
                                                          placeholder="Search for College/University"
                                                          onChange={ (e) => this.handleCollegeChange(e)}
                                                          isSearchable={true}
                                                          isLoading={this.props.getCollegePageLoading}
                                                          name="school"
                                                          id="collegeList"
                                                          isClearable={true}
                                                          filterOption={this.customFilter.bind(this)}
                                                          onInputChange={this.handleInputChange.bind(this)}
                                                          classNamePrefix="xenon"
                                                          onKeyDown={onKeyDown}
                                                         />
                                                         <p
                                                             className="with-error">Please enter
                                                             college/university (Min 3 characters required).</p>
                                                  </div>
                                                }
                                                {this.props.location.pathname.match("/edit")
                                                ?(  <div id=""
                                                className="form-group personal-select-without-error">
                                               <label className="control-label mb-5">Select Test <span
                                                   className="req">*</span></label>
                                               <select className="form-control"
                                                       id="test_id"
                                                       required={true}
                                                       disabled
                                                       onChange={this.onChange.bind(this)}
                                                       data-placeholder="Choose a College"
                                                       value={this.props.createDrive.test_id}
                                                       tabIndex="1">
                                                   <option disabled selected value="">Select Test</option>
                                                   {
                                                       this.props.tests.map((t, i) => (
                                                           <option value={t.id}>{t.name}</option>
                                                       ))
                                                   }
                                                   <option value="not mentioned">Not Mentioned</option>
                                               </select>
                                               <p className="with-error">Please select the Test.</p>
                                           </div>)
                                                :(  <div id=""
                                                className="form-group personal-select-without-error">
                                               <label className="control-label mb-5">Select Test <span
                                                   className="req">*</span></label>
                                               <select className="form-control"
                                                       id="test_id"
                                                       required={true}
                                                       onChange={this.onChange.bind(this)}
                                                       data-placeholder="Choose a College"
                                                       value={this.props.createDrive.test_id}
                                                       tabIndex="1">
                                                   <option disabled selected value="">Select Test</option>
                                                   {
                                                       this.props.tests.map((t, i) => (
                                                           <option value={t.id}>{t.name}</option>
                                                       ))
                                                   }
                                                   <option value="not mentioned">Not Mentioned</option>
                                               </select>
                                               <p className="with-error">Please select the Test.</p>
                                           </div>)}
                                              
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6 col-xs-12 col-sm-12">
                                                            <label className="control-label mb-5">Start Date<span
                                                                className="req">*</span></label>
                                                            <DatePicker
                                                                selected={this.props.createDrive.startDate}
                                                                onChange={this.datePicker_1.bind(this)}
                                                                onBlur={this.handleOnBlur.bind(this)}

                                                                selectsStart
                                                                className="form-control"
                                                                placeholderText="Start Date"
                                                                timeFormat="HH:mm"

                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                showTimeInput
                                                                minDate={ new Date() }
                                                                timeInputLabel="Time:"
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-xs-12 col-sm-12">
                                                            <label className="control-label mb-5">End Date<span
                                                                className="req">*</span></label>
                                                            <DatePicker
                                                                selected={this.props.createDrive.endDate}
                                                                onBlur={this.endDateFocusOut.bind(this)}
                                                                onChange={this.datePicker_2.bind(this)}

                                                                minDate={this.props.createDrive.startDate}
                                                                className="form-control"
                                                                placeholderText="End Date"
                                                                timeFormat="HH:mm"

                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                timeInputLabel="Time:"
                                                                showTimeInput
                                                            />

                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        textAlign: "left",
                                                        display: "block",
                                                        color: "#d50000",
                                                        fontSize: "13px",
                                                        padding: "5px 0 0 0"
                                                    }}>{this.props.createDrive.invalidDateTimeError}</div>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit"
                                                            disabled={((this.props.editDriveDetailsPageLoading || this.props.createDrivePageLoading) ? true : false)}
                                                            className="btn mt-30">{this.props.location.pathname.match("/edit") ? "Update Drive" : "Create Drive"}</button>
                                                    <button name="button"
                                                            style={{marginLeft: "20px"}}
                                                            onClick={()=> this.props.history.push("/dashboard/drive/view/page=1&pageSize=10")}
                                                            className="btn mar-t-2" type="submit">Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {tests} = state.adminTestReducer;
    const {colleges} = state.adminCollegeReducer;
    const {
        getDriveDetailsPageLoading, getDriveDetailsStatus, getDriveDetailsError, getDriveDetailsMessage,
        editDriveDetailsPageLoading, editDriveDetailsStatus, editDriveDetailsError, editDriveDetailsMessage,
        createDrivePageLoading, createDriveError, createDriveMessage, createDriveStatus, createDrive
    } = state.adminDriveReducer;

    return {
        tests, colleges,
        getDriveDetailsPageLoading, getDriveDetailsStatus, getDriveDetailsError, getDriveDetailsMessage,
        editDriveDetailsPageLoading, editDriveDetailsStatus, editDriveDetailsError, editDriveDetailsMessage,
        createDrivePageLoading, createDriveError, createDriveMessage, createDriveStatus, createDrive, state
    }
};

export default withRouter(connect(mapStateToProps)(CreateDrive))
