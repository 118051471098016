import {
    DRIVES_REQUEST, DRIVES_SUCCESS, DRIVES_FAILURE,
    CLEAR_ALL, CLEAR_STATUS
} from "../../../../types/dashboard/user/drives/drives-types";
import {GET_API} from "../../../../middleware/token/get-api";
import { driveApi } from "../../../ApiActions";

const BASE_URL = driveApi();

// calls api to get drive list
export function driveList() {
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/user/drives',
            types: [DRIVES_REQUEST, DRIVES_SUCCESS, DRIVES_FAILURE]
        }
    }
}

// calls action to clear error message, response
export function clearAll() {
    return {type: CLEAR_ALL}
}

// calls action to clear error message, response
export function clearStatus() {
    return {type: CLEAR_STATUS}
}

