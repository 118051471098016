import {
  CHANGE_CREATE_JOB_FORM,
  CLEAR_ALL,
  CLEAR_STATUS,
  CREATE_JOB_FAILURE,
  CREATE_JOB_REQUEST,
  CREATE_JOB_SUCCESS,
  EDIT_JOB_DETAILS_FAILURE,
  EDIT_JOB_DETAILS_REQUEST,
  EDIT_JOB_DETAILS_SUCCESS,
  GET_JOB_DETAILS_FAILURE,
  GET_JOB_DETAILS_REQUEST,
  GET_JOB_DETAILS_SUCCESS,
  GET_EMAIL_REQUEST,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAILURE,
  UPDATE_DETAILS,
  CLEAR_CREATE_JOB_RESPONSE,
  IMAGE_REQUEST,
  IMAGE_SUCCESS,
  IMAGE_FAILURE,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
} from "../../../types/dashboard/admin/job/job";

const initialState = {
  createJob: {
    title: "",
    slug: "",
    meta_description: "",
    name: "",
    summary: "",
    location: "",
    skills: [],
    body: "",
    team: "",
    image: "",
    all_teams: [],
  },
  body: "",
  sendEmail:[],
  edit_job_error: "",
  edit_job_message: "",
  edit_job_status: "",
  pageLoading: false,
  status: "",
  error: "",
  message: "",
};

export default function adminJobReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CREATE_JOB_FORM:
      return Object.assign({}, state, {
        createJob: action.newState,
        body: "",
        error: "",
        message: "",
        status: "",
      });
    case CREATE_JOB_REQUEST:
      return Object.assign({}, state, {
        pageLoading: true,
      });
    case CREATE_JOB_SUCCESS:
      return Object.assign({}, state, {
        pageLoading: false,
        error: action.response.data.error,
        message: action.response.data.message,
        status: 200,
        createJob: {
          slug: "",
          meta_description: "",
          name: "",
          summary: "",
          location: "",
          skills: [],
          body: "",
          team: "",
          image: "",
          all_teams: state.createJob.all_teams,
        },
      });
    case CREATE_JOB_FAILURE:
      return Object.assign({}, state, {
        pageLoading: false,
        error: true,
        message: action.response.data.message,
        status: action.response.status,
      });

    case EDIT_JOB_DETAILS_REQUEST:
      return Object.assign({}, state, {
        pageLoading: true,
      });
    case EDIT_JOB_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        pageLoading: false,
        edit_job_error: action.response.data.error,
        edit_job_message: action.response.data.message,
        edit_job_status: 200,
        createJob: action.response.data.error
          ? state.createJob
          : {
              slug: "",
              meta_description: "",
              name: "",
              summary: "",
              location: "",
              skills: [],
              body: "",
              team: "",
              image: "",
              all_teams: state.createJob.all_teams,
            },
      });
    case EDIT_JOB_DETAILS_FAILURE:
      return Object.assign({}, state, {
        pageLoading: false,
        edit_job_error: true,
        edit_job_message: action.response.data.message,
        edit_job_status: action.response.status,
      });

    case GET_JOB_DETAILS_REQUEST:
      return Object.assign({}, state, {
        pageLoading: true,
        edit_job_error: "",
        edit_job_message: "",
        edit_job_status: "",
      });
    case GET_JOB_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        pageLoading: false,
        get_job_details_error: action.response.data.error,
        get_job_details_message: action.response.data.message,
        get_job_details_status: 200,
        createJob: action.response.data.error
          ? {
              title: state.createJob.title,
              meta_description: state.createJob.meta_description,
              name: state.createJob.name,
              summary: state.createJob.summary,
              location: state.createJob.location,
              skills: state.createJob.keywords,
              body: state.createJob.body,
              team: state.createJob.team_id,
              all_teams: state.createJob.all_teams,
              slug: state.createJob.slug,
            }
          : {
              title: action.response.data.job.title,
              meta_description: action.response.data.job.meta_description,
              name: action.response.data.job.name,
              summary: action.response.data.job.summary,
              location: action.response.data.job.location,
              skills:
                action.response.data.job.keywords === null
                  ? " "
                  : action.response.data.job.keywords.length === 0
                  ? [" "]
                  : action.response.data.job.keywords,
              body: action.response.data.job.body,
              team: action.response.data.job.team_id,
              image: action.response.data.job.featured_image,
              all_teams: state.createJob.all_teams,
              slug: action.response.data.job.slug,
            },
        body: action.response.data.error ? "" : action.response.data.job.body,
      });
    case GET_JOB_DETAILS_FAILURE:
      return Object.assign({}, state, {
        pageLoading: false,
        get_job_details_error: true,
        get_job_details_message: action.response.data.message,
        get_job_details_status: action.response.status,
        createJob: {
          name: state.createJob.name,
          summary: state.createJob.summary,
          location: state.createJob.location,
          skills: state.createJob.skills,
          body: state.createJob.body,
          team: state.createJob.team,
          all_teams: state.createJob.all_teams,
          slug: state.createJob.slug,
        },
        body: "",
      });

    case CLEAR_ALL:
      return Object.assign({}, state, {
        createJob: {
          title: "",
          slug: "",
          meta_description: "",
          name: "",
          summary: "",
          location: "",
          skills: [],
          body: "",
          team: "",
          image: "",
          all_teams: [],
        },
        edit_job_error: "",
        edit_job_message: "",
        edit_job_status: "",
        error: "",
        message: "",
        status: "",
      });
    case CLEAR_CREATE_JOB_RESPONSE:
      return Object.assign({}, state, {
        status: "",
      });
    case CLEAR_STATUS:
      return Object.assign({}, state, {
        status: "",
        message: "",
        error: "",
        edit_job_error: "",
        edit_job_message: "",
        edit_job_status: "",
      });
    case IMAGE_REQUEST:
      return Object.assign({}, state, {
        pageLoading: true,
      });
    case IMAGE_SUCCESS:
      return Object.assign({}, state, {
        pageLoading: false,
        error: action.response.data.error,
        addImageMessage: action.response.data.message,
        status: 200,
      });
    case IMAGE_FAILURE:
      return Object.assign({}, state, {
        pageLoading: false,
        error: action.response.data.error,
        addImageMessage: action.response.data.message,
        status: action.response.status,
      });
    case UPDATE_DETAILS:
      return Object.assign({},state,{
          team_name:action.data
        })
    case GET_EMAIL_REQUEST:
        return Object.assign({}, state, {
          pageLoading: true,
        });
    case GET_EMAIL_SUCCESS:
      return Object.assign({}, state, {
      pageLoading: false,
      error:action.response.data.error,
      teamData:action.response.data.list,
      status:action.response.status
      });
      case GET_EMAIL_FAILURE:
        return Object.assign({},state,{
          pageLoading: false,
          error: action.response.data.error,
          status: action.response.status,
        })
      case SEND_EMAIL_REQUEST:
        return Object.assign({}, state, {
          pageLoading:true,
         });

      case SEND_EMAIL_SUCCESS:
        return Object.assign({}, state, {
          pageLoading: false,
          error: action.response.data.error,
          sendEmail:[],
          sendEmailMessage: action.response.data.message,
          status: 200,
        });
      case SEND_EMAIL_FAILURE:
        return Object.assign({}, state, {
          pageLoading: false,
          error: action.response.data.error,
          sendEmailMessage: action.response.data.message,
          status: action.response.status,
        });
        default:
      return state;
  }
}
