// create JOB

export const CHANGE_CREATE_JOB_FORM = "CHANGE_CREATE_JOB_FORM";

export const CREATE_JOB_REQUEST = "CREATE_JOB_REQUEST";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAILURE = "CREATE_JOB_FAILURE";

export const GET_JOB_DETAILS_REQUEST = "GET_JOB_DETAILS_REQUEST";
export const GET_JOB_DETAILS_SUCCESS = "GET_JOB_DETAILS_SUCCESS";
export const GET_JOB_DETAILS_FAILURE = "GET_JOB_DETAILS_FAILURE";

export const EDIT_JOB_DETAILS_REQUEST = "EDIT_JOB_DETAILS_REQUEST";
export const EDIT_JOB_DETAILS_SUCCESS = "EDIT_JOB_DETAILS_SUCCESS";
export const EDIT_JOB_DETAILS_FAILURE = "EDIT_JOB_DETAILS_FAILURE";

export const IMAGE_REQUEST="IMAGE_RQUEST";
export const IMAGE_SUCCESS="IMAGE_SUCCESS";
export const IMAGE_FAILURE="IMAGE_FAILURE";

export const GET_EMAIL_REQUEST="GET_EMAIL_REQUEST";
export const GET_EMAIL_SUCCESS="GET_EMAIL_SUCCESS";
export const GET_EMAIL_FAILURE="GET_EMAIL_FAILURE";

export const SEND_EMAIL_REQUEST="SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS="SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE="SEND_EMAIL_FAILURE";

export const UPDATE_DETAILS="UPDATE_DETAILS";

export const UPDATE_IMAGES="UPDATE_IMAGES"

export const CLEAR_CREATE_JOB_RESPONSE = "CLEAR_CREATE_JOB_RESPONSE";

export const CLEAR_ALL = "CLEAR_ALL";

export const CLEAR_STATUS = "CLEAR_STATUS";