import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import AdminOnPageNavigation from "../../../../components/dashboard/admin/on_page_navigation/on-page-navigation";
import {
  changePageNumber,
  clearAll,
  dropdownFilterData,
  filterUser,
  getAllUsers,
  selectedCandidate,
  getDropDownData,
  getCandidateList,
  setCSVLink,
} from "../../../../actions/dashboard/admin/user/user";
import moment from "moment";
import createNotification from "../../../../components/app/notification/notification";
import { PulseLoader } from "react-spinners";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet";
import { Table, Pagination, DatePicker, Space , Tag } from "antd";
import "antd/dist/antd.css";
import Multiselect from "multiselect-react-dropdown";

const { RangePicker } = DatePicker;
const BASE_URL = process.env.REACT_APP_AUTH_API;
const { Column } = Table;

const dateFormat = "DD-MM-YYYY";

class Candidates extends Component {
  constructor(props) {
    super(props);
    this.handleStatus = this.handleStatus.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.state = {
      selectedValues: [],
    };
  }
  componentWillMount() {
    this.props.dispatch(clearAll());
    this.props.dispatch(dropdownFilterData());
    this.props.dispatch(changePageNumber(this.props.match.params.pageNumber));
    this.props.dispatch(
      getAllUsers(
        this.props.match.params.pageNumber,
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
    );
  }
  // called when page change
  handlePageChange(pageNumber) {
    this.props.dispatch(changePageNumber(pageNumber));
    this.props.history.push("/dashboard/candidate/list/" + pageNumber);
    this.props.dispatch(
      getAllUsers(
        pageNumber,
        this.props.state.userReducer.loca,
        this.props.state.userReducer.qual,
        this.props.state.userReducer.appl,
        this.props.searchText,
        this.props.state.userReducer.startDate
          ? moment(this.props.state.userReducer.startDate, "DD-MM-YYYY").unix()
          : "",
        this.props.state.userReducer.endDate
          ? moment(this.props.state.userReducer.endDate, "DD-MM-YYYY").unix()+86400
          : "",
        this.props.state.userReducer.exp
      )
    );
    this.forceUpdate();
  }
  // called when  start date picker focus out
  onChange(e) {
    //debugger
    const newState = Object.assign(this.props.state.userReducer, {
      [e.target.name]: e.target.value,
    });
    this.props.dispatch(
      changePageNumber(this.props.state.userReducer.userActivePage)
    );
    this.props.history.push(
      "/dashboard/candidate/list/" + this.props.state.userReducer.userActivePage
    );
    this.props.dispatch(filterUser(newState));
    switch (e.target.name) {
      case "loca":
        return this.props.dispatch(
          getDropDownData(
            e.target.value,
            this.props.state.userReducer.qual,
            this.props.state.userReducer.appl,
            this.props.state.userReducer.exp
          ),
          this.props.dispatch(
            getAllUsers(
              this.props.state.userReducer.userActivePage,
              e.target.value,
              this.props.state.userReducer.qual,
              this.props.state.userReducer.appl,
              this.props.searchText,
              this.props.state.userReducer.startDate
                ? moment(
                    this.props.state.userReducer.startDate,
                    "DD-MM-YYYY"
                  ).unix()
                : "",
              this.props.state.userReducer.endDate
                ? moment(
                    this.props.state.userReducer.endDate,
                    "DD-MM-YYYY"
                  ).unix()+86400
                : "",
              this.props.state.userReducer.exp
            )
          )
        );
      case "appl":
        return this.props.dispatch(
          getDropDownData(
            this.props.state.userReducer.loca,
            this.props.state.userReducer.qual,
            e.target.value,
            this.props.state.userReducer.exp
          ),
          this.props.dispatch(
            getAllUsers(
              this.props.state.userReducer.userActivePage,
              this.props.state.userReducer.loca,
              this.props.state.userReducer.qual,
              e.target.value,
              this.props.searchText,
              this.props.state.userReducer.startDate
                ? moment(
                    this.props.state.userReducer.startDate,
                    "DD-MM-YY"
                  ).unix()
                : "",
              this.props.state.userReducer.endDate
                ? moment(
                    this.props.state.userReducer.endDate,
                    "DD-MM-YY"
                  ).unix()+86400
                : "",
              this.props.state.userReducer.exp
            )
          )
        );
      case "exp":
        return this.props.dispatch(
          getDropDownData(
            this.props.state.userReducer.loca,
            this.props.state.userReducer.qual,
            this.props.state.userReducer.appl,
            e.target.value
          ),
          this.props.dispatch(
            getAllUsers(
              this.props.state.userReducer.userActivePage,
              this.props.state.userReducer.loca,
              this.props.state.userReducer.qual,
              this.props.state.userReducer.appl,
              this.props.searchText,
              this.props.state.userReducer.startDate
                ? moment(
                    this.props.state.userReducer.startDate,
                    "DD-MM-YYYY"
                  ).unix()
                : "",
              this.props.state.userReducer.endDate
                ? moment(
                    this.props.state.userReducer.endDate,
                    "DD-MM-YYYY"
                  ).unix()+86400
                : "",
              e.target.value
            )
          )
        );
      default:
        return 0;
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.putSelectedStudentMessage !== "") {
      if (nextProps.putSelectedStudentError) {
        createNotification("error", nextProps.putSelectedStudentMessage);
      } else {
        nextProps.dispatch(
          getAllUsers(
            this.props.match.params.pageNumber,
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          )
        );
        createNotification("success", nextProps.putSelectedStudentMessage);
      }
    }
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.props.dispatch(
        getAllUsers(
          this.props.state.userReducer.userActivePage,
          this.props.state.userReducer.loca,
          this.props.state.userReducer.qual,
          this.props.state.userReducer.appl,
          e.target.value,
          this.props.state.userReducer.startDate
            ? moment(
                this.props.state.userReducer.startDate,
                "DD-MM-YYYY"
              ).unix()
            : "",
          this.props.state.userReducer.endDate
            ? moment(this.props.state.userReducer.endDate, "DD-MM-YYYY").unix()+86400
            : "",
          this.props.state.userReducer.exp
        )
      );
    }
  }
  downloadList() {
    this.props.dispatch(
      getCandidateList(
        this.props.state.userReducer.loca,
        this.props.state.userReducer.qual,
        this.props.state.userReducer.appl,
        this.props.searchText,
        this.props.state.userReducer.startDate
          ? moment(this.props.state.userReducer.startDate, "DD-MM-YYYY").unix()
          : "",
        this.props.state.userReducer.endDate
          ? moment(this.props.state.userReducer.endDate, "DD-MM-YYYY").unix()+86400
          : "",
        this.props.state.userReducer.exp
      )
    );
  }
  // clear all filter value
  clearFilter() {
    this.props.dispatch(
      filterUser({
        loca: "",
        qual: "",
        appl: "",
        exp: "",
        searchText: "",
        startDate: "",
        endDate: "",
      })
    );
    this.setState({
      selectedValues:[]
    })
    this.props.dispatch(dropdownFilterData());
    this.props.dispatch(
      getAllUsers(
        this.props.match.params.pageNumber,
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
    );
  }
  handleStatus(e, emailId, status) {
    e.preventDefault();
    const body = {
      email: emailId,
      status,
    };
    this.props.dispatch(selectedCandidate(body));
  }

  handleSelect = (value, dateString) => {
    const newState = Object.assign(this.props.state.userReducer, {
     startDate: dateString[0],
      endDate: dateString[1],
    });
    this.props.dispatch(changePageNumber(1));
    this.props.history.push("/dashboard/candidate/list/" + 1);
    this.props.dispatch(filterUser(newState));
    this.props.dispatch(
      getAllUsers(
        this.props.match.params.pageNumber,
        this.props.state.userReducer.loca,
        this.props.state.userReducer.qual,
        this.props.state.userReducer.appl,
        this.props.searchText,
        this.props.state.userReducer.startDate
          ? moment(this.props.state.userReducer.startDate, "DD-MM-YYYY").unix()
          : "",
        this.props.state.userReducer.endDate
          ? moment(this.props.state.userReducer.endDate, "DD-MM-YYYY").unix()+86400
          : "",
        this.props.state.userReducer.exp
      )
    );
  };
  onSelect(selectedList, selectedItem) {
    let qualificationParamString = "";
    selectedList.forEach((selectedVal) => {
      qualificationParamString += "'" + selectedVal.name + "',";
    });
    qualificationParamString = qualificationParamString.substring(
      0,
      qualificationParamString.length - 1
    );
    const newState = Object.assign(this.props.state.userReducer, {
      qual: qualificationParamString,
    });
    this.props.dispatch(filterUser(newState));
    this.setState({
      selectedValues: selectedList,
    });
    this.props.dispatch(
      getDropDownData(
        this.props.state.userReducer.loca,
        qualificationParamString,
        this.props.state.userReducer.appl,
        this.props.state.userReducer.exp
      )
    );
    this.props.dispatch(
      getAllUsers(
        this.props.state.userReducer.userActivePage,
        this.props.state.userReducer.loca,
        qualificationParamString,
        this.props.state.userReducer.appl,
        this.props.searchText,
        this.props.state.userReducer.startDate
          ? moment(this.props.state.userReducer.startDate, "DD-MM-YYYY").unix()
          : "",
        this.props.state.userReducer.endDate
          ? moment(this.props.state.userReducer.endDate, "DD-MM-YYYY").unix()+86400
          : "",
        this.props.state.userReducer.exp
      )
    );
  }

  onRemove(selectedList, removedItem) {
    this.setState({
      selectedValues: selectedList,
    });
  }

  onRemoveTag(deletedIdx, deletedItem) {
    let newSelectedValues = this.state.selectedValues.filter(
      (val) => val.id !== deletedIdx
    );
    this.setState({
      selectedValues: newSelectedValues,
    });
    let deletedQual = "";
    newSelectedValues.forEach((selectedQual) => {
      deletedQual += "'" + selectedQual.name + "',";
    });
    deletedQual = deletedQual.substring(0, deletedQual.length - 1);
    this.props.dispatch(
      getAllUsers(
        this.props.state.userReducer.userActivePage,
        this.props.state.userReducer.loca,
        deletedQual,
        this.props.state.userReducer.appl,
        this.props.searchText,
        this.props.state.userReducer.startDate
          ? moment(this.props.state.userReducer.startDate, "DD-MM-YYYY").unix()
          : "",
        this.props.state.userReducer.endDate
          ? moment(this.props.state.userReducer.endDate, "DD-MM-YYYY").unix()+86400
          : "",
        this.props.state.userReducer.exp
      )
    );
  }
  render() {
    let url = "";
    if (this.props.csvCandidate !== "") {
      url = BASE_URL + "/v1/csv/" + this.props.csvCandidate;
      window.open(url);
      this.props.dispatch(setCSVLink());
    }
    const qualificationOptions = [];
    this.props.state.userReducer.qualifications.forEach((curOption, index) => {
      qualificationOptions.push({ name: curOption, id: index });
    });
    return (
      <div>
        <Helmet>
          <title>Candidates | Career Portal | Xenonstack</title>
        </Helmet>
        <AdminOnPageNavigation
          parentRoute="Dashboard"
          childRoute="All Candidates"
        />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="panel panel-default card-view custom-panel pool-panel">
              <div className="panel-heading candidate-page">
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="panel-heading-mobile"
                >
                  <h5 className="panel-title">
                    All Candidates - (
                    {this.props.state.userReducer.all_records_count} Candidates)
                  </h5>
                  <div>
                    {this.props.state.userReducer
                      .getDownloadCandidate_PageLoading ? (
                      <div className="text-center">
                        <PulseLoader
                          margin="2px"
                          sizeUnit={"px"}
                          size={5}
                          color={"white"}
                          loading={true}
                        />
                      </div>
                    ) : (
                      <a
                        className="btn icons"
                        onClick={() => this.downloadList()}
                        data-tip="Download List"
                      >
                        <i className="material-icons">file_download</i>
                      </a>
                    )}
                  </div>
                </div>

                <div className="form-group candidate-search">
                  <input
                    type="text"
                    name="searchText"
                    className="form-control"
                    value={this.props.searchText}
                    onChange={this.onChange.bind(this)}
                    onKeyDown={this.handleKeyDown.bind(this)}
                    placeholder="Search name and press enter.."
                    autocomplete="off"
                  />
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="panel-wrapper collapse in">
                <div className="panel-body candidates-panel-body">
                  <div className="filter-holder mb-15">
                    {this.props.state.userReducer.dropDownpageLoading ? (
                      <div class="scanning-loader">
                        <div class="spinner-7"></div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-6">
                              <div className="form-group">
                                <label>Date</label>
                                <Space
                                  direction="vertical"
                                  size={12}
                                  style={{ position: "relative", top: "0" }}
                                >
                                  <RangePicker
                                    allowClear={false}
                                    onChange={this.handleSelect}
                                    value={[
                                      this.props.state.userReducer.startDate
                                        ? moment(
                                            this.props.state.userReducer
                                              .startDate,
                                            dateFormat
                                          )
                                        : "",
                                      this.props.state.userReducer.endDate
                                        ? moment(
                                            this.props.state.userReducer
                                              .endDate,
                                            dateFormat
                                          )
                                        : "",
                                    ]}
                                    format={dateFormat}
                                    style={{ height: "40px" }}
                                  />
                                </Space>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="Location">Location</label>
                                <select
                                  id="location-val"
                                  className="form-control"
                                  tabIndex="1"
                                  aria-hidden="true"
                                  name="loca"
                                  value={this.props.state.userReducer.loca}
                                  autoComplete={"off"}
                                  required={false}
                                  onChange={this.onChange.bind(this)}
                                  data-placeholder="Select Location"
                                >
                                  <option value="">Select Location</option>
                                  {this.props.state.userReducer.locations.map(
                                    (d, index) => (
                                      <option value={d} key={index}>
                                        {d}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="Location">Qualification</label>
                                <Multiselect
                                  options={qualificationOptions} // Options to display in the dropdown
                                  selectedValues={this.state.selectedValues} // Preselected value to persist in dropdown
                                  onSelect={this.onSelect} // Function will trigger on select event
                                  onRemove={this.onRemove} // Function will trigger on remove event
                                  displayValue="name"
                                  showCheckbox="true"
                                  closeOnSelect="true"
                                  placeholder="Select Qualification"
                                />
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="Location">Applied For</label>
                                <select
                                  id="location-val"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  tabIndex="3"
                                  aria-hidden="true"
                                  name="appl"
                                  value={this.props.state.userReducer.appl}
                                  autoComplete={"off"}
                                  required={false}
                                  onChange={this.onChange.bind(this)}
                                  data-placeholder="Select Applied For"
                                >
                                  <option value="">Select Applied For</option>

                                  {this.props.state.userReducer.applied.map(
                                    (d, index) => (
                                      <option value={d.id} key={index}>
                                        {d.team_name} - {d.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="Location">Experience</label>
                                <select
                                  id="location-val"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  tabIndex="3"
                                  aria-hidden="true"
                                  name="exp"
                                  value={this.props.state.userReducer.exp}
                                  autoComplete={"off"}
                                  required={false}
                                  onChange={this.onChange.bind(this)}
                                  placeholder="Select Experience"
                                >
                                  <option value="">Select Experience</option>
                                  {this.props.state.userReducer.experiences.map(
                                    (d, index) => (
                                      <option value={d.id} key={index}>
                                        {d.who_you_are}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="filters">
                            <div className="form-group check-filter-button">
                              <ul>
                                {(this.state.selectedValues&&this.state.selectedValues.length>0) &&
                                this.state.selectedValues.map((item) => (
                                  <Tag
                                    closable
                                    onClose={() => {
                                      this.onRemoveTag(item.id, item.name);
                                    }}
                                  >
                                    {item.name}
                                  </Tag>
                                ))}
                              </ul>
                              <button
                                type="button"
                                className="btn"
                                onClick={this.clearFilter.bind(this)}
                              >
                                Clear Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="table-wrap feedback-table">
                    <div className="table-responsive">
                      <div className="text-center">
                        {this.props.state.userReducer.pageLoading && (
                          <PulseLoader
                            margin="30px"
                            sizeUnit={"px"}
                            size={15}
                            color={"#48194e"}
                            loading={this.props.state.userReducer.pageLoading}
                          />
                        )}
                      </div>
                      <div className="react-xd-table-wrapper">
                        {!this.props.state.userReducer.pageLoading &&
                          this.props.state.userReducer.allUsers.length > 0 && (
                            <div>
                              <Table
                                dataSource={
                                  this.props.state.userReducer.allUsers
                                }
                                pagination={false}
                              >
                                <Column
                                  width="7%"
                                  title="Sr. No."
                                  dataIndex="sr_no"
                                  key="sr_no"
                                  render={(text, record, index) =>
                                    index +
                                    10 * (this.props.currentPageNumber - 1) +
                                    1
                                  }
                                />
                                <Column
                                  title="Name"
                                  dataIndex="name"
                                  key="name"
                                  render={(text, record) => (
                                    <NavLink
                                      to={
                                        "/dashboard/candidate/" +
                                        record.email +
                                        "/details"
                                      }
                                    >
                                      {record.name}
                                    </NavLink>
                                  )}
                                />
                                <Column
                                  title="Location"
                                  dataIndex="location"
                                  key="location"
                                />
                                <Column
                                  title="Qualification"
                                  dataIndex="qualification"
                                  key="qualification"
                                />
                                <Column
                                  title="Applied For"
                                  dataIndex="AppliedFor"
                                  key="AppliedFor"
                                  render={(text, record) =>
                                    record.AppliedFor === ""
                                      ? "NA"
                                      : record.AppliedFor
                                  }
                                />
                                <Column
                                  width="13%"
                                  title="Applied Date"
                                  dataIndex="appliedDate"
                                  key="AppliedDate"
                                  render={(text, record) =>
                                    moment(record.appliedDate * 1000).format(
                                      "D MMM YYYY"
                                    )
                                  }
                                />
                                <Column
                                  title="Experience"
                                  dataIndex="experience"
                                  key="experience"
                                />
                                <Column
                                  title="Status"
                                  dataIndex="status"
                                  key="status"
                                />
                                <Column
                                  width="12%"
                                  title="Action"
                                  key="action"
                                  render={(text, record) => (
                                    <Space size="middle">
                                      <div className="table-action-group">
                                        <button
                                          onClick={(e) =>
                                            this.handleStatus(
                                              e,
                                              record.email,
                                              "2"
                                            )
                                          }
                                          className="xd-table-btn view"
                                          data-tip="Shortlist"
                                        >
                                          <i className="material-icons">
                                            check
                                          </i>
                                          <div
                                            style={{
                                              lineHeight: "10px",
                                            }}
                                          >
                                            <ReactTooltip place="bottom" />
                                          </div>
                                        </button>
                                        <button
                                          type="button"
                                          onClick={(e) =>
                                            this.handleStatus(
                                              e,
                                              record.email,
                                              "3"
                                            )
                                          }
                                          className="xd-table-btn delete"
                                          data-tip="Rejected"
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                          <div
                                            style={{
                                              lineHeight: "10px",
                                            }}
                                          >
                                            <ReactTooltip place="bottom" />
                                          </div>
                                        </button>
                                      </div>
                                    </Space>
                                  )}
                                />
                              </Table>
                              <Pagination
                                showSizeChanger={false}
                                defaultCurrent={this.props.currentPageNumber}
                                total={
                                  this.props.state.userReducer.all_records_count
                                }
                                pageSizeOptions={[10, 20, 30, 40, 50]}
                                onChange={this.handlePageChange.bind(this)}
                              />
                            </div>
                          )}
                        {!this.props.state.userReducer.pageLoading &&
                          this.props.state.userReducer.allUsers.length ===
                            0 && (
                            <div className="not-found">
                              No Candidate Found!!
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    putSelectedStudentError,
    putSelectedStudentMessage,
    currentPageNumber,
    searchText,
    csvCandidate,
    getDownloadCandidate_message,
    getDownloadCandidate_error,
  } = state.userReducer;
  return {
    state,
    putSelectedStudentError,
    putSelectedStudentMessage,
    currentPageNumber,
    searchText,
    csvCandidate,
    getDownloadCandidate_message,
    getDownloadCandidate_error,
  };
}

export default withRouter(connect(mapStateToProps)(Candidates));
