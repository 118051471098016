import {CALL_POST_API} from "../../../../middleware/token/post-api";
import {
    CHANGE_CREATE_COLLEGE_FORM, CLEAR_ALL, CLEAR_STATUS, CREATE_COLLEGE_CSV_FAILURE, CREATE_COLLEGE_CSV_REQUEST,
    CREATE_COLLEGE_CSV_SUCCESS, CREATE_COLLEGE_FAILURE, CREATE_COLLEGE_REQUEST, CREATE_COLLEGE_SUCCESS,
    GET_COLLEGE_FAILURE, GET_COLLEGE_REQUEST, GET_COLLEGE_SUCCESS, CHANGE_CREATE_COLLEGE_CSV_FORM,
    DELETE_COLLEGE_REQUEST, DELETE_COLLEGE_SUCCESS, DELETE_COLLEGE_FAILURE,
    GET_COLLEGE_DETAILS_REQUEST, GET_COLLEGE_DETAILS_SUCCESS, GET_COLLEGE_DETAILS_FAILURE,
    EDIT_COLLEGE_DETAILS_REQUEST, EDIT_COLLEGE_DETAILS_SUCCESS, EDIT_COLLEGE_DETAILS_FAILURE, COLLEGE_LOADING,
    SELECTED_DELETE_COLLEGE,CLEAR_TEXTBOX,NEW_PAGE_NUMBER_COLLEGE
} from "../../../../types/dashboard/admin/college/college";
import {GET_API} from "../../../../middleware/token/get-api";
import {logout} from "../../../account/login-actions";
import {CALL_DELETE_API} from "../../../../middleware/token/delete/without-body";
import {PUT_API} from "../../../../middleware/token/put_api/put-api-with-body";
import {driveApi} from "../../../ApiActions";

const BASE_URL = driveApi();

// fires action to change college form
export function changeCreateCollegeForm(newState) {
    return {type: CHANGE_CREATE_COLLEGE_FORM, newState}
}

// calls api to create college
export function createCollege(body) {
    return {
        [CALL_POST_API]: {
            endpoint: BASE_URL + '/v1/college',
            types: [CREATE_COLLEGE_REQUEST, CREATE_COLLEGE_SUCCESS, CREATE_COLLEGE_FAILURE],
            body: body
        }
    }
}

// calls action to clear all response and eror message
export function clearAll() {
    return {type: CLEAR_ALL}
}

export function clearTextBox() {
    return {type: CLEAR_TEXTBOX}
}
// calls action to clear all response and eror message
export function clearStatus() {
    return {type: CLEAR_STATUS}
}
//calls api to get colleges
export function getCollege(pageNumber, keyword) {
    if(pageNumber === undefined){
        pageNumber = 1;
    }

    var endpoint = BASE_URL + '/v1/college?page='+pageNumber;

    if(typeof keyword === 'string' && keyword.length >= 3){
        endpoint += '&search='+keyword
    }
    return {
        [GET_API]: {
            endpoint,
            types: [GET_COLLEGE_REQUEST, GET_COLLEGE_SUCCESS, GET_COLLEGE_FAILURE]
        }
    }
}


// calls api to get college details
export function getCollegeDetails(college_id) {
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/college/'+college_id,
            types: [GET_COLLEGE_DETAILS_REQUEST, GET_COLLEGE_DETAILS_SUCCESS, GET_COLLEGE_DETAILS_FAILURE]
        }
    }
}

// calls api to delete college
export function deleteCollege(college_id) {
    return {
        [CALL_DELETE_API]: {
            endpoint: BASE_URL + '/v1/college/'+ college_id,
            types: [DELETE_COLLEGE_REQUEST, DELETE_COLLEGE_SUCCESS, DELETE_COLLEGE_FAILURE]
        }
    }
}

// calls api to edit college details
export function editCollegeDetails(college_id, body) {

    return {
        [PUT_API]: {
            endpoint: BASE_URL + '/v1/college/' + college_id,
            types: [EDIT_COLLEGE_DETAILS_REQUEST, EDIT_COLLEGE_DETAILS_SUCCESS, EDIT_COLLEGE_DETAILS_FAILURE],
            body: JSON.stringify(body)
        }
    }
}


/*----------------------------------------------------- add csv file to upload colleges---------------------------------------------------------------*/

// calls action to change college csv form
export function changeCreateCollegeCsvForm(newState) {
    return {type: CHANGE_CREATE_COLLEGE_CSV_FORM, newState}
}

// calls action to request create college csv
export function createCollegeCsvRequest() {
    return {type: CREATE_COLLEGE_CSV_REQUEST}
}
// calls action when create college csv success
export function createCollegeCsvSuccess(response) {
    return {type: CREATE_COLLEGE_CSV_SUCCESS, response}
}
// calls action when create college csv failure
export function createCollegeCsvFailure(response) {
    return {type: CREATE_COLLEGE_CSV_FAILURE, response}
}

// calls api to upload colleges through csv
export function uploadCollegeCsv(file) {
    let formData = new FormData();
    formData.append('colleges', file);
    let status = "";
    const config = {
        method: "POST",
        headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`},
        body: formData
    };
    return dispatch => {
        dispatch(createCollegeCsvRequest());
        fetch(BASE_URL + '/v1/csv_college', config)
            .then(function (res) {
                status = res.status;
                return res.json()
            })
            .then(function (res) {
                    if (status === 200) {
                        dispatch(createCollegeCsvSuccess({data: {error: res.error, message: res.message}, status: 200}));
                    }
                    else {
                        if (status === 401) {
                            dispatch(logout())
                        }
                        else {
                            dispatch(createCollegeCsvFailure({data: {error: true, message: res.message}, status: status}))
                        }
                    }
                },
                function () {
                    dispatch(createCollegeCsvFailure({
                        data: {message: "Error while uploading colleges", error: true},
                        status: 500
                    }))
                })
    }
}

// calls action to select college to delete
export function selectedDeleteCollege(college) {
    return {type: SELECTED_DELETE_COLLEGE, college}
}


//calls action to change page no.
export function changePageNumber(newPageNumber) {
    return {
        type: NEW_PAGE_NUMBER_COLLEGE, newPageNumber
    }
}