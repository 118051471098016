import {
  GET_ALL_LANES_FAILURE, GET_ALL_LANES_SUCCESS, GET_ALL_LANES_REQUEST, CREATE_LANE_REQUEST,
   CREATE_LANE_SUCCESS, CREATE_LANE_FAILURE,
  CHANGE_CREATE_LANE_FORM, DELETE_LANE_REQUEST, DELETE_LANE_SUCCESS,DELETE_LANE_FAILURE, 
  DELETE_SELECTED_LANE, EDIT_LANE_REQUEST, EDIT_LANE_SUCCESS, EDIT_LANE_FAILURE,
  CLEAR_ALL, CLEAR_STATUS, EDIT_STATUS_SUCCESS, EDIT_STATUS_FAILURE, EDIT_STATUS_REQUEST,
  GET_SELECTED_CARD, CHANGE_CREATE_COMMENT_FORM, CREATE_COMMENT_REQUEST, CREATE_COMMENT_SUCCESS,
   CREATE_COMMENT_FAILURE,GET_COMMENT_REQUEST, GET_COMMENT_SUCCESS, GET_COMMENT_FAILURE,
} from "../../../../types/dashboard/admin/wekanboard/wekanboard";

const initialState = {
  getLanePageLoading: false,
  getLaneError: "",
  getLaneMessage: "",
  getLaneStatus: "",
  lanes: [],

  createLane: { roundname: "" },
  createLanePageLoading: false,
  createLaneError: "",
  createLaneMessage: "",
  createLaneStatus: "",

  deleteLanePageLoading: false,
  deleteLaneError: "",
  deleteLaneMessage: "",
  deleteLaneStatus: "",
  deleteLane: { id: "", name: "" },

  editLanePageLoading: false,
  editLaneError: "",
  editLaneMessage: "",
  editLaneStatus: "",

  editStatusPageLoading:false,
  editStatusError: "",
  editStatusMessage:"",
  editStatusStatus:"",
  status:{ status:"" ,email:"" },

  cardDetails:{id:"",name:''},
  pageLoading: false,
  click: false,

  comments:{id:'',comment:''},
  createCommentPageLoading:false,
  createCommentMessage:'',
  createCommentError:'',
  createCommentStatus:'',

   getCommentPageLoading:false,
   getCommentMessage:'',
   getCommentError:'',
   getCommentStatus:'',
   cardComment:[]

};
export default function laneReducer(state = initialState, action) {
  switch (action.type) {
      
    case GET_ALL_LANES_REQUEST:
        
    return Object.assign({}, state, {
        getLanePageLoading: true,
        createLaneMessage : '',
        deleteLaneError:'',
        deleteLaneMessage: "",
        editLaneError: "",
        editLaneMessage: "",
        editStatusError: "",
        editStatusMessage:"",
        
      });
    case GET_ALL_LANES_SUCCESS:
    
      return Object.assign({}, state, {
        getLanePageLoading: false,
        getLaneStatus: 200,
        getLaneError: action.response.data.error,
        getLaneMessage: action.response.data.error ? action.response.data.message : "",
        lanes: action.response.data.error ? [] : action.response.data.lanes
      });
    case GET_ALL_LANES_FAILURE:
    
      return Object.assign({}, state, {
        getLanePageLoading: false,
        getLaneError: action.response.data.error,
        getLaneMessage: action.response.data.message,
        getLaneStatus: action.response.data.error ? [] : action.response.data.status,
        lanes: []
      });
    case CHANGE_CREATE_LANE_FORM:
    
      return Object.assign({}, state, {
        createLane: action.newState
      });
    case CREATE_LANE_REQUEST:
      return Object.assign({}, state, {
        createLanePageLoading: true,
        createLaneMessage : ""
      });
    case CREATE_LANE_SUCCESS: 
   
      return Object.assign({}, state, {
        createLanePageLoading: false,
        createLaneError: action.response.data.error,
        createLaneMessage: action.response.data.message,
        createLaneStatus: 200,
        createLane: {
          roundname: ""
        }
      });
    case CREATE_LANE_FAILURE:
      return Object.assign({}, state, {
        createLanePageLoading: false,
        createLaneError: action.response.data.error,
        createLaneMessage: action.response.data.message,
        createLaneStatus: action.response.data.status
      });
    case DELETE_SELECTED_LANE:
      return Object.assign({}, state, {
        deleteLane: action.lane
      });
    case DELETE_LANE_REQUEST:
       
      return Object.assign({}, state, {
        deleteLanePageLoading: true,
        deleteLaneMessage: "",
        deleteLaneStatus:''
      });
    case DELETE_LANE_SUCCESS:
      
      return Object.assign({}, state,{
        deleteLanePageLoading: false,
        deleteLaneError: action.response.data.error,
        deleteLaneMessage: action.response.data.message,
        deleteLaneStatus: 200
      });
    case DELETE_LANE_FAILURE:
       
      return Object.assign({}, state,{
        deleteLanePageLoading: false,
        deleteLaneError: action.response.data.error,
        deleteLaneMessage: action.response.data.message,
        deleteLaneStatus: action.response.data.status
      });

    case EDIT_LANE_REQUEST:
       
      return Object.assign({}, state,{
        editLanePageLoading: true,
        editLaneError: "",
        editLaneMessage: "",
        editLaneStatus: ""
      });
    case EDIT_LANE_SUCCESS:
       
      return Object.assign({}, state,{
        editLanePageLoading: false,
        editLaneError: action.response.data.error,
        editLaneMessage: action.response.data.message,
        editLaneStatus: 200
      });

    case EDIT_LANE_FAILURE:
      
      return Object.assign({}, state,{
        editLanePageLoading: false,
        editLaneError: action.response.data.error,
        editLaneMessage: action.response.data.message,
        editLaneStatus: action.response.data.status
      });

    case EDIT_STATUS_REQUEST:
 
      return Object.assign({},state,{
        editStatusPageLoading:true,
        editStatusError: "",
        editStatusMessage:"",
        editStatusStatus:"",
      })
    case EDIT_STATUS_SUCCESS:
        return Object.assign({},state,{
          editStatusPageLoading:false,
          editStatusError: action.response.data.error,
          editStatusMessage:action.response.data.message,
          editStatusStatus:action.response.data.status,
        })
    case EDIT_STATUS_FAILURE:
    
        return Object.assign({},state,{
          editStatusPageLoading:false,
          editStatusError: action.response.data.error,
          editStatusMessage:action.response.data.message,
          editStatusStatus:action.response.data.status,
        })
    case GET_SELECTED_CARD:
  
        return Object.assign({},state,{
          cardDetails:action.card
        })
    
    case CHANGE_CREATE_COMMENT_FORM:
        return Object.assign({},state,{
          pool:action.newState
        })
    case CREATE_COMMENT_REQUEST:
      return Object.assign({},state,{
        createCommentPageLoading:true,
        createCommentMessage:'',
        createCommentError:'',
        createCommentStatus:'',
      })
    case CREATE_COMMENT_SUCCESS:
   
      return Object.assign({},state,{
        createCommentPageLoading:false,
        createCommentMessage:action.response.data.message,
        createCommentError:action.response.data.error,
        createCommentStatus:action.response.status,
      })
    case CREATE_COMMENT_FAILURE:
      return Object.assign({},state,{
        createCommentPageLoading:false,
        createCommentMessage:action.response.data.message,
        createCommentError:action.response.data.error,
        createCommentStatus:action.response.status,
      })
    case GET_COMMENT_REQUEST:
      return Object.assign({},state,{
        getCommentPageLoading:false,
        getCommentMessage:'',
        getCommentError:'',
        getCommentStatus:'',
        cardComment:[],
        createCommentError :'',
        createCommentStatus : ''
      })
    case GET_COMMENT_SUCCESS:
     
      return Object.assign({},state,{
        getCommentPageLoading:false,
        getCommentMessage:action.response.data.message,
        getCommentError:action.response.data.error,
        getCommentStatus:action.response.data.status,
        cardComment:  action.response.data.comments
      })
    case GET_COMMENT_FAILURE:
      return Object.assign({},state,{
        getCommentPageLoading:false,
        getCommentMessage:action.response.data.message,
        getCommentError:action.response.data.error,
        getCommentStatus:action.response.data.status,
        cardComment:[]
      })
    case CLEAR_ALL:
     
      return Object.assign({}, state,{
        getLaneError: "",
        getLaneMessage: "",
        getLaneStatus: "",
        lanes: [],
      
        createLane: { roundname: "" },
        createLaneError: "",
        createLaneMessage: "",
        createLaneStatus: "",
      
        deleteLaneError: "",
        deleteLaneMessage: "",
        deleteLaneStatus: "",
        deleteLane: { id: "", name: "" },
    
        editLaneError: "",
        editLaneMessage: "",
        editLaneStatus: "",
      })
    case CLEAR_STATUS:
     
      return Object.assign({}, state,{
        editLaneError: "",
        editLaneMessage: "",
        editLaneStatus: "",

        createLaneError: "",
        createLaneMessage: "",
        createLaneStatus: "",

        deleteLaneError: "",
        deleteLaneMessage: "",
        deleteLaneStatus: "",
      })
    default:
      return state;
  }
}
