import React from 'react'

export function ThComponent({ onClick, isSorting, children }) {
  return (
    <th
      onClick={onClick}
    >
      <div className="xd-th-component-header">
        <div className="xd-th-component-content">
          <span className="xd-th-component-text">{children}</span>
          <div className="sorting-group">
            {isSorting ? (
              isSorting.asc ? (
                <i className="fa fa-arrow-down"></i>
              ) : (
                <i className="fa fa-arrow-up"></i>
              )
            ) : null}
          </div>
        </div>
      </div>
    </th>
  )
}
