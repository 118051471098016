import {
  CHANGE_CREATE_TEAM_FORM,
  CLEAR_ALL,
  CLEAR_EDIT_TEAM,
  CLEAR_TEAMS,
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  EDIT_TEAM_DETAILS_FAILURE,
  EDIT_TEAM_DETAILS_REQUEST,
  EDIT_TEAM_DETAILS_SUCCESS,
  GET_TEAM_DETAILS_FAILURE,
  GET_TEAM_DETAILS_REQUEST,
  GET_TEAM_DETAILS_SUCCESS,
  GET_TEAMS_FAILURE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  CLEAR_STATUS,
  SELECTED_DELETE_TEAM,
  UPDATE_SUB_TEAM_INFO,
  UPDATE_TEAM_INFO,
  UPDATE_RES_INFO,
  UPDATE_TEAM_INFORMATION,
  ADD_IMAGE_FAILURE,
  ADD_IMAGE_REQUEST,
  ADD_IMAGE_SUCCESS,
  DELETE_IMAGE_FAILURE,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_SUCCESS,
  UPDATE_IMAGES,
  GET_ADMIN_TEAMS_FAILURE,
  GET_ADMIN_TEAMS_SUCCESS,
  GET_ADMIN_TEAMS_REQUEST,
} from "../../../../types/dashboard/admin/team/team";
import { CALL_POST_API } from "../../../../middleware/token/post-api";
import { PUT_API } from "../../../../middleware/token/put_api/put-api-with-body";
import { CALL_DELETE_API } from "../../../../middleware/token/delete/without-body";
import { logout } from "../../../account/login-actions";
import { GET_API_WITHOUT_TOKEN } from "../../../../middleware/without_token/get-api-without-token";
import { GET_API } from "../../../../middleware/token/get-api";
import { driveApi,authApi } from "../../../ApiActions";

const BASE_URL = authApi();
const BASE_URL_DRIVE = driveApi();
// create TEAM

// calls action to change team form
export function changeCreateTeamForm(newState) {
  return { type: CHANGE_CREATE_TEAM_FORM, newState };
}
export function updateTeamInfo(newState) {
  return { type: UPDATE_TEAM_INFO, newState };
}
export function updateSubTeams(newState) {
  return { type: UPDATE_SUB_TEAM_INFO, newState };
}
export function updateResInfo(newState){
  return{ type:UPDATE_RES_INFO,newState};
}
export function updateTeamInformation(newState){
  return{type:UPDATE_TEAM_INFORMATION,newState}
}

// calls api to create team
export function createTeam(body) {
  return {
    [CALL_POST_API]: {
      endpoint: BASE_URL + "/v1/teamjobs",
      types: [CREATE_TEAM_REQUEST, CREATE_TEAM_SUCCESS, CREATE_TEAM_FAILURE],
      body: body,
    },
  };
}
// calls api to get team details calling at website
export function getAdminTeamDetails(type) {
  return {
    [GET_API]: {
      endpoint: BASE_URL + "/v1/teamjobs/" + type,
      types: [
        GET_TEAM_DETAILS_REQUEST,
        GET_TEAM_DETAILS_SUCCESS,
        GET_TEAM_DETAILS_FAILURE,
      ],
      token: "",
    },
  };
}
// calls api to get team details calling at website
export function getTeamDetails(type) {
  return {
    [GET_API_WITHOUT_TOKEN]: {
      endpoint: BASE_URL + "/v1/teamjob/" + type,
      types: [
        GET_TEAM_DETAILS_REQUEST,
        GET_TEAM_DETAILS_SUCCESS,
        GET_TEAM_DETAILS_FAILURE,
      ],
      token: "",
    },
  };
}

// calls api to edit team details
export function editTeamDetails(team, body) {
  return {
    [PUT_API]: {
      endpoint: BASE_URL + "/v1/teamjobs/" + team,
      types: [
        EDIT_TEAM_DETAILS_REQUEST,
        EDIT_TEAM_DETAILS_SUCCESS,
        EDIT_TEAM_DETAILS_FAILURE,
      ],
      body: JSON.stringify(body),
    },
  };
}

// calls action to clear edit team details response
export function clearEditTeam() {
  return {
    type: CLEAR_EDIT_TEAM,
  };
}
// calls action to clear getteams
export function clear_get_teams() {
  return { type: CLEAR_TEAMS };
}

//calls api to delete team
export function deleteTeam(team) {
  return {
    [CALL_DELETE_API]: {
      endpoint: BASE_URL + "/v1/teamjobs/" + team,
      types: [DELETE_TEAM_REQUEST, DELETE_TEAM_SUCCESS, DELETE_TEAM_FAILURE],
    },
  };
}
export function getAdminTeams(type) {
  var endpoint = BASE_URL + "/v1/teamjobs";
  if (type !== "" && type !== undefined) {
    endpoint += "?type=" + type;
  }
  return {
    [GET_API]: {
      endpoint,
      types: [
        GET_ADMIN_TEAMS_REQUEST,
        GET_ADMIN_TEAMS_SUCCESS,
        GET_ADMIN_TEAMS_FAILURE,
      ],
    },
  };
}

// calls api to get teams
//calling at website
export function getTeams(type) {
  var endpoint = BASE_URL + "/v1/teamjob";
  if (type !== "" && type !== undefined) {
    endpoint += "?type=" + type;
  }
  return {
    [GET_API]: {
      endpoint,
      types: [GET_TEAMS_REQUEST, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE],
    },
  };
}
// calls action to clear error message, response
export function clearAll() {
  return { type: CLEAR_ALL };
}
// calls action to clear error message, response
export function clearStatus() {
  return { type: CLEAR_STATUS };
}

// calls action to delete selected team
export function selectedDeleteTeam(team , name) {
  return { type: SELECTED_DELETE_TEAM, team ,name};
}

// calls action to request add image
export function addImageRequest() {
  return { type: ADD_IMAGE_REQUEST };
}
// calls action when add image success
export function addImageSuccess(response) {
  return { type: ADD_IMAGE_SUCCESS, response };
}
// calls action when add image failure
export function addImageFailure(response) {
  return { type: ADD_IMAGE_FAILURE, response };
}
// calls action to update images
export function updateImages(status, updatedImages) {
  return { type: UPDATE_IMAGES, status, newState: updatedImages };
}

// calls api to add image
export function addImage(file, type, index, adminTeamData) {
  let formData = new FormData();
  formData.append("image", file);
  let status = "";
  const config = {
    method: "POST",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    body: formData,
  };
  return (dispatch) => {
    dispatch(addImageRequest());
    fetch(BASE_URL_DRIVE + "/v1/upload_image", config)
      .then(function(res) {
        status = res.status;
        return res.json();
      })
      .then(
        function(res) {
          if (status === 200) {
            if (res.error) {
              console.log("error");
              dispatch(
                addImageSuccess({
                  data: { error: true, message: res.message },
                  status: 200,
                })
              );
            } else {
              dispatch(
                addImageSuccess({
                  data: { error: false, message: "Image added" },
                  status: 200,
                })
              );
              if (type === "featuredImage") {
                let createTeam = adminTeamData.createTeam;
                createTeam["featured_image"] = res.link;
                dispatch(updateImages("featuredImage",createTeam));
              }
              if (type === "team_image") {
                let what_will_do = adminTeamData.what_will_do;
                what_will_do[index]["image"] = res.link;
                dispatch(updateImages("team_image", what_will_do));
              }
              if (type === "sub_image") {
                let sub_teams = adminTeamData.sub_teams;
                sub_teams[index]["image"] = res.link;
                dispatch(updateImages("sub_image", sub_teams));
              }
              if (type === "res_image") {
                let responsibility_information = adminTeamData.responsibility_information;
                responsibility_information["image"] = res.link;
                dispatch(updateImages("res_image", responsibility_information));
              }
              if (type === "team_info_image1") {
                let team_information = adminTeamData.team_information;
                team_information["image_1"] = res.link;
                dispatch(updateImages("team_info_image1", team_information));
              }
              if (type === "team_info_image2") {
                let team_information = adminTeamData.team_information;
                team_information["image_2"] = res.link;
                dispatch(updateImages("team_info_image2", team_information));
              }
              
            }
          } else {
            if (status === 401) {
              dispatch(logout());
            } else {
              dispatch(
                addImageFailure({
                  data: { error: true, message: res.message },
                  status: status,
                })
              );
            }
          }
        },
        function() {
          dispatch(
            addImageFailure({
              data: { message: "Error while updating image", error: true },
              status: 500,
            })
          );
        }
      );
  };
}

/* --------------------------------------------------------- removing image --------------------------------------------------------*/
// calls action to request delete image
export function deleteImageRequest() {
  return { type: DELETE_IMAGE_REQUEST };
}

// calls action when delete image success
export function deleteImageSuccess(response) {
  return { type: DELETE_IMAGE_SUCCESS, response };
}
// calls action when delete image fail
export function deleteImageFailure(response) {
  return { type: DELETE_IMAGE_FAILURE, response };
}

// calla api to delete image
export function deleteImage(type, parentIndex, image, adminTeamData) {
  let status = "";
  const config = {
    method: "DELETE",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    body: JSON.stringify({ url: image }),
  };
  return (dispatch) => {
    dispatch(deleteImageRequest());
    fetch(BASE_URL_DRIVE + "/v1/delete_image", config)
      .then(function(res) {
        status = res.status;
        return res.json();
      })
      .then(
        function(res) {
          if (status === 200) {
            if (res.error) {
              dispatch(
                deleteImageSuccess({
                  data: { error: true, message: res.message },
                  status: 200,
                })
              );
            } else {
              dispatch(
                deleteImageSuccess({
                  data: { error: false, message: "Image deleted" },
                  status: 200,
                })
              );
              if (type === "team_image") {
                let what_will_do = adminTeamData.what_will_do;
                what_will_do[parentIndex]["image"] = "";

                dispatch(updateImages("team_image", what_will_do));
              }
              if (type === "sub_image") {
                let sub_teams = adminTeamData.sub_teams;
                sub_teams[parentIndex]["image"] = "";

                dispatch(updateImages("sub_image", sub_teams));
              }
            }
          } else {
            if (status === 401) {
              dispatch(logout());
            } else {
              dispatch(
                deleteImageFailure({
                  data: { error: true, message: res.message },
                  status: status,
                })
              );
            }
          }
        },
        function() {
          dispatch(
            deleteImageFailure({
              data: { message: "Error while updating image", error: true },
              status: 500,
            })
          );
        }
      );
  };
}
