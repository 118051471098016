
export const REGISTRATION_REQUEST = "REGISTRATION_REQUEST";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILURE = "REGISTRATION_FAILURE";
export const CHANGE_REGISTRATION_FORM = "CHANGE_REGISTRATION_FORM";
export const CLEAR_API_ERROR_MESSAGE = "CLEAR_API_ERROR_MESSAGE";

export const CHANGE_EDUCATION = "CHANGE_EDUCATION";
export const CHANGE_EXPERIENCE = "CHANGE_EXPERIENCE";
export const STOP_REGISTRATION_LOADING = 'STOP_REGISTRATION_LOADING';

export const GO_TO_REGISTERATION = "GO_TO_REGISTERATION";

export const SIGNUP_TOKEN = "SIGNUP_TOKEN";
export const DO_NOT_GO_TO_REGISTRATION = "DO_NOT_GO_TO_REGISTRATION";

export const COLLEGE_LOADING = "COLLEGE_LOADING";

export const UPDATE_SKIP_EDUCATION = "UPDATE_SKIP_EDUCATION";
export const UPDATE_SKIP_EXPERIENCE = "UPDATE_SKIP_EXPERIENCE";
export const GET_COLLEGE_LIST = "GET_COLLEGE_LIST";
