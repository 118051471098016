/*eslint-disable*/
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    changeEducation,
    changeRegistrationForm
} from '../../../../../actions/account/registration-action';
import Select from '../../../../app/select/select';
import {startEndYear} from "../../../../app/startEnd/startEndYear";
import {month} from "../../../../app/month/month";
import {qualification} from "../../../../app/qualification/qualification";
import {checkValidation} from "../../../../../actions/app/app";
import {clearAll, getCollege} from "../../../../../actions/dashboard/admin/college/college";
import ReactSelect from 'react-select';

const WebSocket = require('websocket').w3cwebsocket;

let ws = null;
let BASE_URL = "";

class EducationSection extends Component {
    componentWillMount() {
        window.scrollTo(0, 0);
        //Calling the API
        BASE_URL = process.env.REACT_APP_COLLEGES_WS;

        // clear al api error, sattus, message
        this.props.dispatch(clearAll());
    }

    componentWillUnmount() {
        // close the web socket connection....
        if (ws !== null && ws.onclose !== null) {
            // close the websocket at the end when leaving page
            ws.close();
        }
    }

    // this function return month number when month is selected
    checkMonthCount(checkMonth) {
        switch (checkMonth) {
            case "January":
                return 1;
                break;
            case "February":
                return 2;
                break;
            case "March":
                return 3;
                break;
            case "April":
                return 4;
                break;
            case "May":
                return 5;
                break;
            case "June":
                return 6;
                break;
            case "July":
                return 7;
                break;
            case "August":
                return 8;
                break;
            case "September":
                return 9;
                break;
            case "October":
                return 10;
                break;
            case "November":
                return 11;
                break;
            case "December":
                return 12;
                break;
            default:
                return 0;
                break;
        }
    }
    componentDidMount(){
        if(this.props.education[0].highest_education === ''){
            const educationData = [];
            this.props.dispatch(changeEducation(educationData))
        }
    }
    // func called when onchange event fired from html field
    onChange(e, index) {
        const self = this.props;
        const thi = this;
        const target = e.target;
        const existingEducation = self.education;
        const value = e.target.value;
        // checks the validation on the html field and add/remove the error
        checkValidation(e);

        existingEducation[index][e.target.name] = e.target.value;
        // calls action to change registration form
        self.dispatch(changeRegistrationForm(Object.assign(self.registrationForm, {education: existingEducation})));
         // calls action to change registration form

            if (target.name !== "school" && target.name !== "highest_education" &&
                self.education[index]["startD"] !== "" && self.education[index]["startY"] !== "" &&
                self.education[index]["endD"] !== "" && self.education[index]["endY"] !== "") {
                if ((Number(self.education[index]["startY"]) <= Number(self.education[index]["endY"]))) {
                    if (Number(self.education[index]["startY"]) === Number(self.education[index]["endY"])) {
                        let startMonth, endMonth;
                        startMonth = thi.checkMonthCount(self.education[index]["startD"]);
                        endMonth = thi.checkMonthCount(self.education[index]["endD"]);
                        if (Number(startMonth) < Number(endMonth)) {
                            const validDates = document.querySelectorAll(".education_select" + index);
                            for (let i = 0; i < validDates.length; i++) {
                                validDates[i].parentElement.classList.remove("has-error");
                                validDates[i].parentElement.classList.add("personal-select-without-error");
                                validDates[i].parentElement.classList.remove("personal-select-with-error");
                            }
                        } else {
                            const inValidDates = document.querySelectorAll(".education_select" + index);
                            for (let i = 0; i < inValidDates.length; i++) {
                                inValidDates[i].parentElement.classList.add("has-error");
                                inValidDates[i].parentElement.classList.add("personal-select-with-error");
                                inValidDates[i].parentElement.classList.remove("personal-select-without-error");
                            }
                        }
                    } else {
                        const validDates = document.querySelectorAll(".education_select" + index);
                        for (let i = 0; i < validDates.length; i++) {
                            validDates[i].parentElement.classList.remove("has-error");
                            validDates[i].parentElement.classList.add("personal-select-without-error");
                            validDates[i].parentElement.classList.remove("personal-select-with-error");
                        }
                    }
                } else {
                    const inValidDates = document.querySelectorAll(".education_select" + index);
                    for (let i = 0; i < inValidDates.length; i++) {
                        inValidDates[i].parentElement.classList.add("has-error");
                        inValidDates[i].parentElement.classList.add("personal-select-with-error");
                        inValidDates[i].parentElement.classList.remove("personal-select-without-error");
                    }
                }

            }
            this.forceUpdate();

    }

    handleCollegeChange(e, index){
      if(e !== null){
       const existingEducation = this.props.education;
       existingEducation[index]['school'] = e.label;
       existingEducation[index]['school_name'] = e.label;
       let education = this.props.registrationForm.education;
       this.props.dispatch(changeRegistrationForm(Object.assign(this.props.registrationForm, {education: education})));
     }else{ 
       
     }
     const education = this.props.registrationForm.education;
     
     for (let i in education) {
         let collegeFromGroup = document.querySelectorAll('.collegeFormGroup');
         if(education[i]["school"] !== ""){
            
         collegeFromGroup[i].classList.remove('has-error');
         } else {
          
         collegeFromGroup[i].classList.add('has-error');
         }
     }
     this.forceUpdate()
    }
    handleInputChange(inputValue){
      if(inputValue.length >= 3){
        this.props.dispatch(getCollege(1,inputValue));
      }
    }
    customFilter() {
      return true;
    }


    // for adding the education
    addEducation() {
        const existingEducation = this.props.education;
        existingEducation.push({
            school: "",
            loading: false,
            schools: [],
            highest_education: "",
            start: "",
            startD: "",
            startY: "",
            end: "",
            endD: "",
            endY: "",
            current: false
        });
        this.props.dispatch(changeEducation(existingEducation))
        this.forceUpdate();
    }

    // for removing the education
    removeEducation(i) {
        const registrationForm = this.props.registrationForm;
        const education = registrationForm.education;
        education.splice(i, 1);
        const newRegistrationForm = Object.assign(registrationForm, {education});
        this.props.dispatch(changeRegistrationForm(newRegistrationForm));
        this.forceUpdate()
    }

    render() {
        const onKeyDown = e => {
            // catch the code of the key pressed
            if (e.keyCode === 13) {
                e.preventDefault()
                return;
            }
          };
      let collegeListUpdated= [];
      if(this.props.colleges.length > 0){
        let CollegeList = this.props.colleges;
        for(let i =0; i < CollegeList.length; i++){
          collegeListUpdated.push({"label": CollegeList[i].name +','+ CollegeList[i].location, "value": CollegeList[i].id})
        }
      }
      const style={
          control:base=>({
              ...base,
              border:0,
              boxShadow:"0 0 1px",
            })
      }
        return (
            <>
            <div className="wrapper">
                <div className="section_header">
                    <h3 className="big-heading">Education</h3>
                </div>
                <div className="education-edit" id="education-edit">
                    {
                        this.props.education.map((d, i) => (
                            <div className={"row-wrapper"}>
                                <div key={i} className="row">
                                    <div className="col-sm-12">
                                            <div
                                                className="form-group select-wrapper personal-select-without-error">
                                                <label>
                                                    {i === 0 ? "Highest Qualification" : "Other Qualification"}<span
                                                    className="req">*</span>
                                                </label>
                                                <Select name="highest_education"
                                                        id="select"
                                                        required={true}
                                                        placeholder={i === 0 ? "Highest Qualification" : "Other Qualification"}
                                                        onChange={(e) => this.onChange(e, i)}
                                                        value={d.highest_education} className="form-ctrl">
                                                    <option disabled
                                                            value="">{i === 0 ? "Highest Qualification" : "Other Qualification"}</option>
                                                            {
                                                                qualification.map((d) => (
                                                                    <option value={d}>{d}</option>
                                                                ))
                                                            }
                                                    <option value="not mentioned">Not Mentioned</option>
                                                </Select>
                                                <p className="with-error">Please provide your highest education..</p>
                                            </div>
                                        </div>
                                    <div className="col-sm-12">
                                        {/*   <div
                                            className="form-group">
                                            <label className="form-group">College/University
                                                <span
                                                    className="req">*</span>
                                                <input list={"browsers"+i}
                                                        minLength={3}
                                                        pattern={"([a-zA-Z.!-”$%&’()*\\+,\\/;\\[\\\\\\]\\^_`{|}~]+\\s)*[a-zA-Z.!-”$%&’()*\\+,\\/;\\[\\\\\\]\\^_`{|}~]+$"}
                                                        placeholder={"College/University"}
                                                        className="form-ctrl" autoComplete="off"
                                                        name="school" required={true}
                                                        onChange={(e) => this.onChange(e, i)}
                                                        value={d.school}/>
                                                <datalist id={"browsers"+i}></datalist>
                                                <BarLoaderSpinner
                                                    pageLoading={d.loading}/>
                                                    <p
                                                    className="with-error">Please enter
                                                    college/university (Min 3 characters required).</p>
                                            </label>
                                        </div>*/}
                                        <div  className="form-group collegeFormGroup">
                                            <label>College/University<span
                                                className="req">*</span></label>
                                            <ReactSelect
                                                options={collegeListUpdated}
                                                placeholder="Search for College/University"
                                                onChange={ (e) => this.handleCollegeChange(e, i)}
                                                isSearchable={true}
                                                isLoading={this.props.getCollegePageLoading}
                                                name="school"
                                                isClearable={true}
                                                id="collegeList"
                                                required={true}
                                                styles={style}
                                                
                                                value={{'label': d.school, 'value' : d.school}}
                                                filterOption={this.customFilter.bind(this)}
                                                onInputChange={this.handleInputChange.bind(this)}
                                                classNamePrefix="xenon"
                                                onKeyDown={onKeyDown}
                                                />
                                                <p
                                                    className="with-error">Please enter
                                                    college/university (Min 3 characters required).</p>
                                        </div>
                                    </div>

                                    <div className="col-md-5">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-6 mar-xs-30">
                                                <div
                                                    className="form-group select-wrapper personal-select-without-error">
                                                    <label>Start Month <span
                                                        className="req">*</span></label>
                                                    <Select id="select" name="startD"
                                                            required={true}
                                                            placeholder="Start Month"
                                                            onChange={(e) => this.onChange(e, i)}
                                                            value={d.startD}
                                                            className={"form-ctrl education_select" + i}>
                                                        <option disabled selected value="">Start Month
                                                        </option>
                                                        {
                                                            month.map((d, i) => (
                                                                <option
                                                                    value={d}>{d}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                    <p className="with-error">Select valid start month.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-6 mar-xs-30">
                                                <div
                                                    className="form-group select-wrapper personal-select-without-error select">
                                                    <label>Start Year <span
                                                        className="req">*</span></label>
                                                    <Select id="select" name="startY"
                                                            className={"form-ctrl education_select" + i}
                                                            placeholder="Start Year"
                                                            required={true}
                                                            onChange={(e) => this.onChange(e, i)}
                                                            value={d.startY}>
                                                        <option disabled="" value="">Start Year</option>
                                                        {
                                                            startEndYear.map((d, i) => (
                                                                <option value={d}>{d}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                    <p className="with-error">Select valid start year.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-4 mar-xs-30">
                                                <div
                                                    className="form-group select-wrapper personal-select-without-error">
                                                    <label>End Month <span
                                                        className="req">*</span></label>
                                                    <Select id="select" name="endD"
                                                            required={true}
                                                            placeholder="End Month"
                                                            onChange={(e) => this.onChange(e, i)}
                                                            value={d.endD}
                                                            className={"form-ctrl education_select" + i}>
                                                        <option disabled="" value="">End Month</option>
                                                        {
                                                            month.map((d, i) => (
                                                                <option
                                                                    value={d}>{d}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                    <p className="with-error">Select valid end month.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 mar-xs-30">
                                                <div
                                                    className="form-group select-wrapper personal-select-without-error">
                                                    <label>End Year <span
                                                        className="req">*</span></label>
                                                    <Select id="select"
                                                            className={"form-ctrl education_select" + i}
                                                            name="endY"
                                                            placeholder="End Year"
                                                            required={true}
                                                            onChange={(e) => this.onChange(e, i)}
                                                            value={d.endY}>
                                                        <option disabled="" value="">End Year
                                                        </option>
                                                        {
                                                            d.startY === "" ?
                                                                startEndYear.map((d, i) => (
                                                                    <option value={d}>{d}</option>
                                                                ))
                                                                :
                                                                startEndYear.filter((data) => data >= d.startY).map((d, i) => (
                                                                    <option value={d}>{d}</option>
                                                                ))
                                                        }

                                                    </Select>
                                                    <p className="with-error">Select valid end year.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                    <div  className="date-error">
                                        End Month and End Year should be greater than Start Month and Start Year.</div> 

                                    </div>
                                </div>
                                {
                                    (i !==0) && (
                                        <div className="cross-btn">
                                            <span onClick={() => this.removeEducation(i)}><i
                                                className="material-icons">clear</i></span>
                                        </div>)
                                }
                            </div>
                        ))
                    }
                    <div className="row">
                        <div className="col-sm-12">
                            <button type="button" style={{cursor: "pointer"}} onClick={this.addEducation.bind(this)}
                                className="btn add-btn"><span>+</span> ADD EDUCATION</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    const {registrationForm, goToRegistration} = state.RegisterReducer;
    const {colleges} = state.adminCollegeReducer;
    const {education} = registrationForm;
    return {education, registrationForm, goToRegistration, colleges}
}

export default withRouter(connect(mapStateToProps)(EducationSection))
