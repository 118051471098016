import {
    GET_ALLPOOLWISE_RESULT_REQUEST,
    GET_ALLPOOLWISE_RESULT_SUCCESS,
    GET_ALLPOOLWISE_RESULT_FAILURE,
    GET_POOLWISE_RESULT_REQUEST,
    GET_POOLWISE_RESULT_SUCCESS,
    GET_POOLWISE_RESULT_FAILURE,
    GET_ALLDRIVEWISE_RESULT_REQUEST,
    GET_ALLDRIVEWISE_RESULT_SUCCESS,
    GET_ALLDRIVEWISE_RESULT_FAILURE,
    GET_DRIVEWISE_RESULT_REQUEST,
    GET_DRIVEWISE_RESULT_SUCCESS,
    GET_DRIVEWISE_RESULT_FAILURE,
    GET_ANALYTICS_REQUEST,
    GET_ANALYTICS_SUCCESS,
    GET_ANALYTICS_FAILURE,NEW_PAGE_NUMBER
    } from '../../../types/dashboard/admin/results/results'

import { GET_API} from '../../../middleware/token/get-api'
import { driveApi } from '../../ApiActions';

const BASE_URL = driveApi();

/*---------------------------------------------------- get all pool result --------------------------------------------------------------------*/

export function getAllPoolwiseResult(){
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/result/poolwise',
            types: [GET_ALLPOOLWISE_RESULT_REQUEST, GET_ALLPOOLWISE_RESULT_SUCCESS, GET_ALLPOOLWISE_RESULT_FAILURE]
        }
    }
}

/*---------------------------------------------------- get poolwise candidates result --------------------------------------------------------------------*/

export function getPoolwiseResult(pool_id,page){
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/result/poolwise/' + pool_id+'?page='+page,
            types: [GET_POOLWISE_RESULT_REQUEST, GET_POOLWISE_RESULT_SUCCESS, GET_POOLWISE_RESULT_FAILURE]
        }
    }
}

/*---------------------------------------------------- get all pool result --------------------------------------------------------------------*/

export function getAllDrivewiseResult(){
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/result/drivewise',
            types: [GET_ALLDRIVEWISE_RESULT_REQUEST, GET_ALLDRIVEWISE_RESULT_SUCCESS, GET_ALLDRIVEWISE_RESULT_FAILURE]
        }
    }
}

/*---------------------------------------------------- get poolwise candidates result --------------------------------------------------------------------*/

export function getDrivewiseResult(drive_id,page){
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/result/drivewise/' + drive_id+'?page='+page,
            types: [GET_DRIVEWISE_RESULT_REQUEST, GET_DRIVEWISE_RESULT_SUCCESS, GET_DRIVEWISE_RESULT_FAILURE]
        }
    }
}

/*---------------------------------------------------- get analytics --------------------------------------------------------------------*/

export function getAnalytics(drive_id){
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/result/analytical/' + drive_id,
            types: [GET_ANALYTICS_REQUEST, GET_ANALYTICS_SUCCESS, GET_ANALYTICS_FAILURE]
        }
    }
}
//calls action to change page no.
export function changePageNumber(newPageNumber) {
    return {
        type: NEW_PAGE_NUMBER, newPageNumber
    }
}

