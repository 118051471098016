import {
  CLEAR_ALL,
  DROPDOWN_FILTER_DATA_FAILURE,
  DROPDOWN_FILTER_DATA_REQUEST,
  DROPDOWN_FILTER_DATA_SUCCESS,
  FILTER_DATA_REQUEST,
  FILTER_DATA_FAILURE,
  FILTER_DATA_SUCCESS,
  FILTER_USER,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  NEW_PAGE_NUMBER,
  PUT_SELECTED_CANDIDATE_REQUEST,
  PUT_SELECTED_CANDIDATE_SUCCESS,
  PUT_SELECTED_CANDIDATE_FAILURE,
  GET_DOWNLOAD_CANDIDATE_REQUEST,
  GET_DOWNLOAD_CANDIDATE_FAILURE,
  GET_DOWNLOAD_CANDIDATE_SUCCESS,
  CHANGE_CSV_DATA,
} from "../../../types/dashboard/admin/user/user";

import {
  UPLOAD_RESUME_REQUEST,
  UPLOAD_RESUME_SUCCESS,
  UPLOAD_RESUME_FAILURE,
} from "../../../types/dashboard/user/activities/activities-types";

const initialState = {
  pageLoading: false,
  getAllUsersMessage: "",
  getAllUsersError: "",
  getAllUsersStatus: "",
  allUsers: [],
  getUserDetailsError: "",
  getUserDetailsMessage: "",
  getUserDetailsStatus: "",
  userDetails: {},
  userActivePage: 1,
  itemPerPage: 10,

  dropDownpageLoading:false,

  allUsersDetails: [],
  putSelectedStudentError: false,
  putSelectedStudentMessage: "",
  putSelectedStudentStatus: "",
  all_records_count: 0,

  loca: "",
  qual: "",
  appl: "",
  exp: "",
  locations: [],
  qualifications: [],
  applied: [],
  experiences:[],
  currentPageNumber: 1,

  searchText: "",
  startDate:"",
  endDate:"",
  
  getDownloadCandidate_PageLoading: false,
  getDownloadCandidate_status: "",
  getDownloadCandidate_error: "",
  getDownloadCandidate_message: "",
  csvCandidate:'',

  downloadCandidate_PageLoading: false,
  downloadCandidate_status: "",
  downloadCandidate_error: "",
  getDownloadCandidate_message: "",
  csvFile:'',
  
  uploadResume_Message:"",
  uploadResume_Error:"",
  uploadResume_Status:""
  
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {

    case GET_ALL_USERS_REQUEST:
      return Object.assign({}, state, {
        pageLoading: true,
        putSelectedStudentError: false,
        putSelectedStudentMessage: "",
      });
    case GET_ALL_USERS_SUCCESS:
        return Object.assign({}, state, {
        pageLoading: false,
        getAllUsersError: action.response.data.error,
        getAllUsersMessage: action.response.data.error
          ? action.response.data.message
          : "",
        getAllUsersStatus: 200,
        allUsers: action.response.data.error
          ? []
          : action.response.data.candidates,
        all_records_count: action.response.data.error
          ? 0
          : action.response.data.total,
        putSelectedStudentError: false,
        putSelectedStudentMessage: "",
        allUsersDetails: action.response.data,
      });
    case GET_ALL_USERS_FAILURE:
      return Object.assign({}, state, {
        pageLoading: false,
        getAllUsersError: true,
        getAllUsersMessage: action.response.data.message,
        getAllUsersStatus: action.response.status,
        allUsers: [],
        allUsersDetails: [],
        all_records_count: 0,
      });

    case GET_USER_DETAILS_REQUEST:
      return Object.assign({}, state, {
        pageLoading: true,
        uploadResume_Message:"",
        uploadResume_Error:"",
       });

    case GET_USER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        pageLoading: false,
        getUserDetailsError: action.response.data.error,
        getUserDetailsMessage: action.response.data.error
          ? action.response.data.message
          : "",
        getUserDetailsStatus: 200,
        userDetails: action.response.data.error ? {} : action.response.data,
      });
    case GET_USER_DETAILS_FAILURE:
      return Object.assign({}, state, {
        pageLoading: false,
        getUserDetailsError: true,
        getUserDetailsMessage: action.response.data.message,
        getUserDetailsStatus: action.response.status,
        userDetails: {},
      });
    case PUT_SELECTED_CANDIDATE_REQUEST:
      return Object.assign({}, state, {
        pageLoading: true,
      });

    case PUT_SELECTED_CANDIDATE_SUCCESS:
      return Object.assign({}, state, {
        pageLoading: false,
        putSelectedStudentError: action.response.data.error,
        putSelectedStudentMessage: action.response.data.error
          ? ""
          : action.response.data.message,
        putSelectedStudentStatus: 200,
      });
    case PUT_SELECTED_CANDIDATE_FAILURE:
      return Object.assign({}, state, {
        pageLoading: false,
        putSelectedStudentError: true,
        putSelectedStudentMessage: action.response.data.message,
        putSelectedStudentStatus: action.response.status,
      });

    case CLEAR_ALL:
      return Object.assign({}, state, {
        getAllUsersMessage: "",
        getAllUsersError: "",
        getAllUsersStatus: "",
        allUsers: [],
        loca: "",
        qual: "",
        appl: "",
        exp:"",
        locations: [],
        qualifications: [],
        applied: [],
        experiences:[],
        startDate:"",
        endDate:"",
      });
    case NEW_PAGE_NUMBER:
      return Object.assign({}, state, {
        currentPageNumber: action.newPageNumber,
      });

    /*------------------------------------------------------------------------ candidate filter -----------------------------------------------------------*/

    case DROPDOWN_FILTER_DATA_REQUEST:
      return Object.assign({}, state, {
        dropDownpageLoading:true,
      });
    case DROPDOWN_FILTER_DATA_SUCCESS:
      return Object.assign({}, state, {
        dropDownpageLoading: false,
        locations: action.response.data.error
          ? []
          : action.response.data.location,
        qualifications: action.response.data.error
          ? []
          : action.response.data.qualification,
        applied: action.response.data.error ? [] : action.response.data.jobs,
        experiences:action.response.data.error
        ?[]
        :action.response.data.experience
      });
    case DROPDOWN_FILTER_DATA_FAILURE:
      return Object.assign({}, state, {
        dropDownpageLoading: false,
        locations: [],
        qualifications: [],
        applied: [],
        experiences:[]
      });
    case FILTER_DATA_REQUEST:
      return Object.assign({}, state, {
        pageLoading: true,
      });

    case FILTER_DATA_SUCCESS:
     return Object.assign({}, state, {
        pageLoading: false,
        locations: action.response.data.error
          ? []
          : action.response.data.location,
          qualifications: action.response.data.error
          ? []
          : action.response.data.qualification,
          applied: action.response.data.error ? [] : action.response.data.jobs,
          experiences:action.response.data.error
          ?[]
          :action.response.data.experience
      });

    case FILTER_DATA_FAILURE:
      return Object.assign({}, state, {
        pageLoading: false,
        locations: [],
        qualifications:[],
        experiences:[]
      });

    case FILTER_USER:
      return Object.assign({}, state, {
        loca: action.newState.loca,
        qual: action.newState.qual,
        appl: action.newState.appl,
        exp:  action.newState.exp,
        searchText: action.newState.searchText,
        startDate:action.newState.startDate,
        endDate:action.newState.endDate,
      });
      case CHANGE_CSV_DATA:
        return Object.assign({}, state, {
          csvCandidate:"",
      });
    
    
    case GET_DOWNLOAD_CANDIDATE_REQUEST:
      return Object.assign({}, state, {
            getDownloadCandidate_PageLoading: false,
            getDownloadCandidate_status: "",
            getDownloadCandidate_error: "",
            getDownloadCandidate_message: "",
            csvCandidate:"",
            });
        case GET_DOWNLOAD_CANDIDATE_SUCCESS:
          return Object.assign({}, state, {
              getDownloadCandidate_PageLoading: false,
              getDownloadCandidate_status: 200,
              getDownloadCandidate_error: action.response.data.error,
              getDownloadCandidate_message: action.response.data.message,
              csvCandidate:action.response.data.file,
            });
        case GET_DOWNLOAD_CANDIDATE_FAILURE:
          return Object.assign({}, state, {
              getDownloadCandidate_PageLoading: false,
              getDownloadCandidate_status: action.response.data.status,
              getDownloadCandidate_error: action.response.data.error,
              getDownloadCandidate_message: action.response.data.message,
                csvCandidate:"",
            });
            case UPLOAD_RESUME_REQUEST:
              return Object.assign({},state,{
                pageLoading:true,
                uploadResume_Message:"",
                uploadResume_Error:"",
                uploadResume_Status:""
              })
          case UPLOAD_RESUME_SUCCESS:
              return Object.assign({},state,{
                pageLoading:true,
                uploadResume_Message:action.response.data.message,
                uploadResume_Error:action.response.data.error,
                uploadResume_Status:action.response.data.status
              })
          case UPLOAD_RESUME_FAILURE:
             return Object.assign({},state,{
                pageLoading:true,
                uploadResume_Message:action.response.data.message,
                uploadResume_Error:true,
                uploadResume_Status:action.response.data.status
              })
        default:
      return state;
  }
}
