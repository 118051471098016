/*eslint-disable*/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../.././static/css/newGrid.css'
import '../.././static/css/newPrimary.css'
import "./footer.css";
import Footerbanner from '../footerbanner/footerbanner';

class Footer extends Component {
  render() {
    return (
      <div className="new-footer">
        <footer id="footer" className="footer pad-top-8">
          <Footerbanner />
          <section className="footer-bottom-section">
            <div className="container">
              <div className="footer-wrapper">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6">
                    <div className="inner-wrapper">
                      <div className="footer-logo">
                        <a href="">
                          <figure>
                            <img src={require('../../static/images/footer-logo.png')} alt="XenonStack" />
                          </figure>
                        </a>
                        <div className="footer-logo-content">
                          <p className="wb-page-desc">
                            At XenonStack, We are committed to becoming the Most
                            Value-Driven Cloud Native, Platform Engineering, and
                            Decision Driven Analytics Company. At XenonStack, We
                            have well-Defined Career Progression Plans and Skill
                            Development Program for an Individual to provide the
                            best Launch-Pad for the Career.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-3 col-md-3">
                    <div className="footer-blocks">
                      <h3 className="footer-navigation-title">Explore Us</h3>
                      <div className="footer-navigation-links">
                        <ul className="footer-links">
                          <li><a href="">About Us</a></li>
                          <li>
                            <a href="">Clients and Partners</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-3 col-md-3">
                    <div className="footer-block">
                      <h3 className="footer-navigation-title">Reach Us</h3>
                      <div className="footer-navigation-links">
                        <ul className="footer-links">
                          <li><a href="">Blogs</a></li>
                          <li><a href="">Insights</a></li>
                          <li><a href="">Contact Us</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </footer>
      </div>

    )
  }
}

export default withRouter(connect(null)(Footer))
