/*eslint-disable*/
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Select from '../../../../../components/app/select/select';
import {
    changeEducation,
    changeRegistrationForm,
    checkSignupStatus
} from '../../../../../actions/account/registration-action';
import {startEndYear} from '../../../../app/startEnd/startEndYear';
import {month} from '../../../../app/month/month';
import NextBackSection from './next-back';
import {qualification} from "../../../../app/qualification/qualification";
import {clearAll, getCollege} from "../../../../../actions/dashboard/admin/college/college";
import {checkValidation} from "../../../../../actions/app/app";
import ReactSelect from 'react-select';

const WebSocket = require('websocket').w3cwebsocket;
const ENV_VAR = process.env.REACT_APP_STATIC_PAGE === undefined ? "https://xenonstack.jobs/" : process.env.REACT_APP_STATIC_PAGE;

let ws = null;
var BASE_URL="";
class EducationSection extends Component {
    constructor(props){
        super(props);
        
    }
    componentWillMount() {
        window.scrollTo(0, 0);
        // calling the API
         

        document.title = "Registration | Education Details | XenonStack Hiring Portal";
        // TODO: RESET AGAIN vvvvv
        checkSignupStatus(this.props.goToRegistration, window.location.href);
        this.props.dispatch(clearAll());
    }

    componentWillUnmount() {
        // close the web socket connection....
        if (ws !== null && ws.onclose !== null) {
            ws.close();
        }

    }

    // this function return month number when month is selected
    checkMonthCount(checkMonth) {
        switch (checkMonth) {
            case "January":
                return 1;
                break;
            case "February":
                return 2;
                break;
            case "March":
                return 3;
                break;
            case "April":
                return 4;
                break;
            case "May":
                return 5;
                break;
            case "June":
                return 6;
                break;
            case "July":
                return 7;
                break;
            case "August":
                return 8;
                break;
            case "September":
                return 9;
                break;
            case "October":
                return 10;
                break;
            case "November":
                return 11;
                break;
            case "December":
                return 12;
                break;
            default:
                return 0;
                break;
        }
    }

    // func called when onchange event fired from html field
    onChange(e, index) {
        const self = this.props;
        const thi = this;
        const target = e.target;
        const existingEducation = self.education;
        const value = e.target.value;
        // checks the validation on the html field and add/remove the error
        checkValidation(e);
        existingEducation[index][e.target.name] = e.target.value;

        // calls action to change registration form

        let education = self.registrationForm.education;
        self.dispatch(changeRegistrationForm(Object.assign(self.registrationForm, {education: education})));
    }


    handleCollegeChange(e, index){
        console.log(e,index,'handlecollegechange')
      if(e !== null){console.log(' inside if')
       const existingEducation = this.props.education;
       existingEducation[index]['school'] = e.label;
       existingEducation[index]['school_name'] = e.label;
       let education = this.props.registrationForm.education;
       this.props.dispatch(changeRegistrationForm(Object.assign(this.props.registrationForm, {education: education})));
     }
     else{
       
     }
     const education = this.props.registrationForm.education;
     
     for (let i in education) {
         let collegeFromGroup = document.querySelectorAll('.collegeFormGroup');
         if(education[i]["school"] !== ""){
            
         collegeFromGroup[i].classList.remove('has-error');
         } else {
          
         collegeFromGroup[i].classList.add('has-error');
         }
     }
    }
    handleInputChange(inputValue){
      if(inputValue.length >= 3){
        this.props.dispatch(getCollege(1,inputValue));
      }
    }
    customFilter() {
      return true;
    }

    addEducation() {
        const existingEducation = this.props.education;
        existingEducation.push({
            school: "",
            loading: false,
            schools: [],
            highest_education: "",
            start: "",
            startD: "",
            startY: "",
            end: "",
            endD: "",
            endY: "",
            current: false
        });
        this.props.dispatch(changeEducation(existingEducation))
    }

    // submit's the form
    submitEducationForm(e) {
        console.log('submit form')
        const self = this.props;
        e.preventDefault();
        let dateError = false;
        let collegeError = false;
        const education = self.education;
        for (let i in education) {
           
            if (education[i]["startY"] !== "" && education[i]["endY"] !== "" && education[i]["startD"] !== "" && education[i]["endD"] !== "") {
                if ((Number(education[i]["startY"]) <= Number(education[i]["endY"]))) {

                    if (Number(education[i]["startY"]) === Number(education[i]["endY"])) {
                        let startMonth, endMonth;

                        startMonth = this.checkMonthCount(education[i]["startD"]);
                        endMonth = this.checkMonthCount(education[i]["endD"]);
                        if (Number(startMonth) < Number(endMonth)) {
                            dateError = false;
                            document.getElementsByClassName('date-error')[i].style.display="none";
                        } else {
                            dateError = true
                            document.getElementsByClassName('date-error')[i].style.display="block";
                        }
                    } else {
                        // dateError = false
                        document.getElementsByClassName('date-error')[i].style.display="none";
                    }
                } else {
                        dateError = true;
                        document.getElementsByClassName('date-error')[i].style.display="block";
                }
            } else {
                    dateError = true;
            }

          let collegeFromGroup = document.querySelectorAll('.collegeFormGroup');
          if(education[i]["school"] !== ""){
              collegeError = false
             collegeFromGroup[i].classList.remove('has-error');
          } else {
            collegeError = true
            collegeFromGroup[i].classList.add('has-error');
          }
        }

        if (e.target.checkValidity() && dateError === false && collegeError === false) {
            this.props.history.push("/apply-manual/experience");
        } else {
            const invalidSelect = document.querySelectorAll("#select");
            for (let i = 0; i < invalidSelect.length; i++) {
                if (invalidSelect[i].value === "") {
                    if (invalidSelect[i].value === "") {
                        invalidSelect[i].parentElement.classList.add("has-error");
                        invalidSelect[i].parentElement.classList.add("personal-select-with-error");
                        invalidSelect[i].parentElement.classList.remove("personal-select-without-error")
                    } else {
                        invalidSelect[i].parentElement.classList.remove("has-error");
                        invalidSelect[i].parentElement.classList.remove("personal-select-with-error");
                        invalidSelect[i].parentElement.classList.add("personal-select-without-error")
                    }
                }
            }
            
            const invalidElmsInput = document.querySelectorAll(".education-form .form-group input:invalid");
            for (let i = 0; i < invalidElmsInput.length; i++) {
                invalidElmsInput[i].parentElement.classList.add("has-error")
            }

        }
    }

    // for removing the education
    removeEducation(i) {
        const registrationForm = this.props.registrationForm;
        const education = registrationForm.education;
        education.splice(i, 1);
        const newRegistrationForm = Object.assign(registrationForm, {education});
        this.props.dispatch(changeRegistrationForm(newRegistrationForm));
    }

    render() {
        const onKeyDown = e => {
            // catch the code of the key pressed
            if (e.keyCode === 13) {
                e.preventDefault()
                return;
            }
          };
        window.onbeforeunload = function () {
            return "Do you really want to leave our brilliant application?";
        };

        let collegeListUpdated= [];
        if(this.props.colleges.length > 0){
          let CollegeList = this.props.colleges;
          for(let i =0; i < CollegeList.length; i++){
            collegeListUpdated.push({"label": CollegeList[i].name +','+ CollegeList[i].location, "value": CollegeList[i].id})
          }
        }
        const style={
            control:base=>({
                ...base,
                border:0,
                boxShadow:"0 0 1px",
              })
        }
        return (
            <div className="wrapper-container signing-container education">
               <div className='container  user-jounrney-form-container'>
               <div className='user-journey-form user-journey-form-internal-steps'>
               <div className="xenonstack-jobs-logo">
                            <a href={ENV_VAR}> <img src={require('../../../../../static/images/logoxe.svg')} /></a>
                        </div>
               <div className='dashbaord-user-form'>
               <div className="section_header">
               <label>Step 2/5</label>
                    <h3 className="big-heading">Education</h3>
                </div>
                <div className="education" id="education">
                    <form className="education-form" onSubmit={this.submitEducationForm.bind(this)}
                    noValidate={true}>
                    {
                        this.props.education.map((d, i) => (
                            <div className={i === 0 ? "" : "row-wrapper"}>
                                <div key={i} className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group select-wrapper personal-select-without-error">
                                            <label> {i === 0 ? "Highest Qualification" : "Other Qualification"}
                                                <span className="req">*</span></label>
                                            <Select name="highest_education"
                                                id="select"
                                                required={true}
                                                placeholder={i === 0 ? "Highest Qualification" : "Other Qualification"}
                                                onChange={(e) => this.onChange(e, i)}
                                                value={d.highest_education} className="form-ctrl">
                                                <option selected disabled value="">
                                                {i === 0 ? "Highest Qualification" : "Other Qualification"}</option>
                                                {
                                                    qualification.map((d,index) => (
                                                        <option value={d} key={index}>{d}</option>
                                                    ))
                                                }
                                                <option value="not mentioned">Not Mentioned</option>
                                            </Select>
                                            <p className="with-error">Please provide your education qualification.</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        {/* TODO: Remove WebSocket */}
                                        <div className="form-group collegeFormGroup">
                                            <label>College/University<span className="req">*</span></label>
                                                {/*  <Select name="school"
                                                    id="collegeList"
                                                    required={true}>
                                            </Select>*/}
                                            <ReactSelect
                                                options={collegeListUpdated}
                                                placeholder="Search for College/University"
                                                onChange={ (e) => this.handleCollegeChange(e, i)}
                                                isSearchable={true}
                                                isLoading={this.props.getCollegePageLoading}
                                                name="school"
                                                id="collegeList"
                                                required={true}
                                                styles={style}
                                                
                                                filterOption={this.customFilter.bind(this)}
                                                onInputChange={this.handleInputChange.bind(this)}
                                                classNamePrefix="xenon"
                                                onKeyDown={onKeyDown}
                                                />
                                                <p className="with-error">Please enter college/university.</p>
                                            {/*  <label className="form-group">College/University
                                                <span
                                                    className="req">*</span>
                                                <input list={"browsers"+i}
                                                        id="college"
                                                        minLength={3}
                                                        pattern={"([a-zA-Z.!-”$%&’()*\\+,\\/;\\[\\\\\\]\\^_`{|}~]+\\s)*[a-zA-Z.!-”$%&’()*\\+,\\/;\\[\\\\\\]\\^_`{|}~]+$"}
                                                        placeholder={"College/University"}
                                                        className="form-ctrl" autoComplete="off"
                                                        name="school" required={true}
                                                        onChange={(e) => this.onChange(e, i)}
                                                        value={d.school}/>
                                                <datalist id={"browsers"+i}></datalist>
                                                <BarLoaderSpinner
                                                    pageLoading={d.loading}/>
                                                <p
                                                    className="with-error">Please enter
                                                    college/university (Min 3 characters required).</p>
                                            </label>*/}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-6 mar-xs-30">
                                                <div
                                                    className="form-group select-wrapper personal-select-without-error">
                                                    <label>Start Month <span
                                                        className="req">*</span></label>
                                                    <Select id="select" name="startD"
                                                            required={true}
                                                            placeholder="Start Month"
                                                            onChange={(e) => this.onChange(e, i)}
                                                            value={d.startD}
                                                            className={"form-ctrl education_select" + i}>
                                                        <option selected disabled value="">Start Month
                                                        </option>
                                                        {
                                                            d.startY !== ''+(new Date()).getFullYear() ?
                                                                month.map((d, i) => (
                                                                    <option key={i}
                                                                        value={d}>{d}</option>
                                                                )) :
                                                                month
                                                                    .filter((data, i) => i <= (new Date()).getMonth())
                                                                    .map((d, i) => (
                                                                        <option key={i}
                                                                            value={d}>{d}</option>
                                                                    ))
                                                        }
                                                    </Select>
                                                    <p className="with-error">Select valid start month</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-6 mar-xs-30">
                                                <div
                                                    className="form-group select-wrapper personal-select-without-error select">
                                                    <label>Start Year <span
                                                        className="req">*</span></label>
                                                    <Select id="select" name="startY"
                                                            className={"form-ctrl education_select" + i}
                                                            placeholder="Start Year"
                                                            required={true}
                                                            onChange={(e) => this.onChange(e, i)}
                                                            value={d.startY}>
                                                        <option selected disabled value="">Start Year</option>
                                                        {
                                                            startEndYear
                                                                .filter((data) => data <= (new Date()).getFullYear())
                                                                .map((d, i) => (
                                                                <option value={d} key={i}>{d}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                    <p className="with-error">Select valid start year.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-6 mar-xs-30">
                                                <div
                                                    className="form-group select-wrapper personal-select-without-error">
                                                    <label>End Month <span
                                                        className="req">*</span></label>
                                                    <Select id="select" name="endD"
                                                            required={true}
                                                            placeholder="End Month"
                                                            onChange={(e) => this.onChange(e, i)}
                                                            value={d.endD}
                                                            className={"form-ctrl education_select" + i}>
                                                        <option selected disabled value="">End Month</option>
                                                        {
                                                            d.startY === d.endY && d.startD !== '' ?
                                                                month
                                                                    .filter((dt, i) => i >= month.indexOf(d.startD))
                                                                    .map((d, i) => (
                                                                        <option key={i}
                                                                            value={d}>{d}</option>
                                                                    )) :
                                                                month
                                                                    .map((d, i) => (
                                                                        <option key={i}
                                                                            value={d}>{d}</option>
                                                                    ))
                                                        }
                                                    </Select>
                                                    <p className="with-error">Select valid end month.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-6 mar-xs-30">
                                                <div
                                                    className="form-group select-wrapper personal-select-without-error">
                                                    <label>End Year <span
                                                        className="req">*</span></label>
                                                    <Select id="select"
                                                            className={"form-ctrl education_select" + i}
                                                            name="endY"
                                                            placeholder="End Year"
                                                            required={true}
                                                            onChange={(e) => this.onChange(e, i)}
                                                            value={d.endY}>
                                                        <option selected disabled value="">End Year
                                                        </option>
                                                        {
                                                            d.startY === "" ?
                                                                startEndYear.map((d, i) => (
                                                                    <option value={d} key={i}>{d}</option>
                                                                ))
                                                                :
                                                                startEndYear
                                                                    .filter((data) => data >= d.startY)
                                                                    .map((de, i) => (
                                                                    <option value={de} key={i}>{de} </option>
                                                                ))
                                                        }
                                                    </Select>
                                                    <p className="with-error">Select valid end year.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div  className="date-error">
                                        End Month and End Year should be greater than Start Month and Start Year.</div> 

                                    </div>
                                </div>
                                {
                                    (i !== 0) && (
                                        <div className="cross-btn">
                                                <span onClick={() => this.removeEducation(i)}><i
                                                    className="material-icons">clear</i></span>
                                        </div>)
                                }
                            </div>
                            ))
                        }
                        <div className="row">
                            <div className="col-sm-12">
                                <button type="button" style={{cursor: "pointer"}}
                                    onClick={this.addEducation.bind(this)}
                                    tabIndex={0} className="btn add-btn"><span>+</span> ADD EDUCATION</button>
                            </div>
                        </div>
                        <NextBackSection>
                        {this.props.children}
                    </NextBackSection>
                    </form>
                </div>
               </div>
               <div className="xenonstack-jobs-copyright">
                            <p>Xenonstack | </p>
                            <i class="fa fa-copyright"></i>
                            <p>2021 All Rights Reserved</p>
                        </div>
               </div>
               </div>
            </div> 
        )
    }
}

function mapStateToProps(state) {
    const {registrationForm, goToRegistration} = state.RegisterReducer;
    const {colleges, getCollegePageLoading} = state.adminCollegeReducer;
    const {education} = registrationForm;
    return {education, registrationForm, goToRegistration, colleges,getCollegePageLoading}
}

export default withRouter(connect(mapStateToProps)(EducationSection))
