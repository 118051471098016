import React, { Component } from 'react';
import './newGrid.css'
import './newheader.css'
import './newPrimary.css'
import logo from '../../static/images/logoxe.svg';
import search from '../../static/images/search.svg';
import cross from '../../static/images/cross-image.svg';

const ENV_VAR = process.env.REACT_APP_STATIC_PAGE === undefined ? "https://xenonstack.jobs/" : process.env.REACT_APP_STATIC_PAGE;
const BASE_URL = process.env.REACT_APP_AUTH_API;

let dropdown;
let countSearch = 0;
var header = document.getElementById("headerblock");
let hamBurger = document.querySelector('.xenonstack-header');
let headerId = document.getElementById("headerblock");
let widthOutput = document.body.offsetWidth;



class Header extends Component {
  constructor(props) {
    super(props)
    this.showsearch = this.showsearch.bind(this)
    this.search = this.search.bind(this)
    this.removeSearch = this.removeSearch.bind(this)
    this.addRemoveClass = this.addRemoveClass.bind(this)
  }


  showMenu() {



    document.querySelectorAll(".xenonstack-internal-content").forEach((el, i) => {
      if (i > 0) {
        if ("" === el.style.display || "none" === el.style.display) {
          el.style.display = "block"
          document.querySelector(".xenonstack-header").style.display =
            "block"
          header.style.backgroundColor = "white"


        }
        else {
          el.style.display = ""
          document.querySelector(".xenonstack-header").style.display = ""
          header.style.backgroundColor = "#fff"


        }
      }
    })
    this.removeSearch()
  }


  showNavMenu(e) {
    let subMenuDropdown = document.getElementById(e).parentElement.children[1];
    if (subMenuDropdown) {
      if (subMenuDropdown.classList.contains("hide-div")) {
        subMenuDropdown.classList.remove("hide-div")
        subMenuDropdown.classList.add("show-div")
      }
      else {
        subMenuDropdown.classList.remove("show-div")
        subMenuDropdown.classList.add("hide-div")
      }

    }
  }

  // // HEADER CODE JS

  xenonstackNavLinkMouseOver() {
    document.getElementById('search-image').style.display = "block";
    document.getElementById('cross-search-image').style.display = "none";

    dropdown = document.querySelector('.xenonstack-header-dropdown-1').classList.remove('xenonstack-header-dropdown-show');
    if (widthOutput > 1024 && header) {
      header.style.backgroundColor = "#fff";
    }

  }
  xenonstackNavLinkMouseOut() {
    if (widthOutput > 1024 && header) {
      header.style.backgroundColor = "#fff";
    }
  }
  applicantResourcesNavLinkMouseOver() {

    document.getElementById('search-image').style.display = "block";
    document.getElementById('cross-search-image').style.display = "none";

    dropdown = document.querySelector('.xenonstack-header-dropdown-1').classList.remove('xenonstack-header-dropdown-show');
    if (widthOutput > 1024 && header) {
      header.style.backgroundColor = "#fff";
    }

  }

  applicantResourcesNavLinkMouseOut() {
    if (widthOutput > 1024 && header) {
      header.style.backgroundColor = "#fff";
    }

  }



  isShowSearch() {
    document.querySelector('.xenonstack-header-dropdown-1').classList.toggle('xenonstack-header-dropdown-show');
  }

  showsearch(e) {
    let searchImage = document.getElementById('search-image');
    let crossImage = document.getElementById('cross-search-image');
    if (countSearch == 0) {
      countSearch = 1;
      searchImage.style.display = searchImage.style.display == 'block' ? 'none' : 'block';
      crossImage.style.display = crossImage.style.display == 'none' ? 'block' : 'none';
    }
    else if (countSearch == 1) {
      let ul = document.querySelector(".hs-search-field__suggestions");
      while (ul.firstChild) {
        ul.removeChild(ul.firstChild);
      }
      document.getElementsByClassName('search-job')[0].value = ''
      countSearch = 0;
      searchImage.style.display = searchImage.style.display == 'none' ? 'block' : 'none';
      crossImage.style.display = crossImage.style.display == 'block' ? 'none' : 'block';
    }

    if (hamBurger && hamBurger.style.display == '') {
      headerId.style.backgroundColor = headerId.style.backgroundColor == 'white' ? e : 'white';
    }
    if (headerId && window.innerWidth > 1024) {
      headerId.style.backgroundColor = headerId.style.backgroundColor == 'white!imporatant' ? e : 'white!imporatant';
    }

    if (document.querySelector('.xenonstack-header-dropdown-1')) {
      document.querySelector('.xenonstack-header-dropdown-1').classList.toggle('xenonstack-header-dropdown-show');
    }
  }

  showMobileSearch(e) {
    let countMobileSearch = 0;
    let searchImageMobile = document.getElementById('search-image-mobile');
    let crossImageMobile = document.getElementById('cross-search-image-mobile');
    if (searchImageMobile && crossImageMobile && countMobileSearch === 0) {
      countMobileSearch = 1;
      searchImageMobile.style.display = searchImageMobile.style.display === 'block' ? 'none' : 'block';
      crossImageMobile.style.display = searchImageMobile.style.display === 'none' ? 'block' : 'none';
    }
    else if (countMobileSearch === 1) {
      let ul = document.querySelector(".hs-search-field__suggestions");
      while (ul.firstChild) {
        ul.removeChild(ul.firstChild);
      }
      document.getElementsByClassName('search-job')[0].value = ''
      countMobileSearch = 0;
      searchImageMobile.style.display = searchImageMobile.style.display === 'none' ? 'block' : 'none';
      crossImageMobile.style.display = searchImageMobile.style.display === 'block' ? 'none' : 'block';
    }
    if (hamBurger && hamBurger.style.display == '') {
      headerId.style.backgroundColor = headerId.style.backgroundColor === 'white' ? e : 'white';
    }
    if (window.innerWidth < 1024) {
      let headerDropdown = document.querySelector('.xenonstack-header');
      if (headerDropdown) {
        var sectionwrapper = document.getElementById("main-body");
        headerDropdown.style.display = headerDropdown.style.display === 'block' ? '' : '';
        sectionwrapper.style.display = sectionwrapper.style.display === 'none' ? 'block' : 'block';
      }
      if (document.querySelector('.xenonstack-header-dropdown-1')) {
        document.querySelector('.xenonstack-header-dropdown-1').classList.toggle('xenonstack-header-dropdown-show');
      }
    }

  }


  removeSearch() {
    document.getElementById('search-image-mobile').style.display = "block";
    document.getElementById('cross-search-image-mobile').style.display = "none";
    dropdown = document.querySelector('.xenonstack-header-dropdown-1').classList.remove('xenonstack-header-dropdown-show');
  }
  addRemoveClass() {
    if (window.pageYOffset >= 100 && document.getElementById("headerblock")) {
      document.getElementById("headerblock").classList.add("headershadow");
    } else {
      document.getElementById("headerblock").classList.remove("headershadow");
    }
  }
  //on enter search

  searching(e) {
    if (e.key === "Enter") {  //checks whether the pressed key is "Enter"
      this.search()
    }
  }


  serachSuggestion() {
    const search = document.getElementsByClassName('search-job')[0].value
    const url = `${BASE_URL}/v1/job?search=${search ? search : ''}`;
    var request = new XMLHttpRequest();
    request.open('GET', (url), true);
    request.onload = function () {
      var resultText = JSON.parse(this.response);
      if (request.status == 200 && resultText.error == false) {
        var ul = document.querySelector(".hs-search-field__suggestions");
        while (ul.firstChild) {
          ul.removeChild(ul.firstChild);
        }
        var temp = document.createElement(`li`);
        temp.innerHTML = `<li id="results-for">Results for "${search}"</li>`;
        ul.appendChild(temp);

        if (resultText && resultText.list) {
          resultText.list.splice(0, 5).forEach((dat, index) => {
            temp.innerHTML = `<a href=${ENV_VAR}jobs/${dat.id} > ${dat.name} </a>`;
            ul.appendChild(temp);
          })
        }

      }
    }
    request.send();
  }
  search() {
    const data = document.getElementsByClassName('search-job')[0].value;
    const urlData = "{{.PortalLink}}";
    window.location = `${ENV_VAR}jobs?search=${data}`;
  }

  componentDidMount() {
    window.addEventListener("scroll", (event) => {
      if (document.getElementById("headerblock")) {
        if (document.getElementById("headerblock").style.display !== 'block') {
          this.addRemoveClass();
        }
      }
    }

    );
  }


  render() {
    return (
      <div className="new-header">
        <header className="header-wrapper teams-header" id="headerblock">
          <div className="container">
            <div className="mobile-header-block">
              <div className="mobile-header-inner">
                <div className="menu-icon">

                  <img src={require('../../static/images/hamburger.svg')} className="xenonstack-hamburger" alt="Xenonstack Hamburger"
                    onClick={this.showMenu.bind(this)}
                  />

                </div>
                <a href="/">
                  <img src={logo} width="200" height="50" alt="Xenonstack Logo" />
                </a>

                <div className="mobile-search" onClick={this.showMobileSearch.bind(this)}>
                  <img src={search} alt="" id="search-image-mobile" style={{ display: 'block' }} />
                  <img src={cross} alt="" id="cross-search-image-mobile" style={{ display: 'none' }} />
                </div>
              </div>
            </div>

            {/* <!-- Search dropdown for mobile Enc --> */}

            {/* <!-- header search dropdown common --> */}
            <div className="xenonstack-header-dropdown-1">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="search-inside">
                      <div className="banner-job-search-form">
                        <input type="text" className="search-job" placeholder="Search" on name="search" onChange={this.serachSuggestion.bind(this)} onKeyPress={this.searching.bind(this)} />

                        <button className="searchbar" onClick={this.search}>
                          <img src={require('../../static/images/Icon_Search.svg')} alt=" search icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <ul className="hs-search-field__suggestions">

                    </ul>
                  </div>
                </div>
                <div className="xenonstack-recommends-wrapper">
                  <h4>Xenonstack Recommends</h4>
                  <div className="row">
                    <div className="col-6">
                      <ul>
                        <li>
                          <img src={require('../../static/images/benefits-and-perks.svg')} loading="lazy"
                            className="" alt="" width="44" height="44" />
                          <a href="/blog/">
                            <p>Benefits & Perks</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul>
                        <li>
                          <img src={require('../../static/images/latest-job-opening.svg')} loading="lazy"
                            className="" alt="" width="44" height="44" />
                          <a href="/neural-company-enablers/">
                            <p>Latest Job Openings</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- header search dropdown end common--> */}

            {/* <!-- Mobile Header End --> */}

            <nav className="xenonstack-header">
              <div className="xenonstack-header-right">
                <ul className="xenonstack-header-content">
                  <li className="xenonstack-internal-contents">
                    <a href={ENV_VAR} > <img src={logo} className="xenonstack-logo" alt="Xenonstack Logo" /></a>
                  </li>

                  <li className="xenonstack-internal-content">
                    {/* <!-- Mobile Header --> */}
                    <div className="mobile-header-heading no-drop-down">
                      <div className="mobile-header-heading-inside">
                        <span className="xenonstack-mobile-heading ">
                          <a href={ENV_VAR + "teams/"} > Area of work </a></span>
                      </div>
                    </div>
                  </li>

                  <li className="xenonstack-internal-content">
                    {/* <!-- Mobile Header --> */}

                    <div className="mobile-header-heading no-drop-down">
                      <div className="mobile-header-heading-inside">
                        <span className="xenonstack-mobile-heading ">
                          <a href={ENV_VAR + "students-and-graduates/"} > Career</a>
                        </span>
                      </div>
                    </div>
                  </li>

                  <li className="xenonstack-internal-content" id="xenonstack-life" onMouseOver={this.xenonstackNavLinkMouseOver.bind(this)} onMouseOut={this.xenonstackNavLinkMouseOut.bind(this)}>
                    {/* <!-- Mobile Header --> */}
                    <div className="mobile-header-heading">

                      <div className="mobile-header-heading-inside" id="industries" onClick={() => this.showNavMenu('industries')}>
                        {/* onClick={() => sayHello('James')} */}
                        <span className="xenonstack-mobile-heading">XenonStack Life</span>
                        <strong><img src={require('../../static/images/desktop-dropdown.svg')} className="desktop-drop-down-img"
                          alt="drop-down-imgs" /></strong>
                        <img src={require('../../static/images/xenonstack-downarrow.svg')} className="xenonstack-down-arrow"
                          alt="Xenonstack Down Arrow" />
                      </div>
                      <div className="mobile-navigation hide-div">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <ul>
                              <li>
                                <div className="xenonstack-life-1">
                                  <a href={ENV_VAR + "xenonstack-culture/"} >
                                    <div className="heading-wrapper">
                                      <div className="xenonstck-heading-img">
                                        <img src={require('../../static/images/Our-Culture-Code.webp')} alt="culture image" />
                                      </div>
                                      <div className="
                                            xenonstack-heading-inner-details-texts
                                            mb-view
                                          ">
                                        <p>Our culture code</p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <ul>
                              <li>
                                <a href={ENV_VAR + "xenonstack-life/benefits/"} >
                                  <div className="xenonstack-life-2">
                                    <div className="heading-wrapper">
                                      <div className="xenonstck-heading-img">
                                        <img src={require('../../static/images/Benefits-and-pirks.svg')} alt=" benefits&perks img" />
                                      </div>
                                      <div className="
                                            xenonstack-heading-inner-details-texts
                                            mb-view
                                          ">
                                        <p>Benefits and Perks</p>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <ul>
                              <li>
                                <div className="xenonstack-life-3">
                                  <a href={ENV_VAR + "xenonstack-life/women-in-tech//"}>
                                    <div className="heading-wrapper">
                                      <div className="xenonstck-heading-img">
                                        <img src={require('../../static/images/Women-in-tech.webp')} alt="Women-in-tech img" />
                                      </div>
                                      <div className="
                                            xenonstack-heading-inner-details-texts
                                            mb-view
                                          ">
                                        <p>Woman in Tech</p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <ul>
                              <li>
                                <div className="xenonstack-life-4">
                                  <a href="https://www.xenonstack.com/blog">
                                    <div className="heading-wrapper">
                                      <div className="xenonstck-heading-img">
                                        <img src={require('../../static/images/Read Blogs.svg')} alt="Read Blogs images" />
                                      </div>
                                      <div className="
                                            xenonstack-heading-inner-details-texts
                                            sub-part
                                          ">
                                        <p>Xenonstack Blogs</p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <ul>
                              <li>
                                <div className="xenonstack-life-5">
                                  <a href={ENV_VAR + "life-at-xenonstack/"}>
                                    <div className="heading-wrapper">
                                      <div className="xenonstck-heading-img">
                                        <img src={require('../../static/images/life-at-xenonstack-51.webp')}
                                          alt="Life at XenonStack image" />
                                      </div>

                                      <div className="
                                            xenonstack-heading-inner-details-texts
                                            sub-part
                                          ">
                                        <p>Life at XenonStack</p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Mobile Header End --> */}
                    <div className="xenonstack-header-dropdown">
                      <div className="container">
                        <div className="xenonstack-header-inner">
                          <div className="row no-gutters xenonstack-industries">
                            <div className="col-6">
                              <div className="xenonstack-heading-inner-wrapper">
                                <div className="xenonstack-heading-inner-details">
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="xenonstack-heading-inner-details-inside">
                                        <ul>
                                          <a href={ENV_VAR + "xenonstack-culture/"}>
                                            <li>
                                              <div className="xenonstack-desktop-view-imgs">
                                                <img src={require('../../static/images/Our-Culture-Code.webp')}
                                                  alt="Our-Culture-Code image" />
                                              </div>
                                              <div className="xenonstack-heading-inner-details-texts">
                                                <h4 className="desktop-head">
                                                  Our Culture Code
                                                </h4>
                                              </div>
                                            </li>
                                          </a>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="xenonstack-heading-inner-details-inside">
                                        <ul>
                                          <a href={ENV_VAR + "xenonstack-life/benefits/"}>
                                            <li className="mid">
                                              <div className="
                                                  xenonstack-desktop-view-imgs
                                                  benefits
                                                ">
                                                <img src={require('../../static/images/Benefits-and-pirks.svg')}
                                                  alt="  Benefits & Perks image" />
                                              </div>
                                              <div className="
                                                  xenonstack-heading-inner-details-texts
                                                ">

                                                <h4 className="benefits-perks">
                                                  Benefits & Perks
                                                </h4>
                                              </div>
                                            </li>
                                          </a>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="xenonstack-heading-inner-details-inside">
                                        <ul>
                                          <a href={ENV_VAR + "xenonstack-life/women-in-tech//"}>
                                            <li>
                                              <div className="xenonstack-desktop-view-imgs">
                                                <img src={require('../../static/images/Women-in-tech.webp')}
                                                  alt="    Women In Tech image" />
                                              </div>
                                              <div className="
                                                  xenonstack-heading-inner-details-texts
                                                ">
                                                <h4 className="desktop-head">
                                                  Women In Tech
                                                </h4>
                                              </div>
                                            </li>
                                          </a>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="xenonstack-heading-inner-wrapper">
                                <div className="xenonstack-heading-inner-detail">
                                  <a href={ENV_VAR + "life-at-xenonstack/"}>
                                    <div className="xenonstack-desktop-view-imgs">
                                      <img src={require('../../static/images/life-at-xenonstack.webp')} alt="life-at-xenonstack" />
                                    </div>
                                    <div className="xenonstack-heading-inner-details-texts
                                        header-part">
                                      <p>
                                        A Playground to <br /> experiment with the new
                                        technologies.
                                      </p>
                                      <h4 className="header-sub-part">
                                        Life at XenonStack
                                      </h4>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="xenonstack-heading-inner-wrapper">
                                <div className="
                                      xenonstack-heading-inner-detail
                                      blog-post
                                      read">
                                  <a href="https://www.xenonstack.com/blog">
                                    <div className="xenonstack-desktop-view-imgs">
                                      <img src={require('../../static/images/Read-Blogs.svg')} alt="Read-Blogs image" />
                                    </div>
                                    <div className="xenonstack-heading-inner-details-texts
                                        header-part">
                                      <p>XenonStack Blogs</p>
                                      <h4 className="header-sub-part">
                                        Read Blogs
                                      </h4>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </li>

                  <li className="xenonstack-internal-content" id="applicant-resources" onMouseOut={this.applicantResourcesNavLinkMouseOut.bind(this)} onMouseOver={this.applicantResourcesNavLinkMouseOver.bind(this)}>
                    {/* <!-- Mobile Header --> */}
                    <div className="mobile-header-heading">

                      <div className="mobile-header-heading-inside" id="company" onClick={() => this.showNavMenu('company')}>
                        <span className="xenonstack-mobile-heading">Applicant Resources</span>
                        <strong><img src={require('../../static/images/desktop-dropdown.svg')} className="desktop-drop-down-imgs"
                          alt="drop-down-imgs" /></strong>
                        <img src={require('../../static/images/xenonstack-downarrow.svg')} className="xenonstack-down-arrow"
                          alt="Xenonstack Down Arrow" />
                      </div>
                      <div className="mobile-navigation hide-div">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <ul>
                              <li>
                                <div className="xenonstack-life-2-phn">
                                  <a href={ENV_VAR + "xenonstack-life/how-we-hire/"}>
                                    <div className="heading-wrapper">
                                      <div className="xenonstck-heading-img">
                                        <img src={require('../../static/images/how-we-hire-phn.webp')}
                                          alt="XenonStack Hiring Process image" />
                                      </div>
                                      <div className="
                                            xenonstack-heading-inner-details-texts
                                          ">
                                        <p>XenonStack Hiring Process</p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <ul>
                              <li>
                                <div className="xenonstack-life-1-phn">
                                  <a href={ENV_VAR + "faq/"}>
                                    <div className="heading-wrapper">
                                      <div className="xenonstck-heading-img">
                                        <img src={require('../../static/images/FaQs.svg')} alt=" FAQ img" />
                                      </div>
                                      <div className="
                                            xenonstack-heading-inner-details-texts
                                          ">
                                        <p>Frequently Asked Questions</p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Mobile Header End --> */}
                    <div className="xenonstack-header-dropdown">
                      <div className="container">
                        <div className="xenonstack-header-inner">
                          <div className="row no-gutters">
                            <div className="col-6">
                              <div className="xenonstack-heading-inner-wrapper-company">
                                <div className="xenonstack-heading-inner-details">
                                  <div className="xenonstack-heading-inner-details-inside">
                                    <ul>
                                      <li>
                                        <div className="xenonstack-deskyop-view-img">
                                          <img src={require('../../static/images/how-we-hire-new.webp')}
                                            alt="XenonStack Hiring Process img" />
                                        </div>
                                        <div className="
                                              xenonstack-heading-inner-details-text
                                              blog-post
                                              question
                                            ">
                                          <p>XenonStack Hiring Process</p>

                                          <div className="inner-head">
                                            <a href={ENV_VAR + "xenonstack-life/how-we-hire/"}>
                                              <h4>How We Hire</h4>
                                            </a>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <a href={ENV_VAR + "faq/"}>
                                <div className="xenonstack-heading-inner-wrapper-company">
                                  <div className="xenonstack-heading-inner-details">
                                    <div className="row">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="
                                            xenonstack-heading-inner-details-inside
                                          ">
                                          <ul>
                                            <li className="mid">
                                              <div className="
                                                  xenonstack-deskyop-view-img
                                                  faq
                                                ">
                                                <img src={require('../../static/images/FaQs.svg')} alt="FAQ img" />
                                              </div>
                                              <div className="
                                                  xenonstack-heading-inner-details-text
                                                  blog-post
                                                  question
                                                ">
                                                <p>Frequently Asked Questions</p>

                                                <div className="inner-head">

                                                  <h4>Read Blogs</h4>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Mobile Header --> */}
                  </li>

                  <li className="xenonstack-header-button" id="login-link">
                    <div className="checkout-button xenonstack-header-btn">
                      <a href={ENV_VAR + "app/login/"} className="xenonstack-contact-us-button">
                        <span>Log In</span>
                      </a>
                    </div>
                  </li>

                  <li className="header-search" onClick={this.showsearch}>
                    <img src={search} alt="" id="search-image" style={{ display: 'block' }} />
                    <img src={cross} alt="" id="cross-search-image" style={{ display: 'none' }} />
                  </li>

                  <li className="xenonstack-header-buttons" id="login-link-two">
                    <div className="checkout-button xenonstack-header-btn">
                      <a href="#" className="xenonstack-contact-us-buttons"> Log In </a>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </div>
    )
  }
}
function mapStateToProps(state) {

  const { auth, logoutMe } = state.LoginReducer;
  const { goToRegistration } = state.RegisterReducer;
  const { isAuthenticated, sys_role } = auth;
  return { isAuthenticated, goToRegistration, state, sys_role, logoutMe }
}


export default Header;
