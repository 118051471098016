import {
    ACTIVITIES_FAILURE,
    ACTIVITIES_REQUEST,
    ACTIVITIES_SUCCESS,
    CLEAR_ALL,GET_USER_RESULT_REQUEST,GET_USER_RESULT_SUCCESS,GET_USER_RESULT_FAILURE,
   
    
} from "../../../types/dashboard/user/activities/activities-types";

const initialState = {
    pageLoading:false,
    activities: [],
    activitiesError: "",
    activitiesMessage: "",
    activitiesStatus: "",
    activitiesPageLoading: false,

    result:[],
    resultPageLoading:'',
    resultStatus:'',
    resultError:'',
    resultMessage:'',
    
  
};

export default function activitiesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIVITIES_REQUEST:
            return Object.assign({}, state, {
                activitiesPageLoading: true
            });
        case ACTIVITIES_SUCCESS:
            return Object.assign({}, state, {
                activitiesPageLoading: false,
                activitiesStatus: 200,
                activitiesError: action.response.data.error,
                activitiesMessage: action.response.data.error ? action.response.data.message : "",
                activities: action.response.data.error ? [] : action.response.data.jobs
            });
        case ACTIVITIES_FAILURE:
            return Object.assign({}, state, {
                activitiesPageLoading: false,
                activitiesStatus: action.response.status,
                activitiesError: true,
                activitiesMessage: action.response.data.message,
                activities: []
            });
            
        case CLEAR_ALL:
            return Object.assign({}, state, {
                activitiesStatus: "",
                activitiesError: "",
                activitiesMessage: "",
                activities: []
            });
        case GET_USER_RESULT_REQUEST:
        return Object.assign({}, state, {
                result:[],
                resultPageLoading:true,
                resultStatus:'',
                resultError:'',
                resultMessage:'',
                uploadResume_Error:"",
                uploadResume_Message:"",
        });
        case GET_USER_RESULT_SUCCESS:
         return Object.assign({}, state, {
            result:action.response.data.error ? []:action.response.data.list,
            resultPageLoading:false,
            resultStatus:200,
            resultError:action.response.data.error,
            resultMessage:action.response.data.message,
        });
        case GET_USER_RESULT_FAILURE:
        return Object.assign({}, state, {
            result:[],
            resultPageLoading:false,
            resultStatus:action.response.data.status,
            resultError:action.response.data.error,
            resultMessage:action.response.data.message
        });
 
        default:
            return state
    }
}
