const local = {
    app: {
        REACT_APP_AUTH_API:"https://careers.neuralcompany.work/api/auth",
        REACT_APP_DRIVE_API:"https://careers.neuralcompany.work/api/drive",
        REACT_APP_TEST_MODULE_URL:"https://careers.neuralcompany.work",
        REACT_APP_IMAGE_URL:"https://www.xenonstack.com/images/career",
        REACT_APP_STATIC_PAGE:"https://xenonstack-jobs-dev.neuralcompany.team/",
    }
  };
  
  const dev = {
    app: {
        REACT_APP_AUTH_API:"https://careers.neuralcompany.work/api/auth",
        REACT_APP_DRIVE_API:"https://careers.neuralcompany.work/api/drive",
        REACT_APP_TEST_MODULE_URL:"https://careers.neuralcompany.work",
        REACT_APP_IMAGE_URL:"https://www.xenonstack.com/images/career",
        REACT_APP_STATIC_PAGE:"https://xenonstack-jobs-dev.neuralcompany.team/",
    },
  };
  
  const prod = {
    app: {
      REACT_APP_AUTH_API:"https://xenonstack.jobs/api/auth",
      REACT_APP_DRIVE_API:"https://xenonstack.jobs/api/drive-portal",
      REACT_APP_IMAGE_URL:"https://xenonstack.jobs/images/career",
      REACT_APP_TEST_MODULE_URL:"https://hiring.xenonstack.com",
      REACT_APP_STATIC_PAGE:"https://xenonstack.jobs/"
    },
  };
  
  const config = process.env.REACT_APP_STAGE === 'production'  ? prod
               : process.env.REACT_APP_STAGE === 'development' ? dev
               : local;
  
  export default {
    // Add common config values here
    ...config
  };
  