import React, {Component} from 'react';
import {Link, withRouter , NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    changeEmailRegisterForm,
    changeLoginForm,
    clearApiErrorMessage,
    login
} from "../../../actions/account/login-actions";
import Button from '../../../components/app/button/button';
import Input from '../../../components/app/input/input';
import BarLoaderSpinner from '../../../components/app/spinner/barloader';
import Helmet from 'react-helmet';
import "../../../static/css/sign-in.css"
const ENV_VAR = process.env.REACT_APP_STATIC_PAGE === undefined ? "https://xenonstack.jobs/" : process.env.REACT_APP_STATIC_PAGE;

class Login extends Component {
    componentWillMount() {
        document.title = "Login | Xenonstack Hiring Portal";
        this.props.dispatch(clearApiErrorMessage());
        this.props.dispatch(changeLoginForm({email: "", password: ""}));
    }
    componentDidMount() {
        document.getElementById("tpt_loginUsername").focus();
        if(localStorage.getItem("token")){
            this.props.history.push("/dashboard")
        
        }
    }

    // submit the form
    submitLoginForm(e) {
        e.preventDefault();
        const self = this.props;
        if (e.target.checkValidity()) {
            const body = {
                username: self.email,
                password: self.password
            };
             self.dispatch(login(body));
            } else {
            const invalidElms = document.querySelectorAll(".login-form .form-group input:invalid");
            invalidElms[0].focus();
            for (let i = 0; i < invalidElms.length; i++)
                invalidElms[i].parentElement.classList.add("has-error")
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status === 200) {
            this.props.history.push("/admin");
        }
    }

    // called when onchange html event fired
    onChange(e) {
        const self = this.props;
        self.dispatch(clearApiErrorMessage());
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove("has-error");
        } else {
            e.target.parentElement.classList.add("has-error");
        }
        if (e.target.id === "checkEmail") {
            const newState = Object.assign(self.emailRegisterForm, {
                [e.target.name]: e.target.value
            });
            self.dispatch(changeEmailRegisterForm(newState))
        } else {
            const newState = Object.assign(self.loginForm, {
                [e.target.name]: e.target.value
            });
            self.dispatch(changeLoginForm(newState))
        }
    }

    render() {
        return (
            <>
            <Helmet>
                <title>Login | XenonStack Careers</title>
             </Helmet>
            {/* <Header/> */}
            <main className="main-body" id="main-body">
                <div className="wrapper-container signing-container">

                 <div className="container user-jounrney-form-container">
                  <div className="user-journey-form">
                  <div className="xenonstack-jobs-logo">
                    <a href={ENV_VAR}> <img src={require('../../../static/images/logoxe.svg')}/></a>
                    </div>
                    <div className="dashbaord-user-form">
                    <div className="section_header">
                            <h2 className="big-heading">Sign In</h2>
                           
                        </div>
                        <div className="signup-page" id="signup-page">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-10 mar-xs-30">
                                    <div className="login-div existing-user" id="existing-user">
                                       
                                        <form onSubmit={this.submitLoginForm.bind(this)} id="loginForm" className="login-form"
                                            autoComplete="off" noValidate={true}>
                                            <div className="row">
                                                <div className="col-12 col-sm-10 col-md-12">
                                                    <div className="form-group">
                                                        <label>Email<span className="req">*</span></label>
                                                        <Input
                                                            id="tpt_loginUsername"
                                                            type="email"
                                                            name="email"
                                                            className="form-ctrl"
                                                            autoComplete="off"
                                                            pattern={"([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}"}
                                                            required={true}
                                                            placeholder="Email Address"
                                                            value={this.props.email}
                                                            onChange={this.onChange.bind(this)}/>
                                                        <p className="with-error">Please enter valid email address.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-10 col-md-12">
                                                    <div className="form-group">
                                                        <label>Password<span className="req">*</span></label>
                                                        <Input
                                                            id="tpt_loginPassword"
                                                            type="password"
                                                            autoComplete="off"
                                                            name="password"
                                                            className="form-ctrl"
                                                            value={this.props.password}
                                                            required={true}
                                                            placeholder="Password"
                                                            onChange={this.onChange.bind(this)}
                                                        />
                                                        <p className="with-error">Please enter password.</p>
                                                        <Link to="/forgot-password" className="forgot_pw"> Forgot password? </Link>
                                                    </div>
                                                </div>
                                                {
                                                    this.props.message !== "" &&
                                                    <div className="col-12 col-sm-12 col-md-12">
                                                        <div className="form-group">
                                                            <div className="bPad24px">
                                                                <span className="errorText">
                                                                    {this.props.message}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-12 col-sm-10 col-md-12">
                                                    <div className="form-group btn-group">
                                                        <Button type="submit" id="loginButton"
                                                                disabled={this.props.loginPageLoading ? true : false}
                                                                className="btn sign-in mar-t-2"
                                                                text="Sign In"/>
                                                    </div>

                                                    <NavLink className="dont-have-an-account" to="/apply-manual">Don’t have an Account ? Create New</NavLink>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                       
                    <div className="xenonstack-jobs-copyright">
                        <p>Xenonstack | </p>
                        <i class="fa fa-copyright"></i>
                        <p>2021 All Rights Reserved</p>
                    </div>
                    
                    </div>
                    <BarLoaderSpinner pageLoading={this.props.loginPageLoading}/>
                  </div>
                </div>
                 
            </main>
            {/* <Footer/> */}   
            </>
        )
    }
}

function mapStateToProps(state) {
    const {loginForm, emailRegisterForm, loginPageLoading, message, status} = state.LoginReducer;
    const {email, password} = loginForm;
    const {emailAddress} = emailRegisterForm;
    return {loginForm, emailRegisterForm, email, password, emailAddress, loginPageLoading, message, status};
}

export default withRouter(connect(mapStateToProps)(Login))
