
/*-------------------------------Get List Of Admins ------------------------*/
export const GET_ADMIN_LIST_REQUEST='GET_ADMIN_LIST_REQUEST';
export const GET_ADMIN_LIST_SUCCESS='GET_ADMIN_LIST_SUCCESS';
export const GET_ADMIN_LIST_FAILURE='GET_ADMIN_LIST_FAILURE';

 
/*----------------------delete the selected admin------------------------*/
export const DELETE_SELECTED_ADMIN='DELETE_SELECTED_ADMIN';

/*------------------------delete API types ------------------------*/
export const DELETE_ADMIN_LIST_REQUEST='DELETE_ADMIN_LIST_'
export const DELETE_ADMIN_LIST_SUCCESS='DELETE_ADMIN_SUCCESS_'
export const DELETE_ADMIN_LIST_FAILURE='DELETE_ADMIN_LIST_FAILURE'

/*-------------------------------Create List------------------------*/
export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAILURE = "CREATE_ADMIN_FAILURE";

/*-------------------------------edit admin details------------------------*/

export const EDIT_ADMIN_DETAILS_REQUEST = "EDIT_COLLEGE_ADMIN_REQUEST";
export const EDIT_ADMIN_DETAILS_SUCCESS = "EDIT_COLLEGE_ADMIN_SUCCESS";
export const EDIT_ADMIN_DETAILS_FAILURE = "EDIT_COLLEGE_ADMIN_FAILURE";
export const CHANGE_CREATE_ADMIN_FORM = "CHANGE_CREATE_ADMIN_FORM";

/*----------------------------- get college details -------------------------*/

export const GET_COLLEGE_ADMIN_REQUEST = "GET_COLLEGE_ADMIN_REQUEST";
export const GET_COLLEGE_ADMIN_SUCCESS = "GET_COLLEGE_ADMIN_SUCCESS";
export const GET_COLLEGE_ADMIN_FAILURE = "GET_COLLEGE_ADMIN_FAILURE";

export const CLEAR_ALL="CLEAR_ALL";

export const CLEAR_STATUS="CLEAR_STATUS"