import React from 'react'

export class TablePagination extends React.Component {
  state = {
    pageSizeOptions: this.props.pageSizeOptions
  }

  static defaultProps = {
    pageSizeOptions: []
  }

  render() {
    const { pageSizeOptions } = this.state
    const { pageSize, hasPrevPage, hasNextPage, handlePrevPage, handleNextPage,currentPage, total } = this.props

    return (
      <div className="pagination-outer">
          <div className="pagination-numbers">
            {currentPage * pageSize - pageSize + 1}-
            {currentPage * pageSize > total && total > 0
              ? total
              : currentPage * pageSize}{' '}
            of {total} entries
          </div>
          <button
            className={hasPrevPage ? 'pagination-btn hasNextPage' : 'pagination-btn noNextPage'}
            onClick={e => handlePrevPage(e)}
          >
            <i className="fa fa-angle-left"></i>
          </button>
          <button
            className={hasNextPage ? 'pagination-btn hasNextPage' : 'pagination-btn noNextPage'}
            onClick={e => handleNextPage(e)}
          >
            <i className="fa fa-angle-right"></i>
          </button>
        </div>
    )
  }
}
