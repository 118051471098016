import {
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
    DELETE_NOTIFICATION_REQUEST,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAILURE,
    DELETE_SINGLE_NOTIFICATION_REQUEST, 
    DELETE_SINGLE_NOTIFICATION_SUCCESS, 
    DELETE_SINGLE_NOTIFICATION_FAILURE
  } from "../../../types/dashboard/admin/notification/notification";
  

  const initialState = {
    notificationListPageLoading: false,
    notificationListError: "",
    notificationListStatus: "",
    notificationList:[],
    notificationCount: 0,
    feedBackCount: 0,
  
    deleteAllNotificationMessage: "",
    deleteAllNotificationError: "",
    deleteAllNotificationStatus: "",
    deleteAllNotificationPageLoading: false,
  
    deleteSingleNotificationMessage: "",
    deleteSingleNotificationError: "",
    deleteSingleNotificationStatus: "",
    deleteSingleNotificationPageLoading: false,
  }

  export default function NotificationReducer(state = initialState, action) {
    switch (action.type) {
        case GET_NOTIFICATION_REQUEST:
            return Object.assign({}, state, {
              notificationListPageLoading: true,
              notificationListError: "",
              notificationListStatus: "",
              deleteSingleNotificationMessage: "",
              deleteAllNotificationMessage: "",
              notificationList:[]
            });
          case GET_NOTIFICATION_SUCCESS:
            return Object.assign({}, state, {
              notificationListPageLoading: false,
              notificationListError: action.response.data.error,
              notificationListStatus: 200,
              notificationList:action.response.data.notification
            });
          case GET_NOTIFICATION_FAILURE:
            return Object.assign({}, state, {
              notificationListPageLoading: false,
              notificationListError: true,
              notificationListStatus: action.response.data.status,
              notificationList:[]
              });
      
          case DELETE_NOTIFICATION_REQUEST:
            return Object.assign({}, state, {
              deleteAllNotificationPageLoading: true,
              deleteAllNotificationMessage: "",
              deleteAllNotificationError: "",
              deleteAllNotificationStatus: "",
              notificationList:[]
            });
          case DELETE_NOTIFICATION_SUCCESS:
            return Object.assign({}, state, {
              deleteAllNotificationPageLoading: false,
              deleteAllNotificationMessage: action.response.data.message,
              deleteAllNotificationError: action.response.data.error,
              deleteAllNotificationStatus: 200,
            });
          case DELETE_NOTIFICATION_FAILURE:
            return Object.assign({}, state, {
              deleteAllNotificationPageLoading: false,
              deleteAllNotificationMessage: action.response.data.message,
              deleteAllNotificationError: true,
              deleteAllNotificationStatus: action.response.data.status,
              });
            
            
            case DELETE_SINGLE_NOTIFICATION_REQUEST:
              return Object.assign({}, state, {
                deleteSingleNotificationPageLoading: true,
                deleteSingleNotificationMessage: "",
                deleteSingleNotificationError: "",
                deleteSingleNotificationStatus: "",
               });
              
            case DELETE_SINGLE_NOTIFICATION_SUCCESS:
             return Object.assign({}, state, {
                deleteSingleNotificationPageLoading: false,
                deleteSingleNotificationMessage: action.response.data.message,
                deleteSingleNotificationError: action.response.data.error,
                deleteSingleNotificationStatus: 200,
              });
              
            case DELETE_SINGLE_NOTIFICATION_FAILURE:
              return Object.assign({}, state, {
                deleteSingleNotificationPageLoading: false,
                deleteSingleNotificationMessage: action.response.data.message,
                deleteSingleNotificationError: true,
                deleteSingleNotificationStatus: action.response.data.status,
              });
            default:
                return state;
    }}