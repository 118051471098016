import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import Helmet from 'react-helmet';

class OnboardingApp extends Component {
    componentWillMount() {
        document.title = "Login | Xenonstack Hiring Portal";
    }

    componentDidMount() {
        this.props.history.push("/login");
    }

    render() {
        return (
            <>
            <Helmet>
                <title>Login | XenonStack Careers</title>
             </Helmet>
            <Header/>
            <main className="main-body" id="main-body">
               
            </main>
            <Footer/>
            </>
        )
    }
}

function mapStateToProps(state) {   
    return state
}

export default withRouter(connect(mapStateToProps)(OnboardingApp))
