import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import Animated from "react-select/lib/animated";
import Input from "../../../../app/input/input";
import {
  changeRegistrationForm,
  clearApiErrorMessage,
} from "../../../../../actions/account/registration-action";
import UploadIcon from "../../../../app/upload_icon/upload-icon";
import {
  areaOfInterest,
  colourStyles,
} from "../../../../app/area_of_interest/area-of-interest";
import { checkValidation } from "../../../../../actions/app/app";

let self = null;

class InformationSection extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedInterest: this.props.registrationForm.selectedInterest,
    };
  }

  componentWillReceiveProps(nextProps) {
    self = nextProps;
  }
  // called when html field value is changed
  onChange(e) {
    const githubRegex = RegExp(/^http(s?):\/\/(www\.)?github\.com\/.*$/);
    const self = this.props;
    // calls action to clear api error message
    self.dispatch(clearApiErrorMessage());
    // check validatio. remove / add the error message
    checkValidation(e);
    const existingRegisterationForm = this.props.registrationForm;
    if (e.target.name === "githubLink") {
      if (e.target.value === "") {
        e.target.required = false;
        e.target.parentElement.classList.remove("has-error");
      }
      else {
            //if url includes wwww
      let gitUrl = e.target.value;
        if (githubRegex.test(gitUrl)) {
          e.target.parentElement.classList.remove("has-error");
        } else {
          e.target.parentElement.classList.add("has-error");
        }
      }
    }

    if (e.target.name === "otherLink") {
      if (e.target.value === "") {
        e.target.required = false;
        e.target.parentElement.classList.remove("has-error");
      } else {
        e.target.required = true;
        if (e.target.checkValidity()) {
          e.target.parentElement.classList.remove("has-error");
        } else {
          e.target.parentElement.classList.add("has-error");
        }
      }
    }
    if (e.target.name === "resume") {
      if (e.target.value === "") {
        e.target.parentElement.classList.add("has-error");
        const newRegistrationForm = Object.assign(self.registrationForm, {
          resume: "",
        });
        // calls action to change registration form
        self.dispatch(changeRegistrationForm(newRegistrationForm));
      } else {
        switch (e.target.value.match(/\.([^\.]+)$/i)[1].toLowerCase()) {
          case "pdf":
            e.target.parentElement.classList.remove("has-error");
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, {
                  resume: document.getElementById("uploadResume_1")
                })
              )
            );
            break;
          case "doc":
            e.target.parentElement.classList.remove("has-error");
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, {
                  resume: document.getElementById("uploadResume_1"),
                })
              )
            );
            break;
          case "docx":
            e.target.parentElement.classList.remove("has-error");
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, {
                  resume: document.getElementById("uploadResume_1"),
                })
              )
            );
            break;
          default:
            e.target.parentElement.classList.add("has-error");
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, { resume: "" })
              )
            );
            return 0;
        }
        //checking the file size
        var input, file;

        input = document.getElementById("uploadResume_1");

        if (!input.files[0]) {
        } else {
          file = input.files[0];

          var i = Math.floor(Math.log(file.size) / Math.log(1024));
          let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
          let result = (file.size / 1024 ** i).toFixed(1);
          if (sizes[i] === "B" || sizes[i] === "KB" || sizes[i] === "MB") {
            if (sizes[i] === "MB" && result > 5) {
              e.target.parentElement.classList.add("has-error");
              self.dispatch(
                changeRegistrationForm(
                  Object.assign(self.registrationForm, { resume: "" })
                )
              );
            }
          } else {
            e.target.parentElement.classList.add("has-error");
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, { resume: "" })
              )
            );
          }

          // alert(re  )
        }
      }
    } else {
      existingRegisterationForm[e.target.name] =
        e.target.type === "checkbox"
          ? !JSON.parse(e.target.value)
          : e.target.value;
      self.dispatch(changeRegistrationForm(existingRegisterationForm));
    }
    this.forceUpdate();
  }

  // called when interest is chanegd
  interestChange = (selectedOption) => {
    if (selectedOption.length > 0) {
      if (selectedOption.length <= 3) {
        this.setState({
          selectedInterest: selectedOption,
        });
        const newRegistrationForm = Object.assign(self.registrationForm, {
          selectedInterest: selectedOption,
        });
        self.dispatch(changeRegistrationForm(newRegistrationForm));
      }
    } else {
      // when removing the area of iterest..
      // if length  === 0

      this.setState({ selectedInterest: [] });

      const newRegistrationForm = Object.assign(self.registrationForm, {
        selectedInterest: [],
      });
      self.dispatch(changeRegistrationForm(newRegistrationForm));
    }
  };

  render() {
    self = this.props;

    return (
      <div className="wrapper">
        <div className="section_header">
          <h3 className="big-heading">Basic Information </h3>
        </div>
        <div id="information-edit" className="information-edit">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 mar-xs-30">
              <div className="section_content">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <div>
                        <label>
                          Area of Interest{" "}
                          <span>
                            <small>
                              {" "}
                              (You can add upto three area of interest){" "}
                            </small>
                          </span>
                        </label>
                        <Select
                          closeMenuOnSelect={false}
                          value={this.props.registrationForm.selectedInterest}
                          components={Animated()}
                          onChange={this.interestChange.bind(this)}
                          placeholder="Area of Interest"
                          isSearchable={true}
                          styles={colourStyles}
                          isMulti={true}
                          options={areaOfInterest}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 mar-xs-30">
              <h4 className="subtitle">
                ADD YOUR SOCIAL LINKS{" "}
                <span>
                  <small>
                    {" "}
                    (Github account add advantage to your profile){" "}
                  </small>
                </span>
              </h4>
              <div className="section_content">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        LinkedIn URL
                      </label>
                      <Input
                        type="text"
                        name="linkedInLink"
                        autoComplete="off"
                        className="form-ctrl"
                        placeholder="LinkedIn URL"
                        pattern={"^(http(s?)://)?[a-z]{2,3}.linkedin.com/.*$"}
                        value={this.props.registrationForm.linkedInLink}
                        onChange={this.onChange.bind(this)}
                        required={false}
                      />
                      <p className="with-error">
                        Please enter valid LinkedIn Url.(For eg:
                        https://www.linkedin.com/in/hiring-job-opening-xenonstack/)
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Github URL</label>
                      <Input
                        type="text"
                        name="githubLink"
                        className="form-ctrl"
                        placeholder="Github URL"
                        value={this.props.registrationForm.githubLink}
                        onChange={this.onChange.bind(this)}
                        required={false}
                      />
                      <p className="with-error">
                        Please enter valid GitHub Url.(For eg:
                        https://github.com/xenonstack)
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Other URL</label>
                      <Input
                        type="url"
                        name="otherLink"
                        className="form-ctrl"
                        placeholder="Other URL"
                        pattern={
                          "(http|https)://((\\w)*|([0-9]*)|([-|_])*)+([\\.|/]((\\w)*|([0-9]*)|([-|_])*))+"
                        }
                        value={this.props.registrationForm.otherLink}
                        onChange={this.onChange.bind(this)}
                        required={false}
                      />
                      <p className="with-error">Please enter valid url.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 form-group ">
              <label>
                UPLOAD YOUR RESUME OR DOCUMENT<span className="req">*</span>
              </label>
              <div className="section_content">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <Input
                        type="file"
                        title=""
                        id="uploadResume_1"
                        name="resume"
                        pattern={"([^\\s])"}
                        accept=".doc, .docx, .pdf"
                        required={
                          this.props.registrationForm.resume === ""
                            ? true
                            : false
                        }
                        onChange={this.onChange.bind(this)}
                        placeholder="Resume"
                        className="form-ctrl select-fileupload"
                      />
                      <p className="with-error">
                        Please upload valid file. Supported files are doc, docx,
                        pdf and file size should be less than 5MB.
                      </p>
                      <UploadIcon fileInoutId={"uploadResume_1"} />
                      <div className="help-note">
                      <span>
                        <small>
                        (Document format should be .pdf or .doc or .docx .)
                        </small>
                      </span>
                      </div>
                      {!!this.props.registrationForm.resume && (
                        <div className="image-preview">Resume Uploaded</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="cstm-checkbox">
                  {" "}
                  Signup for job notification &amp; be the first to know about
                  new opportunities.
                  <Input
                    disabled={false}
                    className="exp-input"
                    type="checkbox"
                    checked={this.props.registrationForm.notify}
                    name="notify"
                    required={false}
                    onChange={this.onChange.bind(this)}
                    value={this.props.registrationForm.notify}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    registrationForm,
    status,
    message,
    goToRegistration,
  } = state.RegisterReducer;
  const { updateJob, updateInterest } = registrationForm;

  return {
    registrationForm,
    status,
    message,
    updateJob,
    updateInterest,
    goToRegistration,
  };
}

export default withRouter(connect(mapStateToProps)(InformationSection));
