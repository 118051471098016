import React, {Component} from 'react'
import AdminOnPageNavigation from '../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import {checkValidation} from "../../../../../actions/app/app";
import { changeCreateAdminForm, createAdmin, clearAll,clearStatus} from '../../../../../actions/dashboard/admin/invite/admin';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import createNotification from "../../../../../components/app/notification/notification";
import {Helmet} from "react-helmet";

class AddAdmin extends Component {
    onChange(e){
        checkValidation(e);
        const newState = Object.assign(this.props.createAdmin, {
            [e.target.name]: e.target.value
        });
        this.props.dispatch(changeCreateAdminForm(newState))
    }
    handleSubmit(e) {        
        e.preventDefault();
        let flag=false;
        const pass=e.target.password.value;
        const cpass=e.target.confirm_password.value;
        if(pass !== cpass){
    
            flag=true;
            document.getElementById("admin_cpassword").parentElement.classList.add("has-error")
        }
        const self = this.props;
        if (e.target.checkValidity() && flag ===false) {
            const body = self.createAdmin;
                self.dispatch(createAdmin(body))
        }
        else {
            const invalidElmsInput = document.querySelectorAll(".create-team-form .form-group input:invalid");
            for (let i = 0; i < invalidElmsInput.length ; i++) {
                invalidElmsInput[i].parentElement.classList.add("has-error")
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        
        if (nextProps.createAdminMessage !== "") {
            if (nextProps.createAdminError) {
                createNotification('error', nextProps.createAdminMessage);
                nextProps.dispatch(clearStatus());
                nextProps.dispatch(clearAll())
            } else {
                createNotification('success', nextProps.createAdminMessage);
                nextProps.dispatch(changeCreateAdminForm({name: "", location: ""}))
                nextProps.history.push("/dashboard/invite/list")
              
            }
        }
       
   }
    render() {
         
        return (
          <div>
          <Helmet>
                <title>Create Admin | Career Portal | Xenonstack</title>
             </Helmet>
            <AdminOnPageNavigation parentRoute="Dashboard"
                                       childRoute="Add Admin"/>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="pool-form-wrapper">
                        <h4 className="content-heading">Add New Admin</h4>
                        <div className="divider"></div>
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-12">
                                <div className="">
                                    <form className="create-team-form"
                                      noValidate={true}
                                      onSubmit={this.handleSubmit.bind(this)}>
                                        <div className="form-body">
                                            <div className="form-group">
                                                <label className="control-label mb-5">Admin Name<span className="req">*</span></label>
                                                <input type="text"
                                                       name="name"
                                                    //    value={this.props.createAdmin.name}
                                                       minLength={3}
                                                       maxLength={50}
                                                    //    pattern={"[a-zA-Z][a-zA-Z ]*"}
                                                       pattern={"[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"}
                                                       autoComplete={"off"}
                                                       required={true}
                                                       onChange={this.onChange.bind(this)}
                                                       className="form-control"
                                                       placeholder="Admin Name"
                                                    
                                                    />
                                                <p className="with-error">Please enter your Name ( Space before & after text is not allowed).</p>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label mb-5">Email Address<span className="req">*</span></label>
                                                <input type="email"
                                                       name="email"
                                                       //pattern={"([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}"}
                                                       pattern="^(([-\w\d]+)(\.[-\w\d]+)*@([-\w\d]+)(\.[-\w\d]+)*(\.([a-zA-Z]{2,3}|[\d]{1,})){1,2})$" 
                                                       autoComplete={"email"}
                                                       onChange={this.onChange.bind(this)}
                                                       required={true}
                                                    //    value={this.props.createAdmin.email}
                                                       className="form-control"
                                                       placeholder="Admin Email"/>
                                                <p className="with-error">Please enter a valid email address</p>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label mb-5">Password<span className="req">*</span></label>
                                                <input type="password"
                                                       name="password"
                                                       id="admin_password"
                                                       onChange={this.onChange.bind(this)}
                                                    //    pattern={"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"}
                                                       autoComplete={"new-password"}
                                                       required={true}
                                                    //    value={this.props.createAdmin.password}
                                                       className="form-control"
                                                       placeholder="Password"/>
                                            
                                                <p className="with-error">Please enter password</p>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label mb-5"> Confirm Password<span className="req">*</span></label>
                                                <input type="password"
                                                       name="confirm_password"
                                                       autoComplete={"new-password"}
                                                       maxLength={32}
                                                       className="form-control"
                                                       placeholder="Confirm Password"                                                                                                        
                                                       id="admin_cpassword"
                                                       onChange={this.onChange.bind(this)}
                                                    //    pattern={"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"}
                                                       autoComplete={"new-password"}
                                                       required={true}
                                                       
                                                       className="form-control"
                                                        />
                                            
                                                <p className="with-error">Password does not match.</p>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn mt-30">Add</button>
                                                <button type="button" onClick={()=> this.props.history.push("/dashboard/invite/list")}
                                                        style={{marginLeft: "20px"}}    className="btn mt-30">Cancel</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        )
    }
}
function mapStateToProps(state){
    const {
        createAdmin,
        createAdminPageLoading,
        createAdminMessage,
        createAdminError,
        createAdminStatus
    }= state.adminListReducer
    
    return  {
        createAdmin,
        createAdminPageLoading,
        createAdminMessage,
        createAdminError,
        createAdminStatus
    }
}

export default withRouter(connect(mapStateToProps)(AddAdmin))
