import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

class ShortlistedCandidates extends Component {
    render() {
        return (
            <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                              {/* <span className="info-box-icon bg-purple">
                                <i className="fa fa-heart" aria-hidden="true"></i>
                              </span> */}
                              <img src={require('../../../../static/images/shortlisted-candidate.svg')}/>
                    <div className="info-box-content">
                        <span className="info-box-text">Shortlisted Candidates</span>
                        <span className="info-box-number">{this.props.Shortlisted}</span>
                    </div>
                </div>
            </div>
        )
    }
}

 

export default withRouter(connect( null)(ShortlistedCandidates))
