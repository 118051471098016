import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AdminOnPageNavigation from '../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import AddCollegeCsv from './Add/csv';
import AddCollegeManually from './Add/manually';
import ListCollege from './list/list';
import {clearAll} from "../../../../actions/dashboard/admin/college/college";
import {Helmet} from "react-helmet";

class College extends Component {
    componentWillMount() {
        this.props.dispatch(clearAll())
        this.state = {
            keyword: '',
            searchInput: '',
        }
    }

    handleChangeKeyword(e){
        if(e.target.name === 'keyword'){
            this.setState({
                searchInput: e.target.value
            })
        }
    }

    handleKeyDown(e){
        if(e.target.name === 'keyword' && e.key === "Enter"){
            this.setState({
                keyword: this.state.searchInput
            })
        }
    }

    render() {
        return (
            <div>
            <Helmet>
                <title>Manage Colleges | Career Portal | Xenonstack</title>
             </Helmet>
                <AdminOnPageNavigation parentRoute="Dashboard" childRoute={this.props.location.pathname.match("/edit") ? "Edit College" : "Add College"}/>
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="pool-form-wrapper">
                            <h4 className="content-heading">Add College</h4>
                            <div className="divider"></div>
                            <div className="">
                                <AddCollegeCsv/>
                                <AddCollegeManually/>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8">
                        <div className="pool-form-wrapper">
                            <div className="row" style={{
                                paddingRight: '15px'
                            }}>
                                <h4 className="content-heading col-sm-12 col-md-12 col-lg-8">All Colleges</h4>
                                <input name="keyword" type="text" className="form-control col-sm-12 col-md-12 col-lg-4"
                                    value={this.state.searchInput} 
                                    onChange={this.handleChangeKeyword.bind(this)} 
                                    onKeyDown={this.handleKeyDown.bind(this)}
                                    placeholder="Enter Part of Name and press enter.." />
                            </div>
                            <div className="divider"></div>
                            <div className="panel panel-default card-view custom-panel pool-panel">
                                <div className="panel-heading">
                                    <h5 className="panel-title">College List</h5>
                                </div>
                                <ListCollege keyword={this.state.keyword}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {state}
}

export default withRouter(connect(mapStateToProps)(College))
