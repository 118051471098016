import React from 'react'

export function TableRow(props) {
  const { component, children, style, className } = props

  return React.createElement(component, {
    ...props,
    component: null,
    children,
    style,
    className
  })
}
