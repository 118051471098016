// create TEST

export const CHANGE_CREATE_TEST_FORM = "CHANGE_CREATE_TEST_FORM";

export const CREATE_TEST_REQUEST = "CREATE_TEST_REQUEST";
export const CREATE_TEST_SUCCESS = "CREATE_TEST_SUCCESS";
export const CREATE_TEST_FAILURE = "CREATE_TEST_FAILURE";

export const GET_TEST_REQUEST = "GET_TEST_REQUEST";
export const GET_TEST_SUCCESS = "GET_TEST_SUCCESS";
export const GET_TEST_FAILURE = "GET_TEST_FAILURE";

export const DELETE_TEST_REQUEST = "DELETE_TEST_REQUEST";
export const DELETE_TEST_SUCCESS = "DELETE_TEST_SUCCESS";
export const DELETE_TEST_FAILURE = "DELETE_TEST_FAILURE";

export const GET_TEST_DETAILS_REQUEST = "GET_TEST_DETAILS_REQUEST";
export const GET_TEST_DETAILS_SUCCESS = "GET_TEST_DETAILS_SUCCESS";
export const GET_TEST_DETAILS_FAILURE = "GET_TEST_DETAILS_FAILURE";

export const EDIT_TEST_DETAILS_REQUEST = "EDIT_TEST_DETAILS_REQUEST";
export const EDIT_TEST_DETAILS_SUCCESS = "EDIT_TEST_DETAILS_SUCCESS";
export const EDIT_TEST_DETAILS_FAILURE = "EDIT_TEST_DETAILS_FAILURE";

export const CLEAR_ALL = "CLEAR_ALL";

export const CLEAR_STATUS = "CLEAR_STATUS";

export const SELECTED_DELETE_TEST = "SELECTED_DELETE_TEST"

export const CHANGE_QUESTION_COUNT='CHANGE_QUESTION_COUNT';