import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BarLoaderSpinner from '../../../../app/spinner/barloader';
import Input from '../../../../../components/app/input/input';
import NextBackSection from './next-back';
import {
    changeRegistrationForm,
    checkEmail,
    clearApiErrorMessage
} from "../../../../../actions/account/registration-action";
import { defaultRegistrationForm } from "../../../../../reducers/account/register-reducer";
import { checkValidation } from "../../../../../actions/app/app";
import { Helmet } from "react-helmet";
const ENV_VAR = process.env.REACT_APP_STATIC_PAGE === undefined ? "https://xenonstack.jobs/" : process.env.REACT_APP_STATIC_PAGE;


class CheckEmailSection extends Component {
    componentWillMount() {
        document.title = "Registration | Check Email Address | XenonStack Careers";
        this.props.dispatch(clearApiErrorMessage());
        const registrationForm = defaultRegistrationForm;
        this.props.dispatch(changeRegistrationForm(Object.assign(registrationForm, { email: "" })))
    }

    componentDidMount() {
        if (!this.props.location.pathname.match("apply-linkedIn") && !this.props.location.pathname.match("apply-Google")) {
            document.getElementsByName("email")[0].focus();
        }
    }

    // called when onchange html event fired
    onChange(e) {
        const self = this.props;
        checkValidation(e);
        const newState = Object.assign(self.registrationForm, {
            [e.target.name]: e.target.value
        });
        self.dispatch(changeRegistrationForm(newState))
    }

    // called when submit the form
    submitCheckEmailForm(e) {
        e.preventDefault();
        const self = this.props;
        if (e.target.checkValidity()) {
            const body = {
                email: self.email
            };
            self.dispatch(checkEmail(body));
        } else {
            const invalidElms = document.querySelectorAll(".check-email-form .form-group input:invalid");
            invalidElms[0].focus();
            for (let i = 0; i < invalidElms.length; i++)
                invalidElms[i].parentElement.classList.add("has-error")
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status === 200 && !nextProps.error) {
            nextProps.history.push("/verify-email");
        }
    }

    render() {
        return (
            <>
                {
                    this.props.location.pathname.match("apply-linkedIn") && !this.props.location.pathname.match("apply-Google") &&
                    <div className="coming-soon-page">
                        <div>
                            <Helmet>
                                <title>Coming Soon | XenonStack Hiring Portal</title>
                            </Helmet>
                            <img src={require("../../../../../static/images/coming-soon-linkedin.svg")}
                                alt={"coming-soon"} />
                            <div>
                                {/*<h3>Apply with Linkedin</h3>*/}
                                <h2>Coming Soon</h2>
                                <p>We are currently working on this page. Thanks for checking out and we'll launch
                                    soon.</p>
                            </div>
                            <div>
                                <Link className="btn mar-t-2 " to="/login">Back to Sign In Page</Link>
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.props.location.pathname.match("apply-linkedIn") && !this.props.location.pathname.match("apply-Google") &&
                     <div className="wrapper-container verify-email-container"> 
                    <div className="container user-jounrney-form-container">
                        <div className="user-journey-form">
                        <div className="xenonstack-jobs-logo">
                    <a href={ENV_VAR}> <img src={require('../../../../../static/images/logoxe.svg')}/></a>
                    </div>  
                    <div className="dashbaord-user-form">
                    <div className="section_header">
                            <h2 className="big-heading">Verify your email address to continue with us</h2>
                            <h4 className="subtitle big-subtitle">It is recommended you apply using the email address
                            associated with your account so we can inform you details related to your account.</h4>
                        </div>
                        <div className="emailVerification"  id="emailVerification">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-10  mar-xs-30">
                                    <form onSubmit={this.submitCheckEmailForm.bind(this)} noValidate={true} className="check-email-form">
                                        <div className="form-group">
                                            <label>Email Address<span className="req">*</span></label>
                                            <Input
                                                type="email"
                                                name="email"
                                                className="form-ctrl"
                                                autoComplete="off"
                                                pattern={"([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}"}
                                                required={true}
                                                placeholder="Email Address"
                                                value={this.props.email}
                                                onChange={this.onChange.bind(this)}/>
                                            <p className="with-error">Please enter valid email address.</p>
                                        </div>
                                        {
                                            this.props.error &&
                                            <div>
                                                <div className="form-group">
                                                    <div className="bPad24px">
                                                        <span className="errorText">
                                                            {this.props.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <NextBackSection>
                                            {this.props.children}
                                        </NextBackSection>
                                    </form>
                                </div>
                            </div>
                        </div> 
                    </div> 
                    <div className="xenonstack-jobs-copyright">
                        <p>Xenonstack | </p>
                        <i class="fa fa-copyright"></i>
                        <p>2021 All Rights Reserved</p>
                    </div>
                        </div>
                        <div style={{position:"relative",top:"20px",width:"50%  "}}>
                        <BarLoaderSpinner pageLoading={this.props.pageLoading}/>
                        </div>
                        
                    </div>
                    </div>
                }
                 {
                    !this.props.location.pathname.match("apply-Google") && !this.props.location.pathname.match("apply-linkedIn") &&
                    <div className="wrapper-hidden">
                        {/* <div className="section_header">
                            <h3 className="big-heading">Verify your email address to continue with us</h3>
                            <h4 className="subtitle">It is recommended you apply using the email address
                            associated with your account so we can inform you details related to your account.</h4>
                        </div>
                        <div className="emailVerification"  id="emailVerification">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 mar-xs-30">
                                    <form onSubmit={this.submitCheckEmailForm.bind(this)} noValidate={true} className="check-email-form">
                                        <div className="form-group">
                                            <label>Email Address<span className="req">*</span></label>
                                            <Input
                                                type="email"
                                                name="email"
                                                className="form-ctrl"
                                                autoComplete="off"
                                                pattern={"([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}"}
                                                required={true}
                                                placeholder="Email Address"
                                                value={this.props.email}
                                                onChange={this.onChange.bind(this)}/>
                                            <p className="with-error">Please enter valid email address.</p>
                                        </div>
                                        {
                                            this.props.error &&
                                            <div>
                                                <div className="form-group">
                                                    <div className="bPad24px">
                                                        <span className="errorText">
                                                            {this.props.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <NextBackSection>
                                            {this.props.children}
                                        </NextBackSection>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div style={{position:"relative",top:"20px"}}>
                        <BarLoaderSpinner pageLoading={this.props.pageLoading}/>
                        </div> */}
                        
                    </div>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { registrationForm, pageLoading, status, error, message } = state.RegisterReducer;
    const { email } = registrationForm;
    return { email, registrationForm, pageLoading, status, error, message }
}

export default withRouter(connect(mapStateToProps)(CheckEmailSection))
