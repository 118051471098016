import {
    ACTIVITIES_FAILURE,
    ACTIVITIES_REQUEST,
    ACTIVITIES_SUCCESS,
    CLEAR_ALL,GET_USER_RESULT_FAILURE,GET_USER_RESULT_REQUEST,GET_USER_RESULT_SUCCESS,
    UPLOAD_RESUME_REQUEST,
    UPLOAD_RESUME_SUCCESS,
    UPLOAD_RESUME_FAILURE,
} from "../../../../types/dashboard/user/activities/activities-types";
import {GET_API} from "../../../../middleware/token/get-api";
import { driveApi,authApi } from "../../../ApiActions";
import { logout } from "../../../account/login-actions";

const AUTH_URL = authApi();
const DRIVE_URL=driveApi();
// calls api to get activities (user/admin)
export function getActivities(portal) {
    if (portal === "/v1/apply") {
        return {
            [GET_API]: {
                endpoint: AUTH_URL + portal,
                types: [ACTIVITIES_REQUEST, ACTIVITIES_SUCCESS, ACTIVITIES_FAILURE]
            }
        }
    } else {
        return {
            [GET_API]: {
                endpoint: AUTH_URL + portal,
                types: [ACTIVITIES_REQUEST, ACTIVITIES_SUCCESS, ACTIVITIES_FAILURE]
            }
        }
    }
}

export function getUserTestResult(){
    return{
        [GET_API]:{
            endpoint: DRIVE_URL +'/v1/user/completed',
            types:[GET_USER_RESULT_REQUEST,GET_USER_RESULT_SUCCESS,GET_USER_RESULT_FAILURE]
        }
    }
}
export function uploadResume(file,email) {
    let formData = new FormData();
    formData.append('resume',file)
    formData.append('email',email)
    let status = "";
    const config = {
        method: "POST",
        headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`},
        body: formData
    };
    return dispatch => {
        dispatch(uploadResumeRequest());
        fetch(AUTH_URL+'/v1/uploadresume', config)
            .then(function (res) {
                status = res.status;
                return res.json()
            })
            .then(function (res) {
                    if (status === 200 && !res.error) {
                        dispatch(uploadResumeSuccess({data: {error: false, message: res.message}, status: 200}));
                    }
                    else {
                        if (status === 401) {
                            dispatch(logout())
                        }
                        else {
                            dispatch(uploadResumeFailure({data:{error: true, message: res.message}, status: status}))
                        }
                    }},
                function () {
                    dispatch(uploadResumeFailure({data:{message: "Error while updating resume", error: true}, status: 500}))
                })
    }
}
export function uploadResumeRequest(){
    return{type:UPLOAD_RESUME_REQUEST}
}

export function uploadResumeSuccess(response){
    return{type:UPLOAD_RESUME_SUCCESS,response}
}

export function uploadResumeFailure(response){
    return{type:UPLOAD_RESUME_FAILURE,response}
}

// calls action to clear error message, response
export function clearAll() {
    return {type: CLEAR_ALL}
}

