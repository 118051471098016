import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AdminOnPageNavigation from "../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation";
import {
  getDrive,
} from "../../../../../actions/dashboard/admin/drive/drive";
import {
  Table,
  TableHeaderRow,
  TableHeader
} from "../../../../../components/dashboard/admin/xd-react-table/react-flex-table";
import { PulseLoader } from "react-spinners";
import Select from "react-select";
import { getAnalytics } from "../../../../../actions/dashboard/result/results";
import {Helmet} from "react-helmet";

class AnalyticalDashboard extends Component {
  componentDidMount() {
    
    this.props.dispatch(getDrive())
  }

  handleClick(id, driveName) {
    localStorage.setItem("drive_name", driveName);
    localStorage.setItem("drive_id", id);
  }
  handleChange = selectedOption => {
    // selectedOption can be null when the `x` (close) button is clicked
    this.props.dispatch(getAnalytics(selectedOption.value));
  };

  render() {
    let data=[]
    if(this.props.drives.length>0){
        let driveList = this.props.drives
        for(let i=0;i<this.props.drives.length;i++){
            data.push({"label": driveList[i].name , "value": driveList[i].id})
        }
    }
    return (
      
      <div>
      <Helmet>
                <title>Result Analytics | Career Portal | Xenonstack</title>
             </Helmet>
        <AdminOnPageNavigation
          parentRoute="Dashboard"
          childRoute={"Analytics"}
        />
     
        <div className="row">
          <div className="col-2 col-sm-2 col-md-2">
            
            <h3>Select Drive</h3> 
          </div>
          <div className="col-8 col-sm-8 col-md-8">
            <Select
              name="form-field-name"
             
              onChange={this.handleChange}
              options={data}
            />
          </div>
        </div>
 
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="panel panel-default card-view custom-panel pool-panel">
              <div className="panel-heading">
                <h5 className="panel-title">Top Accuracy & Score</h5>
              </div>
              <div className="panel-wrapper collapse in">
                <div className="panel-body">
                  <div className="table-wrap">
                    <div className="table-responsive">
                      <div className="text-center">
                        {this.props.getAnalytics_PageLoading && (
                          <PulseLoader
                            margin="30px"
                            sizeUnit={"px"}
                            size={15}
                            color={"#48194e"}
                            loading={this.props.getAnalytics_PageLoading}
                          />
                        )}
                      </div>
                    {( !this.props.getAnalytics_PageLoading && this.props.overAllResult.length>0) &&
                     
                      <div className="react-xd-table-wrapper">
                        <Table
                          data={this.props.overAllResult} 
                          pageSize={this.props.defaultPageSize}
                          pageSizeOptions={[10,20,30,40,50]}
                          currentPage={this.props.pageNumber}
                              render={({
                              rows,
                              
                              }) => {
                            return (
                              <table className="react-xd-table striped-xd-table">
                                <thead className="xd-table-thead">
                                  <tr className="xd-table-row xd-table-th">
                                    <TableHeaderRow>
                                      {/* <TableHeader accessor="sr_no">
                                        <th>Sr No</th>
                                      </TableHeader> */}
                                      <TableHeader accessor="email">
                                        <th>Email</th>
                                      </TableHeader>
                                      <TableHeader accessor="questions">
                                        <th>Questions</th>
                                      </TableHeader>
                                      <TableHeader accessor="attempted">
                                        <th>Attempted</th>
                                      </TableHeader>
                                      <TableHeader accessor="accuracy">
                                        <th>Accuracy</th>
                                      </TableHeader>
                                      <TableHeader accessor="percentage">
                                        <th>Percentage</th>
                                      </TableHeader>
                                      <TableHeader accessor="correct">
                                        <th>Correct</th>
                                      </TableHeader>
                                     
                                    </TableHeaderRow>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rows.map(({ rowKey, rowData }) => {
                                    return (
                                      <tr
                                        key={rowKey}
                                        className="xd-table-row xd-table-td"
                                      >
                                        {rowData.map(
                                          ({
                                            accessor,
                                            data,
                                            accessor_name,
                                            key
                                          }) => {
                                            return <td key={key}>{data}</td>;
                                          }
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            );
                          }}
                        />
                      </div>
                    }
                    </div>
                  </div>
                  {
                      (!this.props.getAnalytics_PageLoading && this.props.overAllResult.length===0) &&
                    
                        <div className="text-center">
                        Select the drive from dropdown to see results!
                        </div>
                  }    
                </div>
              </div>

            </div>
            
          </div>
        </div>
                
        {/*-----------------------------------------------------------------------------------------------------------------------------*/}
        {/* {(!this.props.getAnalytics_PageLoading && this.props.poolResult.length>0) && */}
        <div className="row">
      {( !this.props.getAnalytics_PageLoading && this.props.poolResult.length > 0)&&this.props.poolResult.map((d,i)=>(
      <> 
        
          <div className="col-6 col-sm-6 col-md-6">
            <div className="panel panel-default card-view custom-panel pool-panel">
              <div className="panel-heading">
                <h5 className="panel-title">{d.pool_name}</h5>
              </div>
              <div className="panel-wrapper collapse in">
                <div className="panel-body">
                  <div className="table-wrap">
                    <div className="table-responsive">
                      <div className="text-center">
                        {this.props.getDrivePageLoading && (
                          <PulseLoader
                            margin="30px"
                            sizeUnit={"px"}
                            size={15}
                            color={"#48194e"}
                            loading={this.props.getDrivePageLoading}
                          />
                        )}
                      </div>

                      <div className="center"></div>
                    
                      <div className="react-xd-table-wrapper">
                        <Table
                          data={d.candidates}
                          pageSize={this.props.defaultPageSize}
                          pageSizeOptions={[10,20,30,40,50]}
                          currentPage={this.props.pageNumber}
                              render={({
                              rows,
                              
                              }) => {
                            return (
                              <table className="react-xd-table striped-xd-table">
                                <thead className="xd-table-thead">
                                  <tr className="xd-table-row xd-table-th">
                                    <TableHeaderRow>
                                     
                                      <TableHeader accessor="email">
                                        <th>Email</th>
                                      </TableHeader>
                                     
                                      <TableHeader accessor="accuracy">
                                        <th>Accuracy</th>
                                      </TableHeader>
                                      <TableHeader accessor="percentage">
                                        <th>Percentage</th>
                                      </TableHeader>
                                    </TableHeaderRow>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rows.map(({ rowKey, rowData }) => {
                                    return (
                                      <tr
                                        key={rowKey}
                                        className="xd-table-row xd-table-td"
                                      >
                                        {rowData.map(
                                          ({
                                            accessor,
                                            data,
                                            accessor_name,
                                            key
                                          }) => {
                                            return <td key={key}>{data}</td>;
                                          }
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { pageNumber, defaultPageSize } = state.poolReducer;
  const {
    getAnalytics_PageLoading,
    getAnalytics_status,
    getAnalytics_error,
    getAllPoolwiseResult_message,
    overAllResult,poolResult
  } = state.adminResultReducer;
  const {drives}=state.adminDriveReducer
  return {
    pageNumber,
    defaultPageSize,
    getAnalytics_PageLoading,
    getAnalytics_status,
    getAnalytics_error,
    getAllPoolwiseResult_message,
    drives,overAllResult,poolResult
  };
};

export default withRouter(connect(mapStateToProps)(AnalyticalDashboard));
