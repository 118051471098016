import React from "react";
import moment from "moment";
const CustomCard = props => {
  function onCardClickEvent(e) {
    e.preventDefault();
    document.querySelectorAll(".create-creating-pool.txt-form .form-group")[0].classList.remove("has-error");
    document.getElementsByClassName("overlay-edit-pool")[0].style.display = "block";
  }
  console.log(props, '>>>>>>>>>>>>>>>>>>>>>>>..')
  return (
    <>
      <div style={{ padding: 6, position: "relative" }}>
        <header>
          <div style={{ fontSize: 14, fontWeight: "bold", cursor: "grabbing" }}>
            {props.name}
          </div>
        </header>
        <div className="inner-cardheader">
          <p> {moment(props.date * 1000).format("Do MMM YYYY")}   </p>
        </div>
        <div
          style={{
            fontSize: "13px",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          {props.comment}
          <i
            style={{ cursor: "pointer" }}
            onClick={e => onCardClickEvent(e)}
            class="fa fa-comment-o"
            style={{ marginLeft: 5 }}
          />
        </div>
      </div>
      {/* <div id="popup1" className="overlay overlay-edit-pool">
        <form
          className="create-creating-pool txt-form"
          onSubmit={e => props.onSubmit(e)}
        >
          <div className="board-popup" style={{ position: "absolute" }}>
            <div className="board-popup-heading">
              <h4>{props.getCardId}</h4>
              <a className="board-close" onClick={props.onClosePopUp}>
                &times;
              </a>
            </div>
            <div className="content">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  minLength={3}
                  maxLength={50}
                  pattern={
                    "([a-zA-Z.!-”$%&’()*\\+,\\/;\\[\\\\\\]\\^_`{|}~]+\\s)*[a-zA-Z.!-”$%&’()*\\+,\\/;\\[\\\\\\]\\^_`{|}~]+$"
                  }
                  className="form-control"
                  value={props.comments}
                  name="comments"
                  onChange={props.onChange}
                  placeholder="Write Comment"
                  required={true}
                />
                <p className="with-error">Error.</p>
              </div>
              <div className="btn-group ">
                <button type="submit" className="btn">
                  Add Comment
                </button>
              </div>
            </div>
            <div className="content">
              <p>
                <ul>
                      {this.state.comments.map(comment=>
                      (<li>{comment}</li>))}
                    </ul>

              </p>
            </div>
          </div>
        </form>
        <div className="popup">
            <div className="popup-heading">
            <h5>Comments:</h5>
            </div>
            </div>

      </div> */}
    </>
  );
};

export default CustomCard;
