import React, {Component} from 'react';
import {withRouter , NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import AdminOnPageNavigation from '../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import { Table, TableRow, TableBody, TableData, TableHeaderRow, TableHeader } from '../../../../components/dashboard/admin/xd-react-table/react-flex-table'
import { TableHeadComponent , ThComponent, PageSizeChooser} from '../../../../components/dashboard/admin/xd-react-table/react-flex-table/components';
import { PulseLoader } from 'react-spinners';
import Pagination from 'react-js-pagination'
import { handlePaginationPropsChange} from "../../../../actions/dashboard/admin/pool/pool";
import { getAllPoolwiseResult } from '../../../../actions/dashboard/result/results'
import {Helmet} from "react-helmet";

class PoolList extends Component {
    
    handlePageChange = e => {
        this.props.dispatch(handlePaginationPropsChange(e, this.props.defaultPageSize))
        this.props.history.push('/dashboard/result/pool'+'/page='+e+'&pageSize='+this.props.defaultPageSize)
      }
      handlePageSizeChange = e => {
        
        this.props.dispatch(handlePaginationPropsChange(this.props.pageNumber, Number(e.target.value)))
        this.props.history.push('/dashboard/result/pool'+'/page='+this.props.pageNumber+'&pageSize='+e.target.value)
      }
      componentDidMount() {
        this.props.dispatch(handlePaginationPropsChange(Number(this.props.match.params.pageNumber), Number(this.props.match.params.pageSize)))
        this.props.dispatch(getAllPoolwiseResult())
    }

      handleClick(poolId,poolName){
        localStorage.setItem("pool_name",poolName)
        localStorage.setItem("pool_id",poolId)
        
    } 

    render() { 
       
        return (
                <div>
                <Helmet>
                <title>Poolwise Result | Career Portal | Xenonstack</title>
             </Helmet>
                <AdminOnPageNavigation parentRoute="Dashboard" childRoute="Pool Result"/>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <div className="panel panel-default card-view custom-panel pool-panel">
                            <div className="panel-heading">
                                <h5 className="panel-title">All Pools</h5>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                                 <div className="table-wrap">
                                        <div className="table-responsive">
                                        <div className="text-center">
                                        {this.props.getAllPoolwiseResult_PageLoading &&
                                         <PulseLoader 
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#48194e'}
                                         loading={this.props.getAllPoolwiseResult_PageLoading} 
                                         />
                                        }</div>
                                        <div className="react-xd-table-wrapper">
                                            {!this.props.getAllPoolwiseResult_PageLoading && this.props.allpoolwiseresult.length > 0 &&
                                                        <Table
                                                        data={this.props.allpoolwiseresult}
                                                        pageSize={this.props.defaultPageSize}
                                                        pageSizeOptions={[10,20,30,40,50]}
                                                        currentPage={this.props.pageNumber}
                                                            render={({
                                                            rows,
                                                            pageSize,
                                                            pageSizeOptions,
                                                            handlePageSizeChange,
                                                            handlePrevPage,
                                                            handleNextPage,
                                                            hasNextPage,
                                                            hasPrevPage,
                                                            currentPage,
                                                            total
                                                            }) => {
                                                            return (
                                                                <React.Fragment>
                                                                <div className="react-xd-table-body">
                                                                    <table className="react-xd-table striped-xd-table sortable-table footer-pagination-table">
                                                                    <TableHeaderRow component={TableHeadComponent}>
                                                                    <TableHeader
                                                                        accessor="sr_no"
                                                                        accessor_name="Sr. No."
                                                                        component={ThComponent}
                                                                        >
                                                                        Sr. No .
                                                                        </TableHeader>
                                                                    <TableHeader
                                                                        accessor="name"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Name
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor="rank"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Rank
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor="total"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Candidates
                                                                    </TableHeader>    
                                                                    <TableHeader
                                                                        accessor="percentage"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Percentage 
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor="correct"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Correct
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor="attempted"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                       Attempted
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor="accuracy"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >Accuracy</TableHeader>
                                                                       </TableHeaderRow>
                                                                        <TableBody
                                                                            component="tbody"
                                                                            >
                                                                            {rows.map(({ rowKey, rowData, selected }, rowIndex) => (
                                                                            <TableRow
                                                                                component="tr"
                                                                                className="xd-table-row xd-table-td"
                                                                                key={rowKey}
                                                                            >
                                                                            {rowData.map(({  accessor, data, key , rowsData }) => (
                                                                                
                                                                               <TableData
                                                                                component="td"
                                                                                key={key}
                                                                                >
                                                                                {accessor ? 
                                                                                (accessor === 'name' ? (<NavLink to={"/dashboard/result/poolwise/"+rowsData.id+"/candidates/page=1&pageSize=10"} onClick={() => this.handleClick( rowsData.id,rowsData.name)}>{data}</NavLink> ) :
                                                                                accessor === 'sr_no' ? rowIndex + (this.props.defaultPageSize * (this.props.pageNumber-1))+1:
                                                                                accessor === 'rank' ? ((rowsData.rank === 'same')&&(<i className="material-icons same">remove</i>)||(rowsData.rank === 'up')&&(<i className="material-icons greenarrow">arrow_upward</i>)||(rowsData.rank === 'down')&&(<i className="material-icons redarrow">arrow_downward</i>)) :             
                                                                                (data)
                                                                                ) :
                                                                                
                                                                                ( <div></div>
                                                                                )}
                                                                                
                                                                                </TableData>
                                                                            ))}
                                                                            
                                                                            </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                        </table>
                                                                        </div>
                                                                        <div className="react-xd-table-footer">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-5 col-md-4">
                                                                                <div className="react-xd-pagesize-chooser">
                                                                                    <span>Rows per page:</span>
                                                                                    <PageSizeChooser
                                                                                    pageSize={this.props.defaultPageSize}
                                                                                    pageSizeOptions={pageSizeOptions}
                                                                                    handlePageSizeChange={this.handlePageSizeChange.bind(this)}
                                                                                    />
                                                                                </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-7 col-md-8">
                                                                                <div className="react-xd-pagination-container">
                                                                                <div className="pagination-outer">
                                                                                    <div className="pagination-group">
                                                                                    <Pagination
                                                                                        firstPageText={"First"}
                                                                                        lastPageText="Last"
                                                                                        prevPageText={<i className='fa fa-angle-left'/>}
                                                                                        nextPageText={<i className='fa fa-angle-right'/>}
                                                                                        activePage={this.props.pageNumber}
                                                                                        itemsCountPerPage={this.props.defaultPageSize}
                                                                                        totalItemsCount={total}
                                                                                        pageRangeDisplayed={3}
                                                                                        onChange={this.handlePageChange.bind(this)}
                                                                                        />
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                
                                                                    </React.Fragment>
                                                                )
                                                                }}
                                                            />}

                                                            </div>
                                                            {
                                                    (!this.props.getAllPoolwiseResultPageLoading && this.props.allpoolwiseresult.length === 0 && this.props.getAllPoolwiseResultPage_message === "") &&
                                                     
                                                            <div className="not-found">
                                                                No Pools found !!
                                                            </div>
                                                         
                                                }
                                               
                                                   

                                    
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


const mapStateToProps = (state) => {
    
    const { pageNumber,defaultPageSize} = state.poolReducer;
    const {
        getAllPoolwiseResult_PageLoading,
        getAllPoolwiseResultPage_status,
        getAllPoolwiseResultPage_error,
        getAllPoolwiseResultPage_message,
        allpoolwiseresult,
        } = state.adminResultReducer;
    
    return {
        pageNumber,defaultPageSize,
        getAllPoolwiseResult_PageLoading,
        getAllPoolwiseResultPage_status,
        getAllPoolwiseResultPage_error,
        getAllPoolwiseResultPage_message,
        allpoolwiseresult,
       
    }
};
export default withRouter(connect(mapStateToProps)(PoolList))
