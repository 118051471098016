import * as type from '../../../../types/dashboard/admin/invite/admin_list';
import {GET_API} from "../../../../middleware/token/get-api";
import {CALL_DELETE_API} from "../../../../middleware/token/delete/without-body";
import {CALL_POST_API} from "../../../../middleware/token/post-api";
import { authApi } from "../../../ApiActions";

const BASE_URL=authApi();

export function getAdminList(){
    return{
        [GET_API]:{
            endpoint: BASE_URL +'/v1/adminAccount',
            types:[type.GET_ADMIN_LIST_REQUEST,type.GET_ADMIN_LIST_SUCCESS,type.GET_ADMIN_LIST_FAILURE]
        }
    }
}


export function changeCreateAdminForm(newState) {
   
    return {type: type.CHANGE_CREATE_ADMIN_FORM, newState}
}
export function createAdmin(body){
  
    return{
        [CALL_POST_API]:{
            endpoint: BASE_URL + '/v1/adminAccount',
            body:body,
            types:[type.CREATE_ADMIN_REQUEST,type.CREATE_ADMIN_SUCCESS,type.CREATE_ADMIN_FAILURE]
        }
    }
}

export function selectedDeleteAdmin(list){
    return{
        type:type.DELETE_SELECTED_ADMIN,
        list
    }
}
export function deleteAdminList(admin_id){
    return{
        [CALL_DELETE_API]:{
            endpoint:BASE_URL+ '/v1/adminAccount/'+admin_id,
            types:[type.DELETE_ADMIN_LIST_REQUEST,type.DELETE_ADMIN_LIST_SUCCESS,type.DELETE_ADMIN_LIST_FAILURE]

        }
    }
}
export function clearStatus(){
    return{
        type: type.CLEAR_STATUS
    }
}
export function clearAll(){
    return{
        type: type.CLEAR_ALL
    }
}