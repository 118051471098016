import React, {Component} from 'react';
import {withRouter, NavLink} from 'react-router-dom';
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import {connect} from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./dist/css/homepage/home.css";
import Helmet from 'react-helmet';
import { clearAll } from '../../actions/dashboard/admin/team/team';
const IMAGE_URL= process.env.REACT_APP_IMAGE_URL === undefined ? "https://www.xenonstack.com/images/career": process.env.REACT_APP_IMAGE_URL;

class HomePage extends Component {
    componentWillMount(){
        window.scrollTo(0, 0);
        this.props.dispatch(clearAll())
    }
    render() {
        const settings = {
			customPaging: function(i) {
			  return (
				<a>
					<span>0{i + 1}</span>
				</a>
			  );
			},
			dots: true,
			dotsClass: "slick-dots slick-thumb",
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		  };
        return (
        <>
            <Helmet>
                <title>XenonStack Careers - Opportunities in Automation, Analytics and AI</title>
                <meta name="description" content="XenonStack Job Openings in Product Engineering, DevOps, AI, ML, Big Data Analytics, Data Science & Sales in India." />

                {/* FACEBOOK OPENGRAPH META  */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="Website" />
                <meta property="og:title" content="XenonStack Careers - Opportunities in Automation, Analytics and AI" />
                <meta property="og:description" content="XenonStack Job Openings in Product Engineering, DevOps, AI, ML, Big Data Analytics, Data Science & Sales in India." />
                <meta property="og:url" content="https://careers.xenonstack.com/" />
                <meta property="og:site_name" content="XenonStack" />
                <meta property="og:image" content="https://www.xenonstack.com/images/career/og_image.png" />

                {/* TWITTER CARD META */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@XenonStackCareers"/>
                <meta name="twitter:creator" content="@XenonStackCareers"/>
                <meta name="twitter:url" content="https://careers.xenonstack.com/" />
                <meta name="twitter:title" content="XenonStack Careers - Opportunities in Automation, Analytics and AI" />
                <meta name="twitter:description" content="XenonStack Job Openings in Product Engineering, DevOps, AI, ML, Big Data Analytics, Data Science & Sales in India." />
                <meta name="twitter:image" content="https://www.xenonstack.com/images/career/og_image.png" />
            </Helmet>
			<Header/>
            <main>
                <section className="banner-section home-banner" id="page-banner">
                    <div className="home-banner-container banner-container" id="banner-container">
                        <div className="banner-background-outer home-banner-background"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-10 col-md-6">
                                    <div className="banner-content-box" id="banner-content-box">
                                        <div className="banner-content-box-item">
                                            <span className="text-badge">We At XenonStack</span>
                                            <h1 className="wb-banner-heading">Unleash Your Talent and Get Ready to Make a Difference
                                            </h1>
                                            <p className="wb-page-desc">Work with our team and be a part of the workforce of tomorrow - 
                                            Data Engineering, Data Science, DevOps, Data Visualization, and Cognitive Computing.</p>
                                            <div className="banner-job-search">
                                                <input type="text" className="search-job" placeholder="Search XenonStack Jobs" name="search"/>
                                                <button type="submit" className="search-btn"><i className="fa fa-search"></i></button>
                                            </div>
                                            <NavLink to={"/jobs/"} className="btn">View All Jobs</NavLink>									
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="why-xenonstack-section mar-t-8" id="why-xenonstack">
                    <div className="container">
                        <div className="why-xenonstack-container" id="why-xenonstack-container">
                            <div className="why-xenonstack-wrapper">
                                <div className="section-heading-box" id="section-heading">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-5">
                                            <h2 className="wb-banner-heading">Why XenonStack?</h2>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-7">
                                            <p className="wb-page-desc light-text">A Product Engineering, Technology Services and Consulting 
                                            company building Intelligent Distributed Systems at Scale, AI, and Data-driven Decision Platforms 
                                            and Solutions. We are enabling enterprises for Digital transformation with Cloud, 
                                            Data and AI Strategy and Enterprise Agility.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="xenonstack-points-box">
                                    <div className="box-item">
                                        <div className="box-item-content">
                                            <figure><img src={IMAGE_URL + "/pattern-icon-1.svg"} alt="pattern shape" /></figure>
                                            <h3 className="box-title">Experienced and Adaptive Team</h3>
                                        </div>
                                    </div>
                                    <div className="box-item">
                                        <div className="box-item-content">
                                            <figure><img src={IMAGE_URL+"/pattern-icon-2.svg"} alt="pattern shape" /></figure>
                                            <h3 className="box-title">Products and Solutions</h3>
                                        </div>
                                    </div>
                                    <div className="box-item">
                                        <div className="box-item-content">
                                            <figure><img src={IMAGE_URL+"/pattern-icon-3.svg"} alt="pattern shape" /></figure>
                                            <h3 className="box-title">Trusted by Customers</h3>
                                        </div>
                                    </div>
                                    <div className="box-item">
                                        <div className="box-item-content">
                                            <figure><img src={IMAGE_URL+"/pattern-icon-4.svg"} alt="pattern shape" /></figure>
                                            <h3 className="box-title">300+ Projects Delivered</h3>
                                        </div>
                                    </div>
                                    <div className="box-item">
                                        <div className="box-item-content">
                                            <figure><img src={IMAGE_URL+"/pattern-icon-5.svg"} alt="pattern shape" /></figure>
                                            <h3 className="box-title">Certified Solutions Specialist</h3>
                                        </div>
                                    </div>
                                    <div className="box-item">
                                        <NavLink to="/xenonstack-life/" className="btn">Read More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="build-section mar-t-8" id="build-for-scale">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                                <div className="section-heading-box" id="section-heading">
                                    <span className="text-badge">Our Vision</span>
                                    <h2 className="wb-page-heading">​Let’s be outstanding</h2>
                                    <p className="wb-page-desc">We are empowering workforce transformation by welcoming you to a place where
                                     your ideas lead to something huge and where you are recognised.</p>
                                </div>
                            </div>
                        </div>
                        <div className="Build-scale-boxes three-boxes-container" id="three-boxes-container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4 mar-b-30">
                                    <div className="three-boxes-item item-with-icon">
                                        <figure><img src={IMAGE_URL+"/xenonstack-careers-big-challange-icon.svg"} 
                                        alt="XenonStack Careers Take on big challenges Icon" /></figure>
                                        <h3 className="card-heading">Take on big challenges</h3>
                                        <p className="card-description">We enjoy working on enigmas together because the most 
                                        difficult challenges are usually the most rewarding.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mar-b-30">
                                    <div className="three-boxes-item item-with-icon">
                                        <figure><img src={IMAGE_URL+"/xenonstack-careers-empowering-individuals-icon.svg"} 
                                        alt="XenonStack Careers Empowering Individuals Icon" /></figure>
                                        <h3 className="card-heading">Empowering Individuals</h3>
                                        <p className="card-description">Join our diverse group of pioneers who work collectively 
                                        to solve the next-gen industry's challenges.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mar-b-30">
                                    <div className="three-boxes-item item-with-icon">
                                        <figure><img src={IMAGE_URL+"/xenonstack-careers-shape-future-icon.svg"} 
                                        alt="XenonStack Careers Shape your Future Icon" /></figure>
                                        <h3 className="card-heading">Shape your Future</h3>
                                        <p className="card-description">Reinvent everyday using cutting-edge technologies and market 
                                        leading strategies.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="get-started-section mar-t-8" id="get-started">
                    <div className="container">
                        <div className="xenonstack-pillars-container" id="pillars-container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4">
                                    <div className="section-heading-box" id="section-heading">
                                        <span className="text-badge">Our Mission</span>
                                        <h2 className="wb-page-heading">We are Just Getting started...</h2>
                                        <p className="wb-page-desc">People are an integral part of our Organization. We welcome all 
                                        passionate individuals to accompany us on this journey and make an impact. We respect proactive 
                                        and young dynamic people who are not hesitant to dream big and have the technical proficiency in 
                                        executing through.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-8">
                                    <div className="pillars-list-boxes" id="pillars-list-boxes">
                                        <div className="boxes-item single-box">
                                            <div className="pillars-list-item">
                                                <figure><img src={IMAGE_URL+"/xenonstack-careers-devops-sre-icon.svg"}
                                                 alt="XenonStack Careers Devops and SRE Icon" /></figure>
                                                <h3>Devops and SRE</h3>
                                                <p>Enterprise DevOps Transformation with Continuous Integration and Deployment.</p>
                                            </div>
                                        </div>
                                        <div className="boxes-item double-box">
                                            <div className="pillars-list-item">
                                                <figure><img src={IMAGE_URL+"/xenonstack-careers-big-data-and-iot-icon.svg"}
                                                 alt="XenonStack Careers Big Data Analytics & IoT Icon" /></figure>
                                                <h3>Big Data Analytics & IoT</h3>
                                                <p>Enterprise DataOps Strategy and Consulting Solutions for Data Governance.</p>
                                            </div>
                                            <div className="pillars-list-item">
                                                <figure><img src={IMAGE_URL+"/xenonstack-careers-cyber-security-icon.svg"}
                                                 alt="XenonStack Careers Cyber Security Icon" /></figure>
                                                <h3>Cyber Security</h3>
                                                <p>Continuous Cloud Security Solutions with DevSecOps, IT Infrastructure Security.</p>
                                            </div>
                                        </div>
                                        <div className="boxes-item single-box">
                                            <div className="pillars-list-item">
                                                <figure><img src={IMAGE_URL+"/xenonstack-careers-ai-quantum-icon.svg"}
                                                 alt="XenonStack Careers AI and Quantum Icon" /></figure>
                                                <h3>AI and Quantum</h3>
                                                <p>Services and Solutions for Productionizing Machine Learning Models.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="core-value-section mar-t-8" id="core-value">
                    <div className="container">
                        <div className="home-slider-container">
                        <Slider {...settings}>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <div className="slider-content-item-box">
                                            <div className="slider-content-item">
                                                <span className="text-badge">Core Values</span>
                                                <h2 className="wb-banner-heading">Open Culture, <span>No Bullshit</span> </h2>
                                                <p className="wb-page-desc">Communicates Transparently and Constructively while taking Accountability for their Mistakes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <figure>
                                            <img src={IMAGE_URL+"/careers-core-value-open-culture-image.svg"} 
                                            alt="XenonStack Careers Open Culture, No Bullshit Image"/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <div className="slider-content-item-box">
                                            <div className="slider-content-item">
                                                <span className="text-badge">Core Values</span>
                                                <h2 className="wb-banner-heading">Build with Heart <span>and Balance</span> </h2>
                                                <p className="wb-page-desc">Brings passion and genuine care to work and How they relate to others.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <figure><img src={IMAGE_URL+"/careers-core-value-build-with-heart-image.svg"} 
                                        alt="XenonStack Careers Build with Heart and Balance Image"/></figure>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <div className="slider-content-item-box">
                                            <div className="slider-content-item">
                                                <span className="text-badge">Core Values</span>
                                                <h2 className="wb-banner-heading">Play, <span>as a Team </span></h2>
                                                <p className="wb-page-desc">Creates opportunities for others to share their voice and make sure Teammates feel Valued and Included.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <figure><img src={IMAGE_URL+"/careers-core-value-play-as-team-image.svg"} 
                                        alt="XenonStack Careers Play, as a Team Image"/></figure>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <div className="slider-content-item-box">
                                            <div className="slider-content-item">
                                                <span className="text-badge">Core Values</span>
                                                <h2 className="wb-banner-heading">Be the change <span>you Seek</span></h2>
                                                <p className="wb-page-desc">Perseveres through Difficult challanges and drives to a Conclusion when initiating change.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <figure><img src={IMAGE_URL+"/careers-core-value-be-chnage-you-seek-image.svg"} 
                                        alt="XenonStack Careers Be the change you Seek Image"/></figure>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <div className="slider-content-item-box">
                                            <div className="slider-content-item">
                                                <span className="text-badge">Core Values</span>
                                                <h2 className="wb-banner-heading">Don't #@!% the <span> Customer</span> </h2>
                                                <p className="wb-page-desc">Anticipates the Customer's needs and takes Potenial Customer Impact into Account in making decisions.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <figure><img src={IMAGE_URL+"/careers-core-value-dont-customer-image.svg"} 
                                        alt="XenonStack Careers Don't #@!% the Customer Image"/></figure>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                        </div>		
                    </div>
                </section>
                <section className="our-teams-section mar-t-8" id="our-teams">
                    <div className="container">
                        <div className="home-teams-container" id="home-teams-container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4">
                                    <div className="section-heading-box" id="section-heading">
                                            <span className="text-badge">Opportunities</span>
                                            <h2 className="wb-page-heading">Our Teams</h2>
                                            <p className="wb-page-desc">At XenonStack, We offer you many interesting jobs in various working
                                             fields -Data Engineering, Data Science, DevOps, Data Visualization and Internet of Things.</p>
                                            <NavLink to="/teams/" className="btn">View All Teams</NavLink>
                                        </div>
                                    </div>
                                <div className="col-12 col-sm-12 offset-md-1 col-md-7">
                                    <figure><img  src={IMAGE_URL+"/xenonstack-careers-home-teams-image.png"}
                                     alt="XenonStack Careers Home Page Team Image" /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="hire-process mar-t-8" id="hire-process">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                                <div className="section-heading-box">
                                    <span className="text-badge">Hiring Process</span>
                                    <h2 className="wb-page-heading">How We Hire</h2>
                                    <p className="wb-page-desc">At XenonStack, We’re looking for talented people to do work that they 
                                    love at all levels of the company. Our hiring process is straightforward.</p>
                                </div>
                            </div>
                        </div>
                        <div className="three-boxes-container hire-process-boxes" id="three-boxes-container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-3 mar-b-30">
                                    <div className="three-boxes-item item-with-icon with-center hire-process one">
                                        <figure><img  src={IMAGE_URL+"/xenonstack-hiring-online-application.svg"}
                                        alt="XenonStack Careers Online application Icon" /></figure>
                                        <div className="hire-process-item-content">
                                        <h3 className="card-heading">Online application </h3>
                                        <p className="card-description">Found a role that exhibits your interest and passion.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 mar-b-30">
                                    <div className="three-boxes-item item-with-icon with-center hire-process two">
                                        <figure><img  src={IMAGE_URL+"/xenonstack-hiring-hear-your-story.svg"}
                                        alt="XenonStack Careers Let’s hear your story Icon" /></figure>
                                        <div className="hire-process-item-content">
                                        <h3 className="card-heading">Let’s hear your story</h3>
                                        <p className="card-description">Discussing about your aspirations and experiences and finding out where you fit.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 mar-b-30">
                                    <div className="three-boxes-item item-with-icon with-center hire-process three">
                                        <figure><img  src={IMAGE_URL+"/xenonstack-hiring-online-assessment.svg"}
                                        alt="XenonStack Careers Online Assessment Icon" /></figure>
                                        <div className="hire-process-item-content">
                                        <h3 className="card-heading">Online Assessment</h3>
                                        <p className="card-description">This step of the process is outlined to suit your functioning routine and lifestyle.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12  col-sm-6 col-md-3 mar-b-30">
                                    <div className="three-boxes-item item-with-icon with-center hire-process four">
                                        <figure><img  src={IMAGE_URL+"/xenonstack-hiring-decide.svg"}
                                        alt="XenonStack Careers Decide Icon" /></figure>
                                        <div className="hire-process-item-content">
                                        <h3 className="card-heading">Decide</h3>
                                        <p className="card-description">Let’s decide and finalize if we have a future together.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="with-center">
                                        <NavLink to="/xenonstack-life/how-we-hire/" className="btn mar-t-2">Read More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
		</>
        )
    }
}

export default withRouter(connect(null)(HomePage))
