
const startFrom = 1990
const currentDate = new Date()
const endAt = currentDate.getFullYear() + 10

let years = []

for(var i = startFrom; i <= endAt; i++){
    years.push(i)
}

export const startEndYear = years;