import React from "react";
import {
  deleteLane,
  editLane
} from "../../../../actions/dashboard/admin/wekanboard/wekanboard";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
class CustomLane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  deleteLane = (e,id) => {
    e.preventDefault();
    if (id) {
      this.props.dispatch(deleteLane(id));

    }
  };
  edit = () => {
    this.setState({ editing: true });
  };
  save = (e,id, title) => {
    e.preventDefault();
    let val = this.refs.newText.value;
    this.setState({editing: false });
    const self = this.props;

    const body = { roundname: val };
    self.dispatch(editLane(id, body));
  };
  renderNormal = () => {
    return (
      <header
        style={{
          paddingBottom: 0,
          marginBottom: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <p style={{ fontSize: 14, fontWeight: "bold" }}>{this.props.title}</p>
        <div style={{ width: "30%", textAlign: "right", fontSize: 13 }}>
          <span onClick={this.edit}>
            <i
              style={{ fontSize: "18px", cursor: "pointer" }}
              className="material-icons"
            >
              edit
            </i>
          </span>
          <span onClick={(e) => this.deleteLane(e,this.props.id)}>
            <i
              style={{ fontSize: "18px", cursor: "pointer" }}
              className="material-icons"
            >
              delete
            </i>
          </span>
        </div>
      </header>
    );
  };
  renderForm = () => {
    return (
      <header
        style={{
          paddingBottom: 0,
          marginBottom: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <input
          style={{ height: 26 }}
          pattern="[a-zA-Z][a-zA-Z.\s]{2,}$"
          className="form-control"
          ref="newText"
          defaultValue={this.props.title}
          placeholder="enter title"
        />
        <div style={{ width: "30%", textAlign: "right", fontSize: 13 }}>
          <button
            style={{ cursor: "pointer" }}
            onClick={(e) => this.save(e,this.props.id, this.props.title)}
          >
            done
          </button>
        </div>
      </header>
    );
  };

  render() {
    return (
      <div>
        {this.state.editing ? this.renderForm() : this.renderNormal()}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    lanes,
    click,
    deleteLaneMessage,
    deleteLaneError,
    editLaneMessage,
    createLane
  } = state.laneReducer;
  return { lanes, click,editLaneMessage, deleteLaneMessage, deleteLaneError, createLane };
}

export default withRouter(connect(mapStateToProps)(CustomLane));
