import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import AdminOnPageNavigation from "../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation";
import {
  changeCreatePoolForm,
  clearAll,
  clearStatus,
  createPool,
  deletePool,
  editPool,
  getAllPool,
  selectedDeletePool,
  getPoolDownload,
  getCSVLinkEmpty,
} from "../../../../../actions/dashboard/admin/pool/pool";
import { PulseLoader } from "react-spinners";
import createNotification from "../../../../../components/app/notification/notification";
import { NotificationContainer } from "react-notifications";
import moment from "moment";
import { checkValidation } from "../../../../../actions/app/app";
import DeleteDialogBox from "../../../../../components/app/dialogBox/delete-dialog-box";
import { Helmet } from "react-helmet";

const BASE_URL = process.env.REACT_APP_DRIVE_API;
class ListPool extends Component {
  componentWillMount() {
    this.props.dispatch(selectedDeletePool({}));
    this.props.dispatch(clearAll());
    this.props.dispatch(getAllPool());
  }

  // submit the form
  submitCreateEditPoolForm(e) {
    e.preventDefault();
    const self = this.props;
    if (e.target.checkValidity()) {
      const body = { name: self.name };
      if (self.operation === "create") {
        self.dispatch(createPool(body));
      } else {
        const editPoolId = self.poolId;
        self.dispatch(editPool(editPoolId, body));
      }
    } else {
      const invalidElmsInput = document.querySelectorAll(
        ".create-creating-pool.txt-form .form-group input:invalid"
      );
      for (let i = 0; i < invalidElmsInput.length; i++) {
        invalidElmsInput[i].parentElement.classList.add("has-error");
      }
    }
  }

  // called when  start date picker focus out
  onChange(e) {
    const self = this.props;
    checkValidation(e);
    const newState = Object.assign(self.container, {
      [e.target.name]: e.target.value,
    });
    self.dispatch(changeCreatePoolForm(newState));
  }

  // called when edit  pool event fired
  editPool(pool) {
    document
      .querySelectorAll(".create-creating-pool.txt-form .form-group")[0]
      .classList.remove("has-error");
    this.props.dispatch(
      changeCreatePoolForm(
        Object.assign(
          this.props.container,
          { name: pool.name },
          { operation: "edit" },
          { poolId: pool.id }
        )
      )
    );
    document.getElementsByClassName("overlay-edit-pool")[0].style.display =
      "block";
  }

  // fired when delete pool action performed
  deletePool(e) {
    e.preventDefault();
    this.props.dispatch(deletePool(this.props.selected));
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }
  downloadPool(e) {
    this.props.dispatch(getPoolDownload(e));
  }
  // opens the dialog
  openDeletePoolDialog(pool) {
    this.props.dispatch(selectedDeletePool(pool));

    document.getElementsByClassName("overlay-delete")[1].style.display =
      "block";
  }

  // closes the dialog
  closeDeletePoolDialog() {
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }

  // create the pool
  createPool(pool) {
    document
      .querySelectorAll(".create-creating-pool.txt-form .form-group")[0]
      .classList.remove("has-error");
    this.props.dispatch(
      changeCreatePoolForm(
        Object.assign(
          this.props.container,
          { name: "" },
          { operation: "create" },
          { poolId: "" }
        )
      )
    );
    document.getElementsByClassName("overlay-edit-pool")[0].style.display =
      "block";
  }

  // closes the popup
  closePopup() {
    if (!!document.getElementsByClassName("overlay-edit-pool")[0]) {
      document.getElementsByClassName("overlay-edit-pool")[0].style.display =
        "none";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isPoolDeletedMessage !== "") {
      if (nextProps.error) {
        this.closePopup();
        createNotification("error", nextProps.isPoolDeletedMessage);
        nextProps.dispatch(clearStatus());
      } else {
        this.closePopup();
        createNotification("success", nextProps.isPoolDeletedMessage);
        nextProps.dispatch(getAllPool());
      }
    }
    if (nextProps.isPoolEditedMessage !== "") {
      if (nextProps.error) {
        this.closePopup();
        createNotification("error", nextProps.isPoolEditedMessage);
        nextProps.dispatch(clearStatus());
      } else {
        this.closePopup();
        createNotification("success", nextProps.isPoolEditedMessage);
        nextProps.dispatch(getAllPool());
      }
    }
    if (nextProps.isPoolCreatedMessage !== "") {
      if (nextProps.error) {
        this.closePopup();
        createNotification("error", nextProps.isPoolCreatedMessage);
        nextProps.dispatch(clearStatus());
      } else {
        this.closePopup();
        createNotification("success", nextProps.isPoolCreatedMessage);
        nextProps.dispatch(getAllPool());
      }
    }
  }

  render() {
    let url = BASE_URL + "/v1/pool_csv/" + this.props.csvData;
    if (this.props.csvData !== "") {
      window.open(url);
      this.props.dispatch(getCSVLinkEmpty());
    }
    let curentPageNumber = this.props.pageNumber;

    return (
      <div>
        <Helmet>
          <title>Manage Pool | Career Portal | Xenonstack</title>
        </Helmet>
        <AdminOnPageNavigation
          parentRoute="Dashboard"
          childRoute="Manage Pool"
        />
        <div className="mar-b-3">
          <button className="btn" onClick={this.createPool.bind(this)}>
            Create Pool
          </button>
        </div>
        <div className="row">
          <div className="text-center">
            {this.props.pageLoading && (
              <PulseLoader
                margin="30px"
                sizeUnit={"px"}
                size={15}
                color={"#48194e"}
                loading={this.props.isPageLoading}
              />
            )}
          </div>
          {!this.props.pageLoading &&
            this.props.pools.length > 0 &&
            this.props.pools.map((d, i) => (
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" key={i}>
                <div className="panel panel-warning card-view custom-panel pool-panel">
                  <div className="panel-heading">
                    <span style={{ flex: "1", paddingRight: "10px" }}>
                      {d.name}
                    </span>
                    <div className="pool-operation">
                      <a
                        href="JavaScript:void(0);"
                        className="material-icons txt-light"
                        onClick={() => this.downloadPool(d.id)}
                      >
                        <i
                          style={{ color: "white" }}
                          className="material-icons"
                        >
                          file_download
                        </i>
                      </a>
                      <a
                        name="edit"
                        href="JavaScript:void(0);"
                        onClick={() => this.editPool(d)}
                      >
                        {" "}
                        <i
                          className="material-icons txt-light"
                          style={{ color: "white" }}
                        >
                          {" "}
                          mode_edit
                        </i>
                      </a>
                      <a
                        name="delete"
                        href="JavaScript:void(0);"
                        onClick={() => this.openDeletePoolDialog(d)}
                      >
                        <i
                          className="material-icons txt-light"
                          style={{ color: "white" }}
                        >
                          delete
                        </i>
                      </a>
                    </div>
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div>
                        <span className="txt-dark">
                          created {moment(d.date * 1000).format("Do MMM YYYY")}
                        </span>
                        <NavLink
                          className="view-pool"
                          style={{ cursor: "pointer" }}
                          to={
                            "/dashboard/pool/" +
                            d.id +
                            "/details/page=1&pageSize=10"
                          }
                        >
                          View Pool
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {!this.props.pageLoading &&
            this.props.pools.length === 0 &&
            this.props.message === "" && (
              <div className="not-found">No Pool Found !!</div>
            )}
          {this.props.message !== "" && (
            <div className="not-found">{this.props.message}</div>
          )}
        </div>
        <div id="popup1" className="overlay overlay-edit-pool">
          <form
            className="create-creating-pool txt-form"
            onSubmit={this.submitCreateEditPoolForm.bind(this)}
            noValidate={true}
          >
            <div className="popup">
              <div className="popup-heading">
                <h4>
                  {this.props.operation === "create"
                    ? "Create New Pool"
                    : "Edit Pool"}
                </h4>
                <a className="close" onClick={this.closePopup}>
                  &times;
                </a>
              </div>
              <div className="content">
                <div className="form-group">
                  <label className="control-label mb-5">
                    Pool Name <span className="req">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    autoComplete="off"
                    minLength={3}
                    maxLength={50}
                    onChange={this.onChange.bind(this)}
                    //pattern={"^[a-zA-Z0-9/+&]*$"}
                    //pattern={"[a-zA-Z0-9+/,-& ]+[a-z+/,-&A-Z0-9]$"}
                    pattern={"[a-zA-Z0-9+-/&][a-zA-Z+-/&0-9 ]+[a-z+-/&A-Z0-9]$"}
                    className="form-control"
                    value={this.props.name}
                    placeholder="Pool Name"
                    required={true}
                  />
                  <p className="with-error">
                    Please enter Pool Name (Min 3 characters required, space
                    before and after the text is not allowed, only /+&- special
                    characters are allowed).
                  </p>
                </div>
                <div className="btn-group text-right">
                  <button
                    type="submit"
                    disabled={this.props.pageLoading ? true : false}
                    className="btn"
                  >
                    {this.props.operation === "create"
                      ? "Create Pool"
                      : "Edit Pool"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <DeleteDialogBox
          formName="Delete Pool"
          submitForm={this.deletePool}
          operation="Delete Pool"
          keyword={"Delete"}
          closeForm={this.closeDeletePoolDialog}
          pageLoading={this.props.pageLoading}
          selected={this.props.deletePool.id}
          name={this.props.deletePool.id}
        />
        <NotificationContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    deletePool,
    pools,
    container,
    pageLoading,
    csvData,
    isPoolDownloadMessage,
    error,
    message,
    status,
    isPoolEditedMessage,
    isPoolDeletedMessage,
    isPoolCreatedMessage,
    pageNumber,
  } = state.poolReducer;
  const { name, poolId, operation } = container;
  return {
    deletePool,
    name,
    operation,
    pools,
    container,
    poolId,
    pageLoading,
    error,
    message,
    status,
    isPoolEditedMessage,
    isPoolDeletedMessage,
    isPoolCreatedMessage,
    pageNumber,
    isPoolDownloadMessage,
    csvData,
  };
}

export default withRouter(connect(mapStateToProps)(ListPool));
