import React, {Component} from 'react';
import {NavLink, Link} from 'react-router-dom';
import Header from "../../../components/Header/header";
import Footer from "../../../components/Footer/footer";
import {connect} from 'react-redux';
import "../dist/css/homepage/teams.css";
import {applyJob, getTeamJobs} from "../../../actions/dashboard/user/jobs/jobs";
import {getJobDetails} from "../../../actions/dashboard/admin/job/job";
import {NoDataFound} from 'xd-react-nodatafound';
import {Helmet} from "react-helmet";
let show_jobs = false;  
class JobsDetail extends Component {
    componentWillMount() {
        document.title = "Job Details | XenonStack Hiring Portal";
        this.props.dispatch(getJobDetails(this.props.match.params.job_id));
        window.scrollTo(0, 0);
       
    }
    // redirect to dashboard when apply for job clicked
    applyForJob() {
        this.props.dispatch(applyJob({job:this.props.match.params.job_id}));
        if (this.props.isAuthenticated) {
            this.props.history.push("/dashboard")
        } else {
            this.props.history.push("/dashboard")
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.match.params.job !== nextProps.match.params.job) {
            nextProps.dispatch(getJobDetails(nextProps.match.params.team, nextProps.match.params.job));
            nextProps.dispatch(getTeamJobs(nextProps.match.params.team));
        }
        if (this.props.team_jobs !== nextProps.team_jobs) {
            const jobs = nextProps.team_jobs;
            for (let i in jobs) {
                if (jobs[i]["id"] !== this.props.match.params.job) {
                    show_jobs = true
                }
            }
        }
    }
    render() {
        console.log(this.props.job_details.meta_description,'render')
        return (
        <>
            <Helmet>
                <title>{(this.props.job_details.title !== "" && this.props.job_details.title !== undefined)? this.props.job_details.title : "Current Open Job Positions - XenonStack Careers" }</title>
                <meta name="description" content={(this.props.job_details.meta_description !== "" && 
                this.props.job_details.meta_description !== undefined)? 
                this.props.job_details.meta_description : 
                "XenonStack's current open job positions, Internships and career opportunities in Big Data Analytics, DevOps, and Data Science" }>
                </meta>

                {/* FACEBOOK OPENGRAPH META  */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="Website" />
                <meta property="og:title" content="Current Open Job Positions - XenonStack Careers" />
                <meta property="og:description" content="XenonStack's current open job positions, Internships and career 
                opportunities in Big Data Analytics, DevOps, and Data Science." />
                <meta property="og:url" content="https://careers.xenonstack.com/" />
                <meta property="og:site_name" content="XenonStack" />
                <meta property="og:image" content="https://www.xenonstack.com/images/career/og_image.png" />

                {/* TWITTER CARD META */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@XenonStackCareers"/>
                <meta name="twitter:creator" content="@XenonStackCareers"/>
                <meta name="twitter:url" content="https://careers.xenonstack.com/" />
                <meta name="twitter:title" content="Current Open Job Positions - XenonStack Careers" />
                <meta name="twitter:description" content="XenonStack's current open job positions, Internships and career 
                opportunities in Big Data Analytics, DevOps, and Data Science." />
                <meta name="twitter:image" content="https://www.xenonstack.com/images/career/og_image.png" />
            </Helmet>
            <Header/>
            <main>
                <section className="banner-section job-detail-banner" id="page-banner">
                    <div className="job-detail-banner-container banner-container" id="banner-container">
                        <div className="banner-background-outer job-detail-banner-background"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 offset-md-2 col-md-8 with-center">
                                    <div className="banner-content-box" id="banner-content-box">
                                        <div className="banner-content-box-item">
                                            <h1 className="wb-banner-heading">{this.props.job_details.name}</h1>
                                            <p className="wb-page-desc">{this.props.job_details.location}</p>
                                            <div className="post-content">
                                               		
                                            {(!this.props.pageLoading && !this.props.error && this.props.job_details.id !== undefined && this.props.job_details.id !== null) &&
                                             this.props.job_details.keywords.length>0&&

                                            this.props.job_details.keywords.map((d,i)=>
                                            <span>{d}</span> )
                                            }	
                                             </div>	
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="jobdetail-content">
                    <div className="wrapper">
                        <div className="detail-content">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                    {
                                        (!this.props.pageLoading && !this.props.error && this.props.job_details.id !== undefined && this.props.job_details.id !== null) &&
                                        <div>
                                            {/* <div className="post-detail-header">
                                                <h2 className="page-heading">{this.props.job_details.name}</h2>
                                                <p className="page-heading-desc"> {this.props.job_details.location}</p>
                                                <Link to={this.props.isAuthenticated ? "/dashboard" : "/login"}
                                                      onClick={this.applyForJob.bind(this)} className="btn mar-t-2">Apply
                                                    for this Job</Link>
                                            </div> */}
                                            <div className="post-content">
                                                <div className="mar-b-50">
                                                <h2>Job Summary</h2>
                                                <p>{ this.props.job_details.summary }</p>
                                                <Link to={this.props.isAuthenticated ? "/dashboard" : "/login"}
                                                      onClick={this.applyForJob.bind(this)} className="btn mar-t-2">Apply
                                                    for this Job</Link>
                                                </div>
                                                <div dangerouslySetInnerHTML={{__html: this.props.job_details.body}}/>
                                                <h2>Keywords</h2> 
                                                { this.props.job_details.keywords.length>0&&
                                                    this.props.job_details.keywords.map((d,i)=>
                                                    <span>{d}</span> 
                                                    )
                                                }
                                            </div>
                                           
                                            <Link to={this.props.isAuthenticated ? "/dashboard" : "/login"}
                                                  onClick={this.applyForJob.bind(this)} className="btn mar-t-2">Apply for this Job
                                            </Link>
                                        </div>
                                    }
                                    {
                                        (!this.props.pageLoading && this.props.error) &&
                                        <NoDataFound
                                            NoDataImage={require('../dist/images/no-resultfound.png')}
                                            alt="No Openings"
                                            NoDataTitle="No Current Openings!"
                                            NoDataDesc= {<><NavLink to="/jobs">Click Here</NavLink> to find other related Jobs.</>} 
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                {
                    show_jobs &&
                    <section className="opening-jobs">
                        <div className="wrapper">
                            <div className="job-post-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="page-heading-outer mar-b-6">
                                            <h2 className="page-heading text-center"> More Open Positions
                                                of {(((this.props.match.params.team).split("-")).map((d) => d.charAt(0).toUpperCase() + d.slice(1))).join(" ")}</h2>
                                            <h3 className="subtitle text-center">Find the best Jobs opportunity based on
                                                your area of interest</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        !this.props.pageLoading && this.props.team_jobs.length > 0 &&
                                        (
                                            this.props.team_jobs.map((d, i) => (
                                                d.id !== this.props.match.params.job &&
                                                <div className="col-12 col-sm-12 col-md-6 mar-xs-30"
                                                     onClick={() => this.props.history.push("/jobs/" + this.props.match.params.team + "/" + d.id)}
                                                     >
                                                    <div className="job-post-item">
                                                        <h4 className="subtitle job-post-heading">{d.name}</h4>
                                                        <p className="page-heading-desc">{d.location}</p>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    }
                                     {
                                        (!this.props.pageLoading && this.props.team_jobs_message !== "") &&
                                        <div>{this.props.team_jobs_message}</div>
                                    }

                                    
                                </div>
                            </div>
                        </div>
                    </section>
                }

            </main>
            <Footer/>
        </>
        )
    }
}

function mapStateToProps(state) {
    const {auth} = state.LoginReducer;
    const {isAuthenticated} = auth;
    const {
        job_details, error, message, status, pageLoading,
        team_jobs, team_jobs_error, team_jobs_message, team_jobs_status
    } = state.userJobsReducer;
   
    return {
        isAuthenticated,
        job_details, error, message, status, pageLoading,
        team_jobs, team_jobs_error, team_jobs_message, team_jobs_status
    }
}

export default connect(mapStateToProps)(JobsDetail)
