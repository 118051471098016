/*eslint-disable*/
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {clearAll, driveList} from '../../../../actions/dashboard/user/drives/drives'
import BarLoaderSpinner from "../../../../components/app/spinner/barloader";
import {

    getUserTestResult
} from "../../../../actions/dashboard/user/activities/activities";
import moment from "moment";
import {getProfile} from "../../../../actions/dashboard/profile";
import {Dialogbox} from 'xd-react-dialogbox'

class DriveDetails extends Component {
    componentWillMount() {
        document.title = "Drive Details | Xenonstack Hiring Portal";

        // calling getProfile to check for resume
        this.props.dispatch(getProfile());
        this.props.dispatch(clearAll());
        this.props.dispatch(driveList())
        this.props.dispatch(getUserTestResult());
    }
    onStart(id,test,drivename, start){
      let today=new Date();
      let startDate=moment.unix(start).toDate()
       if(startDate  <= today){
          let width=window.screen.width;
          let height=window.screen.height;
          const url = process.env.REACT_APP_TEST_MODULE_URL+"/test-instructions?token=" + localStorage.getItem("token") + "&drive_id=" + id + "&test_id=" + test+ "&drivename="+drivename;
          let strWindowFeatures = "menubar=no,minimizable=no,status=yes,dialog=yes,width="+width+",height="+height+"fullscreen=1"
          window.open(url,"NewWindow", strWindowFeatures);
        }
        else{
            document.getElementsByClassName("has-error")[0].innerHTML="You cannot start your test before time"
        }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.state.RegisterReducer.status === 200){
            if(!nextProps.state.RegisterReducer.error){
                if(nextProps.registrationForm.resume === ''){
                    this.openResumeDialog()
                }
            }
        }
    }
    openResumeDialog() {
        document.querySelector('.dialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
      }
      // submit response of dialog
    submitResumeDialog(){
          document.querySelector('.dialog').classList.remove('opened');
          document.querySelector('body').classList.remove('has-dialog');
          this.props.history.push('/profile/information/edit')
      }
    cancelResumeDialog() {
        this.props.history.push('/profile')
        document.querySelector('.dialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    render() {
        return (
            <section className="">
                <BarLoaderSpinner pageLoading={this.props.pageLoading}/>
                <div className="container">
                    <div className="content-detail">
                        <div className="text-center">
                            <h1 className="main-heading-h1">Drive Details</h1>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="dash-status">
                                    <div className="current-status">
                                        <h2>Upcoming Tests</h2>
                                        <div className="row">
                                        {
                                            (!this.props.pageLoading && this.props.drives.length > 0) &&

                                            this.props.drives.map((d, i) => (

                                                    <div className="col-12 col-sm-12 col-md-6">
                                                        <div className="status-div upcoming">
                                                            <h3>{d.name.charAt(0).toUpperCase() + d.name.slice(1)} Test</h3>
                                                            <p>Your Online Aptitude and Technical Test has been scheduled from <i>{moment(d.start *1000).format('Do MMM YYYY, h:mm a')}</i> to <i>{moment(d.end *1000).format('Do MMM YYYY, h:mm a')}</i>.</p>
                                                            <div>
                                                            <button type="button" className="btn mar-t-2" onClick={()=>this.onStart(d.id,d.test_id,d.name, d.start)}>Start Test</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                            ))
                                        }</div>
                                        <div className="col-md-12 col-sm-12">
                                               <div className="has-error" style={{color:"#d50000"}}></div>
                                        </div>
                                        {
                                            !this.props.pageLoading && this.props.drives.length === 0 && this.props.message === "" &&
                                            <div className="no-activity text-center">
                                                <div className="">
                                                    <figure><img src={require("../../../../static/images/upcoming.svg")}
                                                                 alt={"No Tet"}/></figure>
                                                </div>
                                                <h3>No Upcoming Test</h3>
                                                <p>You haven't applied for any job yet or may be your job is completed</p>
                                            </div>
                                        }

                                        {
                                            !this.props.pageLoading && this.props.message !== "" &&
                                            <div className="no-activity text-center">
                                                <div className="">
                                                    <figure><img src={require("../../../../static/images/upcoming.svg")}
                                                                 alt={"No Tet"}/></figure>
                                                </div>
                                                <h3>Something went wrong...</h3>
                                                <p>{this.props.message}</p>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="dash-status">
                                    <div className="current-status">
                                        <h2>Completed Tests</h2>


                                        <div className="row">
                                        {(!this.props.resultPageLoading &&
                                            this.props.result.length>0) &&
                                            this.props.result.map((r,i)=>

                                            <div className="col-12 col-sm-12 col-md-6">
                                                <div className="status-div upcoming">
                                                    <h3>{r.test}</h3>
                                                    <p>You have successfully completed your test!</p>
                                                    <div><p
                                                        style={{
                                                            color: "#1AAA55",
                                                            fontSize: "16px",
                                                            margin: "0"
                                                        }}>Completed On: {moment(r.completed * 1000).format('Do MMMM YYYY')}</p></div>
                                                </div>
                                            </div>
                                        )}
                                        {(!this.props.resultPageLoading &&
                                            this.props.result.length===0) &&
                                            <div className="col-12 col-sm-12 col-md-12">
                                            <div className="no-activity text-center">
                                            <div className="">
                                                <figure><img src={require("../../../../static/images/upcoming.svg")}
                                                             alt={"No Tet"}/></figure>
                                            </div>
                                            <h3>No Test Completed</h3>
                                            <p>You haven't applied for any job yet or may be your job is completed</p>
                                        </div></div>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialogbox
                    title="Notification"
                    successButton="Upload"
                    cancelButton="Cancel"
                    submitDialog={this.submitResumeDialog.bind(this)}
                    closeDialog={this.cancelResumeDialog.bind(this)} >
                    <p>Please upload Resume to continue.</p>
                </Dialogbox>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const {drives, error, message, pageLoading} = state.userDrivesReducer;
    const {result,resultPageLoading} = state.activitiesReducer;
    const {registrationForm} = state.RegisterReducer;
    return {drives, error, message, pageLoading,result,resultPageLoading,state,registrationForm}
}

export default withRouter(connect(mapStateToProps)(DriveDetails))
