import {
    GET_ALLPOOLWISE_RESULT_REQUEST,
    GET_ALLPOOLWISE_RESULT_SUCCESS,
    GET_ALLPOOLWISE_RESULT_FAILURE,
    GET_POOLWISE_RESULT_REQUEST,
    GET_POOLWISE_RESULT_SUCCESS,
    GET_POOLWISE_RESULT_FAILURE,
    GET_ALLDRIVEWISE_RESULT_REQUEST,
    GET_ALLDRIVEWISE_RESULT_SUCCESS,
    GET_ALLDRIVEWISE_RESULT_FAILURE,
    GET_DRIVEWISE_RESULT_REQUEST,
    GET_DRIVEWISE_RESULT_SUCCESS,
    GET_DRIVEWISE_RESULT_FAILURE,
    GET_ANALYTICS_REQUEST,
    GET_ANALYTICS_SUCCESS,
    GET_ANALYTICS_FAILURE,
    NEW_PAGE_NUMBER
    } from '../../../types/dashboard/admin/results/results'

const initialState = {

    getAllPoolwiseResult_PageLoading: false,
    getAllPoolwiseResultPage_status: "",
    getAllPoolwiseResultPage_error: "",
    getAllPoolwiseResultPage_message: "",
    allpoolwiseresult: [],
    
    getPoolwiseResult_PageLoading: false,
    getPoolwiseResultPage_status: "",
    getPoolwiseResultPage_error: "",
    getPoolwiseResultPage_message: "",
    poolCandidates: [],
    poolCompare: [],
    poolPie: [],

    getAllDrivewiseResult_PageLoading: false,
    getAllDrivewiseResultPage_status: "",
    getAllDrivewiseResultPage_error: "",
    getAllDrivewiseResultPage_message: "",
    allDrivewiseresult: [],
    
    getDrivewiseResult_PageLoading: false,
    getDrivewiseResultPage_status: "",
    getDrivewiseResultPage_error: "",
    getDrivewiseResultPage_message: "",
    divewiseresult: [],
    driveCandidates: [],
    driveCompare: [],
    drivePie: [],


    getAnalytics_PageLoading: false,
    getAnalyticsPage_status: "",
    getAnalyticsPage_error: "",
    getAnalyticsPage_message: "",
    analytics: [],
    overAllResult:[],
    poolResult:[],
    pageNumber: 1,
    defaultPageSize: 10,
    currentUserPageNumber:1,
    all_records_count: 0,
    drive_record_count:0,
    currentPageNumber:1,


};

export default function adminResultReducer(state= initialState, action) {
    switch(action.type){

    //---------------------------- All Poolwise Result-----------------------------------------//
    
        
        case GET_ALLPOOLWISE_RESULT_REQUEST:
            return Object.assign({}, state, {
                getAllPoolwiseResult_PageLoading: true,
                
            });
        case GET_ALLPOOLWISE_RESULT_SUCCESS:
            return Object.assign({}, state, {
                getAllPoolwiseResult_PageLoading: false,
                getAllPoolwiseResult_status: 200,
                getAllPoolwiseResult_error: action.response.data.error,
                getAllPoolwiseResult_message: action.response.data.message,
                allpoolwiseresult: (action.response.data.pools=== [] && action.response.data.pools === undefined) ?[]:action.response.data.pools,
            });
            case GET_ALLPOOLWISE_RESULT_FAILURE:
                return Object.assign({}, state, {
                    getAllPoolwiseResult_PageLoading: false,
                    getAllPoolwiseResult_status: action.response.data.status,
                    getAllPoolwiseResult_error: action.response.data.error,
                    getAllPoolwiseResult_message: action.response.data.error.message,
                   
                });       

    //---------------------------- Poolwise Result-----------------------------------------//
    
        case GET_POOLWISE_RESULT_REQUEST:
            return Object.assign({}, state, {
                getPoolwiseResult_PageLoading: true,
                
            });
        case GET_POOLWISE_RESULT_SUCCESS:
        return Object.assign({}, state, {
                getPoolwiseResult_PageLoading: false,
                getPoolwiseResult_status: 200,
                getPoolwiseResult_error: action.response.data.error,
                getPoolwiseResult_message: action.response.data.message,
                poolCandidates: (action.response.data.candidates === '' && action.response.data.candidates === undefined) ?[]:action.response.data.candidates,
                poolCompare: (action.response.data.compare === '' && action.response.data.compare === undefined) ?[]:action.response.data.compare,
                poolPie: (action.response.data.pie === '' && action.response.data.pie === undefined) ?[]:action.response.data.pie,
                all_records_count:action.response.data.error ? 0 : action.response.data.total,
            
            });
            case GET_POOLWISE_RESULT_FAILURE:
                return Object.assign({}, state, {
                    getPoolwiseResult_PageLoading: false,
                    getPoolwiseResult_status: action.response.data.status,
                    getPoolwiseResult_error: action.response.data.error,
                    getPoolwiseResult_message: action.response.data.error.message,
                    
                });       
    //---------------------------- All Drivewise Result-----------------------------------------//
    
        
    case GET_ALLDRIVEWISE_RESULT_REQUEST:
            return Object.assign({}, state, {
                getAllDrivewiseResult_PageLoading: true,
                
            });
        case GET_ALLDRIVEWISE_RESULT_SUCCESS:
            return Object.assign({}, state, {
                getAllDrivewiseResult_PageLoading: false,
                getAllDrivewiseResult_status: 200,
                getAllDrivewiseResult_error: action.response.data.error,
                getAllDrivewiseResult_message: action.response.data.message,
                allDrivewiseresult: (action.response.data.drives=== [] && action.response.data.drives === undefined) ?[]:action.response.data.drives,
            });
            case GET_ALLDRIVEWISE_RESULT_FAILURE:
                return Object.assign({}, state, {
                    getAllDrivewiseResult_PageLoading: false,
                    getAllDrivewiseResult_status: action.response.data.status,
                    getAllDrivewiseResult_error: action.response.data.error,
                    getAllDrivewiseResult_message: action.response.data.error.message,
                   
                });       

    //---------------------------- Drivewise Result-----------------------------------------//
    
        case GET_DRIVEWISE_RESULT_REQUEST:
            return Object.assign({}, state, {
                getDrivewiseResult_PageLoading: true,
                
            });
        case GET_DRIVEWISE_RESULT_SUCCESS:
        return Object.assign({}, state, {
                getDrivewiseResult_PageLoading: false,
                getDrivewiseResult_status: 200,
                getDrivewiseResult_error: action.response.data.error,
                getDrivewiseResult_message: action.response.data.message,
                driveCandidates: (action.response.data.candidates === [] && action.response.data.candidates === undefined) ?[]:action.response.data.candidates,
                driveCompare: (action.response.data.compare === '' && action.response.data.compare === undefined) ?[]:action.response.data.compare,
                drivePie: (action.response.data.pie === '' && action.response.data.pie === undefined) ?[]:action.response.data.pie,
                drive_record_count:action.response.data.error ? 0 : action.response.data.total,
            });
            case GET_DRIVEWISE_RESULT_FAILURE:
                return Object.assign({}, state, {
                    getDrivewiseResult_PageLoading: false,
                    getDrivewiseResult_status: action.response.data.status,
                    getDrivewiseResult_error: action.response.data.error,
                    getDrivewiseResult_message: action.response.data.error.message,
                    
                });       
    //---------------------------- Analytics-----------------------------------------//
    
    case GET_ANALYTICS_REQUEST:
            return Object.assign({}, state, {
                getAnalytics_PageLoading: true,
                
            });
        case GET_ANALYTICS_SUCCESS:
        return Object.assign({}, state, {
                getAnalytics_PageLoading: false,
                getAnalytics_status: 200,
                getAnalytics_error: action.response.data.error,
                getAllPoolwiseResult_message: action.response.data.message,
                overAllResult:action.response.data.overall,
                poolResult:action.response.data.pools
            });
        case GET_ANALYTICS_FAILURE:
                return Object.assign({}, state, {
                    getAnalytics_PageLoading: false,
                    getAnalytics_status: action.response.data.status,
                    getAnalytics_error: action.response.data.error,
                    getAnalytics_message: action.response.data.error.message,
                    
                });                   
        case NEW_PAGE_NUMBER:
        return Object.assign({}, state, {
            currentPageNumber: action.newPageNumber
        });   
        default: 
                return state
    }
}