import React, {Component} from 'react';
import {connect} from 'react-redux';
import "../dist/css/homepage/teams.css";
import { getTeams,clearAll as teamClear } from '../../../actions/dashboard/admin/team/team';
import {clearAll, getJobs, fetchJobType,setSelectedJobType,setSelectedTeam,
    getKeywords,setSelectedKeyword} from '../../../actions/dashboard/user/jobs/jobs';
import Select from 'react-select';
import {withRouter,NavLink,Redirect} from 'react-router-dom';
import {NoDataFound} from 'xd-react-nodatafound'
class ListJobs extends Component {
    componentDidMount() {
        this.props.dispatch(clearAll());
        //fetch jobs type eg experience or intern or graduate
        this.props.dispatch(fetchJobType());
        //empty the team & keywords array
        this.props.dispatch(setSelectedTeam([]));
        this.props.dispatch(setSelectedKeyword([]))
        this.fetchData();
    }
   
    fetchData(){
        const teamValue=[],team={};
        const url = window.location.href;
        const requiredUrl = new URL(url);
        let jobType=null,teamId=null,name =null
        
        jobType = requiredUrl.searchParams.get("job-type");
        teamId = requiredUrl.searchParams.get("team-id");
        name = requiredUrl.searchParams.get("team-name");
        //for view jobs in the /teams url
        if (this.props.location.pathname.match('/jobs') && jobType !== null ) {
            if(teamId !== "null" && name !== "null"){
            team["label"]= name
            team["value"]=teamId
            teamValue.push(team);this.props.dispatch(setSelectedTeam(teamValue));
            }
            this.props.dispatch(setSelectedJobType(jobType));
            if(teamValue !== null)
            
            this.props.dispatch(getJobs(jobType,team["value"]))
            this.props.dispatch(getTeams(jobType));
            this.props.dispatch(getKeywords(jobType,teamId));
            
        }
        // LearnMore
        else if(this.props.location.pathname.match('/teams/') ){ 
            this.props.dispatch(getJobs("Experience",this.props.match.params.teamId));
            this.props.dispatch(getKeywords("Experience",this.props.match.params.teamId))
        }
        // All Jobs from welcome or on hitiing /jobs
        else{
            this.props.dispatch(getJobs());
            this.props.dispatch(getTeams());
            this.props.dispatch(getKeywords())
        }
    }
    handleChangeJobType(selectedValue){
        const url = window.location.href;
        const requiredUrl = new URL(url);
        let jobType=null,teamId=null,name =null
        jobType = requiredUrl.searchParams.get("job-type");
        teamId = requiredUrl.searchParams.get("team-id");
        name = requiredUrl.searchParams.get("team-name");
        //set the job type variable
        this.props.dispatch(setSelectedJobType(selectedValue.value));
        // get team of selected job type
        this.props.dispatch(getTeams(selectedValue.value));
        //get jobs of selected job type
        this.props.dispatch(getJobs(selectedValue.value,'',''))
        //get keywords of selected job type
        this.props.dispatch(getKeywords(selectedValue.value));


        if(this.props.location.pathname.match('/jobs') && jobType !== null && name !== null && teamId !== null ){
            this.props.history.push('/jobs?team-id=16&&team-name=Product Team&&job-type='+selectedValue.value+'')
        }
        //clear the dropdown
        this.props.dispatch(setSelectedTeam([]));
      
        this.props.dispatch(setSelectedKeyword([]))
        // this
    }
    handleChangeTeams(selectedValue){
        const url = window.location.href,requiredUrl = new URL(url);
        
        let jobType=null,teamId=null,name =null,team=[],str=[]
        jobType = requiredUrl.searchParams.get("job-type");
        teamId = requiredUrl.searchParams.get("team-id");
        name = requiredUrl.searchParams.get("team-name");
        selectedValue.map((d)=>team.push(d))
        selectedValue.map((d)=>str.push(d.value))
        if(this.props.location.pathname.match('/jobs') && jobType !== null && name !== null && teamId !== null ){
            
            this.props.history.push('/jobs?team-id='+str+'&&team-name=Product Team&&job-type='+this.props.selectedJobType+'')
        }
        //set selected team
        this.props.dispatch(setSelectedTeam(team));
        //fetch jobs of selected job type and team
        if(this.props.selectedJobType ===''){
        this.props.dispatch(getJobs('',str))
        this.props.dispatch(getKeywords('',str));

        }else{
            this.props.dispatch(getJobs(this.props.selectedJobType,str))
            this.props.dispatch(getKeywords(this.props.selectedJobType,str));
        }

    }
    handleChangeKeywords(selectedValue){
        let teamId=[],keywordId=null,keywordValue=null,keyword={},arr=[];
        this.props.selectedTeam.map((d)=>teamId.push(d.value))
        selectedValue.map((d)=>arr.push(d))
        this.props.dispatch(setSelectedKeyword(arr))
        keyword["label"]= keywordId
        keyword["value"]=keywordValue
        let str=[]
        selectedValue.map((d)=>str.push("'"+d.value+"'"))
        if(str !== []){
        this.props.dispatch(getJobs(this.props.selectedJobType,teamId,str));
        }
        else{
            this.props.dispatch(getJobs(this.props.selectedJobType,teamId));
        }
    }
    render() {
        const url = window.location.href;
        const requiredUrl = new URL(url);
        let jobType=null
        let teamId=null;
        let name =null;
        jobType = requiredUrl.searchParams.get("job-type");
        teamId = requiredUrl.searchParams.get("team-id");
        name = requiredUrl.searchParams.get("team-name");
        return (
        <>
            <div className="job-listing-table-container mar-b-30" id="job-listing-table-container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-10">
                    {!this.props.location.pathname.match('/teams/') &&
                        <div className="job-filter-container">
                            <div className="row">
                                <div className=" col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <label for="Location">Job Type</label>
                                        <Select
                                            placeholder="Job Type"
                                            name="Teams"
                                            id="teamsList"
                                            defaultValue={
                                                jobType !== "null" && jobType 
                                                && { label:jobType, value: jobType }}
                                            isClearable={false}
                                            onChange={this.handleChangeJobType.bind(this)}
                                            options={this.props.jobType.map((d)=>({label:d, value: d}))}
                                            multi={true}
                                            isSearchable={true}
                                        />
                                    </div>
                                </div>
                                <div className=" col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <label for="Location">Teams</label>
                                        <Select
                                            placeholder="Teams"
                                            isSearchable={true}
                                            name="sub-teams"
                                            id="subteamsList"
                                            isMulti
                                            defaultValue={teamId && this.props.selectedTeam }
                                            value={(this.props.selectedTeam.length>0)&& this.props.selectedTeam}
                                            isClearable={true}
                                            onChange={this.handleChangeTeams.bind(this)}
                                            options={this.props.get_teams.map((d)=>({label:d.name, value: d.id}))}
                                        />
                                    </div>
                                </div>
                                <div className=" col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <label for="Location">Keywords</label>
                                        <Select
                                            placeholder="Keywords"
                                            isSearchable={true}
                                            name="keywords"
                                            id="jobkeywords"
                                             isMulti
                                            isClearable={true}
                                            value={(this.props.selectedKeyword.length>0)&& this.props.selectedKeyword}
                                            onChange={this.handleChangeKeywords.bind(this)}
                                            options={  this.props.keywords.map((d)=>({label:d.skill, value: d.skill}))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className="job-listing-outer">
                            {this.props.pageLoading&& <div className="with-center"><i class="fa fa-spinner fa-spin" style={{fontSize:"35px",color:"#4692ff"}}></i></div>}        
                            { !this.props.pageLoading&&
                            this.props.jobs.length>0 &&
                            this.props.jobs.map((j,i)=>
                            <>
                                <div className="job-item-box">
                                    <div className="job-item-content">
                                            <NavLink to={"/jobs/"+j.id+"/"}>
                                            <p>{j.name}</p>
                                            <span className="os">{j.team_name}</span>
                                            </NavLink>
                                        
                                    </div>
                                    <div className="job-item-content">
                                        <NavLink to={"/jobs/"+j.id+"/"}>
                                        <div className="text-badge">Keywords</div>
                                        <div><span className="os">
                                        { (j.keywords!==null && j.keywords.length>0)&&
                                                j.keywords.map((s, k) => (
                                                k === (j.keywords.length - 1) ?
                                                    <span>{s}</span>
                                                    :
                                                    <span>{s + " , "}</span>
                                            ))}
                                            </span>
                                        </div>
                                        </NavLink>
                                    </div>
                                    <div className="job-item-content">
                                    <NavLink to={"/jobs/"+j.id+"/"}>
                                        <p className="right-arrow">
                                        
                                        <i className="material-icons">keyboard_arrow_right</i>
                                        
                                        </p>
                                        </NavLink>
                                    </div>
                                </div>
                            </>
                            )}
                            {!this.props.pageLoading&&
                                this.props.jobs.length===0 &&
                                <div style={{alignContent:"center"}}>
                                {this.props.location.pathname.match('/teams/') ?
                                <NoDataFound
                                    NoDataImage={require('../dist/images/no-resultfound.png')}
                                    alt="No Openings"
                                    NoDataTitle="No Current Openings!"
                                    NoDataDesc= {<><NavLink to="/jobs/">Click Here</NavLink> to find other related Jobs.</>} 
                                />
                                :
                                <NoDataFound
                                NoDataImage={require('../dist/images/no-resultfound.png')}
                                alt="No Openings"
                                NoDataTitle="No Current Openings!"
                                NoDataDesc= "Search Jobs in other openings." 
                                />
                                }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            { this.props.team_details_error === true && this.props.team_details_message &&
                <>
                    <Redirect to="/" from="*"/>
                </>
            }
        </>
        )
    }
}


function mapStateToProps(state) {
    const { jobs,pageLoading, error, message, jobType,selectedJobType,keywords,selectedTeam,selectedKeyword} = state.userJobsReducer;
    const {get_teams,team_details_message,team_details_error}=state.adminTeamReducer;

    return { jobs, error,pageLoading, message, jobType,selectedJobType,get_teams,keywords,selectedTeam,team_details_error,
        team_details_message,selectedKeyword}
}


export default withRouter(connect(mapStateToProps)(ListJobs))