import {GET_ADMIN_LIST_REQUEST,
    GET_ADMIN_LIST_SUCCESS,
    GET_ADMIN_LIST_FAILURE,
    DELETE_SELECTED_ADMIN,
    DELETE_ADMIN_LIST_FAILURE,
    DELETE_ADMIN_LIST_SUCCESS,
    DELETE_ADMIN_LIST_REQUEST,
    CLEAR_ALL,
    CLEAR_STATUS,
    CREATE_ADMIN_FAILURE,
    CREATE_ADMIN_REQUEST,
    CREATE_ADMIN_SUCCESS,
    CHANGE_CREATE_ADMIN_FORM} from '../../../types/dashboard/admin/invite/admin_list';
const initialState={
    getListPageLoading:false,
    getListError:'',
    getListMessage:'',
    getListStatus:'',
    list:[],

    deleteAdminPageLoading:false,
    deleteAdminError:'',
    deleteAdminMessage:'',
    deleteAdminStatus:'',
    deleteAdmin:{ id:'', name:'' },
    
    createAdmin:{email:'',password:'',name:''},

    createAdminPageLoading:'',
    createAdminStatus:'',
    createAdminError:'',
    createAdminMessage:''

}
export default function adminListReducer(state=initialState,action){
    switch(action.type){
        case GET_ADMIN_LIST_REQUEST:
            return Object.assign({},state,{
                getListPageLoading:true,
                getListMessage:"",
                getListError:"",
                getListStatus:"",
                deleteAdminError:false,
                deleteAdminMessage:"",
            })
        case GET_ADMIN_LIST_SUCCESS:
            return Object.assign({},state,{
                getListError:action.response.data.error,
                getListMessage:action.response.data.error ? action.response.data.message : "",
                getListPageLoading:false,
                getListStatus:200,
                list:action.response.data.error? [] : action.response.data.accounts 

            })
        case GET_ADMIN_LIST_FAILURE:
            return Object.assign({},state,{
                getListPageLoading:false,
                getListStatus:action.response.data.status,
                getListError:true,
                getListMessage:action.response.data.message,
                list:[]
            }) 
        
        case DELETE_ADMIN_LIST_REQUEST:
            return Object.assign({},state,{
                deleteAdminPageLoading:true
            })
        case DELETE_ADMIN_LIST_SUCCESS:
            return Object.assign({},state,{
                deleteAdminPageLoading:false,
                deleteAdminError:action.response.data.error,
                deleteAdminMessage: action.response.data.message,
                deleteAdminStatus:action.response.status,
            })
        case DELETE_ADMIN_LIST_FAILURE:
            return Object.assign({},state,{
                deleteAdminPageLoading:false,
                deleteAdminError:action.response.data.error,
                deleteAdminStatus:action.response.data.status,
                deleteAdminMessage: action.response.data.message,
            })

        case DELETE_SELECTED_ADMIN:
            return Object.assign({},state,{
                deleteAdmin:action.list
            })
        case CLEAR_ALL:
            return Object.assign({},state,{
                getListPageLoading:false,
                getListError:'',
                getListMessage:'',
                getListStatus:'',
                list:[],

                deleteAdminPageLoading:false,
                deleteAdminError:'',
                deleteAdminMessage:'',
                deleteAdminStatus:'',
                deleteAdmin:{ id:'', name:'' },

                createAdminPageLoading:'',
                createAdminStatus:'',
                createAdminError:'',
                createAdminMessage:''
            })
        case CLEAR_STATUS:
            return Object.assign({},state,{
                deleteAdminError:false,
                deleteAdminMessage:'',
                deleteAdminStatus:'',

                createAdminPageLoading:'',
                createAdminStatus:'',
                createAdminError:'',
                createAdminMessage:''
            })

        case CHANGE_CREATE_ADMIN_FORM:
        
            return Object.assign({},state,{
                createAdmin:action.newState,

            })
        case CREATE_ADMIN_REQUEST:
            return Object.assign({},state,{
                createPageLoading:true
            })
        case CREATE_ADMIN_SUCCESS:
            return Object.assign({},state,{
                createAdminPageLoading:false,
                createAdminError:action.response.data.error,
                createAdminMessage:action.response.data.message,
                createAdminStatus:200,
                createAdmin:{
                    name:"",
                    email:"",
                    password:""
                }
            })
        case CREATE_ADMIN_FAILURE:
      
            return Object.assign({},state,{
                createAdminPageLoading:false,
                createAdminError:true,
                createAdminMessage:action.response.data.message,
                createAdminStatus:action.response.status
            })
        default:
            return state;
    }
}