import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ReactHighcharts from 'react-highcharts/ReactHighcharts';
require('highcharts/highcharts-more.js')(ReactHighcharts.Highcharts);
require('highcharts/modules/no-data-to-display.js')(ReactHighcharts.Highcharts);

ReactHighcharts.Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: "\"Roboto\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",fontSize:"16px"
        }
    }
});

class UserScorePieChart extends Component {

    render() {
      let graphConfig ={
        credits:false,
        title: {
            text: ''
        },
      };


        if(this.props.UserScoreResultData.length!==0){
            graphConfig  = {
                credits:false,
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    height: 200
                },
                colors: ['#F2B64F', '#2eb340', '#d13f2c'],
                title: {
                    text: ''
                },
                tooltip: {
                    headerFormat: '<b>{point.key}</b><br/>',
                    pointFormat: 'Percentange: <b>{point.percentage:.1f}%</b>'

                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                series: [{
                    name: 'User Score ',
                    colorByPoint: true,
                    data:this.props.UserScoreResultData
                }]
            }
      }
      return (
          <div className="charts-outer">
          <ReactHighcharts config={graphConfig} ref="overall-asstes-stats-graph"
                           id="overall-asstes-stats-graph"></ReactHighcharts>
          </div>
        );
    }
}

function mapStateToProps(state) {
    return state
}
export default withRouter(connect(mapStateToProps)(UserScorePieChart));
