import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  clearAll,
  clearStatus,
  getCollege,
  deleteCollege,
  getCollegeDetails,
  selectedDeleteCollege,changePageNumber
} from "../../../../../actions/dashboard/admin/college/college";
import createNotification from "../../../../../components/app/notification/notification";
import DeleteDialogBox from "../../../../../components/app/dialogBox/delete-dialog-box";
import {
  Table,
  TableRow,
  TableBody,
  TableData,
  TableHeaderRow,
  TableHeader
} from "../../../../../components/dashboard/admin/xd-react-table/react-flex-table";
import Pagination from "react-js-pagination";
import {
  TableHeadComponent,
  ThComponent
} from "../../../../../components/dashboard/admin/xd-react-table/react-flex-table/components";
import "../../../../../components/dashboard/admin/xd-react-table/dist/table.css";
import { PulseLoader } from "react-spinners";
import ReactTooltip from 'react-tooltip'

class ListCollege extends Component {
  componentWillMount() {
    this.props.dispatch(getCollege(this.props.match.params.pageNumber, this.props.keyword));
  }
  handlePageChange(pageNumber) {
     
    this.props.dispatch(changePageNumber(pageNumber));
    this.props.history.push('/dashboard/college/add/'+pageNumber)
    this.props.dispatch(getCollege(pageNumber, this.props.keyword));
    this.forceUpdate();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.delete_college_message !== "") {
      if (nextProps.delete_college_error) {
        createNotification("error", nextProps.delete_college_message);
        nextProps.dispatch(clearStatus());
      } else {
        createNotification("success", nextProps.delete_college_message);
        this.props.dispatch(clearAll());
        nextProps.dispatch(getCollege(this.props.currentCollegePageNumber, this.props.keyword));
      }
    }
    if(nextProps.keyword !== this.props.keyword){
      nextProps.dispatch(getCollege(1, nextProps.keyword))
    }
  }
  // for deleting the college
  deleteCollege(e) {
    e.preventDefault();
   
    this.props.dispatch(deleteCollege(this.props.selected));
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }
  // opens the dialog
  openDeleteCollegeDialog(id, name) {
    this.props.dispatch(selectedDeleteCollege({ id, name }));
   
    document.getElementsByClassName("overlay-delete")[1].style.display =
      "block";
  }

  // closes the dialog
  closeDeleteCollegeDialog() {
    
    if (!!document.getElementsByClassName("overlay-delete")[1]) {
      document.getElementsByClassName("overlay-delete")[1].style.display =
        "none";
    }
  }
  // edit the college
  editCollege(id) {
    this.props.dispatch(getCollegeDetails(id));
    this.props.history.push("/dashboard/college/" + id + "/edit");
  }

  render() {
   
    return (
      <div className="panel-wrapper collapse in">
        <div className="panel-body">
          <div className="table-wrap">
          <div className="table-responsive">
            <div className="text-center">
              {this.props.getCollegePageLoading && (
                <PulseLoader
                  margin="30px"
                  sizeUnit={"px"}
                  size={15}
                  color={"#0562e8"}
                  loading={this.props.getCollegePageLoading}
                />
              )}
            </div>
            <div className="react-xd-table-wrapper">
              {(!this.props.getCollegePageLoading && this.props.colleges.length > 0) && (
                <Table
                  data={this.props.colleges}
                  pageSize={10}
                  currentPage={this.props.currentCollegePageNumber}
                  pageSizeOptions={[10, 20, 30, 40, 50]}
                  startIndex={0}
                  endIndex={10}
                  render={({
                    rows,
                    pageSize,
                    pageSizeOptions,
                    handlePageSizeChange,
                    handlePrevPage,
                    handleNextPage,
                    hasNextPage,
                    hasPrevPage,
                    currentPage,
                    total
                  }) => {
                    return (
                      <React.Fragment>
                        <div className="react-xd-table-body">
                          <table className="react-xd-table striped-xd-table sortable-table footer-pagination-table">
                            <TableHeaderRow component={TableHeadComponent}>
                            <TableHeader
                              accessor="sr_no"
                              accessor_name="Sr. No."
                              component={ThComponent}
                              >
                              Sr. No .
                              </TableHeader>
                              <TableHeader accessor="name">
                                <th>Name</th>
                              </TableHeader>
                              <TableHeader accessor="location">
                                <th>Location</th>
                              </TableHeader>
                              <TableHeader  >
                                <th>Actions</th>
                              </TableHeader>
                            </TableHeaderRow>
                            <TableBody component="tbody">
                              {rows.map(
                                ({ rowKey, rowData, selected, }, rowIndex) => (
                                  <TableRow
                                    component="tr"
                                    className="xd-table-row xd-table-td"
                                    key={rowKey}
                                  >
                                    {rowData.map(({ accessor, data, key , rowsData }) => (
                                      <TableData component="td" key={key}>
                                        {accessor ? (
                                          accessor === 'sr_no' ? rowIndex + (pageSize * (this.props.currentCollegePageNumber-1))+1 :
                                          data
                                        ) : (
                                          <div className="table-action-group">
                                          <span className="xd-table-btn view" onClick={() => this.editCollege(rowsData.id)} data-tip="Edit"><i className="material-icons" >mode_edit</i><div className="tooltipHeight"><ReactTooltip place="bottom" /></div></span>
                                          <span className="xd-table-btn delete" onClick={() => this.openDeleteCollegeDialog(rowsData.id, rowsData.name)} data-tip="Delete"><i className="material-icons" >delete</i><div className="tooltipHeight"><ReactTooltip place="bottom" /></div></span>
                                           
                                          </div>
                                        )}
                                      </TableData>
                                    ))}
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </table>
                        </div>
                        <div className="react-xd-table-footer">
                          <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <div className="react-xd-pagination-container">
                              <div className="pagination-outer">
                                  <div className="pagination-group">
                              <Pagination
                                firstPageText={"First"}
                                lastPageText="Last"
                                prevPageText={<i className='fa fa-angle-left'/>}
                                nextPageText={<i className='fa fa-angle-right'/>}
                                activePage={this.props.currentCollegePageNumber}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={this.props.all_records_count}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                                />
                             </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                      </React.Fragment>
                    );
                  }}
                />
              )}
               {
                                    (!this.props.getCollegePageLoading && this.props.colleges.length === 0 && this.props.getCollege_message === "") &&
                                     
                                            <div className="not-found">
                                                No College Found !!
                                            </div>
                                         
                                }
                                {
                                    (!this.props.getCollegePageLoading && this.props.getCollege_message !== "") &&
                                   
                                            <div className="not-found">
                                                {this.props.getCollege_message}
                                            </div>
                                        
                                }
            </div>

          
          </div>
          </div>
        </div>
        <DeleteDialogBox
          formName="Delete College"
          submitForm={this.deleteCollege}
          operation="Delete College"
          keyword={"Delete"}
          closeForm={this.closeDeleteCollegeDialog}
          pageLoading={this.props.pageLoading}
          selected={this.props.deleteCollege.id}
          name={this.props.deleteCollege.name}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    deleteCollege,
    getCollegePageLoading,
    getCollege_status,
    getCollege_error,
    getCollege_message,
    colleges,
    delete_college_pageLoading,
    delete_college_error,
    delete_college_message,
    delete_college_status,
    all_records_count,
    currentCollegePageNumber
  } = state.adminCollegeReducer;
   
  return {
    deleteCollege,
    getCollegePageLoading,
    getCollege_status,
    getCollege_error,
    getCollege_message,
    colleges,
    all_records_count,
    delete_college_pageLoading,
    delete_college_error,
    delete_college_message,
    delete_college_status,
    currentCollegePageNumber
  };
}

export default withRouter(connect(mapStateToProps)(ListCollege));
