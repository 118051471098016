import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AdminOnPageNavigation from '../../../../../components/dashboard/admin/on_page_navigation/on-page-navigation';
import {clearAll, getTest, deleteTest, clearStatus, selectedDeleteTest} from "../../../../../actions/dashboard/admin/test/test";
import createNotification from "../../../../../components/app/notification/notification";
import {NotificationContainer} from 'react-notifications';
import moment from 'moment';
import DeleteDialogBox from "../../../../../components/app/dialogBox/delete-dialog-box";
import { Table, TableRow, TableBody, TableData, TableHeaderRow, TableHeader } from '../../../../../components/dashboard/admin/xd-react-table/react-flex-table'
import { TableHeadComponent , ThComponent, PageSizeChooser} from '../../../../../components/dashboard/admin/xd-react-table/react-flex-table/components';
import '../../../../../components/dashboard/admin/xd-react-table/dist/table.css';
import ReactTooltip from 'react-tooltip'
import {Helmet} from "react-helmet";
import { PulseLoader } from 'react-spinners';
import Pagination from 'react-js-pagination'
import { handlePaginationPropsChange} from "../../../../../actions/dashboard/admin/pool/pool";
class ListTest extends Component {
    handlePageChange = e => {
        this.props.dispatch(handlePaginationPropsChange(e, this.props.defaultPageSize))
        this.props.history.push('/dashboard/test/list'+'/page='+e+'&pageSize='+this.props.defaultPageSize)
      }
      handlePageSizeChange = e => {
        
        this.props.dispatch(handlePaginationPropsChange(this.props.pageNumber, Number(e.target.value)))
        this.props.history.push('/dashboard/test/list'+'/page='+this.props.pageNumber+'&pageSize='+e.target.value)
      }
    componentWillMount() {
  
        this.props.dispatch(handlePaginationPropsChange(Number(this.props.match.params.pageNumber), Number(this.props.match.params.pageSize)))
        this.props.dispatch(clearAll());
        this.props.dispatch(getTest())
    }

    // calls when delete test event fired
    deleteTest(e) {
        e.preventDefault();
        this.props.dispatch(deleteTest(this.props.selected));
        if (!!document.getElementsByClassName("overlay-delete")[1]) {
            document.getElementsByClassName("overlay-delete")[1].style.display = "none";
        }
    }

    // opens the dialog
    openDeleteTestDialog(test_id, test_name) {
       
        this.props.dispatch(selectedDeleteTest({test_id, test_name}));
       
        document.getElementsByClassName("overlay-delete")[1].style.display = "block";
    }

    // closes the dialog
    closeDeleteTestDialog() {
         
        if (!!document.getElementsByClassName("overlay-delete")[1]) {
            document.getElementsByClassName("overlay-delete")[1].style.display = "none";
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.delete_test_message !== "") {
              if (nextProps.delete_test_error) {
                createNotification('error', nextProps.delete_test_message);
                this.props.dispatch(getTest())
                nextProps.dispatch(clearStatus());
            }
            else {
                createNotification('success', nextProps.delete_test_message);
                nextProps.dispatch(getTest())
            }
        }
    }

    render() {
        
        return (
            <div>
            <Helmet>
                <title>View Tests | Career Portal | Xenonstack</title>
             </Helmet>
                <AdminOnPageNavigation parentRoute="Dashboard" childRoute="All Test"/>
                {/* <BarLoaderSpinner pageLoading={this.props.getTestPageLoading}/> */}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-default card-view custom-panel pool-panel">
                            <div className="panel-heading">
                                <h5 className="panel-title">All Test</h5>
                                <div className="clearfix"></div>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                                    <div className="table-wrap">
                                        <div className="table-responsive">
                                        <div className="text-center">
                                        {this.props.getTestPageLoading &&
                                         <PulseLoader 
                                         margin="30px"
                                         sizeUnit={"px"}
                                         size={15}
                                         color={'#48194e'}
                                         loading={this.props.getTestPageLoading} 
                                         />
                                    }</div>
                                            <div className="react-xd-table-wrapper">
                                            {(!this.props.getTestPageLoading && this.props.tests.length > 0) && 
                                          
                                                        <Table
                                                            data={this.props.tests}
                                                            pageSize={this.props.defaultPageSize}
                                                            pageSizeOptions={[10,20,30,40,50]}
                                                            currentPage={this.props.pageNumber}
                                                            render={({
                                                            rows,
                                                            pageSize,
                                                            pageSizeOptions,
                                                            handlePageSizeChange,
                                                            handlePrevPage,
                                                            handleNextPage,
                                                            hasNextPage,
                                                            hasPrevPage,
                                                            currentPage,
                                                            total
                                                            }) => {
                                                                
                                                            return (
                                                                <React.Fragment>
                                                                <div className="react-xd-table-body">
                                                                    <table className="react-xd-table striped-xd-table sortable-table footer-pagination-table">
                                                                    <TableHeaderRow component={TableHeadComponent}>
                                                                    <TableHeader
                                                                        accessor="sr_no"
                                                                        accessor_name="Sr. No."
                                                                        component={ThComponent}
                                                                        >
                                                                        Sr. No .
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="name"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Test Name
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="duration"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Duration
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="total"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Total Questions
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="date"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Test Created
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                        accessor="pools"
                                                                        component={ThComponent}
                                                                        sortable
                                                                        >
                                                                        Pools
                                                                        </TableHeader>
                                                                        <TableHeader
                                                                            component={ThComponent}
                                                                             
                                                                        >
                                                                        
                                                                        Action
                                                                        </TableHeader>
                                                                        </TableHeaderRow>
                                                                        <TableBody
                                                                            component="tbody"
                                                                            >
                                                                            {rows.map(({ rowKey, rowData, selected }, rowIndex) => (
                                                                                 
                                                                            <TableRow
                                                                                component="tr"
                                                                                className="xd-table-row xd-table-td"
                                                                                key={rowKey}
                                                                            >
                                                                            {rowData.map(({ accessor, data, key , rowsData }) => (
                                                                              
                                                                               <TableData
                                                                                component="td"
                                                                                key={key}
                                                                                >
                                                                                {accessor ? 
                                                                                (accessor === 'date' ? (moment(rowsData.date * 1000).format('Do MMM YYYY')) :
                                                                                accessor === 'pools' ? (rowsData.pools.join(" , ")) :
                                                                                accessor === 'sr_no' ? rowIndex + (this.props.defaultPageSize * (this.props.pageNumber-1))+1:                  
                                                                                            (data)
                                                                                ) :
                                                                                ( 
                                                                                <div className="table-action-group">
                                                                                   <span className="xd-table-btn view" onClick={() => this.props.history.push("/dashboard/test/"+rowsData.id+"/edit")} data-tip="Edit"><i className="material-icons" >mode_edit</i><div className="tooltipHeight"><ReactTooltip place="bottom" /></div></span>
                                                                                    <span className="xd-table-btn delete" onClick={() => this.openDeleteTestDialog(rowsData.id,rowsData.name)} data-tip="Delete"><i className="material-icons" >delete</i><div className="tooltipHeight"><ReactTooltip place="bottom" /></div></span>
                                                                                </div>
                                                                                )}  
                                                                                </TableData>
                                                                            ))}
                                                                            </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                        </table>
                                                                        </div>
                                                                        <div className="react-xd-table-footer">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-5 col-md-4">
                                                                                <div className="react-xd-pagesize-chooser">
                                                                                    <span>Rows per page:</span>
                                                                                    <PageSizeChooser
                                                                                    pageSize={this.props.defaultPageSize}
                                                                                    pageSizeOptions={pageSizeOptions}
                                                                                    handlePageSizeChange={this.handlePageSizeChange.bind(this)}
                                                                                    />
                                                                                </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-7 col-md-8">
                                                                                <div className="react-xd-pagination-container">
                                                                                <div className="pagination-outer">
                                                                                    <div className="pagination-group">
                                                                                    <Pagination
                                                                                        firstPageText={"First"}
                                                                                        lastPageText="Last"
                                                                                        prevPageText={<i className='fa fa-angle-left'/>}
                                                                                        nextPageText={<i className='fa fa-angle-right'/>}
                                                                                        activePage={this.props.pageNumber}
                                                                                        itemsCountPerPage={this.props.defaultPageSize}
                                                                                        totalItemsCount={total}
                                                                                        pageRangeDisplayed={3}
                                                                                        onChange={this.handlePageChange.bind(this)}
                                                                                        />
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                    </React.Fragment>
                                                                )
                                                                }}
                                                            />}
                                                             {
                                                    (!this.props.getTestPageLoading && this.props.tests.length === 0 && this.props.getTest_message === "") &&
                                                     
                                                            <div className="not-found">
                                                                No Test Found !!
                                                            </div>
                                                        
                                                }
                                                {
                                                    (!this.props.getTestPageLoading && this.props.getTest_message !== "") &&
                                                    
                                                            <div className="not-found">
                                                                {this.props.getTest_message}
                                                            </div>
                                                         
                                                }
                                                            </div>
                                                                                             
                                                                                               
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DeleteDialogBox
                    formName="Delete Job"
                    submitForm={this.deleteTest}
                    operation="Delete Job"
                    keyword={"Delete"}
                    closeForm={this.closeDeleteTestDialog}
                    pageLoading={this.props.delete_test_pageLoading}
                    selected={this.props.deleteTest.test_id}
                    name={this.props.deleteTest.test_name}
                />
                <NotificationContainer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {
        deleteTest,
        getTestPageLoading, getTest_status, getTest_error, getTest_message, tests,
        delete_test_pageLoading, delete_test_error, delete_test_message, delete_test_status
    } = state.adminTestReducer;
    const { pageNumber,defaultPageSize} = state.poolReducer;
    
    return {
        deleteTest,
        getTestPageLoading, getTest_status, getTest_error, getTest_message, tests,
        delete_test_pageLoading, delete_test_error, delete_test_message, delete_test_status,
        pageNumber,defaultPageSize
    }
};

export default withRouter(connect(mapStateToProps)(ListTest))
