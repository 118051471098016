import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import Input from "../../../../app/input/input";
import NextBackSection from "./next-back";
import {
  changeRegistrationForm,
  checkSignupStatus,
  clearApiErrorMessage,
} from "../../../../../actions/account/registration-action";
import UploadIcon from "../../../../app/upload_icon/upload-icon";
import {
  areaOfInterest,
  colourStyles,
} from "../../../../app/area_of_interest/area-of-interest";
import { checkValidation } from "../../../../../actions/app/app";
const ENV_VAR =
  process.env.REACT_APP_STATIC_PAGE === undefined
    ? "https://xenonstack.jobs/"
    : process.env.REACT_APP_STATIC_PAGE;

let self = null;

class InformationSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInterest: this.props.registrationForm.selectedInterest,
      resumeFileName: "",
    };
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    document.title =
      "Registration | Basic Information | XenonStack Hiring Portal";
    self = this.props;
    // calls action to clear api error message
    this.props.dispatch(clearApiErrorMessage());
    checkSignupStatus(this.props.goToRegistration, window.location.href);
    const selectedInterest = this.props.registrationForm.selectedInterest;
    this.setState({
      selectedInterest,
    });
  }
  componentDidMount() {
    document.getElementById("area_of_interest").focus();
  }
  // submit inform form
  submitInformationForm(e) {
    e.preventDefault();
    const self = this.props;
    let isValidGithubLink = true;

    let isValidResume = true;

    if (self.registrationForm.resume === "") {
      isValidResume = false;
    } else {
      switch (
        self.registrationForm.resume.files[0]["name"]
          .match(/\.([^\.]+)$/i)[1]
          .toLowerCase()
      ) {
        case "pdf":
          isValidResume = true;
          break;
        case "doc":
          isValidResume = true;
          break;
        case "docx":
          isValidResume = true;
          break;
        default:
          isValidResume = false;
          break;
      }
    }

    if (e.target.checkValidity() && isValidResume && isValidGithubLink) {
      self.history.push("/apply-manual/create-password");
    } else {
      if (!isValidGithubLink) {
        document
          .getElementsByName("githubLink")[0]
          .parentElement.classList.add("has-error");
      } else {
        document
          .getElementsByName("githubLink")[0]
          .parentElement.classList.remove("has-error");
      }
      const invalidElms = document.querySelectorAll(
        ".info-form .form-group input:invalid"
      );
      for (let i = 0; i < invalidElms.length; i++)
        invalidElms[i].parentElement.classList.add("has-error");

      if (
        document.getElementsByName("resume")[0].checkValidity() === false ||
        self.registrationForm.resume === ""
      ) {
        document
          .getElementsByName("resume")[0]
          .parentElement.classList.add("has-error");
      }
      if (!isValidResume) {
        if (this.props.registrationForm.resume === "") {
          document
            .getElementsByName("resume")[0]
            .parentElement.classList.add("has-error");
        } else {
          switch (
            self.registrationForm.resume.files[0]["name"]
              .match(/\.([^\.]+)$/i)[1]
              .toLowerCase()
          ) {
            case "pdf":
              document
                .getElementsByName("resume")[0]
                .parentElement.classList.remove("has-error");
              break;
            case "doc":
              document
                .getElementsByName("resume")[0]
                .parentElement.classList.remove("has-error");
              break;
            case "docx":
              document
                .getElementsByName("resume")[0]
                .parentElement.classList.remove("has-error");
              break;
            default:
              document
                .getElementsByName("resume")[0]
                .parentElement.classList.add("has-error");
              return 0;
          }
        }
      }
    }
  }

  // called when onchange html event fired
  onChange(e) {
    const self = this.props;
    self.dispatch(clearApiErrorMessage());
    checkValidation(e);
    const existingRegisterationForm = this.props.registrationForm;
    if (e.target.name === "linkedInLink") {
      if (
        e.target.value ===
          "https://www.linkedin.com/in/hiring-job-opening-xenonstack/" ||
        e.target.value ===
          "https://www.linkedin.com/in/hiring-job-opening-xenonstack"
      ) {
        return;
      }
    }
    if (e.target.name === "githubLink") {
      if (
        e.target.value ===
          "https://github.com/xenonstack/ansible-devops-demo" ||
        e.target.value === "https://github.com/xenonstack/ansible-devops-demo/"
      ) {
        return;
      }
    }
    if (e.target.name === "otherLink") {
      if (e.target.value === "") {
        e.target.required = false;
        e.target.parentElement.classList.remove("has-error");
      } else {
        e.target.required = true;
        if (e.target.checkValidity()) {
          e.target.parentElement.classList.remove("has-error");
        } else {
          e.target.parentElement.classList.add("has-error");
        }
      }
    }
    if (e.target.name === "resume") {
      if (e.target.value === "") {
        e.target.parentElement.classList.add("has-error");
        this.setState({ resumeFileName: "" });
        const newRegistrationForm = Object.assign(self.registrationForm, {
          resume: "",
        });
        self.dispatch(changeRegistrationForm(newRegistrationForm));
      } else {
        switch (e.target.value.match(/\.([^\.]+)$/i)[1].toLowerCase()) {
          case "pdf":
            e.target.parentElement.classList.remove("has-error");
            this.setState({ resumeFileName: e.target.files[0].name });
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, {
                  resume: document.getElementById("uploadResume_1"),
                })
              )
            );
            break;
          case "doc":
            e.target.parentElement.classList.remove("has-error");
            this.setState({ resumeFileName: e.target.files[0].name });
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, {
                  resume: document.getElementById("uploadResume_1"),
                })
              )
            );
            break;
          case "docx":
            e.target.parentElement.classList.remove("has-error");
            this.setState({ resumeFileName: e.target.files[0].name });
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, {
                  resume: document.getElementById("uploadResume_1"),
                })
              )
            );
            break;
          default:
            e.target.parentElement.classList.add("has-error");
            this.setState({ resumeFileName: "" });
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, { resume: "" })
              )
            );
            return 0;
        }
        //checking the file size
        var input, file;

        input = document.getElementById("uploadResume_1");

        if (!input.files[0]) {
        } else {
          file = input.files[0];

          var i = Math.floor(Math.log(file.size) / Math.log(1024));
          let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
          let result = (file.size / 1024 ** i).toFixed(1);
          if (sizes[i] === "B" || sizes[i] === "KB" || sizes[i] === "MB") {
            if (sizes[i] === "MB" && result > 5) {
              e.target.parentElement.classList.add("has-error");
              this.setState({ resumeFileName: "" });
              self.dispatch(
                changeRegistrationForm(
                  Object.assign(self.registrationForm, { resume: "" })
                )
              );
            }
          } else {
            e.target.parentElement.classList.add("has-error");
            this.setState({ resumeFileName: "" });
            self.dispatch(
              changeRegistrationForm(
                Object.assign(self.registrationForm, { resume: "" })
              )
            );
          }

          // alert(re  )
        }
      }
    } else {
      existingRegisterationForm[e.target.name] =
        e.target.type === "checkbox"
          ? !JSON.parse(e.target.value)
          : e.target.value;
      self.dispatch(changeRegistrationForm(existingRegisterationForm));
    }
  }

  // called when interest is changed
  interestChange = (selectedOption) => {
    if (selectedOption.length > 0) {
      if (selectedOption.length <= 3) {
        this.setState({
          selectedInterest: selectedOption,
        });
        const newRegistrationForm = Object.assign(self.registrationForm, {
          selectedInterest: selectedOption,
        });
        self.dispatch(changeRegistrationForm(newRegistrationForm));
      }
    } else {
      // when removing the area of iterest..
      this.setState({ selectedInterest: [] });

      const newRegistrationForm = Object.assign(self.registrationForm, {
        selectedInterest: [],
      });
      self.dispatch(changeRegistrationForm(newRegistrationForm));
    }
  };

  render() {
    window.onbeforeunload = function() {
      return "Do you really want to leave our brilliant application?";
    };
    const { selectedInterest } = this.state;
    return (
      <div className="wrapper-container signing-container basic-information">
        <div className="container  user-jounrney-form-container">
          <div className="user-journey-form user-journey-form-internal-steps">
            <div className="xenonstack-jobs-logo">
              <a href={ENV_VAR}>
                {" "}
                <img src={require("../../../../../static/images/logoxe.svg")} />
              </a>
            </div>
            <div className="dashbaord-user-form">
              <div className="section_header">
                <label>Step 4/5</label>
                <h3 className="big-heading">Basic Information </h3>
              </div>
              <div className="information" id="information">
                <form
                  onSubmit={this.submitInformationForm.bind(this)}
                  className="info-form"
                  noValidate={true}
                >
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <h4 className="subtitle area-of-interest">
                          Area of Interest{" "}
                          <small>
                            {" "}
                            (You can select upto 3 area of interest)
                          </small>{" "}
                        </h4>
                        <Select
                          id="area_of_interest"
                          closeMenuOnSelect={false}
                          value={selectedInterest}
                          onChange={this.interestChange.bind(this)}
                          placeholder="Area of Interest"
                          isSearchable={true}
                          styles={colourStyles}
                          isMulti={true}
                          options={areaOfInterest}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row add-your-social-links-row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <h4 className="subtitle add-your-social-links">
                        Add Your Social Links{" "}
                        <span>
                          <small>
                            (Github account add advantage to your profile){" "}
                          </small>
                        </span>
                      </h4>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <div className="form-group">
                            <label>LinkedIn URL</label>
                            <Input
                              type="url"
                              name="linkedInLink"
                              autoComplete="off"
                              className="form-ctrl"
                              pattern={
                                "^(http(s?)://)?[a-z]{2,3}.linkedin.com/.*$"
                              }
                              placeholder="LinkedIn URL"
                              value={this.props.registrationForm.linkedInLink}
                              onChange={this.onChange.bind(this)}
                              required={false}
                            />
                            <p className="help-note">
                              For eg:
                              https://www.linkedin.com/in/hiring-job-opening-xenonstack/
                            </p>
                            <p className="with-error">
                              Please enter valid LinkedIn url.
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12">
                          <div className="form-group">
                            <label>GitHub URL</label>
                            <Input
                              type="url"
                              autoComplete="off"
                              name="githubLink"
                              className="form-ctrl"
                              placeholder="Github URL"
                              pattern={"^http(s?)://(www.)?github.com/.*$"}
                              value={this.props.registrationForm.githubLink}
                              onChange={this.onChange.bind(this)}
                              required={false}
                            />
                            <p className="help-note">
                              For eg: https://github.com/xenonstack
                            </p>
                            <p className="with-error">
                              Please enter valid GitHub Url.
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12">
                          <div className="form-group">
                            <label>Other URL</label>
                            <Input
                              type="url"
                              name="otherLink"
                              className="form-ctrl"
                              placeholder="Other URL"
                              pattern={
                                "(http|https)://((\\w)*|([0-9]*)|([-|_])*)+([\\.|/]((\\w)*|([0-9]*)|([-|_])*))+"
                              }
                              value={this.props.registrationForm.otherLink}
                              onChange={this.onChange.bind(this)}
                              required={false}
                            />

                            <p className="with-error">
                              Please enter valid Url.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 form-group ">
                      <label>
                        Upload Your Resume <span className="req">*</span>
                      </label>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <Input
                              type="file"
                              title=""
                              id="uploadResume_1"
                              name="resume"
                              pattern={"([^\\s])"}
                              accept=".doc, .docx, .pdf"
                              required={
                                this.props.registrationForm.resume === ""
                                  ? true
                                  : false
                              }
                              onChange={this.onChange.bind(this)}
                              placeholder="Resume"
                              tabIndex={-1}
                              className="form-ctrl select-fileupload"
                            />
                            <p className="with-error">
                              Please upload valid file. Supported files are doc,
                              docx, pdf and file size should be less than 5MB.
                            </p>
                            <div className="help-note">
                              <span>
                                <small>
                                  (Document format should be .pdf or .doc or .docx .)
                                </small>
                              </span>
                            </div>
                            <UploadIcon fileInoutId={"uploadResume_1"} />
                            {!!this.props.registrationForm.resume && (
                              <div className="image-preview">
                                <i>
                                  {
                                    this.props.registrationForm.resume.files[0]
                                      .name
                                  }
                                </i>{" "}
                                Uploaded
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="cstm-checkbox">
                      {" "}
                      Signup for job notification &amp; be the first to know
                      about new opportunities.
                      <Input
                        disabled={false}
                        className="exp-input"
                        type="checkbox"
                        checked={this.props.registrationForm.notify}
                        name="notify"
                        required={false}
                        tabindex={-1}
                        onChange={this.onChange.bind(this)}
                        value={this.props.registrationForm.notify}
                      />
                      <span className="checkmark" tabIndex={0}></span>
                    </label>
                  </div>
                  <NextBackSection>{this.props.children}</NextBackSection>
                </form>
              </div>
            </div>
            <div className="xenonstack-jobs-copyright">
              <p>Xenonstack | </p>
              <i class="fa fa-copyright"></i>
              <p>2021 All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    registrationForm,
    status,
    message,
    goToRegistration,
  } = state.RegisterReducer;
  return { registrationForm, status, message, goToRegistration };
}

export default withRouter(connect(mapStateToProps)(InformationSection));
