import {
    CHANGE_LOGIN_FORM, 
    CHANGE_EMAIL_REGISTER_FORM, 
    LOGIN_REQUEST,
    LOGIN_SUCCESS, 
    LOGIN_FAILURE, 
    CLEAR_API_ERROR_MESSAGE,
    LOGOUT_SUCCESS,
    LOGOUT_ACCOUNT_REQUEST,
    LOGOUT_ACCOUNT_SUCCESS, 
    LOGOUT_ACCOUNT_FAILURE,
    LOGIN_WITH_GOOGLE_REQUEST,
    LOGIN_WITH_GOOGLE_SUCCESS,
    LOGIN_WITH_GOOGLE_FAILURE,
    LOGIN_WITH_LINKEDIN_REQUEST,
    LOGIN_WITH_LINKEDIN_SUCCESS,
    LOGIN_WITH_LINKEDIN_FAILURE, 
} from '../../types/account/Login';
import {POST_WITHOUT_TOKEN} from "../../middleware/without_token/post-api-without-token";
import {GET_API} from "../../middleware/token/get-api";
import { authApi } from '../ApiActions';


const BASE_URL =authApi();

export function changeEmailRegisterForm(newState) {
    return {type: CHANGE_EMAIL_REGISTER_FORM, newState}
}

// calls action to change login form
export function changeLoginForm(newState) {
    return {type: CHANGE_LOGIN_FORM, newState}
}

// calls action to clear api error message and status
export function clearApiErrorMessage() {
    return {type: CLEAR_API_ERROR_MESSAGE }
}

// calls api to login the user/admin
export function login(body) {
    
    return {
        [POST_WITHOUT_TOKEN]:{
            endpoint:  BASE_URL + '/v1/login',
            types: [ LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
            body: JSON.stringify(body)
        }
    }
}


export function googleLogin(redirect){
    return{
        [GET_API]:{
            endpoint:BASE_URL+'/v1/google/login?redirect='+redirect,
            types:[LOGIN_WITH_GOOGLE_REQUEST,LOGIN_WITH_GOOGLE_SUCCESS,LOGIN_WITH_GOOGLE_FAILURE],
            authenticated:true

        }
    }
}


export function linkedInLogin(redirectLink){
    return{
        [GET_API]:{
            endpoint:BASE_URL+'/v1/linkedin/login?redirect='+redirectLink,
            types:[LOGIN_WITH_LINKEDIN_REQUEST,LOGIN_WITH_LINKEDIN_SUCCESS,LOGIN_WITH_LINKEDIN_FAILURE]

        }
    }
}

// calls action to logout the user
export function logout() {
    return {type: LOGOUT_SUCCESS}
}

// calls api to logout account
export function logoutAccount(){
    return {
        [GET_API]:{
            endpoint: BASE_URL+ '/v1/logout',
            types: [ LOGOUT_ACCOUNT_REQUEST, LOGOUT_ACCOUNT_SUCCESS, LOGOUT_ACCOUNT_FAILURE ]
        }
    }
}

// calls action when login success
export function loginSuccess(response) {
    return {type: LOGIN_SUCCESS, response}
}

//