import {
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
    DELETE_NOTIFICATION_REQUEST,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAILURE,
    DELETE_SINGLE_NOTIFICATION_REQUEST, 
    DELETE_SINGLE_NOTIFICATION_SUCCESS, 
    DELETE_SINGLE_NOTIFICATION_FAILURE
} from "../../../../types/dashboard/admin/notification/notification";
import { CALL_DELETE_API } from '../../../../middleware/token/delete/without-body';
import {GET_API} from "../../../../middleware/token/get-api";
import { authApi } from '../../../ApiActions';

const BASE_URL =authApi();

export function getNotification(){
    return{
        [GET_API]:{
            endpoint:BASE_URL+'/v1/notification',
            types:[GET_NOTIFICATION_REQUEST,GET_NOTIFICATION_SUCCESS,GET_NOTIFICATION_FAILURE]
        }
    }
}

export function deleteAllNotification(){
    return{
        [CALL_DELETE_API]:{
            endpoint:BASE_URL+'/v1/notification',
            types:[DELETE_NOTIFICATION_REQUEST,DELETE_NOTIFICATION_SUCCESS,DELETE_NOTIFICATION_FAILURE]

        }
    }
}

export function deleteSingleNotification(id){
    return{
        [CALL_DELETE_API]:{
            endpoint:BASE_URL+'/v1/notification/'+id,
            types:[DELETE_SINGLE_NOTIFICATION_REQUEST,DELETE_SINGLE_NOTIFICATION_SUCCESS,DELETE_SINGLE_NOTIFICATION_FAILURE]
        }
    }
}