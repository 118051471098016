import React from 'react'

export class PageSizeChooser extends React.Component {
  state = {
    pageSizeOptions: this.props.pageSizeOptions
  }

  static defaultProps = {
    pageSizeOptions: []
  }

  render() {
    const { pageSizeOptions } = this.state
    const { pageSize, handlePageSizeChange } = this.props
    return (
      <div onBlur={e => this.setState(state => ({  }))} className="pagesizer-outer">
          <select className="table-select" onChange={handlePageSizeChange}>
            {pageSizeOptions.map(option => (
                <option selected={option === pageSize && "selected"}
                  value={option}
                  key={option}
                >
                  {option}
                </option>
            ))}
          </select>
        </div>
    )
  }
}
